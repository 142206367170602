import { FormControl, InputLabel, Select } from '@material-ui/core';
import React, { useContext, useReducer } from 'react';
import styled from 'styled-components';
import Tooltip from '@mui/material/Tooltip';
import docIcon from '../../../../assets/docx_ico.png';
import pdfIcon from '../../../../assets/pdf_ico.png';
import { LeftBlock, MainDiv, RightBlock } from './Styled';
// import DocAnalysisContext from '../../../DocAnalysisContext';
// import pdfFilereducers, { pdfInitialState } from '../../../../../Reducers/pdfFileReducers';

const PdfFileSetting = (props) => {
  const { FileName, Blob, Container, setPayload, FileType, id } = props;
  const [pdfdata, setPdfdata] = React.useState({
    ...props,
  });

  return (
    <>
      <MainDiv>
        <LeftBlock>
          <FormControl variant="outlined" fullWidth>
            <span style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
              {pdfdata?.FileType === 'unsupported' ? (
                <img src={docIcon} alt="isens" height="30" />
              ) : (
                ''
              )}
              &nbsp;
              {pdfdata?.FileType === 'PDF' ? (
                <img src={pdfIcon} alt="isens" height="30" width="30" />
              ) : (
                ''
              )}
              {pdfdata?.FileType !== 'unsupported' ? (
                <span>
                  <span style={{ color: 'red' }}>{pdfdata?.Error}</span>
                </span>
              ) : (
                ''
              )}
              <Tooltip title={pdfdata?.Blob} placement="top" arrow>
                <b style={{ color: 'blue', wordWrap: 'break-word' }}>
                  {pdfdata?.Blob.substring(0, 25)}
                </b>
              </Tooltip>
            </span>
          </FormControl>
        </LeftBlock>
        <RightBlock>{}</RightBlock>
      </MainDiv>
    </>
  );
};

export default PdfFileSetting;
