import React, { useContext } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import useProjectId from '../../app/useProjectId';
import ProjectContext from '../Project/ProjectContext';
import Module4 from './Module4';
import Module4Context, { Module4ContextProvider } from './Module4Context';

function Module4Router() {
  const { projectId } = useParams() as any;
  const { projects } = useContext(ProjectContext);

  const { project } = useProjectId(projectId, projects);

  if (project === undefined) return null;

  return (
    <Module4ContextProvider project={project}>
      <Module4Context.Consumer>
        {(): any => {
          return (
            <Switch>
              <Route
                path="/project/:projectId/module4/:moduleroute/itracer"
                render={() => {
                  return <Module4 />;
                }}
              />
              <Route
                path="/project/:projectId/module4/:moduleroute"
                render={() => {
                  return <Module4 />;
                }}
              />
            </Switch>
          );
        }}
      </Module4Context.Consumer>
    </Module4ContextProvider>
  );
}

export default Module4Router;
