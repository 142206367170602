import {
  AppBar,
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ProjectContext from '../ProjectContext';
import useProjectId from '../../../app/useProjectId';
import { searchUsers } from '../../../api/users';
import { statsRequirements } from '../../../api/projects';
import ProjectAnalysis from './components/ProjectKPI/ProjectAnalysis';
import NonConformity from './components/ProjectKPI/NonConformity';
import Gap from './components/ProjectKPI/Gap';
import { workloadGetAll } from '../../../api/workloads';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  content: {
    width: '100%',
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    boxShadow: '0px 16px 32px #00000029',
    '&[aria-selected="true"][aria-expanded="true"]>div:nth-of-type(1)': {
      backgroundColor: 'red',
    },
  },
  select: {
    '& > *': { fontWeight: '600 !important', fontSize: theme.typography.body1 },
  },
  formControl: {
    margin: theme.spacing(2),
    backgroundColor: '#FFFFFF',
    width: '95%',
  },
}));
type KpiId = {
  workloadId: any;
  filename: any;
  setting?: any;
};

function ProjectKPI(props) {
  const { selectedOption } = props;
  const { projectId } = useParams() as any;
  const { projects_lite } = useContext(ProjectContext);
  const { project } = useProjectId(projectId, projects_lite);
  const [datatype, setDatatype] = useState<'nc' | 'gap'>('nc');
  const [loading, setLoading] = useState<boolean>(false);
  const [tab, setTab] = useState<any>(0); // 0 = nc
  const [kpiIds, setKpiIds] = useState<KpiId[] | undefined>(undefined);
  const [kpiSelection, setKpiSelection] = useState<KpiId | undefined>(undefined);
  const [kpiUser, setKpiUser] = useState<undefined>(undefined);
  const [projectUsers, setProjectUsers] = useState<any[]>([]);

  useEffect(() => {
    setLoading(true);
    searchUsers({ project_id: projectId }).then((dbusers: any[]) => {
      const myusers = dbusers.map((userdt) => {
        return {
          ...userdt.user,
          role_id: userdt.user_project.role_id,
        };
      });
      setProjectUsers(myusers);
    });
    statsRequirements(projectId)
      .then((resStats) => {
        workloadGetAll(projectId).then((resWorkload) => {
          let kpiList = [] as KpiId[];
          resWorkload
            .filter((r) => {
              return r.app === 'module1';
            })
            .forEach((r) => {
              kpiList = kpiList.concat(
                r.resources.map((resource) => {
                  const resStat = resStats.find(
                    (stats) =>
                      stats.filename === resource.filename &&
                      Number(stats.workload_id) === Number(r.id)
                  );
                  return {
                    workloadId: r.id,
                    filename: resource.filename,
                    settings: resource.settings,
                    count: resStat?.count || 0,
                  };
                })
              );
            });
          setKpiIds(kpiList);
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [projectId]);

  const renderComponent = () => {
    switch (selectedOption) {
      case 'Analysis': {
        return (
          <>
            <ProjectAnalysis
              kpiIds={kpiIds}
              kpiSelection={kpiSelection}
              kpiUser={kpiUser}
              projectUsers={projectUsers}
              setKpiIds={setKpiIds}
              setKpiSelection={setKpiSelection}
              setKpiUser={setKpiUser}
            />
          </>
        );
      }
      case 'Non-Conformity': {
        return (
          <>
            <NonConformity datatype={datatype} />
          </>
        );
      }
      case 'Gap': {
        return (
          <>
            <Gap datatype={datatype} />
          </>
        );
      }

      default: {
        return <></>;
      }
    }
  };

  return <>{renderComponent()}</>;
}

export default ProjectKPI;
