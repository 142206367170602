import React, { useContext, useMemo } from 'react';
import { RexFiltersNew } from '../../../api/module2';

const Rex360Filters = ({ rexFilter, dispatch, callbackFilters }) => {
  if (!rexFilter) {
    return { options: [] };
  }
  const {
    rexPaltform,
    rexSource,
    rexStatus,
    rexTypology,
    rexCountry,
    rexProductLine,
    rexProduct,
    rexSolution,
    rexIssuerSite,
    rexProject,
    rexSelectedPlatform,
    rexSelectedStatus,
    rexSelectedIssuerSite,
    rexSelectedTypology,
    rexSelectedSource,
    rexSelectedSolution,
    rexSelectedProduct,
    rexSelectedProject,
    rexSelectedCountry,
    rexSelectedProductLine,
  } = rexFilter;

  const multifilterCallback = (payload, key) => {
    const {
      IssuerVal,
      rexSourcesVal,
      statusVal,
      typoVal,
      entityVal,
      platformVal,
      solutionVal,
      productVal,
      projectVal,
      countryVal,
    } = payload;
    RexFiltersNew(
      IssuerVal,
      rexSourcesVal,
      statusVal,
      typoVal,
      entityVal,
      platformVal,
      solutionVal,
      productVal,
      projectVal,
      countryVal
    ).then((res: any) => {
      if (res?.filters) {
        const tepm = {
          ...res.filters,
        };

        delete tepm[key];
        callbackFilters(tepm);
      }
    });
  };

  const temp = [
    {
      name: 'Product Line',
      key: 'productLines',
      check: 'false',
      collapsed: false,
      checkAllOption: false,
      options: rexProductLine,
      _cache: {},
      onChangeOption: (option) => {
        dispatch({ type: 'change_productLine', payload: option });
        const payload = {
          IssuerVal: rexSelectedIssuerSite,
          rexSourcesVal: rexSelectedSource,
          statusVal: rexSelectedStatus,
          typoVal: rexSelectedTypology,
          entityVal: option,
          platformVal: rexSelectedPlatform,
          solutionVal: rexSelectedSolution,
          productVal: rexSelectedProduct,
          projectVal: rexSelectedProject,
          countryVal: rexSelectedCountry,
        };
        multifilterCallback(payload, 'productLines');
      },
    },
    {
      name: 'Platform',
      check: 'false',
      key: 'platforms',
      collapsed: false,
      checkAllOption: false,
      options: rexPaltform,
      _cache: {},
      onChangeOption: (option) => {
        dispatch({ type: 'change_platform', payload: option });
        const payload = {
          IssuerVal: rexSelectedIssuerSite,
          rexSourcesVal: rexSelectedSource,
          statusVal: rexSelectedStatus,
          typoVal: rexSelectedTypology,
          entityVal: rexSelectedProductLine,
          platformVal: option,
          solutionVal: rexSelectedSolution,
          productVal: rexSelectedProduct,
          projectVal: rexSelectedProject,
          countryVal: rexSelectedCountry,
        };
        multifilterCallback(payload, 'platforms');
      },
    },
    {
      name: 'Solution',
      key: 'solutions',
      check: 'false',
      collapsed: false,
      checkAllOption: false,
      options: rexSolution,
      _cache: {},
      onChangeOption: (option) => {
        dispatch({ type: 'change_solution', payload: option });
        const payload = {
          IssuerVal: rexSelectedIssuerSite,
          rexSourcesVal: rexSelectedSource,
          statusVal: rexSelectedStatus,
          typoVal: rexSelectedTypology,
          entityVal: rexSelectedProductLine,
          platformVal: rexSelectedPlatform,
          solutionVal: option,
          productVal: rexSelectedProduct,
          projectVal: rexSelectedProject,
          countryVal: rexSelectedCountry,
        };
        multifilterCallback(payload, 'solutions');
      },
    },
    {
      name: 'Product',
      key: 'products',
      check: 'false',
      collapsed: false,
      checkAllOption: false,
      options: rexProduct,
      _cache: {},
      onChangeOption: (option) => {
        dispatch({ type: 'change_product', payload: option });
        const payload = {
          IssuerVal: rexSelectedIssuerSite,
          rexSourcesVal: rexSelectedSource,
          statusVal: rexSelectedStatus,
          typoVal: rexSelectedTypology,
          entityVal: rexSelectedProductLine,
          platformVal: rexSelectedPlatform,
          solutionVal: rexSelectedSolution,
          productVal: option,
          projectVal: rexSelectedProject,
          countryVal: rexSelectedCountry,
        };
        multifilterCallback(payload, 'products');
      },
    },
    {
      name: 'Project',
      key: 'projects',
      check: 'false',
      collapsed: false,
      checkAllOption: false,
      options: rexProject,
      _cache: {},
      onChangeOption: (option) => {
        dispatch({ type: 'change_project', payload: option });
        const payload = {
          IssuerVal: rexSelectedIssuerSite,
          rexSourcesVal: rexSelectedSource,
          statusVal: rexSelectedStatus,
          typoVal: rexSelectedTypology,
          entityVal: rexSelectedProductLine,
          platformVal: rexSelectedPlatform,
          solutionVal: rexSelectedSolution,
          productVal: rexSelectedProduct,
          projectVal: option,
          countryVal: rexSelectedCountry,
        };
        multifilterCallback(payload, 'projects');
      },
    },
    {
      name: 'Country',
      key: 'countries',
      check: 'false',
      collapsed: false,
      checkAllOption: false,
      options: rexCountry,
      _cache: {},
      onChangeOption: (option) => {
        dispatch({ type: 'change_country', payload: option });
        const payload = {
          IssuerVal: rexSelectedIssuerSite,
          rexSourcesVal: rexSelectedSource,
          statusVal: rexSelectedStatus,
          typoVal: rexSelectedTypology,
          entityVal: rexSelectedProductLine,
          platformVal: rexSelectedPlatform,
          solutionVal: rexSelectedSolution,
          productVal: rexSelectedProduct,
          projectVal: rexSelectedProject,
          countryVal: option,
        };
        multifilterCallback(payload, 'countries');
      },
    },
    {
      name: 'Issuer Site',
      key: 'sites',
      check: 'false',
      collapsed: false,
      checkAllOption: false,
      options: rexIssuerSite,
      _cache: {},
      onChangeOption: (option) => {
        dispatch({ type: 'change_source', payload: option });
        const payload = {
          IssuerVal: option,
          rexSourcesVal: rexSelectedSource,
          statusVal: rexSelectedStatus,
          typoVal: rexSelectedTypology,
          entityVal: rexSelectedProductLine,
          platformVal: rexSelectedPlatform,
          solutionVal: rexSelectedSolution,
          productVal: rexSelectedProduct,
          projectVal: rexSelectedProject,
          countryVal: rexSelectedCountry,
        };
        multifilterCallback(payload, 'sites');
      },
    },
    {
      name: 'Source',
      key: 'sources',
      check: 'false',
      collapsed: false,
      checkAllOption: false,
      options: rexSource,
      _cache: {},
      onChangeOption: (option) => {
        dispatch({ type: 'change_source', payload: option });
        const payload = {
          IssuerVal: rexSelectedIssuerSite,
          rexSourcesVal: option,
          statusVal: rexSelectedStatus,
          typoVal: rexSelectedTypology,
          entityVal: rexSelectedProductLine,
          platformVal: rexSelectedPlatform,
          solutionVal: rexSelectedSolution,
          productVal: rexSelectedProduct,
          projectVal: rexSelectedProject,
          countryVal: rexSelectedCountry,
        };
        multifilterCallback(payload, 'sources');
      },
    },
    {
      name: 'Status',
      key: 'statuses',
      check: 'false',
      collapsed: false,
      checkAllOption: false,
      options: rexStatus,
      _cache: {},
      onChangeOption: (option) => {
        dispatch({ type: 'change_status', payload: option });
        const payload = {
          IssuerVal: rexSelectedIssuerSite,
          rexSourcesVal: rexSelectedSource,
          statusVal: option,
          typoVal: rexSelectedTypology,
          entityVal: rexSelectedProductLine,
          platformVal: rexSelectedPlatform,
          solutionVal: rexSelectedSolution,
          productVal: rexSelectedProduct,
          projectVal: rexSelectedProject,
          countryVal: rexSelectedCountry,
        };
        multifilterCallback(payload, 'statuses');
      },
    },
    {
      name: 'Typology',
      key: 'typologies',
      check: 'false',
      collapsed: false,
      checkAllOption: false,
      options: rexTypology,
      _cache: {},
      onChangeOption: (option) => {
        dispatch({ type: 'change_typology', payload: option });
        const payload = {
          IssuerVal: rexSelectedIssuerSite,
          rexSourcesVal: rexSelectedSource,
          statusVal: rexSelectedStatus,
          typoVal: option,
          entityVal: rexSelectedProductLine,
          platformVal: rexSelectedPlatform,
          solutionVal: rexSelectedSolution,
          productVal: rexSelectedProduct,
          projectVal: rexSelectedProject,
          countryVal: rexSelectedCountry,
        };
        multifilterCallback(payload, 'typologies');
      },
    },
  ];

  return { options: temp };
};

export default Rex360Filters;
