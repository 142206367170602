import React, { useMemo } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import RightPanelLayout from './RightPanelLayout';

const InformationPanel = (props) => {
  const { data, isOpen, setSideBar } = props;
  const renderData = useMemo(() => {
    return {
      name: 'Information',
      renderCardIcon: () => {
        return <InfoIcon />;
      },
      cardData: data.map((data) => {
        return {
          content: [
            {
              label: ' Info:',
              value: data.label,
            },
          ],
          url: '',
        };
      }),
      isOpen,
      setSideBar,
      isActionRequired: false,
    };
  }, [data, isOpen]);

  return <RightPanelLayout pannelLayout={renderData} />;
};

export default InformationPanel;
