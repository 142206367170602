import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import ExpandContainer from '../../HOC/ExpandComponent';
import ProjectContext from '../../Project/ProjectContext';
import IsensChatNew from '../../Module2/NewModule/GPT2/ChatContainer';
import { M2GlobalContextProvider } from '../../ContextMager/M2GlobalContext';

const GenAHome = () => {
  const [isChatExpand, setIsChatExpand] = useState(true);

  //   console.log('gdsgfdsg genA');

  return (
    <M2GlobalContextProvider>
      <IsensChatNew
        action={['private', 'public']}
        isChatExpandable={isChatExpand}
        setIsChatExpand={setIsChatExpand}
      />
    </M2GlobalContextProvider>
  );
};

export default GenAHome;
