/**
 * This component renders a Wecome HomePage with leftNavigation.
 * Css also fixed here
 * @param {string} No props
 * implemented switch button for auto hide of  leftNavigation
 * @returns {ReactNode} A React element that leftNavigation.
 *  Copyright @ Alstom 2023
 *  auther @Ranjan
 */
import {
  Box,
  Button,
  CssBaseline,
  Divider,
  Drawer,
  Grid,
  IconButton,
  ClickAwayListener,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  AppBar,
  Avatar,
  Switch,
  ThemeProvider,
  Toolbar,
  Tooltip,
} from '@material-ui/core';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { Menu as MenuIcon } from '@material-ui/icons';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import GroupsIcon from '@mui/icons-material/Groups';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import InboxIcon from '@mui/icons-material/Inbox';
import { makeStyles } from '@material-ui/core/styles';
import NotesOutlinedIcon from '@mui/icons-material/NotesOutlined';
import WorkIcon from '@mui/icons-material/Work';
// import { AppBar, Avatar, Switch, ThemeProvider, Toolbar, Tooltip } from '@mui/material';
import clsx from 'clsx';
import React, { memo, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Route, RouteProps, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import _debounce from 'lodash/debounce';
import { AppJwtType } from 'Auth';
import { isensTheme, useStyles, drawerWidth } from '../app/isensTheme';
import useAppToken from '../app/useAppToken';
import community from '../assets/community.png';
import Adem from '../assets/AdemIcon.png';
import logoIcons from '../assets/logo-icon.png';
import alstom_whiteLogo from '../assets/alstom_whiteLogo.png';
import AppTopBar from '../components/Layout/AppTopBar';
import ProjectTreeView from '../containers/Project/components/ProjectTreeView';
import ProjectContext from '../containers/Project/ProjectContext';
import { HomeuseStyles } from './HomeLayoutTheme';
import { ENV } from '../const';
import UserProfile from '../containers/User/components/UserProfile';
import { M2GlobalContextProvider } from '../containers/ContextMager/M2GlobalContext';
import SideBar from './SideBar';
import theme from '../containers/Home/homeTheme';
import AppTopMenu from './AppTopMenu';
import HomeContainer from '../containers/HOC/FilterComponent';
import SpeedMenu from './SpeedMenu';

const BoxStyled = styled(Box)`
  width: 100%;
  /* padding: 0.5rem; */
  height: calc(100% - 55px);
  overflow: auto;
  max-height: calc(100vh - 55px);
  /* max-height: 100vh; */
`;

const useStylesList = makeStyles((theme) => ({
  listViewBottom: {
    width: '100%',
    flexGrow: 1,
    bottom: 0,
    position: 'fixed',
    maxWidth: drawerWidth,
    '& .Mui-selected': {
      background: '#DC3223 0% 0% no-repeat padding-box',
      border: '1px solid #002A45',
      color: 'white !important',
      // color: 'white',
      fontWeight: 'bold',
      '& .MuiListItemIcon-root': {
        color: 'white',
      },
      '& .MuiListItemText-root': {
        color: 'white !important',
        fontWeight: 'bold',
      },
    },
    '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
      background: '#DC3223 0% 0% no-repeat padding-box',
      // border: '1px solid black',
      borderLeftWidth: 12,
      borderColor: '#002A45',
      // borderSpacing: '15px',
    },
    '& .MuiListItem-root': {
      background: '#EEF1F9 0% 0% no-repeat padding-box',
      boxShadow: '0px 3px 6px #00000029',
      borderBottom: '1px solid #C4D4EC',
    },
  },
  ListItemInside: {
    // '& .MuiListItem-button:hover': {
    //   background: 'red',
    // },
    fontSize: 16,
    padding: '13px 108px 13px 13px',
    '& .MuiListItemIcon-root': {
      color: '#002A45',
      minWidth: '38px',
    },
    '& .MuiListItemText-root': {
      color: '#002A45 !important',
      fontWeight: 'bold',
    },
  },
  ListSubMain: {
    // width: '100%',
    // flexGrow: 1,
    // bottom: 0,
    // position: 'fixed',
    // maxWidth: drawerWidth,
    '& .Mui-selected': {
      background: '#DC3223 0% 0% no-repeat padding-box',
      border: '1px solid #002A45',
      color: 'white !important',
      // color: 'white',
      fontWeight: 'bold',
      '& .MuiListItemIcon-root': {
        color: 'white',
      },
      '& .MuiListItemText-root': {
        color: 'white !important',
        fontWeight: 'bold',
      },
    },
    '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
      background: '#DC3223 0% 0% no-repeat padding-box',
      // border: '1px solid black',
      borderLeftWidth: 12,
      borderColor: '#002A45',
      // borderSpacing: '15px',
    },
    '& .MuiListItem-root': {
      background: '#EEF1F9 0% 0% no-repeat padding-box',
      boxShadow: '0px 3px 6px #00000029',
      borderBottom: '1px solid #C4D4EC',
    },
  },
  ListsubItemInside: {
    // '& .MuiListItem-button:hover': {
    //   background: 'red',
    // },
    // paddingLeft: theme.spacing(4),
    fontSize: 16,
    '& .MuiListItemIcon-root': {
      color: '#002A45',
    },
    '& .MuiListItemText-root': {
      color: '#002A45 !important',
      fontWeight: 'bold',
    },
  },
  avatarColor: {
    '& .MuiAvatar-root': {
      color: 'white',
    },
  },
  switchContainer: {
    '& .css-1itu97k-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
      backgroundColor: 'red',
      opacity: 1,
    },
    '& .css-1itu97k-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked': {
      opacity: 1,
      color: 'white',
    },
    '& .slider.checked': {
      backgroundColor: 'red',
    },
  },
}));
const Main = memo(({ Component, props, setSideBar, sideBar, setModalUserOpen }) => {
  const classes = useStyles();

  return (
    <>
      <main className={classes.content}>
        <M2GlobalContextProvider>
          <MuiThemeProvider theme={theme}>
            <AppTopMenu setModalUserOpen={setModalUserOpen} />
          </MuiThemeProvider>
          <BoxStyled>
            <Component {...props} />
          </BoxStyled>
        </M2GlobalContextProvider>
      </main>
    </>
  );
});
const HomeLayout = ({ component: Component, ...rest }: RouteProps & any): any => {
  const classes = useStyles();
  const userSession = JSON.parse(localStorage.getItem('session') as any);
  const HomeLayoutClasses = HomeuseStyles();
  const { appJWT }: { appJWT: AppJwtType } = useAppToken();
  const classesList = useStylesList();
  const [selected, setSelected] = React.useState();
  const [tabWork, setTabWork] = React.useState(true);
  const [open, setOpen] = React.useState(userSession?.leftBarStatus ?? true);
  const [modes, setModes] = React.useState('');
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [sam, setSam] = React.useState(false);
  const [modalUserOpen, setModalUserOpen] = React.useState(false);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [activeHide, setActiveHide] = React.useState(true);
  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const queryParams = window.location.pathname;
  const getLastPath = queryParams.split('/').pop();

  useEffect(() => {
    if (modes === 'mode1' && sam) {
      setSam((prev) => !prev);
    } else if (modes === 'mode2' && !sam) {
      setSam((prev) => !prev);
    }
  }, [modes]);

  const handleDrawerOpen = () => {
    localStorage.setItem('session', JSON.stringify({ ...userSession, leftBarStatus: true }));
    setOpen(true);
  };
  const handleDrawerClose = () => {
    localStorage.setItem('session', JSON.stringify({ ...userSession, leftBarStatus: false }));
    setOpen(false);
  };
  const history = useHistory();
  function buttonClickCommunity() {
    window.open('https://alstomgroup.sharepoint.com/sites/iSenS_Communication');
  }
  const handleModalProfileShut = (e) => {
    setAnchorE2(null);
    setModalUserOpen(false);
  };
  // handling auto hide
  const handleAutoHide = () => {
    setActiveHide(!activeHide);
  };
  // const handleAutoHideClose = () => {
  //   setActiveHide(true);
  // };
  // console.log('label', activeHide);

  // console.log('Home layout render', Component);

  return (
    <Route
      {...rest}
      render={(props: any): any => (
        <ProjectContext.Consumer>
          {({ projects_lite }) => (
            <>
              <Main Component={Component} setModalUserOpen={setModalUserOpen} props={props} />
              {/* <Fab
                color="primary"
                aria-label="add"
                sx={{
                  position: 'absolute',
                  bottom: 16,
                  right: 16,
                }}
              >
                <AddIcon />
              </Fab> */}
              {/* <SpeedMenu /> */}
              {/* <RightProfile open={modalUserOpen} setOpen={handleModalProfileShut} /> */}
              <UserProfile
                open=""
                onConfirm={handleModalProfileShut}
                onCancel={handleModalProfileShut}
                label="ProjectDelete"
              />
            </>
          )}
        </ProjectContext.Consumer>
      )}
    />
  );
};

export default memo(HomeLayout);
