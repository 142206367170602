import { Card, CardContent, CardMedia, Divider, makeStyles, Typography } from '@material-ui/core';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import { styled } from '@mui/material/styles';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import FingerprintOutlinedIcon from '@mui/icons-material/FingerprintOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import React from 'react';
import { Link as LinkTo, useHistory } from 'react-router-dom';
import logobackground from '../../../assets/card_icons.png';
import { Project } from '../../../react-app-env';
import formatDate, { formatLocal } from '../../../tools/formatDate';

const MuiCard = styled((props) => <Card {...props} />)(({ theme }) => ({
  padding: '5px 15px',
  borderLeft: '11px solid #DC3223 !important',
  borderRight: '1px solid gray !important',
  borderTop: '1px solid gray !important',
  borderBottom: '1px solid gray !important',
  borderColor: theme.palette.primary.light,
  borderRadius: 5,
  margin: 5,
  color: '#EEF1F9',
  width: '20%',
  height: '60%',
  opacity: 1,
  background:
    'transparent linear-gradient(128deg, #002A45 0%, #495866 100%) 0% 0% no-repeat padding-box',
  transition:
    '.7s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12)',
  cursor: 'pointer',
  '&:hover': {
    color: '#EEF1F9',
    //   border: 'none',
    transform: 'scale(1.05)',
    zIndex: 4,
    transition: 'all 0.4s ease-out',
    // transform: 'scale(1.05)',
    // boxShadow: '0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06)',
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '5px 15px',
    borderLeft: '11px solid #DC3223 !important',
    borderRight: '1px solid gray !important',
    borderTop: '1px solid gray !important',
    borderBottom: '1px solid gray !important',
    borderColor: theme.palette.primary.light,
    borderRadius: 5,
    margin: 5,
    width: '300px',
    opacity: 1,
    background:
      'transparent linear-gradient(128deg, #002A45 0%, #495866 100%) 0% 0% no-repeat padding-box',
    transition:
      '.7s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12)',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: '0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06)',
    },
  },
  link: {
    textDecoration: 'none',
    color: '#FFFFFF',
  },
  body: {
    color: '#FFFFFF',
  },
  typographyText: {
    color: '#788291',
    paddingLeft: 5,
    paddingRight: 5,
    font: 'normal normal normal 20px/26px Alstom',
  },
  typographyIcons: {
    color: '#788291',
    marginBottom: -5,
  },
  footer: {
    background: '#003E5C 0% 0% no-repeat padding-box',
    width: '342px',
    marginLeft: -20,
    paddingLeft: 20,
    marginBottom: -5,
    color: '#f2f3f5',
    opacity: 0.5,
  },
  roundNew: {
    border: '1px solid white',
    borderRadius: '100%',
    padding: '2px',
    background: 'white',
    color: '#002A45',
    marginRight: 5,
  },
}));
const ProjectCard = ({ project }: { project: Project }) => {
  const classes = useStyles();
  const history = useHistory();

  const { id, entity, name, users, folders, is_public } = project;
  return (
    <>
      <MuiCard
        onClick={() => {
          history.push(`/home/${id}/modules`);
        }}
      >
        <CardMedia style={{ textAlign: 'left', paddingTop: 17 }}>
          <LinkTo to={`/projects/${id}`} className={classes.link} title="Activity">
            <PlaylistAddCheckOutlinedIcon className={classes.roundNew} />
          </LinkTo>
          {/* <LinkTo to={`/projects/${id}/manage/users`} className={classes.link} title="Users">
            <PersonRoundedIcon />
          </LinkTo> */}
          <LinkTo
            to={`/projects/${id}/manage/config`}
            className={classes.link}
            title="Configuration"
          >
            <SettingsOutlinedIcon className={classes.roundNew} />
          </LinkTo>
          <LinkTo to={`/projects/${id}/manage/analysis`} className={classes.link} title="Analysis">
            <AssessmentOutlinedIcon className={classes.roundNew} />
          </LinkTo>
          <LinkTo
            to={`/projects/${id}/manage/requirements`}
            className={classes.link}
            title="Requirements"
          >
            <FormatListBulletedRoundedIcon className={classes.roundNew} />
          </LinkTo>
        </CardMedia>
        <LinkTo to={`/projects/${id}`} className={classes.link}>
          <CardContent style={{ textAlign: 'left', padding: 5, position: 'relative' }}>
            <img
              src={logobackground}
              alt=""
              style={{
                position: 'absolute',
                top: 15,
                left: 182,
                paddingLeft: 'inherit',
                paddingRight: 'inherit',
                objectFit: 'contain',
                objectPosition: '80% 100%',
                width: 77,
              }}
              width="100px"
              height="100px"
            />

            <Typography variant="subtitle1" className={classes.body}>
              {name.slice(0, 20)}
            </Typography>

            <Typography variant="subtitle2">
              <span>
                <FingerprintOutlinedIcon className={classes.typographyIcons} />
              </span>
              <span className={classes.typographyText}>Entity</span>{' '}
              <span className={classes.body} style={{ marginLeft: 19 }}>
                : {entity}
              </span>
            </Typography>
            <Typography variant="subtitle2">
              <span>
                <AssignmentIndOutlinedIcon className={classes.typographyIcons} />
              </span>
              <span className={classes.typographyText}>Users</span>{' '}
              <span className={classes.body} style={{ marginLeft: 22 }}>
                : {users?.length ? users.length : 0}
              </span>
            </Typography>
            <Typography variant="subtitle2">
              <span>
                <FolderOpenOutlinedIcon className={classes.typographyIcons} />
              </span>
              <span className={classes.typographyText}>Folders</span>{' '}
              <span className={classes.body} style={{ marginLeft: 10 }}>
                : {folders?.length ? folders.length : 0}
              </span>
            </Typography>
            <Typography variant="subtitle2">
              <span>
                <DescriptionOutlinedIcon className={classes.typographyIcons} />
              </span>
              <span className={classes.typographyText}>Files</span>{' '}
              <span className={classes.body} style={{ marginLeft: 30 }}>
                :{' '}
                {folders?.reduce((acc, el) => {
                  return acc + (el.resources?.length || 0);
                }, 0)}
              </span>
            </Typography>
          </CardContent>
        </LinkTo>
        <Divider />
        {is_public ? (
          <div>
            <Typography variant="overline" style={{ fontWeight: 'bold' }}>
              public
            </Typography>
          </div>
        ) : (
          <div className={classes.footer}>
            <Typography variant="overline">
              Created on <span> {formatDate(project.created_at, formatLocal('fr'))}</span>
            </Typography>
          </div>
        )}
      </MuiCard>
    </>
  );
};

export default ProjectCard;
