/* eslint-disable react/no-unescaped-entities */
import React, { useCallback, useContext, useMemo, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  ListItem,
  List,
  Button,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SelectMUI from '../../HOC/core/SelectMUI';
import HomeContext from '../../ContextMager/HomeContext';
import useTransation from '../Hooks/useTransation';
import { deepl_language } from '../../../const';

const TranslatorFeild = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  color: theme.palette.primary.main,
  gap: '10px',
  height: '20rem',
  transition: 'display 0.3s ease-out',
  '&.hide': {
    display: 'none',
  },
}));

const SelectorContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  width: '100%',
  height: '3rem',
  display: 'flex',
  // backgroundColor: '#fafafa',
  position: 'relative',
  transition: 'all 0.3s ease-out',
  //   border: '1px solid #ccd6f2',
}));

const Header = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  height: '100%',
  width: 'calc(100% / 2 - 20px)',
  // cursor: 'pointer',
  backgroundColor: '#fafafa',
  // border: '1px solid #998760CE',
  // transition: 'all 0.3s ease-out',
  // '&:hover': {
  //   color: '#EEF1F9',
  //   //   border: 'none',
  //   transform: 'scale(1.0)',
  //   zIndex: 4,
  //   transition: 'border 0.1s ease-out',
  //   border: '1px solid #002a45',
  //   // transform: 'scale(1.05)',
  //   // boxShadow: '0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06)',
  // },
}));

const Selector = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'inline-block',
  border: '1px solid #998760CE',
  transition: 'all 0.3s ease-out',
  borderRadius: '8px',
  height: '100%',
  position: 'relative',
  cursor: 'pointer',
  '&:hover': {
    // color: '#EEF1F9',
    //   border: 'none',
    transform: 'scale(1.0)',
    zIndex: 4,
    transition: 'border 0.1s ease-out',
    border: '1px solid #002a45',
    // transform: 'scale(1.05)',
    // boxShadow: '0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06)',
  },
}));

const LangDropDown = styled((props) => <Box {...props} />)(({ theme, isVisible }) => ({
  position: 'absolute',
  width: '100%',
  transformOrigin: 'top left',
  height: '10rem',
  backgroundColor: '#fafafa',
  zIndex: 1000,
  border: '1px solid #998760CE',
  opacity: isVisible ? 1 : 0,
  visibility: isVisible ? 'visible' : 'hidden',
  transition: 'opacity 0.3s ease, visibility 0.3s ease',
  pointerEvents: isVisible ? 'auto' : 'none', // Ensure dropdown can receive mouse events when visible
}));

const TranslatorResult = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  transition: 'all 0.3s ease-out',
  height: 'calc(100% - 3rem)',
  gap: '44px',
  padding: '4px',
}));

const TranslatorInputFeild = styled((props) => <textarea {...props} />)(({ theme }) => ({
  display: 'flex',
  height: '100%',
  width: '50%',
  alignItems: 'flex-start',
  fontFamily: 'Roboto Condensed, serif',
  transition: 'all 0.3s ease-out',
  //   border: '1px solid #ccd6f2',
  color: '#002a45',
  fontSize: '22px',
  outline: 'none',
  border: 'none',
  resize: 'none',
  backgroundColor: 'transparent',
  '&::placeholder': {
    fontSize: '22px',
    color: 'rgba(128, 128, 128, 0.4)',
  },
}));

const TranslatorResultFeild = styled((props) => <textarea {...props} />)(({ theme }) => ({
  flexBasis: '50%',
  height: '100%',
  resize: 'none',
  outline: 'none',
  color: '#002a45',
  fontSize: '22px',
  transition: 'all 0.3s ease-out',
  backgroundColor: '#fafafa',
  border: '1px solid #998760CE',
  borderRadius: '8px',
}));

const Label = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  height: '100%',
  justifyContent: 'space-between',
  width: '100%',
  transition: 'all 0.3s ease-out',
  padding: '0.3rem 0.3rem 0.3rem 0.9rem',
  alignItems: 'center',
  color: '#002a45',
  fontWeight: 800,
}));

const Translator = ({ selectedIndex }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [inputText, setinputText] = useState('');
  const [resultText, setResultText] = useState('');
  const [translationState, setTranslationState] = useState({
    sourceLang: '',
    destinationLang: 'English-EN',
    deepL: 'Microsoft',
  });
  const { languageCode } = useContext(HomeContext);
  const { handleTextChange } = useTransation({
    translationState,
    setTranslationState,
    setResultText,
    languageCode,
  });

  const languageOptions = useMemo(() => {
    return translationState.deepL === 'DeepL'
      ? deepl_language.map((item) => {
          return `${item.language}-${item.code}`;
        })
      : languageCode;
  }, [translationState, languageCode]);

  const HandleOnChangeOptions = useCallback(
    (language, type) => {
      if (type === 'Source') {
        setTranslationState((prevState) => ({
          ...prevState,
          sourceLang: language,
        }));
      } else if (type === 'Target') {
        setTranslationState((prevState) => ({
          ...prevState,
          destinationLang: language,
        }));
        if (language && translationState.deepL && inputText) {
          const _cache = {
            ...translationState,
            destinationLang: language,
          };
          handleTextChange(inputText, _cache);
        }
      } else {
        setTranslationState((prevState) => ({
          ...prevState,
          deepL: language,
        }));
        if (language && translationState.deepL && inputText) {
          const _cache = {
            ...translationState,
            deepL: language,
          };
          handleTextChange(inputText, _cache);
        }
      }
    },
    [translationState]
  );

  // console.log('translationState', translationState);
  return (
    <>
      <TranslatorFeild className={selectedIndex !== 1 ? 'hide' : ''}>
        <SelectorContainer>
          <Header>
            <SelectMUI
              style={{ width: '100%' }}
              name="Source"
              selectedVal={translationState.sourceLang}
              options={languageOptions}
              onChange={HandleOnChangeOptions}
              optionStyle={{ gridTemplateColumns: 'repeat(4, 1fr)' }}
            />
          </Header>
          <IconButton
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              color: '#002a45',
            }}
            onClick={() => {
              setTranslationState((prevState) => ({
                ...prevState,
                sourceLang: translationState.destinationLang,
                destinationLang: translationState.sourceLang,
              }));
              const temp = inputText;
              setinputText(resultText);
              setResultText(temp);
            }}
          >
            <SwapHorizIcon />
          </IconButton>
          <Header style={{ marginLeft: '40px' }}>
            <SelectMUI
              style={{ width: '75%' }}
              name="Target"
              selectedVal={translationState.destinationLang}
              optionStyle={{ gridTemplateColumns: 'repeat(3, 1fr)' }}
              options={languageOptions}
              onChange={HandleOnChangeOptions}
            />
            <SelectMUI
              style={{ marginLeft: '10px', width: 'calc(25% - 10px)' }}
              name="Microsoft"
              selectedVal={translationState.deepL}
              optionStyle={{ gridTemplateColumns: 'repeat(1, 1fr)' }}
              options={['Microsoft', 'DeepL']}
              onChange={HandleOnChangeOptions}
            />
          </Header>
        </SelectorContainer>
        <TranslatorResult>
          <TranslatorInputFeild
            placeholder="Enter Text"
            value={inputText}
            onChange={(e) => {
              if (e.target.value.length === 0) {
                setResultText('');
              } else {
                handleTextChange(e.target.value);
              }
              setinputText(e.target.value);
            }}
          />
          <TranslatorResultFeild value={resultText} readOnly />
        </TranslatorResult>
      </TranslatorFeild>
    </>
  );
};

export default Translator;
