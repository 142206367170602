/* eslint-disable react/destructuring-assignment */
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import {
  ColumnChooser,
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableColumnVisibility,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  Toolbar,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import {
  Button,
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  TextField,
  Tooltip,
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/styles';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import { useHistory, useParams } from 'react-router-dom';
import { Cell, Pie, PieChart, Tooltip as TooltipGraph } from 'recharts';
import { Workload3Create } from 'WorkloadType';
import {
  workloadCreate,
  workloadGetById,
  workloadRunPost,
  workloadRunDocToDocPDF,
  workloadRunPostPdfCsv,
} from '../../../../../../src/api/workloads';
import { fileDetails, iCaptureCsv, iCaptureExcel } from '../../../../../api/module1';
import ExcelDoc from '../../../../../assets/icons8-excel-48.png';
import PdfDoc from '../../../../../assets/pdf_ins.png';
import PptDoc from '../../../../../assets/icons8-powerpoint-48.png';
import DocxDoc from '../../../../../assets/icons8-microsoft-word-2019-48.png';
import CsvDoc from '../../../../../assets/csv_icons.png';
import docIcon from '../../../../../assets/docx_ico.png';
import pdfIcon from '../../../../../assets/pdf_ico.png';
import xlsIcon from '../../../../../assets/xlsx_ico.png';
import TStyles, {
  rowComponent,
  tableAllocatorComponent,
  tableHeaderComponent,
  tUseStyles,
} from '../../../../../components/Datagrid/TStyles';
import ToolBarPlugin from '../../../../../components/Datagrid/ToolBarPlugin';
import formatBytes from '../../../../../tools/formatBytes';
import Module1Context from '../../../Module2Context';
import NERParamTarget from '../../../../../components/NERParamTarget';
import NavigationGoto from '../../../../../components/Core/NavigationGoto';
import { isensTheme } from '../../../../../app/isensTheme';
import { getCaptureName } from '../../../../../api/breakdowns';
import Loading from '../../../../../components/Core/Loading';
import MultiAllocTableComp from '../../../../../components/Datagrid/MultiAllocTableComp';
import NavigationGotoNew from '../../../../../components/Core/NavigationGotoNew';
import FileSetting from './FileSetting';

// table header
const TableComponentBase = ({ classes, ...restProps }: any) => (
  <Table.Table {...restProps} className={classes.tableStriped} />
);

const TableComponent = withStyles(TStyles, { name: 'TableComponent' })(TableComponentBase);

const columns: any = [
  { name: 'filename', title: 'File Name' },
  { name: 'size', title: 'Size' },
  { name: 'folderName', title: 'Folder' },
  { name: 'date', title: 'Date upload' },
  { name: 'userName', title: 'Uploaded by' },
];

const COLORS = [
  `${isensTheme.palette.primary.main}`,
  `${isensTheme.palette.secondary.main}`,
  `${isensTheme.palette.green.light}`,
  `${isensTheme.palette.secondary.veryLight}`,
  `${isensTheme.palette.primary.dark}`,
  `${isensTheme.palette.secondary.light}`,
];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 1.1;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {/* {`${(percent * 100).toFixed(0)}%`} */}
      {/* to remove 0% from graph display */}
      {percent * 100 > 1 ? `${`${(percent * 100).toFixed(0)}%`}` : ''}
    </text>
  );
};

function TabContainer(props: any) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    '& .MuiPaper-rounded': {
      background: 'red !important',
    },
  },
  captureHandle: {
    '& .MuiInputLabel-outlined': {
      color: '#002A45 !important',
      fontWeight: 'bold',
    },
  },
  pagingPanelContainer: {
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // marginTop: '10px',
    // width: '50% !important',
    padding: '0px !important',
    marginRight: '244px !important',
    '& .Pager-pager': {
      padding: 0,
    },
    // Apply other custom styles as needed
    '& .MuiIconButton-label': {
      color: '#001F3C',
      padding: 5,
      backgroundColor: '#FFFFFF',
      borderRadius: '50%',
      boxShadow: '#00000029',
    },
    // '& .MuiButton-label': {
    //   color: '#001F3C',
    //   padding: 2,
    //   backgroundColor: '#FFFFFF',
    //   borderRadius: '50%',
    //   boxShadow: '#00000029',
    // },
    '& .MuiButton-root': {
      '&.MuiButton-textPrimary .Pagination-activeButton': {
        backgroundColor: 'red !important',
        color: 'white !important',
        // Add other custom styles as needed
      },
      color: '#001F3C',
      padding: '0px 5px',
      backgroundColor: 'white',
      // borderRadius: '50%',
      boxShadow: '#00000029',
      // gap: 10,
      margin: 9,
      // '&.Pagination-activeButton': {
      //   background: 'red !important',
      // },
      // '& .Pagination-button': {
      //   '& .Pagination-activeButton': {
      //     background: 'red !important',
      //   },
      // },
    },
  },
}));

type PayloadText2Dataset = {
  Entity_type: string;
};

type Props = {
  id?: number;
  files?: any;
  setFiles?: any;
};

function ComapareVersions({ id: workloadId, files, setFiles }: Props) {
  const classes = useStyles();
  const history = useHistory();
  const tableUseStyles = tUseStyles();
  const { projectId } = useParams() as any;
  const [toggleHeight, setToggleHeight] = useState(false);
  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);
  const exporterRef = useRef(null);
  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);
  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `${currentCaptureName}.xlsx`
      );
    });
  };
  const { project } = useContext(Module1Context);
  // const [files, setFiles] = useState<any[]>([]);
  const [selection, setSelection] = useState([]);

  // to control the loader and display data full Grid
  const [loading, setLoading] = useState(false);
  const [display, setDisplay] = useState(false);

  const [loadingResult, setLoadingResult] = useState(false);

  const { setSnack } = useContext(Module1Context);
  const [messageToDisplay, setMessageToDisplay] = useState<any>();

  const [dataoutput, setDataoutput] = useState<any>([]);
  const [columnWidth, setColumnWidth] = useState<any>([]);

  // required when we dont have fixed named columns
  const [rows, setRows] = useState<any>([]);
  const pageSizes = [5, 10, 20, 50];
  const [columnsAre, setColumnsAre] = useState<any>([]);
  const [rows1, setRows1] = useState<any>([]);
  const [columnsAre1, setColumnsAre1] = useState<any>([]);
  const [rows2, setRows2] = useState<any>([]);
  const [columnsAre2, setColumnsAre2] = useState<any>([]);
  const [columnNamesAre, setColumnNamesAre] = useState<any>([]);
  const [newLine, setNewLine] = useState<any>('');
  const [filter, setFilter] = useState<any>([]);
  const [payload1, setPayload1] = useState<any>();
  const [resourcesAre, setResourcesAre] = useState<any>();
  const [resourcesPDF, setResourcesPDF] = useState<any>();
  const [inputCaptureName, setInputCaptureName] = useState('');
  const [currentCaptureName, setCurrentCaptureName] = useState<any>('');
  const [arrayFileLen, setArrayFileLen] = useState();
  const inputHandler = (event: any) => setInputCaptureName(event.target.value);

  const [sheetName0, setSheetName0] = useState<any>();
  const [targetName0, setTargetName0] = useState<any>();
  const [columnName0, setColumnName0] = useState<any>();

  const [sheetName1, setSheetName1] = useState<any>();
  const [targetName1, setTargetName1] = useState<any>();
  const [columnName1, setColumnName1] = useState<any>();

  const [similarity, setSimilarity] = useState<any>('');
  // for table data
  const [filesOP1, setFilesOP1] = useState<any[]>([]);
  const [filesOP2, setFilesOP2] = useState<any[]>([]);
  // for graph
  const [graphOP1, setGraphOP1] = useState<any>([]);
  const [graphOP2, setGraphOP2] = useState<any>([]);

  const [fileName1, setFileName1] = useState<any>('');
  const [fileName2, setFileName2] = useState<any>('');
  const [captureNames, setCaptureNames] = useState<any[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [value, setValue] = React.useState(0);
  const [tempPage, setTemPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [dis, setDis] = useState(true);
  const [disA, setDisA] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const [currentPage1, setCurrentPage1] = useState(0);
  const [tempPage1, setTemPage1] = useState(0);
  const [dis1, setDis1] = useState(true);
  const [pageSize1, setPageSize1] = useState(5);

  const [currentPage2, setCurrentPage2] = useState(0);
  const [tempPage2, setTemPage2] = useState(0);
  const [dis2, setDis2] = useState(true);
  const [pageSize2, setPageSize2] = useState(5);

  const handlePageNum2 = () => {
    const rel = tempPage2 - 1;
    setCurrentPage2(rel);
  };
  const handlePageNum1 = () => {
    const rel = tempPage1 - 1;
    setCurrentPage1(rel);
  };

  const handlePageNum = () => {
    const rel = tempPage - 1;
    setCurrentPage(rel);
  };

  const handleSetRowsColumns = async (myRows: {}[]) => {
    // const myCols = Object.keys(myRows[0]).map((key) => ({
    //   name: key,
    //   title: key.toUpperCase(),
    // })) as Column[];
    const araryCol = Object.keys(myRows[0]);
    const myCols: any = [];
    araryCol.map((key) => {
      // const keyTemp = key.replace(' ', '_');
      if (key !== 'RawData') {
        if (key !== 'Coordinates') {
          if (key !== 'i18n') {
            myCols.push({
              name: key,
              title: key.toUpperCase(),
            });
          }
        }
      }
      return key;
    });
    setColumnsAre(myCols);
    const tempArr: any = [];
    // eslint-disable-next-line array-callback-return
    myCols.map((c: any) => {
      tempArr.push(c.name);
    });
    setColumnNamesAre(tempArr);
    setRows(myRows);
  };
  useEffect(() => {
    getCaptureName(projectId).then((resp) => {
      if (resp) {
        setCaptureNames(resp);
      }
    });
  }, []);

  useEffect(() => {
    if (pageSize > files.length) {
      if (dis) {
        setDis((prev) => {
          return !prev;
        });
      }
    } else if (pageSize < files.length) {
      setDis((prev) => {
        return !prev;
      });
    }
  }, [pageSize]);

  const handleSetRfilowsColumns1 = async (myRows: {}[]) => {
    // const myCols = Object.keys(myRows[0]).map((key) => ({
    //   name: key,
    //   title: key,
    // })) as Column[];
    // setColumnsAre1(myCols);
    const araryCol = Object.keys(myRows[0]);
    const myCols: any = [];
    araryCol.map((key) => {
      // const keyTemp = key.replace(' ', '_');
      if (key !== 'RawData') {
        if (key !== 'Coordinates') {
          if (key !== 'i18n') {
            myCols.push({
              name: key,
              title: key.toUpperCase(),
            });
          }
        }
      }
      return key;
    });
    setColumnsAre1(myCols);

    const tempArr: any = [];
    // eslint-disable-next-line array-callback-return
    myCols.map((c: any) => {
      tempArr.push(c.name);
    });

    // Required to delete i18n from Rows
    const tempdeleted = myRows.map((data) => {
      // eslint-disable-next-line no-param-reassign
      delete data?.i18n;
      return data;
    });
    setRows1(tempdeleted);
  };
  const handleSetRfilowsColumns2 = async (myRows: {}[]) => {
    const araryCol = Object.keys(myRows[0]);
    const myCols: any = [];
    araryCol.map((key) => {
      // const keyTemp = key.replace(' ', '_');
      // if (key !== 'i18n') {
      //   myCols.push({
      //     name: key,
      //     title: key.toUpperCase(),
      //   });
      // }
      if (key !== 'RawData') {
        if (key !== 'Coordinates') {
          if (key !== 'i18n') {
            myCols.push({
              name: key,
              title: key.toUpperCase(),
            });
          }
        }
      }

      return key;
    });
    setColumnsAre2(myCols);
    const tempArr: any = [];
    // eslint-disable-next-line array-callback-return
    myCols.map((c: any) => {
      tempArr.push(c.name);
    });
    const tempdeleted = myRows.map((data) => {
      // eslint-disable-next-line no-param-reassign
      delete data?.i18n;
      return data;
    });
    setRows2(tempdeleted);
  };
  function handleChange(event, newValue) {
    setValue(newValue);
  }

  /**
   * Get all files from project
   */
  useEffect(() => {
    const temp = files.map((data) => {
      return {
        ...data,
        isNew: false,
      };
    });
    setFiles(temp);
  }, []);

  /**
   * Render results
   */
  useEffect(() => {
    if (files.length === 0 || projectId === undefined || workloadId === undefined) {
      return;
    }
    setLoading(true);
    workloadGetById(workloadId)
      .then((payload) => {
        // console.log(payload);
        setMessageToDisplay(payload?.status?.errors[0]?.msg);
        setSimilarity(payload?.results_data[0]?.data.output[4]?.Similarity);
        setResourcesAre(payload?.resources);
        // setResourcesPDF(payload?.resources?.output?.);

        setLoading(false);
        setDisplay(true);
        setCurrentCaptureName(payload?.resources[0]?.output?.NameOfCapture);
        // console.log('idocdiffer payload:', payload?.resources[0]);
        // addding start
        const unordered = JSON.parse(payload.results_data[0].data.output[1]);
        const itemHere = [
          { Tag: 'Unchanged', Count: 0 },
          { Tag: 'Removed/Deleted', Count: 0 },
          { Tag: 'New', Count: 0 },
          { Tag: 'Slightly Modified', Count: 0 },
          { Tag: 'Modified', Count: 0 },
          { Tag: 'Significantly Modified', Count: 0 },
        ];
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < itemHere.length; index++) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          addItem(itemHere[index], unordered);
        }
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const reOrdered = unordered.sort(dynamicSort('Tag'));
        // console.log(payload.output[1]);
        // console.log('reOrdered ac to key: ', reOrdered);
        // adding  end
        setFilesOP1(JSON.parse(payload.results_data[0].data.output[0]));
        handleSetRfilowsColumns1(JSON.parse(payload.results_data[0].data.output[0]));
        setGraphOP1(
          reOrdered.map((rec: any) => {
            return {
              name: rec.Tag,
              value: rec.Count,
            };
          })
        );

        // addding start
        const unordered_second = JSON.parse(payload.results_data[0].data.output[3]);
        const itemHere_second = [
          { Tag: 'Unchanged', Count: 0 },
          { Tag: 'Removed/Deleted', Count: 0 },
          { Tag: 'New', Count: 0 },
          { Tag: 'Slightly Modified', Count: 0 },
          { Tag: 'Modified', Count: 0 },
          { Tag: 'Significantly Modified', Count: 0 },
        ];
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < itemHere_second.length; index++) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          addItem(itemHere_second[index], unordered_second);
        }
        const reOrdered_second = unordered_second.sort(dynamicSort('Tag'));
        // console.log(payload.output[3]);
        // console.log('reOrdered ac to key: ', reOrdered_second);
        // adding  end
        setFilesOP2(JSON.parse(payload.results_data[0].data.output[2]));
        handleSetRfilowsColumns2(JSON.parse(payload.results_data[0].data.output[2]));
        setGraphOP2(
          reOrdered_second.map((rec: any) => {
            return {
              name: rec.Tag,
              value: rec.Count,
            };
          })
        );

        function dynamicSort(property: any) {
          let sortOrder = 1;
          if (property[0] === '-') {
            sortOrder = -1;
            // eslint-disable-next-line no-param-reassign
            property = property.substr(1);
          }
          // eslint-disable-next-line func-names
          return function (a: any, b: any) {
            // eslint-disable-next-line eqeqeq
            if (sortOrder == -1) {
              return b[property].localeCompare(a[property]);
              // eslint-disable-next-line no-else-return
            } else {
              return a[property].localeCompare(b[property]);
            }
          };
        }

        function addItem(item: any, inArray: any) {
          // eslint-disable-next-line eqeqeq
          const index = inArray.findIndex((x: any) => x.Tag == item.Tag);
          if (index === -1) {
            inArray.push(item);
          } else {
            // console.log('object already exists');
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setSnack(messageToDisplay, 'error');
        setDisplay(false);
      })
      .finally(() => {});
  }, [files, messageToDisplay, projectId, workloadId]);

  const getFileDetails = (inputFiles: any[]) => {
    setLoading(true);
    setDisplay(false);
    const falseArray: any = [];
    const arrayIs: any = [{ columnName: 'Entity', width: 300 }];
    // console.log('passedArray: ', selectedRawFiles);

    for (const myFile of inputFiles) {
      falseArray.push(
        fileDetails(files[myFile].filename, files[myFile].filename, files[myFile].container)
      );
      arrayIs.push({
        columnName: files[myFile].filename.split('.').slice(0, -1).join('.'),
        width: 300,
      });
    }
    setColumnWidth(arrayIs);
    Promise.all(falseArray)
      .then((responses) => {
        // Get a JSON object from each of the responses
        return Promise.all(
          responses.map((response) => {
            return response;
          })
        );
      })
      .then((data) => {
        // console.log(data);
        setDataoutput(data.map((c: any) => c.output));
        setLoading(false);
        setDisA(true);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });

    // promise all end
  };

  const handleSelectionChange = (selectedKeys: any) => {
    setFilter([]);
    const ArrayFiles = selectedKeys;
    setDisA(false);
    setSelection(selectedKeys);
    if (ArrayFiles.length > 2) {
      setSnack('more than 2 files not allowed!', 'error');
    } else {
      setArrayFileLen(ArrayFiles.length);
      setSimilarity([]);
      setErrorMessage('');
      getFileDetails(ArrayFiles);
    }
  };
  const [targetColumnsAre, setTargetColumnsAre] = useState<any>([]);

  useEffect(() => {
    if (pageSize1 > rows1.length) {
      if (dis1) {
        setDis1((prev) => {
          return !prev;
        });
      }
    } else if (pageSize1 < rows1.length) {
      setDis1((prev) => {
        return !prev;
      });
    }
  }, [pageSize1]);

  useEffect(() => {
    if (pageSize2 > rows2.length) {
      if (dis2) {
        setDis2((prev) => {
          return !prev;
        });
      }
    } else if (pageSize2 < rows2.length) {
      setDis2((prev) => {
        return !prev;
      });
    }
  }, [pageSize2]);

  function HandleReg(e: any, indexIs: any) {
    // console.log('dataoutput is: ', dataoutput);
    if (e.target.name === 'sheetname') {
      const state = [...targetColumnsAre];
      state[indexIs] =
        dataoutput[indexIs].Sheets[dataoutput[indexIs].Sheet_names.indexOf(e.target.value)];
      setTargetColumnsAre(state);
      Object.assign(dataoutput[indexIs], { SheetName: e.target.value });
      if (indexIs === 0) {
        setSheetName0(e.target.value);
      } else {
        setSheetName1(e.target.value);
      }
    }
    if (e.target.name === 'targetColumn') {
      Object.assign(dataoutput[indexIs], { TargetColumn: e.target.value });
      if (indexIs === 0) {
        setTargetName0(e.target.value);
      } else {
        setTargetName1(e.target.value);
      }
    }
    if (e.target.name === 'columnname') {
      Object.assign(dataoutput[indexIs], { TargetColumn: e.target.value });
      if (indexIs === 0) {
        setColumnName0(e.target.value);
      } else {
        setColumnName1(e.target.value);
      }
    }
  }

  function handleAnalyzePdf(fileExtensionOne: any, fileExtensionTwo: any) {
    const containerIs = dataoutput[0].Container;
    let tempdataoutput: any = [];
    tempdataoutput = JSON.parse(JSON.stringify(dataoutput));
    let fileone = null;
    let filetwo = null;
    if (dataoutput[0].FileType === 'PDF') {
      fileone = {
        output: { NameOfCapture: inputCaptureName, FileName: dataoutput[0].Blob },
        TextLength: 100000,
        settingsModule: {
          FileName: dataoutput[0].Blob,
          FilePath: dataoutput[0].FilePath,
          Container: dataoutput[0].Container,
          BlobName: dataoutput[0].Blob,
          id: dataoutput[0].Blob,
          FileType: 'pdf',
          Language: dataoutput[0].Language,
          StartPage: 0,
          EndPage: 0,
          HeaderMargin: 0,
          FooterMargin: 0,
          TypesOfCapture: 'Paragraph_Capture',
          RegId: '',
          TableSwitch: true,
          MergeBullet: false,
          SmartHeaderFooter: true,
          SmartTableOfContent: true,
          TranslateTo: ['en'],
          NameOfCapture: inputCaptureName,
        },
      };
    }
    if (dataoutput[1].FileType === 'PDF') {
      filetwo = {
        output: { NameOfCapture: inputCaptureName, FileName: dataoutput[1].Blob },
        TextLength: 100000,
        settingsModule: {
          FileName: dataoutput[1].Blob,
          FilePath: dataoutput[1].FilePath,
          Container: dataoutput[1].Container,
          BlobName: dataoutput[1].Blob,
          id: dataoutput[1].Blob,
          FileType: 'pdf',
          Language: dataoutput[1].Language,
          StartPage: 0,
          EndPage: 0,
          HeaderMargin: 0,
          FooterMargin: 0,
          TypesOfCapture: 'Paragraph_Capture',
          RegId: '',
          TableSwitch: true,
          MergeBullet: false,
          SmartHeaderFooter: true,
          SmartTableOfContent: true,
          TranslateTo: ['en'],
          NameOfCapture: inputCaptureName,
        },
      };
    }

    if (dataoutput[0].FileType === 'PDF' && dataoutput[1].FileType === 'PDF') {
      const bothPdfFile: any = [fileone, filetwo];
      workloadCreate({
        project_id: projectId,
        app: 'module2',
        action: 'docdiffer',
        resources: bothPdfFile,
      })
        .then((workloadNew: Workload3Create) => {
          workloadRunDocToDocPDF(Number(workloadNew.id), bothPdfFile, 'm2-docdifferPDF')
            .then(() => {
              window.history.pushState('', '', `/projects/${projectId}`);
              history.push(`/projects/${projectId}`);
            })
            .catch((e) => {
              setErrorMessage(e.detail);
              setSnack('Error while workload post.', 'error');
              setDisplay(false);
              setLoading(false);
            })
            .finally(() => {});
        })
        .catch(() => {
          setSnack('Error while workload create.', 'error');
          setDisplay(false);
          setLoading(false);
        })
        .finally(() => {});
    } else {
      let filePDF: any = null;
      let fileIndex: any = null;
      if (dataoutput[0].FileType === 'PDF') {
        filePDF = fileone;
        fileIndex = 1;
      }
      if (dataoutput[1].FileType === 'PDF') {
        filePDF = filetwo;
        fileIndex = 0;
      }
      Promise.all([
        fileDetails(
          files[selection[fileIndex]].filename,
          files[selection[fileIndex]].filename,
          files[selection[fileIndex]].container
        ),
      ])
        .then(async (responseAll) => {
          // for data 0 process to do iCapture
          const arrPromises: any = [];
          function captureAll(): Promise[] {
            for (const [i, itemData] of responseAll.entries()) {
              if (itemData.output.FileType === 'Excel') {
                let columnPosition: any;
                let sheetNameFind: any;
                let targetNames: any;

                if (sheetName0 !== undefined) {
                  if (sheetName0 !== null) {
                    columnPosition = (responseAll[0]?.output?.Sheet_names).indexOf(sheetName0);
                    sheetNameFind = sheetName0;
                    targetNames = targetName0;
                  }
                }
                if (sheetName1 !== undefined) {
                  if (sheetName1 !== null) {
                    columnPosition = (responseAll[0]?.output?.Sheet_names).indexOf(sheetName1);
                    sheetNameFind = sheetName1;
                    targetNames = targetName1;
                  }
                }
                const body = {
                  FilePath: itemData.output.FilePath,
                  Container: itemData.output.Container,
                  BlobName: itemData.output.Blob,
                  NameOfCapture: inputCaptureName,
                  Sheet: sheetNameFind,
                  TargetColumn: targetNames,
                  ColumnNames: itemData?.output?.Sheets[columnPosition]?.ColumNames,
                  Header: true,
                };

                arrPromises.push(iCaptureExcel(body));
                // console.log(tempdataoutput);
                // to arrange sheet number in first position because of middleware 388-405
                // eslint-disable-next-line no-plusplus
                for (let index = 0; index < tempdataoutput.length; index++) {
                  if (tempdataoutput[index].FileName === itemData.output.Blob) {
                    // eslint-disable-next-line no-plusplus
                    for (let jindex = 0; jindex < tempdataoutput[index].Sheets.length; jindex++) {
                      // eslint-disable-next-line eqeqeq
                      if (tempdataoutput[index].Sheets[jindex].SheetName == sheetNameFind) {
                        tempdataoutput[index].Sheet = tempdataoutput[index].Sheets[jindex];
                      }
                    }
                  }
                }
              } else if (itemData.output.FileType === 'CSV') {
                const body = {
                  FilePath: itemData.output.FilePath,
                  Container: itemData.output.Container,
                  BlobName: itemData.output.Blob,
                  NameOfCapture: inputCaptureName,
                  TargetColumn: columnName0 === undefined ? columnName1 : columnName0,
                  ColumnNames: itemData?.output?.ColumnNames,
                  Header: true,
                };
                arrPromises.push(iCaptureCsv(body));
              }
            }
            return arrPromises;
          }
          const responseCharacters = await Promise.all(captureAll());
          let len1 = null;
          let len2 = null;

          if (fileIndex === 0) {
            len1 =
              JSON.parse(responseCharacters[0].output?.Text)?.length > 0
                ? JSON.parse(responseCharacters[0].output?.Text)?.length
                : 0;
            len2 = 100000;
          }
          if (fileIndex === 1) {
            len2 =
              JSON.parse(responseCharacters[0].output?.Text)?.length > 0
                ? JSON.parse(responseCharacters[0].output?.Text)?.length
                : 0;
            len1 = 100000;
          }
          if (len1 === 0 || len2 === 0) {
            setSnack('Invalid file format', 'error');
            setLoadingResult(false);
            setDisplay(false);
            return;
          }
          const TextLen = len1 > len2 ? len1 : len2;

          const rrr = responseCharacters.map((d, index) => ({
            ...d,
            FileName: responseAll[index].output.Blob,
            scopeSettingOP: tempdataoutput,
            TextLength: TextLen,
            filesPDF: filePDF,
          }));
          const totalcount: any = [];
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < responseCharacters.length; index++) {
            if (responseCharacters[index].output !== undefined) {
              Object.keys(responseCharacters[index]?.output).forEach((k: any) => {
                if (k === 'TargetColumn') {
                  totalcount.push('found');
                }
              });
            }
          }
          if (responseCharacters.length === totalcount.length) {
            // workload here
            workloadCreate({
              project_id: projectId,
              app: 'module2',
              action: 'docdiffer',
              resources: rrr,
            })
              .then((workloadNew: Workload3Create) => {
                workloadRunPostPdfCsv(Number(workloadNew.id), rrr, 'm2-docdiffer-pdf-csv')
                  .then(() => {
                    window.history.pushState('', '', `/projects/${projectId}`);
                    history.push(`/projects/${projectId}`);
                  })
                  .catch((e) => {
                    setErrorMessage(e.detail);
                    setSnack(
                      `Error from workload post api, either one of file is non-extractable`,
                      'error'
                    );
                    setDisplay(false);
                    setLoading(false);
                    setLoadingResult(false);
                  })
                  .finally(() => {});
              })
              .catch(() => {
                setSnack('Error from workload create api.', 'error');
                setDisplay(false);
                setLoading(false);
                setLoadingResult(false);
              })
              .finally(() => {});
          } else {
            setSnack('Please select all dropdown options.', 'error');
            setDisplay(false);
            setLoading(false);
            setLoadingResult(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setSnack('Error while processing the scopeSetting for both files.', 'error');
          setDisplay(false);
          setLoading(false);
          setLoadingResult(false);
        });
    }
  }

  function handleAnalyze() {
    setErrorMessage('');

    if (arrayFileLen !== 2) {
      setSnack('Please select one more file', 'error');
      return;
    }
    if (inputCaptureName.trim().length === 0) {
      setSnack('Please enter activity name', 'error');
      return;
    }
    if (captureNames.includes(inputCaptureName.trim())) {
      setSnack('That capture name is taken.Please try another', 'error');
      return;
    }
    const fileExtensionOne = files[selection[0]].filename.toLowerCase().split('.').pop();
    const fileExtensionTwo = files[selection[1]].filename.toLowerCase().split('.').pop();
    if (fileExtensionOne === 'pdf' || fileExtensionTwo === 'pdf') {
      handleAnalyzePdf(fileExtensionOne, fileExtensionTwo);
    } else {
      if (dataoutput[0]?.FileType === 'Excel' && dataoutput[1]?.FileType === 'Excel') {
        if (!sheetName0 || !sheetName1) {
          setSnack('Please select both dropdown options', 'error');
          return;
        }
        if (!targetName0 || !targetName1) {
          setSnack('Please select both dropdown options', 'error');
          return;
        }
      } else if (dataoutput[0]?.FileType === 'CSV' && dataoutput[1]?.FileType === 'CSV') {
        if (!columnName0 || !columnName1) {
          setSnack('Please select both dropdown options', 'error');
          return;
        }
      } else if (dataoutput[0]?.FileType === 'CSV' && dataoutput[1]?.FileType === 'Excel') {
        if (!columnName0) {
          setSnack('Please select both dropdown options', 'error');
          return;
        }
        if (!sheetName1) {
          setSnack('Please select both dropdown options', 'error');
          return;
        }
        if (!targetName1) {
          setSnack('Please select both dropdown options', 'error');
          return;
        }
      } else if (dataoutput[1]?.FileType === 'CSV' && dataoutput[0]?.FileType === 'Excel') {
        if (!columnName1) {
          setSnack('Please select both dropdown options', 'error');
          return;
        }
        if (!sheetName0) {
          setSnack('Please select both dropdown options', 'error');
          return;
        }
        if (!targetName0) {
          setSnack('Please select both dropdown options', 'error');
          return;
        }
      }

      // setLoading(true);
      setDisplay(false);
      setLoadingResult(true);

      Promise.all([
        fileDetails(
          files[selection[0]].filename,
          files[selection[0]].filename,
          files[selection[0]].container
        ),
        fileDetails(
          files[selection[1]].filename,
          files[selection[1]].filename,
          files[selection[1]].container
        ),
      ])
        .then(async (responseAll) => {
          // for data 0 process to do iCapture
          // console.log(responseAll);
          const arrPromises: any = [];
          function captureAll(): Promise[] {
            for (const [i, itemData] of responseAll.entries()) {
              if (itemData.output.FileType === 'Excel') {
                let columnPosition: any;
                // console.log(`${sheetName0},${sheetName1}`);
                if (sheetName0 !== undefined) {
                  if (sheetName0 !== null) {
                    columnPosition = (responseAll[0]?.output?.Sheet_names).indexOf(sheetName0);
                  }
                }
                if (sheetName1 !== undefined) {
                  if (sheetName1 !== null) {
                    columnPosition = (responseAll[1]?.output?.Sheet_names).indexOf(sheetName1);
                  }
                }

                let targetpass: string;
                if (i === 0) {
                  targetpass = targetName0;
                } else {
                  targetpass = targetName1;
                }

                const body = {
                  FilePath: itemData.output.FilePath,
                  Container: itemData.output.Container,
                  BlobName: itemData.output.Blob,
                  NameOfCapture: inputCaptureName,
                  Sheet: i === 0 ? sheetName0 : sheetName1,
                  TargetColumn: targetpass,
                  ColumnNames: itemData?.output?.Sheets[columnPosition]?.ColumNames,
                  Header: true,
                };

                arrPromises.push(iCaptureExcel(body));
              } else if (itemData.output.FileType === 'CSV') {
                let columnpass: any;
                if (i === 0) {
                  columnpass = columnName0;
                } else {
                  columnpass = columnName1;
                }

                const body = {
                  FilePath: itemData.output.FilePath,
                  Container: itemData.output.Container,
                  BlobName: itemData.output.Blob,
                  NameOfCapture: inputCaptureName,
                  TargetColumn: columnpass,
                  ColumnNames: itemData?.output?.ColumnNames,
                  Header: true,
                };
                arrPromises.push(iCaptureCsv(body));
              }
            }
            return arrPromises;
          }
          const responseCharacters = await Promise.all(captureAll());
          const len1 =
            JSON.parse(responseCharacters[0].output?.Text)?.length > 0
              ? JSON.parse(responseCharacters[0].output?.Text)?.length
              : 0;
          const len2 =
            JSON.parse(responseCharacters[1].output?.Text)?.length > 0
              ? JSON.parse(responseCharacters[1].output?.Text)?.length
              : 0;
          if (len1 === 0 || len2 === 0) {
            setSnack('Invalid file format', 'error');
            setLoading(false);
            setDisplay(false);
            return;
          }
          const TextLen = len1 > len2 ? len1 : len2;
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < responseCharacters.length; index++) {
            if (responseCharacters[index]?.output?.TargetColumn === 'Similar Text') {
              // responseCharacters[index].output.TargetColumn = 'Text';
              setSnack('Please donot use target column as Similar Text', 'error');
              setLoading(false);
              return;
            }
          }
          const r = responseCharacters.map((d, index) => ({
            ...d,
            FileName: responseAll[index].output.Blob,
            TextLength: TextLen,
          }));

          // console.log(r);
          workloadCreate({
            project_id: projectId,
            app: 'module2',
            action: 'docdiffer',
            resources: r,
          }).then((workloadNew: Workload3Create) => {
            workloadRunPost(Number(workloadNew.id), { r }, 'm2-docdiffer')
              .then(() => {
                window.history.pushState('', '', `/projects/${projectId}`);
                history.push(`/projects/${projectId}`);
              })
              .catch((e) => {
                setErrorMessage(e.detail);
                setSnack(
                  'Error while processing both files for docdiffer. Please check the input files.',
                  'error'
                );
              })
              .finally(() => {
                setLoadingResult(false);
              });
          });
        })
        .catch((error) => {
          console.log(error);
          setSnack('Error while processing the scopeSetting for both files.', 'error');
          setLoading(false);
        });
    }
  }

  function filterActivation(valueIs: any) {
    handleSetRowsColumns(
      JSON.parse(payload1.output).filter((row: PayloadText2Dataset) => row.Entity_type === valueIs)
    );
  }

  const renderFileName = ({ row }: any) => {
    const ext = row.filename.split('.');
    const extensions = {
      xlsx: ExcelDoc,
      csv: CsvDoc,
      pptx: PptDoc,
      docx: DocxDoc,
      pdf: PdfDoc,
    };
    return (
      <div
        style={{
          position: 'relative',
          padding: '0.3rem',
          width: 'max-content',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.2rem' }}>
          <img src={extensions[ext[ext.length - 1]]} height="30" width="30" alt="img" />
          <Typography>{row.filename}</Typography>
        </div>
        {row?.isNew && (
          <div
            style={{
              width: 'max-content',
              padding: '0.2rem',
              borderRadius: '10px',
              backgroundColor: '#19AA6E',
              color: 'white',
              position: 'absolute',
              top: '-12px',
              right: '-35px',
            }}
          >
            <Typography style={{ fontSize: '0.85em' }}>New !</Typography>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div>
        <Box style={{ backgroundColor: '#EEF1F9', border: '1px solid #002A45' }}>
          <ExpressGrid rows={files} columns={columns}>
            <DataTypeProvider for={['filename']} formatterComponent={renderFileName} />
            <SelectionState selection={selection} onSelectionChange={handleSelectionChange} />
            <FilteringState />
            <IntegratedFiltering />
            <SortingState />
            <IntegratedSorting />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={setCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
            />
            <IntegratedPaging />
            <VirtualTable />
            <Table
              tableComponent={MultiAllocTableComp}
              containerComponent={tableAllocatorComponent}
              headComponent={tableHeaderComponent}
            />
            <TableHeaderRow showSortingControls />
            <TableFilterRow rowComponent={rowComponent} />
            {/* <PagingPanel pageSizes={pageSizes} /> */}
            <PagingPanel
              pageSizes={pageSizes}
              containerComponent={(props) => (
                <>
                  <PagingPanel.Container {...props} className={classes.pagingPanelContainer} />
                </>
              )}
            />
            <TableSelection />
          </ExpressGrid>
          <NavigationGotoNew
            setPageSize={setPageSize}
            fileLength={files.length}
            pageSize={pageSize}
            dis={dis}
            setTemPage={setTemPage}
            handlePageNum={handlePageNum}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Box>
      </div>
      <span style={{ color: 'black' }}>
        <h3>Total files selected: {selection.length}</h3>{' '}
      </span>
      {disA && (
        <>
          <Box style={{ border: '1px solid #002A45' }}>
            {dataoutput.length > 0 && (
              <>
                <div className={classes.root}>
                  <Grid container style={{ marginLeft: 10 }}>
                    <div
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                      <div>
                        <SettingsApplicationsOutlinedIcon style={{ width: 40, height: 40 }} />
                      </div>
                      <div>
                        <h2>Scope Settings</h2>
                      </div>
                    </div>
                  </Grid>
                  <div>
                    <center>
                      <h3 style={{ color: 'blue' }}>
                        {fileName1 && fileName2 && (
                          <>
                            {fileName1} <b style={{ color: 'red', fontSize: 20 }}>vs</b> {fileName2}{' '}
                          </>
                        )}
                      </h3>
                    </center>
                  </div>
                  <FileSetting
                    dataoutput={dataoutput}
                    targetColumnsAre={targetColumnsAre}
                    HandleReg={HandleReg}
                  />
                  <Grid
                    container
                    xs={12}
                    style={{
                      backgroundColor: '#EEF1F9',
                      padding: '1rem',
                      borderTop: '1px solid #002A45',
                    }}
                  >
                    <Grid item xs={6} alignItems="center" justify="center">
                      {errorMessage !== '' && (
                        <Typography style={{ color: '#DC3223' }}>Error : {errorMessage}</Typography>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <Box style={{ display: 'flex' }}>
                        <Box style={{ flexBasis: '30%' }}>{}</Box>
                        <Box
                          style={{
                            flexBasis: '78%',
                            display: 'flex',
                            height: '2.5rem',
                            gap: '20px',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                        >
                          <TextField
                            id="outlined-basic"
                            label="Enter Activity Name"
                            variant="outlined"
                            name="captureName"
                            onChange={inputHandler}
                            size="small"
                            style={{
                              width: '170px',
                              height: '38px',
                              marginTop: 6,
                              marginBottom: 6,
                              backgroundColor: 'white',
                            }}
                          />
                          <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={handleAnalyze}
                            startIcon={
                              loadingResult && (
                                <CircularProgress style={{ color: 'white' }} size={20} />
                              )
                            }
                            style={{
                              width: '150px',
                              height: '38px',
                              marginTop: 6,
                              marginBottom: 6,
                            }}
                          >
                            Analyze
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </div>
              </>
            )}
          </Box>
        </>
      )}
      {loading && <Loading />}
      {display && (
        <>
          <Paper className={tableUseStyles.panelMain}>
            {console.log('resourcesAre: ', resourcesAre)}
            {display && (
              <div className={classes.root}>
                <AppBar position="static" style={{ backgroundColor: '#002A45' }}>
                  <Tabs value={value} onChange={handleChange}>
                    {/* <Tab label={`${resourcesAre[0]?.FileName} vs ${resourcesAre[1]?.FileName}`} />
                    <Tab label={`${resourcesAre[1]?.FileName} vs ${resourcesAre[0]?.FileName}`} /> */}
                    {resourcesAre.length > 1 ? (
                      // const fileExtensionOne = resourcesAre[0]?.FileName.toLowerCase().split('.').pop();
                      // const fileExtensionTwo = resourcesAre[1]?.FileName.toLowerCase().split('.').pop();
                      <Tabs value={value} onChange={handleChange}>
                        {
                          // eslint-disable-next-line no-prototype-builtins
                          resourcesAre[0].hasOwnProperty('settingsModule') === true &&
                          // eslint-disable-next-line no-prototype-builtins
                          resourcesAre[1].hasOwnProperty('settingsModule') === true ? (
                            <>
                              <Tab
                                // eslint-disable-next-line max-len
                                label={`${resourcesAre[0]?.output?.FileName} vs ${resourcesAre[1]?.output?.FileName}`}
                              />
                              <Tab
                                // eslint-disable-next-line max-len
                                label={`${resourcesAre[1]?.output?.FileName} vs ${resourcesAre[0]?.output?.FileName}`}
                              />
                            </>
                          ) : (
                            <>
                              <Tab
                                // eslint-disable-next-line max-len
                                label={`${resourcesAre[0]?.FileName} vs ${resourcesAre[1]?.FileName}`}
                              />
                              <Tab
                                // eslint-disable-next-line max-len
                                label={`${resourcesAre[1]?.FileName} vs ${resourcesAre[0]?.FileName}`}
                              />
                            </>
                          )
                        }
                      </Tabs>
                    ) : (
                      <Tabs value={value} onChange={handleChange}>
                        {console.log('resourcesAre not: ')}
                        <Tab
                          // eslint-disable-next-line max-len
                          label={`${resourcesAre[0]?.settingsModule[0]?.FileName} vs ${resourcesAre[0]?.scopeSettingOP[1]?.FileName}`}
                        />
                        <Tab
                          // eslint-disable-next-line max-len
                          label={`${resourcesAre[0]?.scopeSettingOP[1]?.FileName} vs ${resourcesAre[0]?.scopeSettingOP[0]?.FileName}`}
                        />
                      </Tabs>
                    )}
                  </Tabs>
                </AppBar>
                {value === 0 && (
                  <TabContainer>
                    {/* <ReactJson src={{ payloadOutput0 }} collapsed={true} theme="monokai" /> */}
                    <Grid container>
                      <Grid item xs={8}>
                        <PieChart width={800} height={400}>
                          <Pie
                            isAnimationActive={true}
                            data={graphOP1}
                            cx={300}
                            cy={200}
                            outerRadius={170}
                            innerRadius={100}
                            labelLine={false}
                            label={renderCustomizedLabel}
                            fill="#8884d8"
                            dataKey="value"
                            cornerRadius={10}
                          >
                            {graphOP1.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                          </Pie>
                          <TooltipGraph />
                        </PieChart>
                      </Grid>
                      <Grid item xs={2}>
                        <h3>
                          {' '}
                          Similarity: <span style={{ color: 'blue' }}>{similarity}%</span>
                        </h3>
                      </Grid>
                      <Grid item xs={2}>
                        {/* <img src={docdifferbase64} alt="Red dot" height="50%" /> */}
                        <div>
                          <span>
                            <svg width="20" height="20">
                              <rect
                                width="20"
                                height="20"
                                style={{
                                  fill: `${isensTheme.palette.green.light}`,
                                }}
                              />
                            </svg>
                          </span>
                          <span>&nbsp; Removed/Deleted</span>
                        </div>
                        <div>
                          <span>
                            <svg width="20" height="20">
                              <rect
                                width="20"
                                height="20"
                                style={{
                                  fill: `${isensTheme.palette.primary.main}`,
                                }}
                              />
                            </svg>
                          </span>
                          <span>&nbsp; Modified</span>
                        </div>
                        <div>
                          <span>
                            <svg width="20" height="20">
                              <rect
                                width="20"
                                height="20"
                                style={{
                                  fill: `${isensTheme.palette.secondary.main}`,
                                }}
                              />
                            </svg>
                          </span>
                          <span>&nbsp; New</span>
                        </div>
                        <div>
                          <span>
                            <svg width="20" height="20">
                              <rect
                                width="20"
                                height="20"
                                style={{
                                  fill: `${isensTheme.palette.secondary.light}`,
                                }}
                              />
                            </svg>
                          </span>
                          <span>&nbsp; Unchanged</span>
                        </div>
                        <div>
                          <span>
                            <svg width="20" height="20">
                              <rect
                                width="20"
                                height="20"
                                style={{
                                  fill: `${isensTheme.palette.primary.dark}`,
                                }}
                              />
                            </svg>
                          </span>
                          <span>&nbsp; Slightly Modified</span>
                        </div>
                        <div>
                          <span>
                            <svg width="20" height="20">
                              <rect
                                width="20"
                                height="20"
                                style={{
                                  fill: `${isensTheme.palette.secondary.veryLight}`,
                                }}
                              />
                            </svg>
                          </span>
                          <span>&nbsp; Significantly Modified</span>
                        </div>
                      </Grid>
                    </Grid>
                    <div>
                      <Paper>
                        <Grid container style={{ backgroundColor: 'light blue' }}>
                          <Grid item xs={6} style={{ alignItems: 'center' }}>
                            <b>{fileName1}</b>
                          </Grid>
                          <Grid item xs={6} style={{ alignItems: 'center' }}>
                            <b>{fileName2}</b>
                          </Grid>
                        </Grid>
                        <Box style={{ backgroundColor: '#EEF1F9', border: '1px solid #002A45' }}>
                          <ExpressGrid rows={rows1} columns={columnsAre1}>
                            <DataTypeProvider
                              for={['Text', 'Similar Requirement']}
                              formatterComponent={({ value }) => <span title={value}>{value}</span>}
                            />
                            <FilteringState defaultFilters={[{ columnName: 'Text', value: '' }]} />
                            <IntegratedFiltering />
                            <SortingState />
                            <IntegratedSorting />
                            <PagingState
                              currentPage={currentPage1}
                              onCurrentPageChange={setCurrentPage1}
                              pageSize={pageSize1}
                              onPageSizeChange={setPageSize1}
                            />
                            <IntegratedPaging />
                            <Table
                              tableComponent={TableComponent}
                              columnExtensions={[
                                ...columnsAre1.map((c) => {
                                  return {
                                    columnName: c.name,
                                    width: '180px',
                                    wordWrapEnabled: toggleHeight,
                                  };
                                }),
                              ]}
                            />
                            {/* <TableColumnResizing
                              defaultColumnWidths={[
                                ...columnsAre1.map((c) => {
                                  // console.log(
                                  //   'width',
                                  //   parseInt(Math.ceil(2400 / columnsAre1.length), 10)
                                  // );
                                  return {
                                    columnName: c.name,
                                    width: `${parseInt(
                                      Math.ceil(2400 / columnsAre1.length),
                                      10
                                    )}px`,
                                  };
                                }),
                              ]}
                            /> */}
                            {columnsAre1 && columnsAre1.length > 0 && (
                              <TableColumnResizing
                                defaultColumnWidths={[
                                  ...columnsAre1.map((c) => {
                                    try {
                                      const width = parseInt(
                                        Math.ceil(2400 / columnsAre1.length),
                                        10
                                      );
                                      // eslint-disable-next-line no-restricted-globals
                                      if (isNaN(width)) {
                                        throw new Error('Invalid width calculation');
                                      }
                                      return {
                                        columnName: c.name,
                                        width: `${width}px`,
                                      };
                                    } catch (error) {
                                      console.error('Error calculating column width:', error);
                                      return {
                                        columnName: c.name,
                                        width: '100px', // Fallback width
                                      };
                                    }
                                  }),
                                ]}
                              />
                            )}
                            <TableFilterRow rowComponent={rowComponent} />
                            <TableHeaderRow showSortingControls />
                            <TableColumnVisibility
                              hiddenColumnNames={hiddenColumnNames}
                              onHiddenColumnNamesChange={setHiddenColumnNames}
                            />
                            <Toolbar />
                            <ColumnChooser />
                            <ToolBarPlugin name="Download" onClick={startExport} />
                            <PagingPanel pageSizes={pageSizes} />
                            <ToolBarPlugin
                              name="Height"
                              title="Wrap Text"
                              onClick={() => setToggleHeight(!toggleHeight)}
                            />
                            {/* <PagingPanel pageSizes={pageSizes} /> */}
                          </ExpressGrid>
                          <NavigationGoto
                            setPageSize={setPageSize1}
                            fileLength={rows1.length}
                            pageSize={pageSize1}
                            dis={dis1}
                            setTemPage={setTemPage1}
                            handlePageNum={handlePageNum1}
                            currentPage={currentPage1}
                            setCurrentPage={setCurrentPage1}
                          />
                        </Box>
                        <GridExporter
                          ref={exporterRef}
                          rows={rows1}
                          columns={columnsAre1}
                          onSave={onSave}
                        />
                      </Paper>
                    </div>
                  </TabContainer>
                )}
                {value === 1 && (
                  <TabContainer>
                    {/* <ReactJson src={{ graphOP2 }} collapsed={true} theme="monokai" /> */}
                    <Grid container>
                      <Grid item xs={8}>
                        <PieChart width={800} height={400}>
                          <Pie
                            isAnimationActive={true}
                            data={graphOP2}
                            cx={300}
                            cy={200}
                            outerRadius={170}
                            innerRadius={100}
                            labelLine={false}
                            label={renderCustomizedLabel}
                            fill="#8884d8"
                            dataKey="value"
                            cornerRadius={10}
                          >
                            {graphOP2.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                          </Pie>
                          <TooltipGraph />
                        </PieChart>
                      </Grid>
                      <Grid item xs={2}>
                        <h3>
                          {' '}
                          Similarity: <span style={{ color: 'blue' }}>{similarity}%</span>
                        </h3>
                      </Grid>
                      {/* <Grid item xs={2}>
                          <img src={docdifferbase64} alt="Red dot" height="50%" />
                        </Grid> */}
                      <Grid item xs={2}>
                        {/* <img src={docdifferbase64} alt="Red dot" height="50%" /> */}
                        <div>
                          <span>
                            <svg width="20" height="20">
                              <rect
                                width="20"
                                height="20"
                                style={{
                                  fill: `${isensTheme.palette.green.light}`,
                                }}
                              />
                            </svg>
                          </span>
                          <span>&nbsp; Removed/Deleted</span>
                        </div>
                        <div>
                          <span>
                            <svg width="20" height="20">
                              <rect
                                width="20"
                                height="20"
                                style={{
                                  fill: `${isensTheme.palette.primary.main}`,
                                }}
                              />
                            </svg>
                          </span>
                          <span>&nbsp; Modified</span>
                        </div>
                        <div>
                          <span>
                            <svg width="20" height="20">
                              <rect
                                width="20"
                                height="20"
                                style={{
                                  fill: `${isensTheme.palette.secondary.main}`,
                                }}
                              />
                            </svg>
                          </span>
                          <span>&nbsp; New</span>
                        </div>
                        <div>
                          <span>
                            <svg width="20" height="20">
                              <rect
                                width="20"
                                height="20"
                                style={{
                                  fill: `${isensTheme.palette.secondary.light}`,
                                }}
                              />
                            </svg>
                          </span>
                          <span>&nbsp; Unchanged</span>
                        </div>
                        <div>
                          <span>
                            <svg width="20" height="20">
                              <rect
                                width="20"
                                height="20"
                                style={{
                                  fill: `${isensTheme.palette.primary.dark}`,
                                }}
                              />
                            </svg>
                          </span>
                          <span>&nbsp; Slightly Modified</span>
                        </div>
                        <div>
                          <span>
                            <svg width="20" height="20">
                              <rect
                                width="20"
                                height="20"
                                style={{
                                  fill: `${isensTheme.palette.secondary.veryLight}`,
                                }}
                              />
                            </svg>
                          </span>
                          <span>&nbsp; Significantly Modified</span>
                        </div>
                      </Grid>
                    </Grid>
                    <div>
                      <Paper>
                        <Grid container>
                          <Grid item xs={6} style={{ alignItems: 'center' }}>
                            <b>{fileName2}</b>
                          </Grid>
                          <Grid item xs={6} style={{ alignItems: 'center' }}>
                            <b>{fileName1}</b>
                          </Grid>
                        </Grid>
                        <Box style={{ backgroundColor: '#EEF1F9', border: '1px solid #002A45' }}>
                          <ExpressGrid rows={rows2} columns={columnsAre2}>
                            <DataTypeProvider
                              for={['Text', 'Similar Requirement']}
                              formatterComponent={({ value }) => <span title={value}>{value}</span>}
                            />
                            <SortingState />
                            <IntegratedSorting />
                            <PagingState
                              currentPage={currentPage2}
                              onCurrentPageChange={setCurrentPage2}
                              pageSize={pageSize2}
                              onPageSizeChange={setPageSize2}
                            />
                            <IntegratedPaging />
                            <Table
                              tableComponent={TableComponent}
                              columnExtensions={[
                                ...columnsAre2.map((c) => {
                                  return {
                                    columnName: c.name,
                                    width: '180px',
                                    wordWrapEnabled: toggleHeight,
                                  };
                                }),
                              ]}
                            />
                            <TableColumnResizing
                              defaultColumnWidths={[
                                ...columnsAre2.map((c) => {
                                  return {
                                    columnName: c.name,
                                    width: parseInt(Math.ceil(2400 / columnsAre2.length), 10),
                                  };
                                }),
                              ]}
                            />
                            {/* <TableFilterRow rowComponent={rowComponent} /> */}
                            <TableHeaderRow showSortingControls />

                            <TableColumnVisibility
                              hiddenColumnNames={hiddenColumnNames}
                              onHiddenColumnNamesChange={setHiddenColumnNames}
                            />
                            <Toolbar />
                            <ColumnChooser />
                            <ToolBarPlugin name="Download" onClick={startExport} />
                            <ToolBarPlugin
                              name="Height"
                              title="Wrap Text"
                              onClick={() => setToggleHeight(!toggleHeight)}
                            />
                            <PagingPanel pageSizes={pageSizes} />
                          </ExpressGrid>

                          <GridExporter
                            ref={exporterRef}
                            rows={rows2}
                            columns={columnsAre2}
                            onSave={onSave}
                          />
                          <NavigationGoto
                            setPageSize={setPageSize2}
                            fileLength={rows2.length}
                            pageSize={pageSize2}
                            dis={dis2}
                            setTemPage={setTemPage2}
                            handlePageNum={handlePageNum2}
                            currentPage={currentPage2}
                            setCurrentPage={setCurrentPage2}
                          />
                        </Box>
                      </Paper>
                    </div>
                  </TabContainer>
                )}
              </div>
            )}
          </Paper>
        </>
      )}
    </>
  );
}

export default ComapareVersions;
