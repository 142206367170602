import React, { useEffect, useMemo, useReducer } from 'react';
import { getAllProjectCreationLanguages } from '../../api/projects';

export interface HomeContextType {
  rexResult: any;
  setRexResult: any;
  languageCode: any;
  setLanguageCode: any;
  response: any;
  setResponse: any;
}

type Props = {
  children: any;
};

const HomeContext = React.createContext<HomeContextType>({
  rexResult: {} as any,
  setRexResult: {} as any,
  languageCode: [] as any,
  setLanguageCode: [] as any,
  response: {} as any,
  setResponse: {} as any,
});

const HomeContextProvider = ({ children }: Props) => {
  const [rexResult, setRexResult] = React.useState<any>([]);
  const [response, setResponse] = React.useState({});
  const [languageCode, setLanguageCode] = React.useState<any>([]);

  useEffect(() => {
    getAllProjectCreationLanguages().then((dbProjects) => {
      const db = dbProjects.map((item) => {
        return `${item.language}-${item.codes}`;
      });
      setLanguageCode(db);
    });
  }, []);

  const value = useMemo(() => ({ rexResult, setRexResult, languageCode, response, setResponse }), [
    rexResult,
    languageCode,
    response,
    setResponse,
  ]);

  return <HomeContext.Provider value={value}>{children}</HomeContext.Provider>;
};

export default HomeContext;

export { HomeContextProvider };
