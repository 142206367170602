import React, { useEffect } from 'react';
import Pagination from '@mui/material/Pagination';
import dayjs, { Dayjs } from 'dayjs';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import useProjectHistory from './Hooks/useProjectHistory';
import ServiceItem, { RowItem } from './components/ServiceItem';

// Define custom styles
const useStyles = makeStyles({
  customStack: {
    paddingTop: '0 !important',
  },
});

const Container = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  alignItems: 'center',
  width: '80%',
  margin: '0 auto',
  justifyContent: 'center',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const FilterHeader = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'grid',
  width: '100%',
  // padding: '0.5rem',
  fontSize: '0.89rem',
  gridTemplateColumns: '0.5fr 1fr 1fr 1fr 0.5fr 0.5fr',
  justifyContent: 'end',
  gap: '0.5rem',
  borderBottom: '1px solid #e0e0e0',
}));

const EachItem = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  // backgroundColor: 'rgb(23, 193, 232)',
  color: 'white',
  padding: '0.5rem',
  borderRadius: '5px',
  cursor: 'pointer',
}));
const action_service: any[] = [
  { value: 'capture', label: 'iCapturer' },
  { value: 'characterizer', label: 'iCharacterizer' },
  { value: 'allocator', label: 'iAllocator' },
  { value: 'DocToDataset', label: 'Similarity Analysis' },
  { value: 'DocToDoc', label: 'Document similiarity' },
  { value: 'docdiffer', label: 'Compare versions' },
  { value: 'SelfDoc', label: 'Find duplicates' },
  { value: 'ner', label: 'Extract topics' },
  { value: 'Translator', label: 'Translator' },
  { value: 'RexBook', label: 'RexBook' },
  { value: 'ScheduleAnalysis', label: 'Schedule Analysis' },
];

const ProjectTable = ({ props, rows, totalCount, currentPage, handleChangePage }) => {
  return (
    <>
      <RowItem style={{ fontWeight: 'bold' }}>
        <div>ID</div>
        <div>Resources</div>
        <div>Action</div>
        <div>Status</div>
        <div>Created By</div>
        <div>Created At</div>
      </RowItem>
      {rows.map((item) => {
        return (
          <>
            <ServiceItem {...props} data={item} />
          </>
        );
      })}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'sticky',
          bottom: 0,
          backgroundColor: 'white',
          padding: '0.3rem',
          width: '100%',
        }}
      >
        <Pagination
          count={Math.ceil(totalCount / 10)}
          variant="outlined"
          shape="rounded"
          page={currentPage}
          onChange={handleChangePage}
        />
      </div>
    </>
  );
};

const ProjectHistory = (props) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const { modelService, workload } = props;
  const classes = useStyles();
  const today = dayjs();
  const yesterday = dayjs().add(-1, 'day');

  // console.log('yesterday', yesterday.format('YYYY-MM-DD'));
  const ActionOptions = [''];
  const [filterColumns, setFilterColumns] = React.useState({
    activityId: '',
    modules: [],
    action: [],

    fromDate: '',
    toDate: '',
  });

  const { historyData, getWorkLoadAll } = useProjectHistory({
    serviceName: modelService,
    workload,
    currentPage,
    filterColumns,
  });

  const { loading, rows, totalCount } = historyData;

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setCurrentPage(newPage);
  };
  const handleReset = () => {
    setFilterColumns({
      activityId: '',
      modules: [],
      action: [],
      fromDate: '',
      toDate: '',
    });
    getWorkLoadAll(currentPage, 'none');
  };
  const handleApply = () => {
    getWorkLoadAll(currentPage, 'none', filterColumns);
  };
  const handleActivityIdChange = (e) => {
    setFilterColumns({ ...filterColumns, activityId: e.target.value });
  };
  const handleFromDateChange = (e) => {
    if (dayjs(e).isAfter(yesterday)) {
      setFilterColumns({ ...filterColumns, fromDate: '' });
      alert('Please select a date before today');
    } else if (e !== null) {
      setFilterColumns({ ...filterColumns, fromDate: e.format('YYYY-MM-DD') });
    }
  };

  const handleToDateChange = (e) => {
    if (dayjs(e).isAfter(today)) {
      setFilterColumns({ ...filterColumns, toDate: '' });
      alert('Please select a date within today');
    } else if (e !== null) {
      setFilterColumns({ ...filterColumns, toDate: e.format('YYYY-MM-DD') });
    }
  };
  const handleChangeAction = (event) => {
    const {
      target: { value },
    } = event;

    if (value.length === 0) {
      setFilterColumns({ ...filterColumns, action: [] });
    } else {
      setFilterColumns((prev) => {
        let currentActions = [...prev.action];

        value.forEach((item) => {
          if (!currentActions.includes(item)) {
            currentActions.push(item);
          }
          currentActions = currentActions.filter((item) => value.includes(item));
        });
        return { ...prev, action: currentActions };
      });
    }
  };

  return (
    <Container>
      <FilterHeader>
        <EachItem>
          <TextField
            style={{ flex: 1, backgroundColor: 'white', color: '#002A45', height: '40px' }}
            size="small"
            type="number"
            id="outlined-basic"
            label="Activity Id"
            variant="outlined"
            value={Number(filterColumns.activityId)}
            onChange={handleActivityIdChange}
          />
        </EachItem>

        {/* {[1].map((item) => {
          return <EachItem>{item}</EachItem>;
        })}{' '} */}
        <EachItem>
          <FormControl size="small" style={{ width: '100%', backgroundColor: 'white' }}>
            <InputLabel id="demo-multiple-checkbox-label">Action</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={filterColumns.action}
              onChange={handleChangeAction}
              size="small"
              input={<OutlinedInput label="Action" size="small" />}
              renderValue={(selected) => selected.join(', ')}
              style={{ width: '100%' }}
            >
              {action_service.map((item) => (
                <MenuItem key={item.value} value={item.value} style={{ height: '40px' }}>
                  <Checkbox checked={filterColumns.action.includes(item.value)} />
                  <ListItemText primary={item.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </EachItem>
        <EachItem>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <DatePicker
                label="From date"
                slotProps={{
                  textField: {
                    size: 'small',
                    sx: {
                      paddingTop: 0,
                      backgroundColor: 'white',
                      color: '#002A45',
                    },
                  },
                }}
                value={dayjs(filterColumns.fromDate)}
                maxDate={yesterday}
                onChange={handleFromDateChange}
              />
            </DemoContainer>
          </LocalizationProvider>
        </EachItem>
        <EachItem>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <DatePicker
                label="To Date"
                slotProps={{
                  textField: {
                    size: 'small',
                    sx: {
                      backgroundColor: 'white',
                      color: '#002A45',
                    },
                  },
                }}
                value={dayjs(filterColumns.toDate)}
                minDate={dayjs(filterColumns.fromDate)}
                maxDate={today}
                onChange={handleToDateChange}
              />
            </DemoContainer>
          </LocalizationProvider>
        </EachItem>
        <EachItem>
          {' '}
          <Button
            style={{
              backgroundColor: 'rgb(23, 193, 232)',
              color: 'rgb(255, 255, 255)',
              width: '100%',
            }}
            onClick={handleApply}
          >
            Apply
          </Button>
        </EachItem>
        <EachItem>
          {' '}
          <Button
            style={{
              backgroundColor: 'rgb(23, 193, 232)',
              color: 'rgb(255, 255, 255)',
              width: '100%',
            }}
            onClick={handleReset}
          >
            Reset
          </Button>
        </EachItem>
      </FilterHeader>

      <ProjectTable
        props={props}
        rows={rows}
        totalCount={totalCount}
        currentPage={currentPage}
        handleChangePage={handleChangePage}
      />
    </Container>
  );
};

export default ProjectHistory;
