import React, { useContext } from 'react';
import {
  UserWorkloadCreate,
  userWorkloadRunDocToDocPDF,
  userWorkloadRunPost,
  userWorkloadRunPostPDF,
  UserWorkloadRunPostPdfCsv,
  workloadCreate,
  workloadRunDocToDocPDF,
  workloadRunPost,
  workloadRunPostPDF,
} from '../../api/workloads';
import { iCaptureCsv, iCaptureExcel } from '../../api/module1';
import M2GlobalContext from '../ContextMager/M2GlobalContext';

const useModule2services = (props) => {
  const docOperation = [
    'Similarity Analysis',
    'Document Similarity',
    'Compare Versions',
    'Extract Topics',
    // 'Translate',
    'Find Duplicates',
  ];

  //   const [captureName, setCaptureName] = React., boolValueuseState('');
  const { scopeSetting, fileSetting, captureName, boolValue, serviceName, serviceFor } = props;
  const [loadingResult, setLoadingResult] = React.useState(false);
  //   const [boolValue, setBoolValue] = React.useState(false);
  const { state, dispatch } = useContext(M2GlobalContext);
  const { similarityFilter } = state;
  const {
    simiProductLine,
    simiPlatform,
    simiSolution,
    simiProduct,
    simiProject,
    simiDocumentProfile,
    selectedSimiProductLine,
    selectedSimiPlatform,
    selectedSimiSolution,
    selectedSimiProduct,
    selectedSimiProject,
    selectedSimiDocumentProfile,
    countValue,
    threshold,
  } = similarityFilter;

  const WorkloadCreateion = async (payload) => {
    const { name, id } = serviceFor;
    if (name === 'user') {
      const newWorkload = await UserWorkloadCreate({ ...payload });
      return newWorkload;
    }

    if (name === 'project') {
      const newWorkload = await workloadCreate({
        ...payload,
        project_id: id,
      });
      return newWorkload;
    }
    return null;
  };

  const HandleValidation = () => {
    const FileterkeysSettings = Object.keys(fileSetting);
    for (let i = 0; i < FileterkeysSettings.length - 1; i += 1) {
      const element = FileterkeysSettings[i];
      if (fileSetting[element]?.fileType === 'Excel') {
        if (!fileSetting[element]?.sheetName) {
          //   setSnack('Please Enter Excel Sheet Column', 'error');
          return { error: true, message: 'Please Enter Excel Sheet Column' };
        }
        if (!fileSetting[element]?.targetColumn) {
          //   setSnack('Please Enter Excel Target Column', 'error');
          return { error: true, message: 'Please Enter Excel Target Column' };
        }
      } else if (fileSetting[element].fileType === 'CSV') {
        if (!fileSetting[element]?.columnName) {
          //   setSnack('Please Enter CSV Column Name', 'error');
          return { error: true, message: 'Please Enter CSV Column Name' };
        }
      }
    }
    if (!captureName) {
      //   setSnack('Please Enter Activity Name', 'error');
      return { error: true, message: 'Please Enter Activity Name' };
    }
    return true;
  };

  const handleAnalyzePdfiles = async (service: string) => {
    let servicesType: any = null;
    if (captureName.trim().length === 0) {
      //   setSnack('Enter activity name', 'error');
      return { error: true, message: 'Enter activity name' };
    }
    setLoadingResult(true);
    let fileone = null;
    let filetwo = null;
    if (scopeSetting[0].FileType === 'PDF') {
      fileone = {
        output: { NameOfCapture: captureName, FileName: scopeSetting[0].Blob },
        TextLength: 10000,
        settingsModule: {
          FileName: scopeSetting[0].Blob,
          FilePath: scopeSetting[0].FilePath,
          Container: scopeSetting[0].Container,
          BlobName: scopeSetting[0].Blob,
          id: scopeSetting[0].Blob,
          FileType: 'pdf',
          FileIndex: 0,
          Language: scopeSetting[0].Language,
          StartPage: 0,
          EndPage: 0,
          HeaderMargin: 0,
          FooterMargin: 0,
          TypesOfCapture: 'Paragraph_Capture',
          RegId: '',
          TableSwitch: true,
          MergeBullet: false,
          SmartHeaderFooter: true,
          SmartTableOfContent: true,
          TranslateTo: ['en'],
          NameOfCapture: captureName,
        },
      };
    }
    if (scopeSetting[1].FileType === 'PDF') {
      filetwo = {
        output: { NameOfCapture: captureName, FileName: scopeSetting[1].Blob },
        TextLength: 10000,
        settingsModule: {
          FileName: scopeSetting[1].Blob,
          FilePath: scopeSetting[1].FilePath,
          Container: scopeSetting[1].Container,
          BlobName: scopeSetting[1].Blob,
          id: scopeSetting[1].Blob,
          FileType: 'pdf',
          FileIndex: 1,
          Language: scopeSetting[1].Language,
          StartPage: 0,
          EndPage: 0,
          HeaderMargin: 0,
          FooterMargin: 0,
          TypesOfCapture: 'Paragraph_Capture',
          RegId: '',
          TableSwitch: true,
          MergeBullet: false,
          SmartHeaderFooter: true,
          SmartTableOfContent: true,
          TranslateTo: ['en'],
          NameOfCapture: captureName,
        },
      };
    }

    if (scopeSetting[0].FileType === 'PDF' && scopeSetting[1].FileType === 'PDF') {
      const bothPdfFile: any = [fileone, filetwo];
      if (service === 'DocToDoc') {
        servicesType = 'DocToDocPDF';
      }
      if (service === 'docdiffer') {
        servicesType = 'm2-docdifferPDF';
      }

      try {
        const newWorkload = await WorkloadCreateion({
          app: 'module2',
          action: service,
          resources: bothPdfFile,
        });

        if (serviceFor.name === 'user') {
          const result = await userWorkloadRunDocToDocPDF(
            Number(newWorkload.id),
            bothPdfFile,
            servicesType
          );
        }
        if (serviceFor.name === 'project') {
          const result = await workloadRunDocToDocPDF(
            Number(newWorkload.id),
            bothPdfFile,
            servicesType
          );
        }
        setLoadingResult(false);

        return { error: false, message: 'success', workloadId: newWorkload.id };
      } catch (error) {
        setLoadingResult(false);

        return { error: true, message: 'Error while workload post.' };
      }

      //   setSnack('Error while workload post.', 'error');
    } else {
      let filePDF: any = null;
      let fileIndex: any = null;
      if (service === 'DocToDoc') {
        servicesType = 'm2-doctodoc-pdf-csv';
      }
      if (service === 'docdiffer') {
        servicesType = 'm2-docdiffer-pdf-csv';
      }
      if (scopeSetting[0].FileType === 'PDF') {
        filePDF = fileone;
        fileIndex = 1;
      }
      if (scopeSetting[1].FileType === 'PDF') {
        filePDF = filetwo;
        fileIndex = 0;
      }
      const allPromisesFun = [scopeSetting[fileIndex]].map((data, index) => {
        if (data.FileType === 'Excel') {
          const body = {
            FilePath: data.FilePath,
            Container: data.Container,
            BlobName: data.Blob,
            NameOfCapture: captureName,
            Sheet: fileSetting[index].sheetName,
            TargetColumn: fileSetting[index].targetColumn,
            ColumnNames: fileSetting[index].columns,
            Header: true,
          };
          return () => {
            return iCaptureExcel(body);
          };
        }

        if (data.FileType === 'CSV') {
          const body = {
            FilePath: data.FilePath,
            Container: data.Container,
            BlobName: data.Blob,
            NameOfCapture: captureName,
            TargetColumn: fileSetting[index].columnName,
            ColumnNames: fileSetting[index].columnNames,
            Header: true,
          };
          return () => {
            return iCaptureCsv(body);
          };
        }
        return null;
      });

      try {
        const allresponse = await Promise.all(allPromisesFun.map((fn) => fn()));

        let len1 = null;
        let len2 = null;
        if (fileIndex === 0) {
          const len1 =
            JSON.parse(allresponse[0].output?.Text)?.length > 0
              ? JSON.parse(allresponse[0].output?.Text)?.length
              : 0;
          len2 = 10000;
        }
        if (fileIndex === 1) {
          const len2 =
            JSON.parse(allresponse[1].output?.Text)?.length > 0
              ? JSON.parse(allresponse[1].output?.Text)?.length
              : 0;
          len1 = 10000;
        }
        if (len1 === 0 || len2 === 0) {
          // setSnack('Invalid file format', 'error');
          return { error: true, message: 'Invalid file format' };
        }
        const TextLen = len1 > len2 ? len1 : len2;
        const filterScopeSettings = scopeSetting.map((data, index) => {
          if (data.FileType === 'Excel') {
            return {
              ...data,
              Sheet: data?.Sheets?.filter(
                (data) => data.SheetName === fileSetting[index].sheetName
              )[0],
            };
          }
          return data;
        });

        const r = allresponse.map((data, index) => {
          return {
            ...data,
            FileName: filterScopeSettings[index].Blob,
            scopeSettingOP: [filterScopeSettings[index]],
            TextLength: TextLen,
            filesPDF: filePDF,
          };
        });

        const workloadNew = await WorkloadCreateion({
          app: 'module2',
          action: service,
          resources: r,
        });

        if (service === 'DocToDoc') {
          const result = await UserWorkloadRunPostPdfCsv(Number(workloadNew.id), r, servicesType);

          setLoadingResult(false);
          return { error: false, message: 'Success', workloadId: workloadNew.id };
        }
        const result = await UserWorkloadRunPostPdfCsv(Number(workloadNew.id), r, servicesType);
        setLoadingResult(false);
        return { error: false, message: 'Success', workloadId: workloadNew.id };
      } catch (error) {
        return { error: true, message: 'Error while workload post.' };
      }
    }
  };

  const TwoFileAnalysis = async (service: string) => {
    const fileExtensionOne = scopeSetting[0]?.FileName.toLowerCase().split('.').pop();
    const fileExtensionTwo = scopeSetting[1]?.FileName.toLowerCase().split('.').pop();
    if (fileExtensionOne === 'pdf' || fileExtensionTwo === 'pdf') {
      const result = await handleAnalyzePdfiles(service);
      return result;
    }

    if (HandleValidation()) {
      setLoadingResult(true);
      const allPromisesFun = scopeSetting.map((data, index) => {
        if (data.FileType === 'Excel') {
          const body = {
            FilePath: data.FilePath,
            Container: data.Container,
            BlobName: data.Blob,
            NameOfCapture: captureName,
            Sheet: fileSetting[index].sheetName,
            TargetColumn: fileSetting[index].targetColumn,
            ColumnNames: fileSetting[index].columns,
            Header: true,
          };
          return () => {
            return iCaptureExcel(body);
          };
        }

        if (data.FileType === 'CSV') {
          const body = {
            FilePath: data.FilePath,
            Container: data.Container,
            BlobName: data.Blob,
            NameOfCapture: captureName,
            TargetColumn: fileSetting[index].columnName,
            ColumnNames: fileSetting[index].columnNames,
            Header: true,
          };
          return () => {
            return iCaptureCsv(body);
          };
        }
        return null;
      });
      const allresponse = await Promise.all(allPromisesFun.map((fn) => fn()));
      try {
        const len1 =
          JSON.parse(allresponse[0].output?.Text)?.length > 0
            ? JSON.parse(allresponse[0].output?.Text)?.length
            : 0;
        const len2 =
          JSON.parse(allresponse[1].output?.Text)?.length > 0
            ? JSON.parse(allresponse[1].output?.Text)?.length
            : 0;
        if (len1 === 0 || len2 === 0) {
          return { error: true, message: 'Invalid file format' };
        }
        const TextLen = len1 > len2 ? len1 : len2;
        const filterScopeSettings = scopeSetting.map((data, index) => {
          if (data.FileType === 'Excel') {
            return {
              ...data,
              Sheet: data?.Sheets?.filter(
                (data) => data.SheetName === fileSetting[index].sheetName
              )[0],
            };
          }
          return data;
        });

        const r = allresponse.map((data, index) => {
          return {
            ...data,
            FileName: filterScopeSettings[index].Blob,
            scopeSettingOP: [filterScopeSettings[index]],
            TextLength: TextLen,
          };
        });

        const workloadNew = await WorkloadCreateion({
          app: 'module2',
          action: service,
          resources: r,
        });
        if (serviceFor.name === 'user') {
          const result = await userWorkloadRunPost(
            Number(workloadNew.id),
            r,
            `m2-${service.toLowerCase()}`
          );
        }

        if (serviceFor.name === 'project') {
          const result = await workloadRunPost(
            Number(workloadNew.id),
            r,
            `m2-${service.toLowerCase()}`
          );
        }

        setLoadingResult(false);
        return { error: false, message: 'Success', workloadId: workloadNew.id };
      } catch (error) {
        setLoadingResult(false);
        return { error: true, message: 'Error while capture.' };
      }
    }

    return { error: false, message: 'Success' };
  };

  const docToDatasetPdfFile = async () => {
    if (!captureName) {
      return { error: true, message: 'Please Enter Activity Name' };
    }
    // start here
    let entityIs: any = [];
    let platform: any = [];
    let sol: any = [];
    let prod: any = [];
    let project: any = [];
    let documentProfile: any = [];
    // console.log(alignment);
    selectedSimiProductLine.forEach((obj: any) => {
      entityIs.push(obj.value);
    });
    selectedSimiPlatform.forEach((obj: any) => {
      platform.push(obj.value);
    });
    selectedSimiProject.forEach((obj: any) => {
      project.push(obj.value);
    });
    selectedSimiDocumentProfile.forEach((obj: any) => {
      documentProfile.push(obj.value);
    });
    selectedSimiSolution.forEach((obj: any) => {
      sol.push(obj.value);
    });
    selectedSimiProduct.forEach((obj: any) => {
      prod.push(obj.value);
    });

    if (entityIs.length === 0 || entityIs.length === simiProductLine.length) {
      entityIs = ['*'];
    }
    if (platform.length === 0 || platform.length === simiPlatform.length) {
      platform = ['*'];
    }
    if (sol.length === 0 || sol.length === simiSolution.length) {
      sol = ['*'];
    }
    if (prod.length === 0 || prod.length === simiProduct.length) {
      prod = ['*'];
    }
    if (project.length === 0 || project.length === simiProject.length) {
      project = ['*'];
    }
    if (documentProfile.length === 0 || documentProfile.length === simiDocumentProfile.length) {
      documentProfile = ['*'];
    }
    // done here
    const generateComplianceStatus = boolValue;
    const r = [
      {
        output: { NameOfCapture: captureName, FileName: scopeSetting[0].Blob },
        TextLength: 100000,
        settingsModule: {
          FileName: scopeSetting[0].Blob,
          FilePath: scopeSetting[0].FilePath,
          Container: scopeSetting[0].Container,
          BlobName: scopeSetting[0].Blob,
          id: scopeSetting[0].Blob,
          FileType: 'pdf',
          Language: scopeSetting[0].Language,
          StartPage: 0,
          EndPage: 0,
          HeaderMargin: 0,
          FooterMargin: 0,
          TypesOfCapture: 'Paragraph_Capture',
          RegId: '',
          TableSwitch: true,
          MergeBullet: false,
          SmartHeaderFooter: true,
          SmartTableOfContent: true,
          TranslateTo: ['en'],
          NameOfCapture: captureName,
          modeService: 'DocToDataset',
        },
        doctodatset: {
          businessEntity: entityIs,
          generateComplianceStatus,
          platform,
          solution: sol,
          product: prod,
          project,
          documentProfile,
        },
      },
    ];
    try {
      const newWorkload = await WorkloadCreateion({
        app: 'module2',
        action: 'DocToDataset',
        resources: r,
      });

      if (serviceFor.name === 'user') {
        const result = await userWorkloadRunPostPDF(Number(newWorkload.id), r, 'm2-multi-text');
      }

      if (serviceFor.name === 'project') {
        const result = await workloadRunPostPDF(Number(newWorkload.id), r, 'm2-multi-text');
      }

      // console.log('Run post', data);
      setLoadingResult(false);
      return { error: false, message: 'Success', workloadId: newWorkload.id };
    } catch (error) {
      setLoadingResult(false);
      return { error: true, message: 'Error while workload create.' };
    }
  };

  const SimilarityAnalysis = async (service: string) => {
    if (scopeSetting[0]?.FileType === 'PDF') {
      const result = await docToDatasetPdfFile();
      return result;
    }

    if (HandleValidation()) {
      setLoadingResult(true);
      let entityIs: any = [];
      let platform: any = [];
      let project: any = [];
      let documentProfile: any = [];
      let sol: any = [];
      let prod: any = [];
      selectedSimiProductLine.forEach((obj: any) => {
        entityIs.push(obj.value);
      });
      selectedSimiPlatform.forEach((obj: any) => {
        platform.push(obj.value);
      });
      selectedSimiProject.forEach((obj: any) => {
        project.push(obj.value);
      });
      selectedSimiDocumentProfile.forEach((obj: any) => {
        documentProfile.push(obj.value);
      });
      selectedSimiSolution.forEach((obj: any) => {
        sol.push(obj.value);
      });
      selectedSimiProduct.forEach((obj: any) => {
        prod.push(obj.value);
      });

      if (entityIs.length === 0 || entityIs.length === simiProductLine.length) {
        entityIs = ['*'];
      }
      if (platform.length === 0 || platform.length === simiPlatform.length) {
        platform = ['*'];
      }
      if (project.length === 0 || project.length === simiProject.length) {
        project = ['*'];
      }
      if (documentProfile.length === 0 || documentProfile.length === simiDocumentProfile.length) {
        documentProfile = ['*'];
      }
      if (sol.length === 0 || sol.length === simiSolution.length) {
        sol = ['*'];
      }
      if (prod.length === 0 || prod.length === simiProduct.length) {
        prod = ['*'];
      }

      const allPromisesFun = scopeSetting.map((data, index) => {
        if (data.FileType === 'Excel') {
          const body = {
            FilePath: data.FilePath,
            Container: data.Container,
            BlobName: data.Blob,
            NameOfCapture: 'm2-iSimilarizer_Doc2Dataset_Excel',
            Sheet: fileSetting[index].sheetName,
            TargetColumn: fileSetting[index].targetColumn,
            ColumnNames: fileSetting[index].columns,
            Header: true,
            businessEntity: entityIs,
            platform,
            project,
            documentProfile,
          };
          return () => {
            return iCaptureExcel(body);
          };
        }

        if (data.FileType === 'CSV') {
          const body = {
            FilePath: data.FilePath,
            Container: data.Container,
            BlobName: data.Blob,
            NameOfCapture: 'm2-iSimilarizer_Doc2Dataset_Csv',
            TargetColumn: fileSetting[index].columnName,
            ColumnNames: fileSetting[index].columnNames,
            Header: true,
          };
          return () => {
            return iCaptureCsv(body);
          };
        }
        return null;
      });

      const allResponse = await Promise.all(allPromisesFun.map((fn) => fn()));
      try {
        const len =
          JSON.parse(allResponse[0].output.Text)?.length > 0
            ? JSON.parse(allResponse[0].output.Text)?.length
            : 0;
        if (len === 0) {
          return { error: true, message: 'Invalid file format' };
        }

        const rPayload = scopeSetting.map((data, index) => {
          if (data.FileType === 'Excel') {
            const r = {
              Text: allResponse[0].output.Text,
              TextLength: len,
              selectedColumn: fileSetting[index].targetColumn,
              generateComplianceBoolean: boolValue,
              entityPassed: entityIs,
              platformPassed: platform,
              projectPassed: project,
              documentProfilePassed: documentProfile,
              solutionPassed: sol,
              productPassed: prod,
              FileName: data.Blob,
              FilePath: data.FilePath,
              NameOfCapture: captureName,
              Sheet: fileSetting[index].sheetName,
            };
            return r;
          }
          if (data.FileType === 'CSV') {
            const r = {
              Text: allResponse[0].output.Text,
              TextLength: len,
              selectedColumn: fileSetting[index].columnName,
              generateComplianceBoolean: boolValue,
              entityPassed: entityIs,
              platformPassed: platform,
              projectPassed: project,
              documentProfilePassed: documentProfile,
              solutionPassed: sol,
              productPassed: prod,
              FileName: data.Blob,
              FilePath: data.FilePath,
              NameOfCapture: captureName,
            };
            return r;
          }
          return null;
        });
        const newWorkload = await WorkloadCreateion({
          app: 'module2',
          action: 'DocToDataset',
          resources: rPayload,
        });

        const _payload = {
          dataframe: { Text: allResponse[0].output.Text },
          column_name: fileSetting[0].columnName ?? fileSetting[0].targetColumn,
          generateComplianceStatus: boolValue,
          businessEntity: entityIs,
          platform,
          project,
          documentProfile,
          solution: sol,
          product: prod,
        };

        if (serviceFor.name === 'user') {
          const filnalResponse = await userWorkloadRunPost(
            Number(newWorkload.id),
            {
              ..._payload,
            },
            'm2-multi-text'
          );
        }

        if (serviceFor.name === 'project') {
          const filnalResponse = await workloadRunPost(
            Number(newWorkload.id),
            {
              ..._payload,
            },
            'm2-multi-text'
          );
        }

        setLoadingResult(false);
        return { error: false, message: 'Success', workloadId: newWorkload.id };
      } catch (error) {
        setLoadingResult(false);
        return { error: true, message: 'Error while capture.' };
      }
    }
    return { error: false, message: 'Success' };
  };

  const handleOneFileServise = async (service: string) => {
    if (HandleValidation()) {
      setLoadingResult(true);
      // console.log(scopeSetting);
      if (scopeSetting[0].FileType === 'PDF') {
        const r = [
          {
            output: { NameOfCapture: captureName, FileName: scopeSetting[0].Blob },
            TextLength: 100000,
            settingsModule: {
              FileName: scopeSetting[0].Blob,
              FilePath: scopeSetting[0].FilePath,
              Container: scopeSetting[0].Container,
              BlobName: scopeSetting[0].Blob,
              id: scopeSetting[0].Blob,
              FileType: 'pdf',
              Language: scopeSetting[0].Language,
              StartPage: 0,
              EndPage: 0,
              HeaderMargin: 0,
              FooterMargin: 0,
              TypesOfCapture: 'Paragraph_Capture',
              RegId: '',
              TableSwitch: true,
              MergeBullet: false,
              SmartHeaderFooter: true,
              SmartTableOfContent: true,
              TranslateTo: ['en'],
              NameOfCapture: captureName,
              modeService: 'SelfDoc',
            },
          },
        ];

        try {
          const newWorkload = await WorkloadCreateion({
            app: 'module2',
            action: 'SelfDoc',
            resources: r,
          });

          if (serviceFor.name === 'user') {
            const result = await userWorkloadRunPostPDF(Number(newWorkload.id), r, 'm2-selfdoc');
          }

          if (serviceFor.name === 'project') {
            const result = await workloadRunPostPDF(Number(newWorkload.id), r, 'm2-selfdoc');
          }

          setLoadingResult(false);
          return { error: false, message: 'success', workloadId: newWorkload.id };
        } catch (error) {
          setLoadingResult(false);
          return { error: true, message: 'Error while workload create.' };
        }
      } else {
        const allPromisesFun = scopeSetting.map((data, index) => {
          if (data.FileType === 'Excel') {
            const body = {
              FilePath: data.FilePath,
              Container: data.Container,
              BlobName: data.Blob,
              NameOfCapture: captureName,
              Sheet: fileSetting[index].sheetName,
              TargetColumn: fileSetting[index].targetColumn,
              ColumnNames: fileSetting[index].columns,
              Header: true,
            };
            return () => {
              return iCaptureExcel(body);
            };
          }

          if (data.FileType === 'CSV') {
            const body = {
              FilePath: data.FilePath,
              Container: data.Container,
              BlobName: data.Blob,
              NameOfCapture: captureName,
              TargetColumn: fileSetting[index].columnName,
              ColumnNames: fileSetting[index].columnNames,
              Header: true,
            };
            return () => {
              return iCaptureCsv(body);
            };
          }
          return null;
        });
        try {
          const allResponse = await Promise.all(allPromisesFun.map((fn) => fn()));
          // console.log(payload);
          const len =
            JSON.parse(allResponse[0]?.output?.Text)?.length > 0
              ? JSON.parse(allResponse[0]?.output?.Text)?.length
              : 0;
          if (len === 0) {
            return { error: true, message: 'Invalid file format' };
          }

          const filterScopeSettings = scopeSetting.map((data, index) => {
            if (data.FileType === 'Excel') {
              return {
                ...data,
                Sheet: data?.Sheets?.filter(
                  (data) => data.SheetName === fileSetting[index].sheetName
                )[0],
                SheetName: fileSetting[index].sheetName,
                TargetColumn: fileSetting[index].targetColumn,
              };
            }

            if (data.FileType === 'CSV') {
              return {
                ...data,
                TargetColumn: fileSetting[index].columnName,
                ColumnNames: fileSetting[index].columnNames,
              };
            }
            return data;
          });

          const r = allResponse.map((data, index) => {
            return {
              ...data,
              FileName: filterScopeSettings[index].Blob,
              scopeSettingOP: filterScopeSettings,
              TextLength: len,
            };
          });
          const newWorkload = await WorkloadCreateion({
            app: 'module2',
            action: 'SelfDoc',
            resources: r,
          });

          if (serviceFor.name === 'user') {
            const result = await userWorkloadRunPost(Number(newWorkload.id), r, 'm2-selfdoc');
          }

          if (serviceFor.name === 'project') {
            const result = await workloadRunPost(Number(newWorkload.id), r, 'm2-selfdoc');
          }

          setLoadingResult(false);

          return { error: false, message: 'success', workloadId: newWorkload.id };
        } catch (error) {
          setLoadingResult(false);
          return { error: true, message: 'Error while capture.' };
        }
      }
    }
    return { error: false, message: 'success' };
  };

  const handleExtractTopicsAnalysis = async (serv) => {
    if (HandleValidation()) {
      // setLoadingResult(true);
      // console.log(scopeSetting);
      const containerIs = scopeSetting[0].Container;
      const FilterData = scopeSetting.map((data, index) => {
        if (data.FileType === 'Excel') {
          return {
            Container: data.Container,
            Blob: data.Blob,
            SheetName: fileSetting[index].sheetName,
            TargetColumn: fileSetting[index].targetColumn,
          };
        }
        if (data.FileType === 'CSV') {
          return {
            Blob: data.Blob,
            ColumnNamesNoHeader: data.ColumnNamesNoHeader,
            Container: data.Container,
            LanguageCode: data.LanguageCode,
            TargetColumn: fileSetting[index].columnName,
            ColumnNames: fileSetting[index].columnNames,
            TextNoHeader: data.TextNoHeader,
          };
        }
        if (data.FileType === 'PDF') {
          return {
            Blob: data.Blob,
            Container: data.Container,
            LanguageCode: data.LanguageCode,
            TargetColumn: 'forced',
          };
        }
        return null;
      });

      const body = {
        Container: containerIs,
        BlobFiles: FilterData,
        nameOfCapture: captureName,
      };
      try {
        const workloadNew = await WorkloadCreateion({
          app: 'module2',
          action: 'iner',
          resources: body,
        });

        if (serviceFor.name === 'user') {
          const result = await userWorkloadRunPost(
            Number(workloadNew.id),
            body,
            'm2-onto-param-analysis'
          );
        }

        if (serviceFor.name === 'project') {
          const result = await workloadRunPost(
            Number(workloadNew.id),
            body,
            'm2-onto-param-analysis'
          );
        }
        setLoadingResult(false);
        return { error: false, message: 'success', workloadId: workloadNew.id };
      } catch (error) {
        setLoadingResult(false);
        return { error: true, message: 'Error while workload create.' };
      }
    }
    return { error: false, message: 'success' };
  };

  const handleAnalyze = async () => {
    switch (serviceName) {
      case 'Similarity Analysis': {
        const reuslt = await SimilarityAnalysis('DocToDataset');
        return reuslt;
      }
      // return null;
      case 'Document Similarity': {
        const reuslt = await TwoFileAnalysis('DocToDoc');
        return reuslt;
      }
      case 'Compare Versions': {
        const reuslt = await TwoFileAnalysis('docdiffer');
        return reuslt;
      }
      case 'Extract Topics': {
        const reuslt = await handleExtractTopicsAnalysis('iner');
        return reuslt;
      }
      case 'Find Duplicates': {
        const reuslt = await handleOneFileServise('SelfDoc');
        return reuslt;
      }
      default:
        return { error: true, message: 'Invalid service' };
    }
  };

  return { handleAnalyze, loadingResult };
};

export default useModule2services;
