import React, { useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import InboxIcon from '@mui/icons-material/Inbox';
import PsychologyIcon from '@mui/icons-material/Psychology';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import FolderIcon from '@mui/icons-material/Folder';
import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';

import WorkIcon from '@mui/icons-material/Work';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import BallotIcon from '@mui/icons-material/Ballot';

import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';

import ProjectConfigContainer from '../ProjectConfigContainer';
import ProjectReq from '../ProjectReq';
import ProjectAnalysisContainer from '../ProjectAnalysisContainer';
import ProjectInbox from './ProjectInBox';
import ProjectConfiguration from './ProjectConfiguration';
import ProjectKPI from './ProjectKPI';
import ProjectActivityContainer from './ProjectActivityContainer';
import ProjectRequirements from './ProjectRequirement';
import ModuleContainer from '../../Home/components/ModuleContainer';
import ProjectFolder from './components/ProjectFolder/ProjectFolder';
import ProjectContext from '../ProjectContext';
import { getProjectbyID } from '../../../api/projects';
import LayoutContainer from '../../../layouts/LayoutContainer';
import ProjectHistory from '../../../components/History/ProjectHistory';

const ProjectComponents = () => {
  const { setProjects, projects, getProjectDetails } = useContext(ProjectContext);
  const { projectId } = useParams();
  const project = getProjectDetails(Number(projectId));

  useEffect(() => {
    if (project) {
      getProjectbyID(projectId).then((data) => {
        const obj = {
          ...project,
          folders: data[0]?.folders,
        };
        const arr = [];
        arr.push(obj);
        setProjects(arr);
      });
    }
  }, [projectId, project]);

  const componentsData = useMemo(
    () => [
      {
        name: 'AI Modules',
        projectName: project?.name ?? '',
        icon: <PsychologyIcon fontSize="small" />,
        route: `/project/${projectId}/Modules`,
        renderComponent: () => {
          return (
            <>
              <ModuleContainer />
            </>
          );
        },
        enableLeftOption: false,
      },
      {
        name: 'Project Activity',
        projectName: project?.name ?? '',
        icon: <PlaylistAddCheckOutlinedIcon fontSize="small" />,
        route: `/project/${projectId}`,
        renderComponent: (porps) => {
          return (
            <>
              <ProjectHistory {...porps} />
            </>
          );
        },
        enableLeftOption: false,
      },
      {
        name: 'Folders',
        projectName: project?.name ?? '',
        icon: <FolderIcon fontSize="small" />,
        route: `/project/${projectId}/folders`,
        renderComponent: () => {
          return (
            <>
              <ProjectFolder />
            </>
          );
        },
        enableLeftOption: false,
      },
      {
        name: 'iDocExplorer',
        projectName: project?.name ?? '',
        icon: <FolderIcon fontSize="small" />,
        route: `/project/${projectId}/iDocExplorer`,
        renderComponent: () => {
          return <>{/* <ProjectFolder /> */}</>;
        },
        enableLeftOption: false,
      },
      {
        name: 'Configuration',
        projectName: project?.name ?? '',
        icon: <SettingsOutlinedIcon fontSize="small" />,
        route: `/project/${projectId}/configuration`,
        renderComponent: () => {
          return (
            <>
              <ProjectConfigContainer />
            </>
          );
        },
        enableLeftOption: true,
        leftOption: [
          {
            name: 'Properties',
            icon: <WorkIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <ProjectConfiguration {...props} />
                </>
              );
            },
          },
          {
            name: 'Users',
            icon: <PersonRoundedIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <ProjectConfiguration {...props} />
                </>
              );
            },
          },
          {
            name: 'Groups',
            icon: <GroupRoundedIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <ProjectConfiguration {...props} />
                </>
              );
            },
          },
          {
            name: 'Attributes',
            icon: <BallotIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <ProjectConfiguration {...props} />
                </>
              );
            },
          },
          {
            name: 'iVerifier',
            icon: <BallotIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <ProjectConfiguration {...props} />
                </>
              );
            },
          },
          {
            name: 'iQualimeter',
            icon: <BallotIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <ProjectConfiguration {...props} />
                </>
              );
            },
          },
        ],
      },
      {
        name: 'Inbox',
        projectName: project?.name ?? '',
        icon: <InboxIcon />,
        route: `/project/${projectId}/inbox`,
        enableLeftOption: true,
        leftOption: [
          {
            name: 'information',
            icon: <ErrorOutlineOutlinedIcon fontSize="small" />,
            renderComponent: (props) => {
              return (
                <>
                  <ProjectInbox {...props} />
                </>
              );
            },
          },
          {
            name: 'alert',
            icon: <AccessAlarmOutlinedIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <ProjectInbox {...props} />
                </>
              );
            },
          },
          {
            name: 'notification',
            icon: <NotificationsOutlinedIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <ProjectInbox {...props} />
                </>
              );
            },
          },
        ],
      },
      // {
      //   name: 'Requirements',
      //   projectName: project?.name ?? '',
      //   icon: <FormatListBulletedRoundedIcon fontSize="small" />,
      //   route: `/project/${projectId}/route`,
      //   renderComponent: () => {
      //     return (
      //       <>
      //         <ProjectRequirements />
      //       </>
      //     );
      //   },
      //   enableLeftOption: false,
      // },
      {
        name: 'Data Analytics & KPI',
        projectName: project?.name ?? '',
        icon: <AssessmentOutlinedIcon fontSize="small" />,
        route: `/project/${projectId}/DataAnalytics&KPI`,
        renderComponent: () => {
          return (
            <>
              <ProjectAnalysisContainer />
            </>
          );
        },
        enableLeftOption: true,
        leftOption: [
          {
            name: 'Analysis',
            icon: <AssessmentOutlinedIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <ProjectKPI {...props} />
                </>
              );
            },
          },
          {
            name: 'Non-conformity',
            icon: <RemoveDoneIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <ProjectKPI {...props} />
                </>
              );
            },
          },
          {
            name: 'Gap',
            icon: <AutoAwesomeMosaicIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <ProjectKPI {...props} />
                </>
              );
            },
          },
        ],
      },
    ],
    [projectId, project, project?.name]
  );

  return (
    <LayoutContainer
      componentsData={componentsData}
      workload={{
        serviceFor: { name: 'project', id: projectId },
      }}
    />
  );
};

export default ProjectComponents;
