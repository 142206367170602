import React, { memo } from 'react';
import { styled, ThemeProvider } from '@mui/material/styles';
import { UserContextProvider } from '../../containers/ContextMager/UserContext';
import UserRouter from '../../containers/UserWorkSpace/UserRouter';
import theme from '../../containers/Home/homeTheme';

const UserRouterContainer = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <UserContextProvider>
          <UserRouter />
        </UserContextProvider>
      </ThemeProvider>
    </>
  );
};

export default memo(UserRouterContainer);
