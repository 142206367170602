import React from 'react';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import { Box, Typography } from '@material-ui/core';

function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
  const { value, size = 100 } = props;
  return (
    <Box
      style={{
        position: 'relative',
        display: 'inline-flex',
      }}
    >
      <CircularProgress size={size} variant="determinate" style={{ color: '#002A45' }} {...props} />
      <Box
        style={{
          top: '0',
          left: '0',
          bottom: '0',
          right: '0',
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          style={{ fontWeight: 'bold', color: '#002A45', fontSize: '1rem' }}
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(value)} %`}</Typography>
      </Box>
    </Box>
  );
}

export default CircularProgressWithLabel;
