import React, { memo } from 'react';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import ExcelFileSetting from './core/ExcelFileSetting';
import CSVFileSetting from './core/CSVFileSetting';
import PdfFileSetting from './core/PdfFileSetting';
import useModule2services from '../../Hooks/useModule2services';

const Container = styled((props) => <Box {...props} />)(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const TopSection = styled((props) => <Box {...props} />)(({ theme }) => ({
  height: 'calc(100% - 4rem)',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
}));

const BottomSection = styled((props) => <Box {...props} />)(({ theme }) => ({
  height: '4rem',
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
}));

const BottomLeftSection = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  height: '100%',
  width: '100%',
}));

const BottomRightSection = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
  height: '100%',
  width: '100%',
}));
const ModuleAnalysis = (porps) => {
  const { scopeSettings, numberOfFilesSupport, serviceName, serviceFor, setWorkload } = porps;
  const [payload, setPayload] = React.useState({});

  const { loadingResult, handleAnalyze } = useModule2services({
    scopeSetting: scopeSettings,
    captureName: 'sdsecewafsfd',
    boolValue: false,
    fileSetting: Object.values(payload),
    serviceName,
    serviceFor,
  });

  const renderFileSetting = (item, id) => {
    const { FileType } = item;

    switch (FileType) {
      case 'Excel':
        return <ExcelFileSetting {...item} setPayload={setPayload} id={id} />;
      case 'CSV':
        return <CSVFileSetting {...item} setPayload={setPayload} id={id} />;
      case 'PDF':
        return <PdfFileSetting {...item} setPayload={setPayload} id={id} />;
      default:
        return <></>;
    }
  };

  const HandleService = async () => {
    const result = await handleAnalyze();
    if (result.error) {
      console.log('service error', result.message);
    } else {
      setWorkload((prev) => {
        return {
          ...prev,
          workload: result.workloadId,
          serviceFor,
        };
      });
    }
  };

  // console.log('analysis', scopeSettings);

  return (
    <>
      <Container>
        <TopSection>
          {scopeSettings?.map((item, index) => {
            return <div key={item.id}>{renderFileSetting(item, index)}</div>;
          })}
        </TopSection>

        <BottomSection>
          <BottomLeftSection>
            <Button variant="contained" color="primary">
              Filter
            </Button>
          </BottomLeftSection>
          <BottomRightSection>
            <Button
              disabled={loadingResult}
              variant="contained"
              color="primary"
              onClick={() => {
                // console.log('scopeSettings', scopeSettings);
                HandleService();
              }}
            >
              Start Analysis
            </Button>
          </BottomRightSection>
        </BottomSection>
      </Container>
    </>
  );
};

export default memo(ModuleAnalysis);
