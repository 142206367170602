/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import ArticleIcon from '@mui/icons-material/Article';
import ModuleLayout from '../../../../layouts/ModuleLayout';
import ModuleAnalysis from '../ModuleAnalysis';
import HeaderButton from '../core/HeaderButton';
import ProjectContext from '../../../Project/ProjectContext';
import useUpload from '../../../../layouts/Hooks/useUpload';

const CompareVersion = (props) => {
  const [inputFileOpen, setInputFileOpen] = React.useState(false);
  const [isAnalysis, setIsAnalysis] = React.useState(false);
  const [selectionFile, setSelectionFile] = React.useState(null);
  const {
    enableDescription,
    renderDescription,
    numberOfFilesSupport,
    renderFiles,
    setSelectedChildIndex,
    workload,
    setWorkload,
  } = props;
  const { projectId } = useParams();
  const { getProject } = useContext(ProjectContext);
  const project = getProject(Number(projectId)) || {};
  const { loading, handleChangeInDragefile, handleUploadFile, FindeFileDetails } = useUpload({
    project,
    projectId,
  });

  const compareVersionProps = useMemo(
    () => ({
      state: {
        inputFileOpen,
        setInputFileOpen,
        isAnalysis,
        setIsAnalysis,
        selectionFile,
        setSelectionFile,
        setSelectedChildIndex,
        loading,
        workload,
        setWorkload,
      },
      enableDescription,
      enableHeader: false,
      renderFiles,
      serviceName: 'Compare Versions',
      serviceFor: { name: 'project', id: projectId },
      renderDescription,
      numberOfFilesSupport,
      handleChangeInDragefile,
      handleUploadFile,
      FindeFileDetails,
      renderHeader: () => {
        return (
          <>
            <HeaderButton icon={<ArticleIcon />} name="Scope setting" />
          </>
        );
      },
      renderAnalysis: (props) => {
        return (
          <>
            <ModuleAnalysis {...props} />
          </>
        );
      },
    }),
    [
      inputFileOpen,
      isAnalysis,
      selectionFile,
      enableDescription,
      renderDescription,
      loading,
      workload,
    ]
  );

  return (
    <>
      <ModuleLayout moduleData={compareVersionProps} />{' '}
    </>
  );
};

export default memo(CompareVersion);
