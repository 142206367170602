/* eslint-disable react/destructuring-assignment */
import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, IconButton, InputBase, Typography, Button } from '@mui/material';
import { styled } from '@mui/styles';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FolderIcon from '@mui/icons-material/Folder';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ProjectContext from '../../../ProjectContext';
import { getProjectbyID } from '../../../../../api/projects';
import { DocumentIcon, FileDocIcon } from '../../../../../assets/svg';
import Files from './Files';

const FileBtn = styled((props) => <Button {...props} />)(({ theme, disabled, cancel = false }) => ({
  // margin: '0 auto !important',
  minHeight: '3rem !important',
  minWidth: '5rem !important',
  opacity: disabled ? '0.3' : '1',
  backgroundColor: cancel ? 'rgb(255, 255, 255)' : 'rgb(23, 193, 232) !important',
  color: cancel ? '#002A45' : 'rgb(255, 255, 255) !important',
  display: 'flex !important',
  borderRadius: '0.5rem !important',
  alignItems: 'center !important',
  justifyContent: 'center !important',
  transition: 'all 0.3s ease !important',
  boxShadow:
    'rgba(20, 20, 20, 0.12) 0rem 0.25rem 0.375rem -0.0625rem, rgba(20, 20, 20, 0.07) 0rem 0.125rem 0.25rem -0.0625rem !important',
}));

const FolderContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  width: '6rem',
  height: '6rem',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  padding: '1px',
  '&:hover': {
    backgroundColor: '#dde4ea',
    transition: 'all 0.4s ease-out',
  },
}));

const FolderContainerLabel = styled((props) => <Typography {...props} />)(({ theme }) => ({
  color: '#002A45',
  letterSpacing: 'normal',
  fontSize: '0.7rem',
  // fontWeight: 'bold',
}));

const SpaceContent = styled((props) => <Box {...props} />)(({ theme }) => ({
  padding: '1rem 0.5rem',
  // border: '1px solid #ccd6f2',
  gap: '5px',
  width: '100%',
  height: 'calc(100% - 3.5rem)',
  maxHeight: '100%',
  overflow: 'auto',
}));

const FileHeader = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'grid',
  height: '3.5rem',
  borderBottom: '1px solid #ccd6f2',
  backgroundColor: 'rgb(255, 255, 255)',
  padding: '0.5rem',
  gridTemplateColumns: '1fr 1fr',
}));

const FileHeaderNav = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  gap: '10px',
  height: '100%',
  alignItems: 'center',
}));

const FileHeaderIconBtn = styled((props) => <IconButton {...props} />)(({ theme }) => ({
  '&.MuiIconButton-root': {
    padding: '2px',
    margin: '2px',
  },
}));

const FileInputContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  margin: '2px',
  width: '100%',
  height: '100p%',
  maxHeight: '100%',
  borderRadius: '8px',
  border: '1px solid #998760CE',
}));

const FileInputFeildEnter = styled((props) => <InputBase {...props} />)(({ theme }) => ({
  fontSize: 'average',
  height: '100%',
  borderRadius: '8px 0 0 8px',
  [theme.breakpoints.up('lg')]: {
    fontSize: '0.89em',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: 'average',
  },
}));

const Folders = memo(({ folders, setFiles, setMoveTo }) => {
  return (
    <>
      {folders?.map((folder) => {
        return (
          <FolderContainer
            onClick={() => {
              setFiles(folder?.resources ?? []);
              setMoveTo('files');
              // setProjectBread(name);
              // setFloderBread(folder.name);
              // history.push(`/project/${projectId}/folders/${folder.id}`);
            }}
          >
            <FolderIcon style={{ width: '2rem', height: '2rem', color: '#006A45' }} />
            <FolderContainerLabel>{folder.name}</FolderContainerLabel>
          </FolderContainer>
        );
      })}
    </>
  );
});

const ProjectFolder = (props) => {
  const { isChild, numberOfFilesSupport, handleScopeSettings } = props || false;
  const history = useHistory();
  const [files, setFiles] = useState([]);
  const [moveTo, setMoveTo] = useState('folder');
  const { setProjects, projects, getProject } = useContext(ProjectContext);
  const { projectId } = useParams();
  const [searchfiles, setSearchFiles] = useState('');
  const project = getProject(Number(projectId));
  const [selectedFiles, setSelectedFiles] = useState([]);

  const folders = useMemo(() => project?.folders ?? [], [project]);

  const actualFiles = useMemo(() => {
    if (searchfiles) {
      return files.filter((file) =>
        file.filename.toLowerCase().includes(searchfiles.toLowerCase())
      );
    }
    return files;
  }, [files, searchfiles]);

  // console.log('folders', folders);m

  const renderComponent = () => {
    switch (moveTo) {
      case 'folder':
        return (
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Folders folders={folders} setFiles={setFiles} setMoveTo={setMoveTo} />
          </div>
        );
      case 'files':
        return (
          <Files
            data={actualFiles}
            setSelectedFiles={setSelectedFiles}
            selectedFiles={selectedFiles}
            numberOfFilesSupport={numberOfFilesSupport}
          />
        );
      default:
        return <Folders folders={folders} setFiles={setFiles} setMoveTo={setMoveTo} />;
    }
  };

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <FileHeader>
        <FileHeaderNav style={{ justifyContent: 'flex-start' }}>
          <FileHeaderIconBtn
            onClick={() => {
              setMoveTo('folder');
            }}
          >
            <ArrowBackIcon fontSize="small" />
          </FileHeaderIconBtn>
          <FileHeaderIconBtn
            onClick={() => {
              setMoveTo('files');
            }}
          >
            <ArrowForwardIcon fontSize="small" />
          </FileHeaderIconBtn>
        </FileHeaderNav>

        <FileHeaderNav style={{ justifyContent: 'flex-end' }}>
          {moveTo === 'files' && (
            <>
              <IconButton>
                <FileUploadIcon />
              </IconButton>
              <FileInputContainer>
                <FileInputFeildEnter
                  placeholder="Search for file"
                  sx={{ flex: 9, pl: 1, color: '#002A45' }}
                  onChange={(e) => {
                    setSearchFiles(e.target.value);
                  }}
                />
              </FileInputContainer>
            </>
          )}

          {isChild && props.renderCross()}
        </FileHeaderNav>
      </FileHeader>
      <SpaceContent>{renderComponent()}</SpaceContent>
      {moveTo === 'files' && (
        <Box
          style={{
            // height: '3rem',
            padding: '0.3rem 1rem',
            width: '100%',
            display: 'flex',
            gap: '0.5rem',
            border: '1px solid #ccd6f2',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            backgroundColor: 'rgb(255, 255, 255)',
          }}
        >
          <FileBtn
            disabled={selectedFiles.length === 0}
            cancel={true}
            // style={{ backgroundColor: 'rgb(255, 255, 255)', color: '#002A45' }}
            onClick={() => {
              setSelectedFiles([]);
            }}
          >
            Cancel
          </FileBtn>
          <FileBtn
            disabled={numberOfFilesSupport !== selectedFiles.length}
            onClick={() => {
              handleScopeSettings(selectedFiles);
            }}
          >
            Apply
          </FileBtn>
        </Box>
      )}
    </div>
  );
};

export default memo(ProjectFolder);
