import React, { memo, useCallback, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const Selector = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'inline-block',
  border: '1px solid #998760CE',
  transition: 'all 0.3s ease-out',
  borderRadius: '8px',
  height: '100%',
  position: 'relative',
  cursor: 'pointer',
  '&:hover': {
    // color: '#EEF1F9',
    //   border: 'none',
    transform: 'scale(1.0)',
    zIndex: 4,
    transition: 'border 0.1s ease-out',
    border: '1px solid #002a45',
    // transform: 'scale(1.05)',
    // boxShadow: '0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06)',
  },
}));

const Label = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  height: '100%',
  justifyContent: 'space-between',
  width: '100%',
  transition: 'all 0.3s ease-out',
  padding: '0.3rem 0.3rem 0.3rem 0.9rem',
  alignItems: 'center',
  color: '#002a45',
  fontWeight: 800,
}));

const LangDropDown = styled((props) => <Box {...props} />)(({ theme, isVisible }) => ({
  position: 'absolute',
  width: '100%',
  marginTop: '0.5rem',
  fontSize: '0.72rem',
  transformOrigin: 'top left',
  // height: '15rem',
  minHeight: '5rem',
  maxHeight: '15rem',
  overflowY: 'auto',
  backgroundColor: '#fafafa',
  zIndex: 1000,
  border: '1px solid #998760CE',
  opacity: isVisible ? 1 : 0,
  visibility: isVisible ? 'visible' : 'hidden',
  borderRadius: '8px',
  transition: 'opacity 0.3s ease, visibility 0.3s ease',
  pointerEvents: isVisible ? 'auto' : 'none', // Ensure dropdown can receive mouse events when visible
  boxShadow: 'rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem',
}));

const OptionContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'grid',
  padding: '0.5rem',
  gap: '0.25rem',
}));

const OptionItem = styled((props) => <Box {...props} />)(({ theme, isSelected }) => ({
  padding: '0.5rem',
  textAlign: 'center',
  cursor: 'pointer',
  borderRadius: '4px',
  background: isSelected ? '#998760CE' : '',
  color: isSelected ? 'white' : '',
  '&:hover': {
    background: '#998760CE',
    color: 'white',
  },
}));

const MultiOption = ({ options, style = {}, selectedVal = '', onChange }) => {
  const [selectedItem, setSelectedItem] = useState(selectedVal);
  return (
    <>
      <OptionContainer style={{ ...style }}>
        {options.map((option, index) => (
          <OptionItem
            isSelected={option === selectedItem}
            key={index}
            onClick={() => {
              setSelectedItem(option);
              onChange(option);
            }}
          >
            {option}
          </OptionItem>
        ))}
      </OptionContainer>
    </>
  );
};

const SelectMUI = (props) => {
  const { name = '', selectedVal = '', onChange, options, style = {}, optionStyle = {} } = props;

  // console.log('options', option);

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handelClick = useCallback(
    (val) => {
      onChange(val, name);
      toggleDropdown();
    },
    [name, isDropdownOpen]
  );

  return (
    <>
      {' '}
      <Selector style={{ ...style }} onClick={toggleDropdown}>
        <Label>
          {!selectedVal ? name : selectedVal}
          <IconButton color="primary">
            <ArrowDropDownIcon fontSize="large" />
          </IconButton>
        </Label>
        <LangDropDown isVisible={isDropdownOpen} onClick={(e) => e.stopPropagation()}>
          <MultiOption
            options={options}
            selectedVal={selectedVal}
            style={optionStyle}
            onChange={handelClick}
          />
        </LangDropDown>
      </Selector>
    </>
  );
};

export default memo(SelectMUI);
