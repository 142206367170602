/* eslint-disable eqeqeq */
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableFilterRow,
  TableHeaderRow,
  Toolbar,
  TableColumnVisibility,
  ColumnChooser,
} from '@devexpress/dx-react-grid-material-ui';
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Breadcrumbs,
  Typography,
  Box,
  Select,
  CardHeader,
} from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import MultiSelect from 'react-multi-select-component';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Pie,
  PieChart,
  Sector,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import ReactJson from 'react-json-view';
import {
  getAdminKpi,
  getAdminProjectUserSelectedKPI,
  getClusterName,
  getNonChangeRedinessProject,
  getRadinessProjectName,
  getRadinessProjects,
  getRmName,
  getSubRegionName,
} from '../../../api/kpi';
import useAppToken from '../../../app/useAppToken';
import NavigationGoto from '../../../components/Core/NavigationGoto';
import TableComponent from '../../../components/Datagrid/TableComponent';
import Loading from '../../../components/Core/Loading';
import ToolBarPlugin from '../../../components/Datagrid/ToolBarPlugin';
import { rowComponent, toolbarComponent } from '../../../components/Datagrid/TStyles';
import ModalAssesmentEditor from './AdminModal/ModalAssesmentEditor';
import { getAllAdminProjects } from '../../../api/projects';
import { searchUsers } from '../../../api/users';
import { resultStyle } from '../../../components/Filter/textAnalysis';
import Module1Context from '../../Module1/Module1Context';
import ProjectContext from '../../Project/ProjectContext';

const useStyles = makeStyles((theme) => ({
  HideSelectHeading: {
    '&.rmsc .dropdown-heading': {
      display: 'none',
    },
    '&.rmsc .dropdown-container': {
      //   display: 'none',
    },
    '&.rmsc .dropdown-content': {
      paddingTop: -0,
      position: 'unset',
    },
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  barGraphContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '20rem',
    backgroundColor: '#EEF1F9',
    border: '1px solid #002A45',
  },
  barGraphTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '4rem',
  },
  barGraph: {
    overflowX: 'scroll',
    padding: '1rem',
    '&::-webkit-scrollbar': {
      width: '0.1em',
      height: '10px',
      backgroundColor: '#F5F5F5',
    },
    '&::-webkit-scrollbar-track': {
      width: '0.1em',
      height: 2,
      backgroundColor: '#F5F5F5',
    },
    '&::-webkit-scrollbar-thumb: horizontal': {
      background: 'red !important',
      outline: '1px solid #708090',
      borderRadius: '3px',
    },
  },
  barGraph2: {
    padding: '1.5rem',
  },
  ToggleResultContainer: {
    maxWidth: 600,
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    boxShadow: 'none',
    '& .MuiPaper-root': {
      backgroundColor: '#FBFBFB',
    },
  },
  ToggleResBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardContentProjUser: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 19,
    fontWeight: 'bold',
  },
  cardRootProUser: {
    // flexBasis: '50%',
    border: '1px solid #002A45',
    boxShadow: 'none',
    '& .MuiPaper-root': {
      // height: '40rem',
      maxHeight: '40rem',
      minHeight: '40rem',
    },
    '& .MuiCardHeader-root': {
      color: 'white',
      padding: '1px',
      backgroundColor: '#002A45',
      paddingLeft: '1rem',
      borderBottom: '1px solid #002A45',
    },
    '& .MuiTypography-h5': {
      fontSize: '15px',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    '& .MuiCardContent-root': {
      backgroundColor: '#EEF1F9',
    },
    '.MuiPaper-rounded': {
      borderRadius: '10x',
    },
  },
  cardRoot: {
    // flexBasis: '50%',
    border: '1px solid #002A45',
    boxShadow: 'none',
    '& .MuiPaper-root': {
      height: '40rem',
    },
    '& .MuiCardHeader-root': {
      color: 'white',
      padding: '1px',
      backgroundColor: '#002A45',
      paddingLeft: '1rem',
      borderBottom: '1px solid #002A45',
    },
    '& .MuiTypography-h5': {
      fontSize: '15px',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    '& .MuiCardContent-root': {
      backgroundColor: '#EEF1F9',
    },
    '.MuiPaper-rounded': {
      borderRadius: '10x',
    },
  },
  cardRoot2: {
    border: '1px solid #002A45',
    boxShadow: 'none',
    '& .MuiPaper-root': {
      height: '40rem',
    },
    '& .MuiCardHeader-root': {
      color: '#002A45',
      backgroundColor: '#EEF1F9',
      padding: '1rem',
      paddingLeft: '1rem',
      borderBottom: '1px solid #002A45',
    },
    '& .MuiTypography-h5': {
      fontSize: '1.2rem',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    '& .MuiCardContent-root': {
      backgroundColor: '#EEF1F9',
    },
    '.MuiPaper-rounded': {
      borderRadius: '10x',
    },
  },
  expertParentList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  expertBargrph: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // border: '1px solid #002A45',
  },
  resultPanel: {
    border: `1px solid ${theme.palette.primary.main}`,
    padding: '2px !important',
    backgroundColor: '#EEF1F9',
  },
  pieChart: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'black',
    fontSize: '1rem',
  },
  barGraphs: {
    overflowX: 'scroll',
    padding: '1rem',
    '&::-webkit-scrollbar': {
      width: '0.2em',
      backgroundColor: '#F5F5F5',
    },
  },
  barGraphs2: {
    padding: '1.5rem',
  },
}));
type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];
const AdminKpi = () => {
  const classes = useStyles();
  const classes2 = resultStyle();
  const [value, onChange] = useState<Value>(new Date());
  const [dropDown1, setDropDown1] = React.useState([]);
  const [selectedFinalProjectName, setSelectedFinalProjectName] = useState([]);
  const [dropDown2, setDropDown2] = React.useState([]);
  const [selectedFinalUserName, setSelectedFinalUserName] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ProjectList, setProjectList] = React.useState([]);
  const [UserList, setUserList] = React.useState([]);
  const [activeIndex3, setActivate3] = useState(0);
  const [myProjects, setMyProjects] = useState([]);
  const [myTotalKpi, setMyTotalKpi] = useState({});
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  // kpi options
  const [selectedProjectName, setSelectedProjectName] = useState([]);
  const [selectedUserName, setSelectedUserName] = useState([]);
  // date
  const [dateValue1, setDateValue1] = useState<any>('');
  const [dateValue2, setDateValue2] = useState<any>('');
  const [validationError, setValidationError] = useState('');
  const { setSnack } = useContext(ProjectContext);
  useEffect(() => {
    // console.log('use start');
    setLoading(true);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    getAllProjectsList();
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    getAllUsersList();
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    // setLoading(false);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    getAdminAllKpiList();
    // console.log('use stop');
  }, []);
  // Project List
  const getAllProjectsList = () => {
    getAllAdminProjects().then((dbProjects: any[]) => {
      // console.log('dbProjects', dbProjects);
      const tempProjects = dbProjects.map((project) => {
        return {
          ...project,
          createdBy: project.owner.name,
        };
      });
      setMyProjects(tempProjects);
      // setSelection([]);
      // setLoading(false);
      const tempProjects2 = tempProjects.map((project) => {
        return {
          label: project.name,
          value: project.id,
        };
      });
      setProjectList(tempProjects2);
    });
  };
  const getAllUsersList = () => {
    searchUsers().then((dbusers: any[]) => {
      // console.log('dbusers', dbusers);
      const usersArray = [];
      dbusers.forEach(
        (item: {
          profile: any;
          activated_at: any;
          id: any;
          projects_details: any;
          email: any;
          is_admin: any;
          name: any;
        }) => {
          return usersArray.push({
            label: item.name,
            value: item.id,
          });
        }
      );
      setUserList(usersArray);
      // setLoading(false);
    });
  };
  // useEffect(() => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     // eslint-disable-next-line @typescript-eslint/no-use-before-define
  //     getAdminAllKpiList();
  //   }, 2000);
  // }, []);
  const getAdminAllKpiList = () => {
    getAdminKpi().then((dbkpi: any[]) => {
      // console.log('dbkpi', dbkpi);
      setMyTotalKpi(dbkpi);
      setLoading(false);
    });
  };
  // date function started
  const onChangeDateFrom = (e) => {
    // const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD');
    // console.log(newDate); // always log "1970-01-01"
    // setInterval(() => {
    //   console.log('default date', dateValue1);
    // }, 1000);
    // eslint-disable-next-line func-names
    setDateValue1(e.target.value);

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    validateDates(e.target.value, dateValue2);
    // setTimeout(() => {
    //   console.log('default date', dateValue1);
    // }, 1000);
  };
  const onChangeDateTo = (e) => {
    setDateValue2(e.target.value);
    // console.log('default date2', dateValue2);
    // console.log(newDate); // always log "1970-01-01"
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    validateDates(dateValue1, e.target.value);
    if (selectedFinalProjectName.length === 0 && selectedFinalUserName.length === 0) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      filterCall2(selectedFinalProjectName, selectedFinalUserName, dateValue1, e.target.value);
    }
  };
  const validateDates = (from, to) => {
    if (from && to) {
      const fromDateObj = new Date(from);
      const toDateObj = new Date(to);

      if (fromDateObj > toDateObj) {
        setValidationError('From date must be less than or equal to To date');
        // setSnack('From date must be less than or equal to To date', 'warning');
      } else {
        setValidationError('');
      }
    }
  };
  function onChangeProjectDrop(option: any) {
    setDropDown1(option);
    const extra = 'noChange_entity';
    multifilterProjectCall(option, selectedProjectName);
    // setDropDown1(selected.length === 0 ? null : selected[0]);
  }
  function multifilterProjectCall(en: any, plat: any) {
    const entityVal: any = [];
    en.forEach((o: any) => {
      entityVal.push(o.value);
    });
    const Program_name = entityVal.length ? entityVal.join() : undefined;
    const selectedFinalProjectName = [...entityVal];
    setSelectedFinalProjectName([...selectedFinalProjectName]);
    // console.log('dateValue1', dateValue1);
    // console.log('dateValue2', dateValue2);
    // console.log('selectedFinalProjectName.length', selectedFinalProjectName);
    // console.log('selectedFinalUserName.length', selectedFinalUserName);

    if (
      selectedFinalProjectName ||
      (selectedFinalUserName && dateValue1 != '' && dateValue2 != '')
    ) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      filterCall2(selectedFinalProjectName, selectedFinalUserName, dateValue1, dateValue2);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      filterCall(selectedFinalProjectName, selectedFinalUserName);
    }
  }
  function onChangeUserDrop(option: any) {
    setDropDown2(option);
    const extra = 'noChange_entity';
    multifilterUserCall(option, selectedUserName);
    // setDropDown1(selected.length === 0 ? null : selected[0]);
  }
  function multifilterUserCall(en: any, plat: any) {
    const entityVal: any = [];
    en.forEach((o: any) => {
      entityVal.push(o.value);
    });
    const Program_name = entityVal.length ? entityVal.join() : undefined;
    const selectedFinalUserName = [...entityVal];
    setSelectedFinalUserName([...selectedFinalUserName]);
    if (
      selectedFinalProjectName ||
      (selectedFinalUserName && dateValue1 != '' && dateValue2 != '')
    ) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      filterCall2(selectedFinalProjectName, selectedFinalUserName, dateValue1, dateValue2);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      filterCall(selectedFinalProjectName, selectedFinalUserName);
    }
    // // eslint-disable-next-line @typescript-eslint/no-use-before-define
    // filterCall(selectedFinalProjectName, selectedFinalUserName);
  }
  function filterCall(projectAllArr, userAllArr) {
    // console.log('SelectedFinalProjectName', projectAllArr.join(','));
    // console.log('selectedFinalUserName', userAllArr.join(','));
    // console.log('selectedDate');
    // console.log('selectedFinalUserName', userAllArr.join(','));
    if (dateValue1 === '' && dateValue2 === '') {
      getAdminProjectUserSelectedKPI(projectAllArr.join(','), userAllArr.join(','), '', '')
        .then((dbkpi: any[]) => {
          setMyTotalKpi(dbkpi);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  function filterCall2(projectAllArr, userAllArr, fromDate, toDate) {
    // console.log('This function will get Called when date is Selected');
    // console.log('SelectedFinalProjectName', projectAllArr.join(','));
    // console.log('selectedFinalUserName', userAllArr.join(','));
    // console.log('selectedDate');
    // console.log('selectedFinalUserName', userAllArr.join(','));
    getAdminProjectUserSelectedKPI(projectAllArr.join(','), userAllArr.join(','), fromDate, toDate)
      .then((dbkpi: any[]) => {
        setMyTotalKpi(dbkpi);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <>
      {/* <ReactJson src={{ myTotalKpi }} collapsed={true} theme="google" /> */}
      {loading ? (
        <Loading />
      ) : (
        <>
          {validationError && <p style={{ color: 'red' }}>{validationError}</p>}
          <Grid container>
            <Grid item xs={2} style={{ height: '20vh' }}>
              <div>
                <div style={{ height: '68vh' }}>
                  <Card
                    style={{
                      // border: '1px solid #DC3223',
                      borderRadius: 0,
                      boxShadow: '0px 3px 6px #00000029',
                      background: '#002A45 0% 0% no-repeat padding-box',
                      color: 'white',
                      textAlign: 'center',
                    }}
                  >
                    <Typography component="h3">Select By date</Typography>
                  </Card>
                  <div style={{ padding: 8 }}>
                    <div>
                      <span>From :</span>
                      <input
                        id="dateValue1"
                        type="date"
                        value={dateValue1}
                        // onChange={(e) => setDateValue1(e.target.value)}
                        onChange={onChangeDateFrom}
                      />
                    </div>
                    <div style={{ marginTop: 5, marginLeft: 15 }}>
                      <span>To :&nbsp;</span>
                      <input
                        id="dateValue2"
                        type="date"
                        value={dateValue2}
                        // onChange={(e) => {
                        //   setDateValue2(e.target.value);
                        // filterCall2(
                        //   selectedFinalProjectName,
                        //   selectedFinalUserName,
                        //   dateValue1,
                        //   dateValue2
                        // );
                        // }}
                        onChange={onChangeDateTo}
                      />
                    </div>
                  </div>
                  <Card
                    style={{
                      // border: '1px solid #DC3223',
                      borderRadius: 0,
                      boxShadow: '0px 3px 6px #00000029',
                      background: '#002A45 0% 0% no-repeat padding-box',
                      color: 'white',
                      textAlign: 'center',
                    }}
                  >
                    <Typography component="h3">Project</Typography>
                  </Card>
                  <Paper>
                    <MultiSelect
                      options={ProjectList}
                      labelledBy="Select" // Providing a unique id to labelledBy will hide the heading
                      hasSelectAll={false}
                      isOpen={true}
                      value={dropDown1}
                      onChange={onChangeProjectDrop}
                      className={classes.HideSelectHeading}
                    />
                  </Paper>
                </div>
                <br />
                <div style={{ paddingTop: 3 }}>
                  <Card
                    style={{
                      // border: '1px solid #DC3223',
                      borderRadius: 0,
                      boxShadow: '0px 3px 6px #00000029',
                      background: '#002A45 0% 0% no-repeat padding-box',
                      color: 'white',
                      textAlign: 'center',
                    }}
                  >
                    <Typography component="h3">User Name</Typography>
                  </Card>
                  <Paper>
                    <MultiSelect
                      options={UserList}
                      labelledBy="Select" // Providing a unique id to labelledBy will hide the heading
                      hasSelectAll={false}
                      isOpen={true}
                      value={dropDown2}
                      onChange={onChangeUserDrop}
                      className={classes.HideSelectHeading}
                    />
                  </Paper>
                </div>
              </div>
            </Grid>
            {/* {console.log('hi', myTotalKpi)} */}
            {Object.keys(myTotalKpi).length > 0 && (
              <>
                <Grid item xs={10}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={6}>
                              <Card className={classes.cardRootProUser}>
                                <CardHeader title="Total Number Of Projects" />

                                <CardContent className={classes.cardContentProjUser}>
                                  {myTotalKpi?.total_num_projects[0].total_num_projects}
                                </CardContent>
                              </Card>
                            </Grid>
                            <Grid item xs={6}>
                              <Card className={classes.cardRootProUser}>
                                <CardHeader title="Total Number Of Users" />
                                <CardContent className={classes.cardContentProjUser}>
                                  {myTotalKpi?.total_num_users[0].total_num_users}
                                </CardContent>
                              </Card>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Paper className={classes.ToggleResultContainer}>
                            <Box className={classes.ToggleResBox}>
                              <Card className={classes.cardRoot}>
                                <CardHeader title="Number Of Services" />
                                <CardContent>
                                  <Box className={classes2.barGraph}>
                                    <BarChart
                                      width={600}
                                      height={210}
                                      data={myTotalKpi?.num_of_service}
                                      layout="horizontal"
                                      // data={myTotalKpi?.num_of_service.filter((data, i) => {
                                      //   return i <= 7;
                                      // })}
                                      margin={{ top: 0, right: 30, left: 20, bottom: 40 }}
                                    >
                                      <CartesianGrid strokeDasharray="3 3" />
                                      <XAxis
                                        dataKey="action"
                                        textAnchor="end"
                                        sclaeToFit="true"
                                        verticalAnchor="start"
                                        interval={0}
                                        angle="-45"
                                        stroke="#000"
                                        fontSize={10}
                                        margin={{
                                          bottom: 40,
                                        }}
                                        // padding={{ left: 10, right: 20 }}
                                      />
                                      <YAxis />
                                      <Tooltip />
                                      <Legend verticalAlign="top" height={36} align="left" />
                                      <Bar dataKey="count_of_services" fill="#118dff" />
                                    </BarChart>
                                  </Box>
                                </CardContent>
                              </Card>
                            </Box>
                          </Paper>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Paper className={classes.ToggleResultContainer}>
                        <Box className={classes.ToggleResBox}>
                          <Card className={classes.cardRoot}>
                            <CardHeader title="Number Of Project" />
                            <CardContent>
                              <Box className={classes2.barGraph}>
                                <BarChart
                                  width={2000}
                                  height={300}
                                  data={myTotalKpi?.user_per_project}
                                  layout="horizontal"
                                  // data={myTotalKpi?.num_of_service.filter((data, i) => {
                                  //   return i <= 7;
                                  // })}
                                  margin={{ top: 0, right: 30, left: 20, bottom: 40 }}
                                >
                                  <CartesianGrid strokeDasharray="3 3" />
                                  <XAxis
                                    dataKey="name"
                                    textAnchor="end"
                                    sclaeToFit="true"
                                    verticalAnchor="start"
                                    interval={0}
                                    angle="-45"
                                    stroke="#000"
                                    fontSize={10}
                                    margin={{
                                      bottom: 40,
                                    }}
                                    // padding={{ left: 10, right: 20 }}
                                  />
                                  <YAxis />
                                  <Tooltip />
                                  <Legend verticalAlign="top" height={36} align="left" />
                                  <Bar dataKey="count_of_user" fill="#118dff" />
                                </BarChart>
                              </Box>
                            </CardContent>
                          </Card>
                        </Box>
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={6}>
                      <Paper className={classes.ToggleResultContainer}>
                        <Box className={classes.ToggleResBox}>
                          <Card className={classes.cardRoot}>
                            <CardHeader title="User Per Modules" />
                            <CardContent>
                              <Box className={classes2.barGraph}>
                                <BarChart
                                  width={2000}
                                  height={210}
                                  data={myTotalKpi?.num_of_modules}
                                  layout="horizontal"
                                  // data={myTotalKpi?.num_of_service.filter((data, i) => {
                                  //   return i <= 7;
                                  // })}
                                  margin={{ top: 0, right: 30, left: 20, bottom: 40 }}
                                >
                                  <CartesianGrid strokeDasharray="3 3" />
                                  <XAxis
                                    dataKey="module"
                                    textAnchor="end"
                                    sclaeToFit="true"
                                    verticalAnchor="start"
                                    interval={0}
                                    angle="-45"
                                    stroke="#000"
                                    fontSize={10}
                                    margin={{
                                      bottom: 40,
                                    }}
                                    // padding={{ left: 10, right: 20 }}
                                  />
                                  <YAxis />
                                  <Tooltip />
                                  <Legend verticalAlign="top" height={36} align="left" />
                                  <Bar dataKey="count_of_module" fill="#118dff" />
                                </BarChart>
                              </Box>
                            </CardContent>
                          </Card>
                        </Box>
                      </Paper>
                    </Grid>
                    <Grid item xs={6}>
                      <Paper className={classes.ToggleResultContainer}>
                        <Box className={classes.ToggleResBox}>
                          <Card className={classes.cardRoot}>
                            <CardHeader title="Project Per User" />
                            <CardContent>
                              <Box className={classes2.barGraph}>
                                <BarChart
                                  width={2000}
                                  height={210}
                                  data={myTotalKpi?.project_per_users}
                                  layout="horizontal"
                                  // data={myTotalKpi?.num_of_service.filter((data, i) => {
                                  //   return i <= 7;
                                  // })}
                                  margin={{ top: 0, right: 30, left: 20, bottom: 40 }}
                                >
                                  <CartesianGrid strokeDasharray="3 3" />
                                  <XAxis
                                    dataKey="name"
                                    textAnchor="end"
                                    sclaeToFit="true"
                                    verticalAnchor="start"
                                    interval={0}
                                    angle="-45"
                                    stroke="#000"
                                    fontSize={10}
                                    margin={{
                                      bottom: 40,
                                    }}
                                    // padding={{ left: 10, right: 20 }}
                                  />
                                  <YAxis />
                                  <Tooltip />
                                  <Legend verticalAlign="top" height={36} align="left" />
                                  <Bar dataKey="count_of_projects" fill="#118dff" />
                                </BarChart>
                              </Box>
                            </CardContent>
                          </Card>
                        </Box>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default AdminKpi;
