import React, { memo, useEffect, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import LinearProgress from '@mui/material/LinearProgress';
import TuneIcon from '@mui/icons-material/Tune';
import EmergencyRecordingIcon from '@mui/icons-material/EmergencyRecording';
import DirectionsIcon from '@mui/icons-material/Directions';
import PeopleIcon from '@mui/icons-material/People';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import { Card, CardContent, Typography, Grid, Box, ListItem, List, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import useRexApiCall from '../../HOC/useRexApiCall';
import Translator from './Translator';
import IsensImage, {
  aiBackground,
  expertSearchIcon,
  translatorIcon,
  GenaMenuIcon,
  rex360Icon,
} from '../../../assets/IconComponents/IsensImage';
import HomeBackgroundImage from '../../HOC/core/HomeBackgroundImage';
import useKnowledge360Api from '../../HOC/useKnowledge360Api';

const InputContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '50px',
  maxHeight: '50px',
  fontSize: 'average',
  transition: 'display 0.3s ease-out',
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.5em',
    height: '55px',
    maxHeight: '55px',
  },
  [theme.breakpoints.up('xl')]: {
    height: '100px',
    maxHeight: '100px',
  },
  // transition: 'all 0.3s ease-out',
  '&.hide': {
    display: 'none',
  },
}));

const InputFeildEnter = styled((props) => <InputBase {...props} />)(({ theme }) => ({
  fontSize: 'average',
  height: '100%',
  border: '1px solid #998760CE',
  backgroundColor: '#fafafa',
  [theme.breakpoints.up('lg')]: {
    fontSize: '0.89em',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: 'average',
  },
  // [theme.breakpoints.up('lg')]: {
  //   fontSize: 'large',
  //   height: '70px',
  //   maxHeight: '70px',
  // },
  // [theme.breakpoints.down('md')]: {
  //   fontSize: 'average',
  // },
}));

const WrapInputFeild = styled((props) => <Box {...props} />)(({ theme }) => ({
  height: '100%',
  flex: 9,
  display: 'flex',
  flexDirection: 'column',
}));

const ButtonSearch = styled((props) => <Button {...props} />)(({ theme }) => ({
  fontSize: 'large',
  height: '100%',
  color: '#EEF1F9',
  backgroundColor: '#20635e !important',
  border: '1px solid rgba(128, 128, 128, 0.4)',
  // color: '#002A45',
  [theme.breakpoints.up('lg')]: {
    fontSize: '0.89em',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: 'average',
  },
  '&:hover': {
    backgroundColor: '#00988c',
    color: '#EEF1F9',
  },
}));

const ServiceButton = styled((props) => <Button {...props} />)(({ theme, isSelectd }) => ({
  border: isSelectd ? '1px solid #998760CE' : '1px solid rgba(128, 128, 128, 0.4)',
  // backgroundColor: isSelectd ? '#20635e' : '#fafafa',
  // color: '#EEF1F9',
  color: '#002A45',
  scale: isSelectd ? '1.05' : '1',
  boxShadow: isSelectd ? '0 8px 22px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06)' : 'none',
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.1em',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: 'average',
  },
  '&:hover': {
    border: '1px solid #998760CE',
    // color: '#EEF1F9',
  },
}));

const MuiCard = styled((props) => <Box {...props} />)(({ theme }) => ({
  margin: 5,
  color: '#EEF1F9',
  width: '20%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  opacity: 1,
  background: 'transparent',
  transition:
    '.7s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12)',
  cursor: 'pointer',
  // '&:hover': {
  //   backgroundColor: '#dde4ea',
  //   transition: 'all 0.4s ease-out',
  // },
}));

type ServicesProps = {
  option: string[];
  selectedOption: number;
  handleItemSelection: (service: Object) => void;
};

const Services = memo(({ option = [], selectedOption = 0, handleItemSelection }: ServicesProps) => {
  return (
    <div style={{ display: 'flex', gap: '8px' }}>
      {option.map((item, index) => {
        return (
          <ServiceButton
            key={index}
            isSelectd={selectedOption === index}
            onClick={() => {
              handleItemSelection({ index, item });
            }}
          >
            {item}
          </ServiceButton>
        );
      })}
    </div>
  );
});

export const InputField = memo(
  ({
    filterType,
    toggleCollapse,
    isCollapsed,
    SubmitSearch,
    onChangeOption,
    enterValue,
    handleTextChange,
    style = {},
    options = [],
    check,
    placeholder = 'Enter Text',
  }) => {
    const [serviceFilter, setServiceFilter] = React.useState('');
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const option = useMemo(() => {
      setSelectedIndex(0);
      setServiceFilter(options[0]);
      return options;
    }, [filterType]);

    useEffect(() => {
      toggleCollapse({ open: isCollapsed.open, service: serviceFilter });
    }, [serviceFilter]);

    const handleItemSelection = (payload) => {
      const { index, item } = payload;
      setSelectedIndex(index);
      setServiceFilter(item);
      onChangeOption(payload);
    };

    return (
      <>
        <InputContainer style={style} className={check}>
          <ButtonSearch
            sx={{ flex: 1, borderRight: 'none', borderRadius: '8px 0 0 8px' }}
            onClick={() => {
              toggleCollapse({ open: true, service: serviceFilter });
            }}
            startIcon={<TuneIcon />}
          >
            Filters
          </ButtonSearch>

          <InputFeildEnter
            value={enterValue}
            sx={{ flex: 9, pl: 1, color: '#002A45' }}
            placeholder={placeholder}
            onChange={handleTextChange}
            inputProps={{ 'aria-label': `${placeholder}` }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                SubmitSearch({ selectedService: option[selectedIndex] });
              }
            }}
          />

          <ButtonSearch
            sx={{ flex: '0.5', borderLeft: 'none', borderRadius: '0 8px 8px 0' }}
            onClick={() => {
              SubmitSearch({ selectedService: option[selectedIndex] });
            }}
          >
            <SearchIcon fontSize="large" />
          </ButtonSearch>
        </InputContainer>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Services
            selectedOption={selectedIndex}
            handleItemSelection={handleItemSelection}
            option={option}
          />
        </div>
      </>
    );
  }
);

const MainContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  // paddingTop: '5rem',
  display: 'inline-flex',
  alignItems: 'center',
  // justifyContent: 'space-between',
  scrollBehavior: 'smooth',
  flexDirection: 'column',
  // gap: '50px',
  width: '100%',
  height: 'calc(100% - 5px)',
  maxHeight: 'calc(100% - 5px)',
  overflow: 'auto',
}));

const SearchBackground = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  zIndex: 1,
  justifyContent: 'center',
  backgroundImage: `url(${aiBackground})`,
  // backgroundSize: 'cover',
  // backgroundRepeat: 'no-repeat',
  // backgroundPosition: 'center',
  width: '100%',
  filter: 'brightness(1)',
  height: 'calc(50rem - 200px)',
  minHeight: 'calc(50rem - 200px)',
  [theme.breakpoints.up('lg')]: {
    height: 'calc(100% - 200px)',
  },
  [theme.breakpoints.up('xl')]: {
    height: 'calc(100% - 200px)',
  },
  // maxHeight: '40rem',
  // '&::after': {
  //   content: '""',
  //   position: 'absolute',
  //   width: '100%',
  //   height: '100%',
  //   top: 0,
  //   left: 0,
  //   backgroundColor: 'rgba(0, 0, 0, 0.55)',
  // },
}));

const TopSection = styled((props) => <Box {...props} />)(({ theme }) => ({
  paddingTop: '3.8rem',
  display: 'inline-flex',
  minHeight: '20rem',
  color: '#EEF1F9',
  zIndex: 2,
  maxHeight: '40rem',
  // justifyContent: 'center',
  // justifyContent: 'center',
  // height: '100%',
  // alignItems: 'center',
  flexDirection: 'column',
  width: '85%',
  [theme.breakpoints.up('lg')]: {
    width: '70%',
  },
  [theme.breakpoints.up('xl')]: {
    width: '75%',
  },
  //   height: '40%',
  gap: '20px',
}));

const ServiceList = styled((props) => <List {...props} />)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'space-around',
}));

const ServiceListItem = styled((props) => <ListItem {...props} />)(({ theme, isSelected }) => ({
  width: '20%',
  display: 'flex',
  justifyContent: 'center',
  gap: '0.3rem',
  // flexBasis: '16%',
  flexGrow: 0,
  flexShrink: 0,
  // flex: 1,
  fontFamily: 'Roboto Condensed, serif',
  fontSize: '1.67rem',
  letterSpacing: '0.055rem',
  fontWeight: '600',
  cursor: 'pointer',
  // maxWidth: '24%',
  textAlign: 'center',
  borderBottom: isSelected ? '5px solid #DC3223' : 'none',
  color: isSelected ? '#DC3223' : '#002A45',
}));

const BottomSection = styled((props) => <Box {...props} />)(({ theme, isSelected }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '200px',
  padding: '2rem',
  backgroundColor: '#002A45',
}));

const IconBackground = styled((props) => <Box {...props} />)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '2rem',
  height: '20rem',
}));

const Homepage = ({ toggleCollapse, isCollapsed }) => {
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [enterValue, setEnterValue] = React.useState('');
  // const { sideFilter: isCollapsed, setSideFilter } = React.useContext(M2GlobalContext);
  const [services, setServices] = React.useState([
    {
      name: 'GenA',
      selected: false,
      icon: GenaMenuIcon,
      enableOption: false,
      placeholder: 'Search AI insights on train engineering',
    },
    { name: 'Translator', selected: true, icon: translatorIcon, enableOption: false },
    {
      name: 'Knowledge360',
      selected: false,
      icon: expertSearchIcon,
      placeholder: 'Search for Knowledge360',
      enableOption: true,
      options: ['Content Search', 'Similarity Search', 'Referents Search', 'People Search'],
    },
    {
      name: 'Rex360',
      selected: false,
      icon: rex360Icon,
      placeholder: 'Search for Rex360',
      enableOption: true,
      options: [
        'REX Cards',
        'Requirements',
        'Referents',
        'Tests',
        'Change Requests',
        'Lean Six Sigma',
        'APSYS',
        'CSS Project',
      ],
    },
  ]);

  const history = useHistory();
  const { handleApiCall, loading, error, progressTime } = useRexApiCall();
  const {
    progressTime: progressTimeKnowledge,
    handleMuliApiCall,
    knowlede360loading,
  } = useKnowledge360Api();

  const SubmitSearch = async (payload) => {
    switch (selectedIndex) {
      case 0: {
        return history.push('/home/GenA');
      }

      case 1: {
        return history.push('/home/GenA');
      }
      case 2: {
        const { selectedService } = payload;
        return handleMuliApiCall(enterValue, '/home/Knowledge360', selectedService ?? '', true);
      }
      case 3: {
        return handleApiCall(enterValue, '/home/Rex360', true);
      }

      // case 4: {
      //   const { selectedService } = payload;
      //   return handleMuliApiCall(enterValue, '/home/Knowledge360', selectedService ?? '', true);
      // }

      default:
        return null;
    }
  };

  const handleItemSelection = (index) => {
    setSelectedIndex(index);
    const newServices = services.map((item, i) => {
      if (i === index) {
        return { ...item, selected: true };
      }
      return { ...item, selected: false };
    });
    setServices(newServices);
  };

  const handleTextChange = (e) => {
    setEnterValue(e.target.value);
  };

  const RouteGenA = () => {
    history.push('/GenA');
  };

  const renderService = (index) => {
    switch (index) {
      case 0:
        return RouteGenA();
      case 1:
        return <Translator selectedIndex={selectedIndex} />;
      case 2:
        return (
          <InputField
            filterType="Content Search"
            toggleCollapse={toggleCollapse}
            isCollapsed={isCollapsed}
            SubmitSearch={SubmitSearch}
            enterValue={enterValue}
            onChangeOption={() => {}}
            handleTextChange={handleTextChange}
            placeholder={services[selectedIndex].placeholder ?? 'Enter Text'}
            options={services[selectedIndex].enableOption ? services[selectedIndex].options : []}
            check={![0, 2, 3].includes(selectedIndex) ? 'hide' : ''}
          />
        );
      case 3:
        return (
          <InputField
            filterType="Rex Cards"
            toggleCollapse={toggleCollapse}
            onChangeOption={() => {}}
            isCollapsed={isCollapsed}
            SubmitSearch={SubmitSearch}
            enterValue={enterValue}
            handleTextChange={handleTextChange}
            placeholder={services[selectedIndex].placeholder ?? 'Enter Text'}
            options={services[selectedIndex].enableOption ? services[selectedIndex].options : []}
            check={![0, 2, 3].includes(selectedIndex) ? 'hide' : ''}
          />
        );
      default:
        return <HomeBackgroundImage />;
    }
  };
  // console.log('rendering homepage search');

  const barLaoding = loading || knowlede360loading;
  const progressTimeBar = (progressTime || progressTimeKnowledge) + 10;

  return (
    <>
      <>
        <Box sx={{ width: '100%', height: '5px' }}>
          {barLaoding && (
            <LinearProgress variant="determinate" color="secondary" value={progressTimeBar} />
          )}
        </Box>
      </>

      <MainContainer>
        <SearchBackground>
          <TopSection>
            {/* <h1>Knowledge360 Search</h1> */}
            <ServiceList>
              {services.map((item, index) => {
                return (
                  <ServiceListItem
                    isSelected={item.selected}
                    onClick={() => {
                      handleItemSelection(index);
                    }}
                  >
                    <img src={item.icon} alt={item.name} width="35" height="35" />
                    {item.name}
                  </ServiceListItem>
                );
              })}
            </ServiceList>
            {renderService(selectedIndex)}
          </TopSection>
        </SearchBackground>
        <BottomSection>
          <MuiCard
            component="a"
            href="https://alstomgroup.sharepoint.com/sites/iSenS_Communication"
            target="_blank"
          >
            <PeopleIcon
              fontSize="large"
              style={{ color: 'rgb(74, 101, 238)', marginBottom: '0.5rem' }}
            />
            <Typography variant="h6" style={{ color: '#EEF1F9' }}>
              iSenS Community
            </Typography>
            <Typography style={{ color: '#EEF1F9', fontSize: '0.89rem' }}>
              200+ recordings
            </Typography>
          </MuiCard>
          <MuiCard
            component="a"
            href="https://isens-qual.alstom.com/user-manual/user_manual.html"
            target="_blank"
          >
            <LocalLibraryIcon
              fontSize="large"
              style={{ color: 'rgb(74, 101, 238)', marginBottom: '0.5rem' }}
            />
            <Typography variant="h6" style={{ color: '#EEF1F9' }}>
              User Manual
            </Typography>
            <Typography style={{ color: '#EEF1F9', fontSize: '0.89rem' }}>
              200+ recordings
            </Typography>
          </MuiCard>
          <MuiCard
            component="a"
            href="https://alstomgroup.sharepoint.com/sites/ISENS/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FISENS%2FShared%20Documents%2F98%20%2D%20Training%20and%20Videos%2FTraining%5FVideos&p=true&ga=1"
            target="_blank"
          >
            <OndemandVideoIcon
              fontSize="large"
              style={{ color: 'rgb(74, 101, 238)', marginBottom: '0.5rem' }}
            />
            <Typography variant="h6" style={{ color: '#EEF1F9' }}>
              Help Videos
            </Typography>
            <Typography style={{ color: '#EEF1F9', fontSize: '0.89rem' }}>
              200+ recordings
            </Typography>
          </MuiCard>
          {/* </MuiCard> */}
        </BottomSection>
      </MainContainer>
    </>
  );
};

export default memo(Homepage);
