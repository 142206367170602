import { Grid, Paper } from '@material-ui/core';
import React, { memo, useContext, useEffect, useMemo, useRef } from 'react';
import useAzureToken from '../../../../app/useAzureToken';
import formatDate from '../../../../tools/formatDate';
import BoardNewMessage from './BoardNewMessage';
import { ChatContext } from './ChatContext';
import DiscussBoardItemAssistant from './DiscussBoardItemAssistant';
import DiscussBoardItemAvatar from './DiscussBoardItemAvatar';
import InputPrompt from './InputPrompt';
import { DiscussionType } from './chat';

import { useStyles } from './DiscussBoard';
import DiscussBoardItemButtonsCitations from './DiscussBoardItemButtonsCitations';

type Props = {
  discussion?: DiscussionType;
  onOpenCitation?: any;
  toggleCollapse?: (item: any) => void;
  setToggleMenu?: any;
};

const DiscussBoardWithData = React.forwardRef(
  ({ discussion, onOpenCitation, toggleCollapse, setToggleMenu }: Props, ref) => {
    const { dispatch, state } = useContext(ChatContext);
    const classes = useStyles();

    // extract username + initiale
    const {
      jwt: { idTokenClaims },
    } = useAzureToken();
    const { name: username } = idTokenClaims;
    const initialname = useMemo(
      () =>
        username
          .split(' ')
          .map((u: string) => u.substring(1, 0))
          .slice(0, 2)
          .join(''),
      [username]
    );

    const refBottom = useRef(null);

    useEffect(() => {
      const container = document.getElementById('gpt-dashboard');
      if (container) {
        if (state.scrollBottom) {
          if (refBottom.current) {
            container.scrollTop = container.scrollHeight;
          }
        }
      }
    }, [state]);

    if (discussion === undefined) {
      return <BoardNewMessage toggleCollapse={toggleCollapse} setToggleMenu={setToggleMenu} />;
    }

    if (!discussion.cache) {
      return <>Conversation is empty</>;
    }
    return (
      <>
        <Paper className={classes.root} ref={ref} id="gpt-dashboard">
          {discussion?.created_at && (
            <h5
              style={{
                width: '100%',
                textAlign: 'center',
                borderBottom: '1px solid #707070',
                lineHeight: '0.1em',
              }}
            >
              <span
                style={{
                  padding: '0 10px',
                  backgroundColor: '#fff',
                  color: '#707070',
                }}
              >
                {formatDate(discussion?.created_at, 'DD/MM/YYYY hh:mm')}
              </span>
            </h5>
          )}
          {discussion?.cache.length > 0 && (
            <Grid container>
              {discussion.cache.map((message, k) => {
                // fix streaming error
                if (!message || !message.role) return null;

                // Skip the first message if it's system or assistant
                if (k === 0 && (message.role === 'system' || message.role === 'assistant'))
                  return null;

                // Render user messages
                if (message.role === 'user') {
                  return (
                    <DiscussBoardItemAvatar
                      key={`div-${k}`}
                      rowNumber={k}
                      classes={classes}
                      discussionId={discussion.id}
                      dispatch={dispatch}
                      initialname={initialname}
                      message={message}
                      isStreaming={!!state.loadingPrompt}
                    />
                  );
                }

                // Render assistant/system messages
                if (message.role === 'system' || message.role === 'assistant') {
                  return (
                    <div key={`assistant-container-${k}`}>
                      <DiscussBoardItemAssistant
                        key={`div-${k}`}
                        rowNumber={k}
                        messageId={message.id}
                        classes={classes}
                        discussionId={discussion.id}
                        dispatch={dispatch}
                        initialname={initialname}
                        isStreaming={!!state.loadingPrompt && k === discussion.cache.length - 1}
                        message={message}
                        metadata={discussion.cache_metadata?.find((m) => m.row_number === k)}
                      />
                      <DiscussBoardItemButtonsCitations
                        key={`cit-list-${k}`}
                        input={{ context: message.context, content: message.content }}
                        classes={classes}
                        onOpenCitation={onOpenCitation}
                      />
                    </div>
                  );
                }
                return null;
              })}
            </Grid>
          )}
          {/* scroll to bottom */}
          <div ref={refBottom} />
        </Paper>
        <InputPrompt toggleCollapse={toggleCollapse} setToggleMenu={setToggleMenu} />
      </>
    );
  }
);

export default memo(DiscussBoardWithData);
