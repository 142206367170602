import React, { useContext, useMemo } from 'react';
import M2GlobalContext from '../../ContextMager/M2GlobalContext';
import { iSearcheSixLayerNew, textToText } from '../../../api/module2';

const SimilarityFilters = ({ similarityFilter, dispatch, callbackFilters }) => {
  if (!similarityFilter) {
    return { options: [] };
  }
  const {
    simiProductLine,
    simiPlatform,
    simiSolution,
    simiProduct,
    simiProject,
    simiDocumentProfile,
    selectedSimiProductLine,
    selectedSimiPlatform,
    selectedSimiSolution,
    selectedSimiProduct,
    selectedSimiProject,
    selectedSimiDocumentProfile,
    countValue,
    threshold,
  } = similarityFilter;

  const multifilterCallback = (payload, key) => {
    const { productLine, project, documentProfile, platforms, products, solution } = payload;
    textToText(productLine, platforms, solution, products, project, documentProfile).then(
      (res: any) => {
        if (res?.filters) {
          const tepm = {
            ...res.filters,
          };

          delete tepm[key];
          callbackFilters(tepm);
        }
      }
    );
  };

  const temp = [
    {
      name: 'Product Line',
      key: 'alstomEntities',
      check: 'false',
      collapsed: false,
      checkAllOption: false,
      options: simiProductLine,
      _cache: {},
      onChangeOption: (option) => {
        dispatch({ type: 'change_simi_ProductLine', payload: option });
        const payload = {
          productLine: option,
          project: selectedSimiProject,
          documentProfile: selectedSimiDocumentProfile,
          platforms: selectedSimiPlatform,
          products: selectedSimiProduct,
          solution: selectedSimiSolution,
        };
        multifilterCallback(payload, 'alstomEntities');
      },
    },
    {
      name: 'Platforms',
      key: 'platforms',
      check: 'false',
      collapsed: false,
      checkAllOption: false,
      options: simiPlatform,
      _cache: {},
      onChangeOption: (option) => {
        dispatch({ type: 'change_simi_Platform', payload: option });
        const payload = {
          productLine: selectedSimiProductLine,
          project: selectedSimiProject,
          documentProfile: selectedSimiDocumentProfile,
          platforms: option,
          products: selectedSimiProduct,
          solution: selectedSimiSolution,
        };
        multifilterCallback(payload, 'platforms');
      },
    },
    {
      name: 'Solution',
      key: 'solutions',
      check: 'false',
      collapsed: false,
      checkAllOption: false,
      options: simiSolution,
      _cache: {},
      onChangeOption: (option) => {
        dispatch({ type: 'change_simi_Solution', payload: option });
        const payload = {
          productLine: selectedSimiProductLine,
          project: selectedSimiProject,
          documentProfile: selectedSimiDocumentProfile,
          platforms: selectedSimiPlatform,
          products: selectedSimiProduct,
          solution: option,
        };
        multifilterCallback(payload, 'solutions');
      },
    },
    {
      name: 'Product',
      key: 'products',
      check: 'false',
      collapsed: false,
      checkAllOption: false,
      options: simiProduct,
      _cache: {},
      onChangeOption: (option) => {
        dispatch({ type: 'change_simi_Product', payload: option });
        const payload = {
          productLine: selectedSimiProductLine,
          project: selectedSimiProject,
          documentProfile: selectedSimiDocumentProfile,
          platforms: selectedSimiPlatform,
          products: option,
          solution: selectedSimiSolution,
        };
        multifilterCallback(payload, 'products');
      },
    },
    {
      name: 'Projects',
      key: 'projects',
      check: 'false',
      collapsed: false,
      checkAllOption: false,
      options: simiProject,
      _cache: {},
      onChangeOption: (option) => {
        dispatch({ type: 'change_simi_Project', payload: option });
        const payload = {
          productLine: selectedSimiProductLine,
          project: option,
          documentProfile: selectedSimiDocumentProfile,
          platforms: selectedSimiPlatform,
          products: selectedSimiProduct,
          solution: selectedSimiSolution,
        };
        multifilterCallback(payload, 'projects');
      },
    },
    {
      name: 'Document Profiles',
      key: 'documentProfiles',
      check: 'false',
      collapsed: false,
      checkAllOption: false,
      options: simiDocumentProfile,
      _cache: {},
      onChangeOption: (option) => {
        dispatch({ type: 'change_simi_DocumentProfile', payload: option });
        const payload = {
          productLine: selectedSimiProductLine,
          project: selectedSimiProject,
          documentProfile: option,
          platforms: selectedSimiPlatform,
          products: selectedSimiProduct,
          solution: selectedSimiSolution,
        };
        multifilterCallback(payload, 'documentProfiles');
      },
    },
    // {
    //   name: 'Country',
    //   key: 'countries',
    //   check: 'false',
    //   collapsed: false,
    //   checkAllOption: false,
    //   options: isearcherCountry,
    //   _cache: {},
    //   onChangeOption: (option) => {
    //     dispatch({ type: 'change_IsercherCountry', payload: option });
    //     const payload = {
    //       productLine: selectedIsearcherProductLine,
    //       project: selectedIsearcherProjects,
    //       documentProfile: selectedIsearcherDocumentProfiles,
    //       platforms: selectedIsearcherPlatforms,
    //       products: selectedIsearcherProduct,
    //       solution: selectedIsearcherSolution,
    //       country: option,
    //     };
    //     multifilterCallback(payload);
    //   },
    // },
  ];
  return { options: temp };
};

export default SimilarityFilters;
