import { createMuiTheme, makeStyles } from '@material-ui/core';
import { createTheme } from '@mui/material/styles';
// import { makeStyles } from '@material-ui/core';

export const drawerWidth = 272;
export const isensTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#7B909C',
      veryLight: '#002A452E',
      main: '#002A45',
      dark: '#002884',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#DC3223',
      light: '#E15A50',
      veryLight: '#EB827D',
      dark: '#002884',
    },
    green: {
      light: '#46B98C',
      veryLight: '#73CDAA',
      main: '#19AA6E',
      dark: '#002884',
    },
    blue: {
      light: '#EEF1F9',
      dark: '#002884',
    },
    error: {
      main: '#DC3223',
      dark: '#002884',
    },
  },
  typography: {
    body1: '16px',
    subtitle1: {
      font: 'normal normal medium 40px/52px Alstom',
      fontWeight: 600,
    },
  },
  background: {
    default: '#FBFBFB',
  },
  overrides: {
    MuiTypography: {
      fontFamily: ['AlstomRegular'].join(','),
      subtitle1: {
        fontSize: '1.25rem',
      },
      body1: {
        fontSize: '1rem',
      },
    },
  },
});

export default isensTheme;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    background: '#FBFBFB',
  },
  btnSelected: {
    '.Mui-selected': {
      backgroundColor: 'red',
    },
    '& .MuiListItem-button': {
      color: 'white',
    },
  },
  searchPaper: {
    padding: '20px 10px',
    marginTop: '50px',
    display: 'flex',
    alignItems: 'center',
    width: '60vw',
    flexDirection: 'row',
  },
  searchResultPaper: {
    padding: '20px 10px',
    marginTop: '50px',
    display: 'flex',
    alignItems: 'column',
    width: '60vw',
    flexDirection: 'row',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 5,
  },
  menuButtonBadge: {
    color: '#002A45',
    // marginRight: 10,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    backgroundColor: isensTheme.palette.primary.light,
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    // height: '100vh',
    left: 0,
    margin: 0,
    maxHeight: '100vh',
    maxWidth: '100vw',
    padding: 0,
    position: 'fixed',
    right: 0,
    top: 0,
    width: '100vw',
    // zIndex: 1000,
    height: '100vh',
    overflow: 'hidden',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  moduleCard: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  modulecardContent: {
    flexGrow: 1,
    backgroundColor: isensTheme.palette.secondary.light,
  },
  modulePaper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    backgroundColor: isensTheme.palette.primary.main,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  select: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    paddingLeft: '10px',
    margin: '5px',
  },
  icon: {
    fill: theme.palette.common.white,
  },
  buttonIcon: {
    marginRight: theme.spacing(2),
    color: 'white',
  },
  listItemSelected: {
    backgroundColor: '#ff0000',
  },
  leftBarTab: {
    marginTop: 10,
    padding: 10,
  },
  activeTab: {
    marginTop: 10,
    padding: 10,
    boxShadow: `0 2px 2px 2px ${theme.palette.primary.main}`,
  },
  avatarRoot: {
    '&.MuiAvatar-root': {
      width: '111px',
      padding: '0px 3px',
    },
    '& .MuiAvatar-img': {
      width: '100%',
      height: '44%',
    },
  },
}));
