import React from 'react';
import Dataset from './DataSet';

const Gap = ({ datatype }) => {
  return (
    <>
      {' '}
      <Dataset datatype={datatype} />
    </>
  );
};

export default Gap;
