import React from 'react';
import { Route, Switch, useParams } from 'react-router-dom';

import UserWorkSpaceContainer from '../Home/components/UserWorkSpaceContainer';
import DocumentContainer from './Containers/DocumentContainer';
import DocumentAnalysisContainer from './Containers/DocumentAnalysisContainer';
import { UserSpaceContextProvider } from './UserSpaceContext';

const UserRouter = () => {
  const { service } = useParams();

  // console.log('service', service);

  return (
    <UserSpaceContextProvider>
      <Switch>
        <Route
          exact
          path="/userspace/DocAnalysis"
          render={() => {
            return (
              <>
                <DocumentAnalysisContainer />
              </>
            );
          }}
        />

        <Route
          exact
          path="/userspace/DocumentTranslator"
          render={() => {
            return (
              <>
                <DocumentContainer />
              </>
            );
          }}
        />
        <Route
          exact
          path="/"
          render={() => {
            return (
              <>
                <UserWorkSpaceContainer />
              </>
            );
          }}
        />
      </Switch>
    </UserSpaceContextProvider>
  );
};

export default UserRouter;
