import React, { useContext } from 'react';
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { HomeContext } from '../../../pages/home/homeContextProvider';

interface SummaryTopicCardByStatusProps {
  statusname: string;
  count: number;
  onFilter?: any;
}

const Label = ({ count, allStatus, statusname }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '2px' }}>
      <div
        style={{
          width: '1rem',
          height: '1rem',
          color: 'white',
          padding: '0.6rem',
          backgroundColor: 'green',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '50%',
        }}
      >
        {count}
      </div>{' '}
      <div>{allStatus.find((a) => a.label === statusname)?.title || ''}</div>
    </div>
  );
};

const SummaryTopicCardByStatus: React.FC<SummaryTopicCardByStatusProps> = ({
  statusname,
  count,
  onFilter,
}) => {
  const { allStatus } = useContext<any>(HomeContext);



  return (
    <div>
      {/* <Card sx={{ width: 250 }}>
        <CardContent sx={{ justifyContent: 'center' }}></CardContent>
        <CardActions sx={{ backgroundColor: '#333353', justifyContent: 'center' }}>
          <Typography color="white" variant="subtitle1">
            {count}
          </Typography>
        </CardActions>
      </Card> */}
      <div style={{ padding: '0.3rem', width: 'max-content' }}>
        <Typography
          sx={{ mb: 1 }}
          textAlign="center"
          variant="body2"
          color="black"
          fontWeight="bold"
        >
          <FormControlLabel
            control={<Checkbox value={statusname} onChange={onFilter} />}
            label={<Label count={count} statusname={statusname} allStatus={allStatus} />}
          />
        </Typography>
      </div>
    </div>
  );
};

export default SummaryTopicCardByStatus;
