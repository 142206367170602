import { Grid, Paper, makeStyles } from '@material-ui/core';
import React, { memo, useContext, useEffect, useMemo, useRef } from 'react';
import useAzureToken from '../../../../app/useAzureToken';
import formatDate from '../../../../tools/formatDate';
import BoardNewMessage from './BoardNewMessage';
import { ChatContext } from './ChatContext';
import InputPrompt from './InputPrompt';
import DiscussBoardItemAssistant from './DiscussBoardItemAssistant';
import DiscussBoardItemAvatar from './DiscussBoardItemAvatar';
import { DiscussionType } from './chat';

export const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: '8px',
    // maxHeight: '100%',
    // minHeight: '100%',
    overflow: 'auto',
    padding: 10,
    paddingLeft: 30,
    paddingRight: 30,
    // height: '100%',
    // paddingBottom: 60,
    paddingBottom: 30,
    boxShadow: 'None',
    width: '100%',
    '@media print': {
      maxHeight: '100%',
      minHeight: '100%',
      maxWidth: '100%',
      minWidth: '100%',
      overflowY: 'hidden',
    },
    // [theme.breakpoints.down('xl')]: {
    //   maxHeight: '85%',
    //   minHeight: '60%',
    // },
    // [theme.breakpoints.down('lg')]: {
    //   maxHeight: '78%',
    //   minHeight: '50%',
    // },
    // [theme.breakpoints.down('md')]: {
    //   maxHeight: '68%',
    //   minHeight: '40%',
    // },
    // [theme.breakpoints.down('sm')]: {
    //   maxHeight: '55%',
    //   minHeight: '30%',
    // },
    // [theme.breakpoints.down('xs')]: {
    //   maxHeight: '30%',
    //   minHeight: '20%',
    // },
    // [theme.breakpoints.down('xl')]: {
    //   maxWidth: '95vw',
    // },
    // [theme.breakpoints.down('lg')]: {
    //   maxWidth: '90vw',
    // },
    // [theme.breakpoints.down('md')]: {
    //   maxWidth: '80vw',
    // },
    // [theme.breakpoints.down('sm')]: {
    //   maxWidth: '70vw',
    // },
    // [theme.breakpoints.down('xs')]: {
    //   maxWidth: '60vw',
    // },
  },

  userMessage: {
    background: '#EEF1F9 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 6px #00000029',
    border: '1px solid #002A45',
    padding: '10px 0',
    gap: 0,
    borderRadius: '10px',
    position: 'relative',
  },

  rootMessage: {
    margin: '20px 0',
  },

  boxMessage: {
    alignItems: 'center',
  },

  contentMessage: {
    padding: '0 15px',
    wordBreak: 'break-word',
  },

  imgMessage: {
    paddingLeft: 10,
    gap: 0,
    minWidth: 80,
    maxWidth: 80,
  },

  btnDelete: {
    color: '#dc3223',
    '&:hover': {
      color: '#dc3223',
    },
    position: 'absolute',
    top: '10px',
    right: '10px',
  },

  btnThumb: {
    display: 'flex',
    justifyContent: 'flex-end',
    color: '#dc3223',
    marginRight: '5px',
  },
  btnThumbIcon: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  buttonCitations: {
    '&.MuiChip-root': {
      padding: '0 5px',
      background: theme.palette.primary.main, // Change this to your desired color
      color: 'white',
      border: `1px solid ${theme.palette.primary.main}`,
      marginRight: 10,
      fontWeight: 'bold',
      fontSize: '14px',
      '&.MuiChip-outlined': {
        color: theme.palette.primary.main,
        background: 'white', // Change this to your desired color
      },
    },
  },

  popupContent: {
    maxHeight: '70vh',
    minHeight: '40vh',
    overflowY: 'scroll',
  },
}));
type Props = {
  discussion?: DiscussionType;
  toggleCollapse?: (item: any) => void;
  setToggleMenu?: any;
};

const DiscussBoard = React.forwardRef(
  ({ discussion, toggleCollapse, setToggleMenu }: Props, ref) => {
    const { dispatch, state } = useContext(ChatContext);
    const classes = useStyles();

    // extract username + initiale
    const {
      jwt: { idTokenClaims },
    } = useAzureToken();
    const { name: username } = idTokenClaims;
    const initialname = useMemo(
      () =>
        username
          .split(' ')
          .map((u: string) => u.substring(1, 0))
          .slice(0, 2)
          .join(''),
      [username]
    );

    const refBottom = useRef(null);

    useEffect(() => {
      const container = document.getElementById('gpt-dashboard');
      if (container) {
        if (state.scrollBottom) {
          if (refBottom.current) {
            container.scrollTop = container.scrollHeight;
          }
        }
      }
    }, [state]);

    if (discussion === undefined) {
      return <BoardNewMessage toggleCollapse={toggleCollapse} setToggleMenu={setToggleMenu} />;
    }

    if (!discussion.cache) {
      return <>Conversation is empty</>;
    }
    return (
      <>
        <Paper className={classes.root} ref={ref} id="gpt-dashboard">
          {discussion?.created_at && (
            <h5
              style={{
                width: '100%',
                textAlign: 'center',
                borderBottom: '1px solid #707070',
                lineHeight: '0.1em',
              }}
            >
              <span
                style={{
                  padding: '0 10px',
                  backgroundColor: '#fff',
                  color: '#707070',
                }}
              >
                {formatDate(discussion?.created_at, 'DD/MM/YYYY hh:mm')}
              </span>
            </h5>
          )}
          {discussion?.cache.length > 0 && (
            <Grid container>
              {discussion.cache.map((message, k) => {
                if (k === 0 && (message.role === 'system' || message.role === 'assistant'))
                  return null;
                if (message.role === 'user') {
                  return (
                    <DiscussBoardItemAvatar
                      key={`div-${k}`}
                      rowNumber={k}
                      classes={classes}
                      discussionId={discussion.id}
                      dispatch={dispatch}
                      initialname={initialname}
                      message={message}
                      isStreaming={!!state.loadingPrompt}
                    />
                  );
                }

                if (message.role === 'system' || message.role === 'assistant') {
                  return (
                    <DiscussBoardItemAssistant
                      key={`div-${k}`}
                      rowNumber={k}
                      messageId={message.id}
                      classes={classes}
                      discussionId={discussion.id}
                      dispatch={dispatch}
                      initialname={initialname}
                      isStreaming={!!state.loadingPrompt && k === discussion.cache.length - 1}
                      message={message}
                      metadata={discussion.cache_metadata?.find((m) => m.row_number === k)}
                    />
                  );
                }
                return null;
              })}
            </Grid>
          )}
          {/* scroll to bottom */}
          <div ref={refBottom} />
        </Paper>
        <InputPrompt toggleCollapse={toggleCollapse} setToggleMenu={setToggleMenu} />
      </>
    );
  }
);

export default memo(DiscussBoard);
