import { isEmpty } from '@antv/util';
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import {
  ColumnChooser,
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableColumnVisibility,
  TableFilterRow,
  TableHeaderRow,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import {
  Box,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Paper,
  IconButton,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import FullscreenIcon from '@material-ui/icons/Fullscreen'; // Import Fullscreen icon
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import DescriptionIcon from '@mui/icons-material/Description';
import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

import WorkOutlinedIcon from '@mui/icons-material/WorkOutlined';
import { List } from '@mui/material';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Pie,
  PieChart,
  Tooltip,
  XAxis,
  YAxis,
  Sector,
} from 'recharts';

// import DownloadPlugin from '../../../components/Datagrid/DownloadPlugin';
import TStyles, { rowComponent, toolbarComponent } from '../../../components/Datagrid/TStyles';
import IsearcherStyle from '../../../components/Layout/IsearcherStyle';
import { resultStyle, ListItem2 } from '../../../components/Filter/textAnalysis';
import ToolBarPlugin from '../../../components/Datagrid/ToolBarPlugin';
import NavigationGoto from '../../../components/Core/NavigationGoto';

const getPath = (x, y, width, height) => `M${x},${y + height}
          C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${x + width / 2}, ${y}
          C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${
  y + height
}
          Z`;
const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;

  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};

const columns: any = [
  { name: 'artifactText', title: 'Text' },
  { name: 'alstomEntity', title: 'Entity' },
  { name: 'platform', title: 'Platform' },
  { name: 'project', title: 'Project' },
  { name: 'documentProfile', title: 'Doc Profile' },
  { name: 'doorsArtifactAllocation', title: 'Allocation' },
  { name: 'documentTitle', title: 'Doc Title' },
  { name: 'documentPath', title: 'Doc Path' },
  { name: 'documentVersion', title: 'Doc Version' },
  { name: 'documentABSActivity', title: 'Doc ABS' },
  { name: 'documentOBSOwner', title: 'Doc OBS' },
  { name: 'artifactType', title: 'Artifact Type' },
  { name: 'lastModifiedBy', title: 'Last Modified By' },
  { name: 'lastModifiedOn', title: 'Last Modified On' },
  { name: 'url', title: 'URL' },
  { name: 'moduleLastModifiedBy', title: 'Module Last Modified By' },
  { name: 'moduleLastModifiedOn', title: 'Module Last Modified On' },
  { name: 'moduleUrl', title: 'Module Url' },
  { name: 'createdBy', title: 'Created By' },
  { name: 'createdOn', title: 'Created On' },
  { name: 'moduleCreatedBy', title: 'Module Created By' },
  { name: 'moduleCreatedOn', title: 'Module Created On' },
  { name: 'compliance', title: 'Compliance' },
  { name: 'artifactLifeCycle', title: 'Artifact Life Cycle' },
  { name: 'artifactActivity', title: 'Artifact Activity' },
];
const COLORS = [
  '#1e3246',
  '#dc3223',
  '#19aa6e',
  '#4b5a69',
  '#788291',
  '#d2d7dc',
  '#eb827d',
  '#fad7d2',
  '#d2f0e1',
  '#73cdaa',
  '#9b875f',
  '#afa082',
  '#ebe6dc',
  '#c3b9a0',
  '#C3DDF6',
  '#468AD1',
  '#F8A600',
  '#2f475f',
  '#95372f',
  '#7a231b',
  '#94a2b8',
  '#5b8f75',
  '#546c60',
  '#36443d',
  '#748c80',
  '#4fa7ff',
  '#174471',
  '#384a5c',
  '#acacad',
  '#bdbdbd',
];

const COLORS_ENTITY = ['#DC3223', '#19AA6E'];

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`PV ${value}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const TableComponentBase = ({ classes, ...restProps }: any) => (
  <Table.Table {...restProps} className={classes.tableStriped} />
);

const TableComponent = withStyles(TStyles, { name: 'TableComponent' })(TableComponentBase);
const renderText = ({ value, row }: DataTypeProvider.ValueFormatterProps) => (
  <a
    id={`search-${row.id}`}
    // href="https://myworkplace.alstom.hub/Citrix/Alstom_PROD_StoreWeb/"
    href={row.url}
    target="_blank"
    rel="noreferrer"
    onClick={() => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      urlSearch(String(row.url));
    }}
    style={{ textDecoration: 'none' }}
    title={value}
  >
    {value}
  </a>
);
function urlSearch(a: any) {
  // console.log(a);
}
function PeopleSearch({
  rows,
  payloadPeopleSearch,
  alstomEntityWiseDistribution,
  platformWiseDistribution,
  projectWiseDistribution,
  documentProfileWiseDistribution,
  solutionWiseDistribution,
  countryWiseDistribution,
  productWiseDistribution,
  artifactWiseDistribution,
  timeTaken,
  TotalPeopleResult,
  oldPayload,
  handleClickText2DatasetNew,
}) {
  const classes = IsearcherStyle();
  const classes2 = resultStyle();
  const [tempPage, setTemPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const [dis, setDis] = useState(true);
  const [pageSize, setPageSize] = useState(5);
  const [activeIndex1, setActivate1] = useState(0);
  const [activeIndex2, setActivate2] = useState(0);
  const [activeIndex3, setActivate3] = useState(0);
  const [activeIndex4, setActivate4] = useState(0);
  const [activeIndex5, setActivate5] = useState(0);
  const [activeIndex6, setActivate6] = useState(0);
  const [activeIndex7, setActivate7] = useState(0);
  const [activeIndex8, setActivate8] = useState(0);
  const [graphType, setGraphType] = React.useState<any>('bar');
  const [toggleHeight, setToggleHeight] = useState(false);
  const [hiddenColumnNames, setHiddenColumnNames] = useState([
    'doorsArtifactAllocation',
    // 'documentTitle',
    'documentVersion',
    'documentABSActivity',
    'documentOBSOwner',
    'documentPath',
    'artifactType',
    'lastModifiedBy',
    'lastModifiedOn',
    'url',
    'moduleLastModifiedOn',
    'moduleLastModifiedBy',
    'moduleUrl',
    'createdBy',
    'createdOn',
    'moduleCreatedBy',
    'moduleCreatedOn',
    'compliance',
    'artifactLifeCycle',
    'artifactActivity',
  ]);
  const [operation, setOperation] = useState(0);
  const [pageSizes] = useState([5, 10, 20, 50]);

  const handlePageNum = () => {
    const rel = tempPage - 1;
    setCurrentPage(rel);
  };
  const onPieEnter1 = (_, index) => {
    setActivate1(index);
  };
  const onPieEnter2 = (_, index) => {
    setActivate2(index);
  };
  const onPieEnter3 = (_, index) => {
    setActivate3(index);
  };
  const onPieEnter4 = (_, index) => {
    setActivate4(index);
  };
  const onPieEnter5 = (_, index) => {
    setActivate5(index);
  };
  const onPieEnter6 = (_, index) => {
    setActivate6(index);
  };
  const onPieEnter7 = (_, index) => {
    setActivate7(index);
  };
  const onPieEnter8 = (_, index) => {
    setActivate8(index);
  };

  const handleChangeGraph = (event: any, passedData: string) => {
    setGraphType(passedData);
    if (passedData === '') {
      setGraphType('bar');
    }
  };

  const exporterRef = useRef(null);

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'iSearcher_Report.xlsx');
    });
  };

  useEffect(() => {
    if (sessionStorage.getItem('peopleSearch') !== null) {
      setHiddenColumnNames(sessionStorage.getItem('peopleSearch')?.split(','));
    }
  }, []);
  useEffect(() => {
    sessionStorage.setItem('peopleSearch', hiddenColumnNames);
  }, [hiddenColumnNames]);

  useEffect(() => {
    if (pageSize > rows.length) {
      if (dis) {
        setDis((prev) => {
          return !prev;
        });
      }
    } else if (pageSize < rows.length) {
      setDis((prev) => {
        return !prev;
      });
    }
  }, [pageSize]);
  const handleFullScreen1 = useFullScreenHandle(); // For the first chart
  const handleFullScreen2 = useFullScreenHandle(); // For the second chart
  const handleFullScreen3 = useFullScreenHandle(); // For the third chart
  const handleFullScreen4 = useFullScreenHandle(); // For the first chart
  const handleFullScreen5 = useFullScreenHandle(); // For the second chart
  const handleFullScreen6 = useFullScreenHandle(); // For the third chart
  const fullScreenStyle = {
    backgroundColor: '#fff', // Match the background color of your CardContent
    padding: '20px', // Add padding if needed
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    placeContent: 'center',
    height: '100vh', // Fullscreen height
    width: '100vw', // Fullscreen width
    boxSizing: 'border-box',
    overflowX: 'scroll',
  };

  return (
    <>
      <Box
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        {TotalPeopleResult > 0 && (
          <>
            <Box style={{ display: 'flex', gap: '20px' }}>
              <Card style={{ flexBasis: '50%' }}>
                <div
                  style={{
                    background: '#002A45 0% 0% no-repeat padding-box',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingTop: 10,
                      paddingBottom: 10,
                      paddingLeft: 10,
                    }}
                  >
                    <Typography variant="h5" component="div" style={{ color: '#FFFFFF' }}>
                      Found People
                    </Typography>
                  </div>
                </div>
                <CardContent style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
                  {/* {oldPayload?.people?.map((onePeople: any) => {
                    return (
                      <ButtonBase style={{ display: 'flex' }}>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          style={{ color: '#002A45', textDecoration: 'underline' }}
                          //   value={items}
                          // onClick={() => handleClickText2DatasetNew(onePeople, false)}
                        >
                          {onePeople}
                        </Typography>
                      </ButtonBase>
                    );
                  })} */}
                  <List style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                    {oldPayload?.people?.map((onePeople: any, i: any) => {
                      return (
                        <ListItem2
                          button
                          style={{ display: 'flex' }}
                          onClick={() => {
                            setOperation(i);
                            handleClickText2DatasetNew(onePeople, '', oldPayload, false);
                          }}
                          selected={operation === i}
                        >
                          <Typography variant="subtitle1" value={onePeople}>
                            {onePeople}
                          </Typography>
                        </ListItem2>
                      );
                    })}
                  </List>
                </CardContent>
              </Card>
              <Card style={{ flexBasis: '50%' }}>
                <div
                  style={{
                    borderRadius: '11px 0px 135px 0px',
                    background:
                      'transparent linear-gradient(96deg, #012434 0%, #003E5C 100%) 0% 0% no-repeat padding-box',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingTop: 10,
                      color: '#FFFFFF',
                      paddingBottom: 10,
                    }}
                  >
                    <IconButton style={{ color: '#FFFFFF' }}>
                      <PersonOutlineOutlinedIcon />
                    </IconButton>
                    <Box style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
                      <Typography variant="h5" component="div">
                        {payloadPeopleSearch?.smartIdCard?.name}
                      </Typography>
                      <Typography>{payloadPeopleSearch?.smartIdCard?.designation}</Typography>
                    </Box>
                  </div>
                </div>
                <CardContent>
                  <div className={classes.cardContentDiv1}>
                    <div className={classes.cardContentDiv2}>
                      <BusinessOutlinedIcon className={classes.cardIcon} />
                      <Typography
                        variant="body1"
                        gutterBottom
                        style={{
                          marginRight: 10,
                          marginLeft: 5,
                          color: '#DC3223',
                        }}
                      >
                        Business Unit
                      </Typography>
                      :
                      <Typography
                        variant="body1"
                        gutterBottom
                        style={{ marginLeft: 5, fontWeight: 'bold' }}
                      >
                        {payloadPeopleSearch?.smartIdCard?.profile.map((a: any, i: any) => {
                          return (
                            <span
                              style={{
                                color: '#002A45',
                                textOverflow: 'hidden',
                              }}
                            >
                              {payloadPeopleSearch?.smartIdCard?.profile[i]}
                              {payloadPeopleSearch?.smartIdCard?.profile.length - 1 > i ? ', ' : ''}
                            </span>
                          );
                        })}
                      </Typography>
                    </div>
                  </div>
                  <br />
                  <div className={classes.cardContentDiv1}>
                    <div className={classes.cardContentDiv2}>
                      <DevicesOutlinedIcon className={classes.cardIcon} />
                      <Typography
                        variant="body1"
                        gutterBottom
                        style={{
                          marginRight: 37,
                          marginLeft: 5,
                          color: '#DC3223',
                        }}
                      >
                        Platform
                      </Typography>
                      :
                      <Typography
                        variant="body1"
                        gutterBottom
                        style={{ marginLeft: 5, fontWeight: 'bold' }}
                      >
                        {payloadPeopleSearch?.smartIdCard?.platforms.map((a: any, i: any) => {
                          return (
                            <span style={{ color: '#002A45' }}>
                              {payloadPeopleSearch?.smartIdCard?.platforms[i]}
                              {payloadPeopleSearch?.smartIdCard?.platforms.length - 1 > i
                                ? ', '
                                : ''}
                            </span>
                          );
                        })}
                      </Typography>
                    </div>
                  </div>
                  <br />
                  <div className={classes.cardContentDiv1}>
                    <div className={classes.cardContentDiv2}>
                      <WorkOutlinedIcon className={classes.cardIcon} />
                      <Typography
                        variant="body1"
                        gutterBottom
                        style={{
                          marginRight: 48,
                          marginLeft: 5,
                          color: '#DC3223',
                        }}
                      >
                        Project
                      </Typography>
                      :
                      <Typography
                        variant="body1"
                        gutterBottom
                        style={{ marginLeft: 5, fontWeight: 'bold' }}
                      >
                        {payloadPeopleSearch?.smartIdCard?.projects.map((person: any, i: any) => {
                          return (
                            <span style={{ color: '#002A45' }}>
                              {payloadPeopleSearch?.smartIdCard?.projects[i]}
                              {payloadPeopleSearch?.smartIdCard?.projects.length - 1 > i
                                ? ', '
                                : ''}
                            </span>
                          );
                        })}
                      </Typography>
                    </div>
                  </div>
                  <br />
                  <div className={classes.cardContentDiv1}>
                    <div className={classes.cardContentDiv2}>
                      <DescriptionIcon className={classes.cardIcon} />
                      <Typography
                        variant="body1"
                        gutterBottom
                        style={{
                          marginRight: 30,
                          marginLeft: 5,
                          color: '#DC3223',
                        }}
                      >
                        Document
                      </Typography>
                      :
                      <Typography
                        variant="body1"
                        gutterBottom
                        style={{ marginLeft: 5, fontWeight: 'bold' }}
                      >
                        {payloadPeopleSearch?.smartIdCard?.specifications.map((a: any, i: any) => {
                          return (
                            <span style={{ color: '#002A45' }}>
                              {payloadPeopleSearch?.smartIdCard?.specifications[i]}
                              {payloadPeopleSearch?.smartIdCard?.specifications.length - 1 > i
                                ? ', '
                                : ''}
                            </span>
                          );
                        })}
                      </Typography>
                    </div>
                  </div>
                  {/* <Box
                    style={{uni
                      display: 'flex',
                      marginTop: '0.5rem',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      gap: '0.5rem',
                      padding: '0.5rem',
                    }}
                  >
                    <a
                      href={`mailto:${payloadPeopleSearch?.smartIdCard?.email}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={outlook} alt="outlook" />
                    </a>

                    <a
                      href={`https://teams.microsoft.com/l/chat/0/0?users=${payloadPeopleSearch?.smartIdCard?.email}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={teamsIcon} alt="teams" />
                    </a>
                  </Box> */}
                </CardContent>
              </Card>
            </Box>
          </>
        )}

        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
          <span>
            <b>{rows.length}</b> out of <b>{TotalPeopleResult}</b> results
          </span>
          <span style={{ marginLeft: '10px' }}>({timeTaken} seconds)</span>
        </Typography>
        <Box style={{ backgroundColor: '#EEF1F9', border: '1px solid #002A45' }}>
          <ExpressGrid rows={rows} columns={columns}>
            <DataTypeProvider
              for={[
                'alstomEntity',
                'artifactText',
                'platform',
                'project',
                'documentProfile',
                'artifactType',
                'doorsArtifactAllocation',
                'documentTitle',
                'documentVersion',
                'documentABSActivity',
                'documentOBSOwner',
                'documentPath',
                'lastModifiedBy',
                'lastModifiedOn',
                'url',
              ]}
              formatterComponent={({ value }) => <span title={value}>{value}</span>}
            />
            <DataTypeProvider for={['url']} formatterComponent={renderText} />
            <FilteringState defaultFilters={[{ columnName: 'alstomEntity', value: '' }]} />
            <IntegratedFiltering />
            <SortingState />
            <IntegratedSorting />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={setCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
            />
            <IntegratedPaging />
            <Table
              tableComponent={TableComponent}
              columnExtensions={[
                { columnName: 'alstomEntity', width: '100px' },
                { columnName: 'platform', width: '200px' },
                //   { columnName: 'Score', width: '100px' },
                {
                  columnName: 'artifactText',
                  width: '300px',
                  wordWrapEnabled: toggleHeight,
                },
                {
                  columnName: 'documentPath',
                  width: '300px',
                  wordWrapEnabled: toggleHeight,
                },
                {
                  columnName: 'url',
                  width: '500px',
                  wordWrapEnabled: toggleHeight,
                },
              ]}
            />
            <TableColumnResizing
              defaultColumnWidths={[
                { columnName: 'Document_Title', width: 400 },
                { columnName: 'artifactText', width: 300 },
                { columnName: 'alstomEntity', width: 100 },
                { columnName: 'platform', width: 100 },
                { columnName: 'project', width: 200 },
                { columnName: 'documentProfile', width: 150 },
                { columnName: 'artifactType', width: 200 },
                { columnName: 'doorsArtifactAllocation', width: 200 },
                { columnName: 'documentTitle', width: 200 },
                { columnName: 'documentVersion', width: 100 },
                { columnName: 'documentABSActivity', width: 200 },
                { columnName: 'documentOBSOwner', width: 200 },
                { columnName: 'documentPath', width: 200 },
                { columnName: 'lastModifiedBy', width: 200 },
                { columnName: 'lastModifiedOn', width: 200 },
                { columnName: 'url', width: 500 },
                { columnName: 'moduleLastModifiedOn', width: 200 },
                { columnName: 'moduleLastModifiedBy', width: 200 },
                { columnName: 'moduleUrl', width: 500 },
                { columnName: 'createdBy', width: 200 },
                { columnName: 'createdOn', width: 200 },
                { columnName: 'moduleCreatedBy', width: 200 },
                { columnName: 'moduleCreatedOn', width: 200 },
                { columnName: 'compliance', width: 200 },
                { columnName: 'artifactLifeCycle', width: 200 },
                { columnName: 'artifactActivity', width: 200 },
              ]}
            />
            {/* table header bold <TableHeaderRow showSortingControls cellComponent={TableHeaderCellWrap} /> */}
            <TableHeaderRow showSortingControls />
            <TableFilterRow rowComponent={rowComponent} />
            <TableColumnVisibility
              hiddenColumnNames={hiddenColumnNames}
              onHiddenColumnNamesChange={setHiddenColumnNames}
            />
            <Toolbar rootComponent={toolbarComponent} />
            <ColumnChooser />
            <ToolBarPlugin name="Download" onClick={startExport} />
            <ToolBarPlugin
              name="Height"
              title="Wrap Text"
              onClick={() => setToggleHeight(!toggleHeight)}
            />
            <PagingPanel pageSizes={pageSizes} />
          </ExpressGrid>
          <GridExporter ref={exporterRef} rows={rows} columns={columns} onSave={onSave} />
          <NavigationGoto
            setPageSize={setPageSize}
            fileLength={rows.length}
            pageSize={pageSize}
            dis={dis}
            setTemPage={setTemPage}
            handlePageNum={handlePageNum}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Box>
        {alstomEntityWiseDistribution.length > 0 ||
        platformWiseDistribution.length > 0 ||
        projectWiseDistribution.length > 0 ||
        documentProfileWiseDistribution.length > 0 ? (
          <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ToggleButtonGroup
              size="small"
              value={graphType}
              exclusive
              onChange={handleChangeGraph}
              className={classes.toggle}
            >
              <ToggleButton value="bar">
                <span>
                  <b style={{ textTransform: 'none' }}>BAR GRAPH</b>
                </span>
              </ToggleButton>
              <ToggleButton value="pie">
                <span>
                  <b style={{ textTransform: 'none' }}>PIE GRAPH</b>
                </span>
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        ) : null}
        {graphType === 'pie' && (
          <Paper className={classes2.ToggleResultContainer}>
            <Box className={classes2.ToggleResBox}>
              {alstomEntityWiseDistribution.length > 0 && (
                <Card className={classes2.cardRoot}>
                  <CardHeader title="ProductLine Wise Distribution" />
                  <CardContent className={classes2.cardContent}>
                    <PieChart width={600} height={400} className={classes2.pieChart}>
                      <Pie
                        isAnimationActive={true}
                        activeIndex={activeIndex1}
                        data={alstomEntityWiseDistribution}
                        activeShape={renderActiveShape}
                        cx="50%"
                        cy="50%"
                        innerRadius={90}
                        outerRadius={120}
                        fill="#8884d8"
                        dataKey="value"
                        legendType="circle"
                        onMouseEnter={onPieEnter1}
                      >
                        {alstomEntityWiseDistribution.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS_ENTITY[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </CardContent>
                </Card>
              )}
              {platformWiseDistribution.length > 0 && (
                <Card className={classes2.cardRoot}>
                  <CardHeader title="Platform Wise Distribution" />
                  <CardContent className={classes2.cardContent}>
                    <PieChart width={600} height={400} className={classes2.pieChart}>
                      <Pie
                        isAnimationActive={true}
                        activeIndex={activeIndex2}
                        data={platformWiseDistribution}
                        activeShape={renderActiveShape}
                        cx="50%"
                        cy="50%"
                        innerRadius={90}
                        outerRadius={120}
                        fill="#8884d8"
                        dataKey="value"
                        legendType="circle"
                        onMouseEnter={onPieEnter2}
                      >
                        {platformWiseDistribution.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </CardContent>
                </Card>
              )}
            </Box>
            <Box className={classes2.ToggleResBox}>
              {solutionWiseDistribution.length > 0 && (
                <Card className={classes2.cardRoot}>
                  <CardHeader title="Solution Wise Distribution" />
                  <CardContent className={classes2.cardContent}>
                    <PieChart width={600} height={400} className={classes2.pieChart}>
                      <Pie
                        isAnimationActive={true}
                        activeIndex={activeIndex6}
                        data={solutionWiseDistribution}
                        activeShape={renderActiveShape}
                        cx="50%"
                        cy="50%"
                        innerRadius={90}
                        outerRadius={120}
                        fill="#8884d8"
                        dataKey="value"
                        legendType="circle"
                        onMouseEnter={onPieEnter6}
                      >
                        {solutionWiseDistribution.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS_ENTITY[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </CardContent>
                </Card>
              )}
              {productWiseDistribution.length > 0 && (
                <Card className={classes2.cardRoot}>
                  <CardHeader title="Product Wise Distribution" />
                  <CardContent className={classes2.cardContent}>
                    <PieChart width={600} height={400} className={classes2.pieChart}>
                      <Pie
                        isAnimationActive={true}
                        activeIndex={activeIndex7}
                        data={productWiseDistribution}
                        activeShape={renderActiveShape}
                        cx="50%"
                        cy="50%"
                        innerRadius={90}
                        outerRadius={120}
                        fill="#8884d8"
                        dataKey="value"
                        legendType="circle"
                        onMouseEnter={onPieEnter7}
                      >
                        {platformWiseDistribution.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </CardContent>
                </Card>
              )}
            </Box>
            <Box className={classes2.ToggleResBox}>
              {projectWiseDistribution.length > 0 && (
                <Card className={classes2.cardRoot}>
                  <CardHeader title="Project Wise Distribution" />
                  <CardContent className={classes2.cardContent}>
                    <PieChart className={classes2.pieChart} width={600} height={400}>
                      <Pie
                        isAnimationActive={true}
                        activeIndex={activeIndex3}
                        data={projectWiseDistribution}
                        activeShape={renderActiveShape}
                        cx="50%"
                        cy="50%"
                        innerRadius={90}
                        outerRadius={120}
                        fill="#8884d8"
                        dataKey="value"
                        legendType="circle"
                        onMouseEnter={onPieEnter3}
                      >
                        {projectWiseDistribution.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </CardContent>
                </Card>
              )}
              {documentProfileWiseDistribution.length > 0 && (
                <Card Card className={classes2.cardRoot}>
                  <CardHeader title="Document Profile Wise Distribution" />
                  <CardContent className={classes2.cardContent}>
                    <PieChart className={classes2.pieChart} width={600} height={400}>
                      <Pie
                        isAnimationActive={true}
                        activeIndex={activeIndex4}
                        data={documentProfileWiseDistribution}
                        activeShape={renderActiveShape}
                        cx="50%"
                        cy="50%"
                        innerRadius={90}
                        outerRadius={120}
                        fill="#8884d8"
                        dataKey="value"
                        legendType="circle"
                        onMouseEnter={onPieEnter4}
                      >
                        {documentProfileWiseDistribution.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </CardContent>
                </Card>
              )}
            </Box>
            {/* <Box>
              {countryWiseDistribution.length > 0 && (
                <Card Card className={classes2.cardRoot2}>
                  <CardHeader title="Country Level Distribution" />
                  <CardContent className={classes2.cardContent}>
                    <PieChart className={classes2.pieChart} width={600} height={400}>
                      <Pie
                        isAnimationActive={true}
                        activeIndex={activeIndex8}
                        data={countryWiseDistribution}
                        activeShape={renderActiveShape}
                        cx="50%"
                        cy="50%"
                        innerRadius={90}
                        outerRadius={120}
                        fill="#8884d8"
                        dataKey="value"
                        legendType="circle"
                        onMouseEnter={onPieEnter8}
                      >
                        {countryWiseDistribution.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </CardContent>
                </Card>
              )}
            </Box> */}
            {/* <Box>
              {artifactWiseDistribution.length > 0 && (
                <Card Card className={classes2.cardRoot2}>
                  <CardHeader title="Artifact Level Distribution" />
                  <CardContent className={classes2.cardContent}>
                    <PieChart className={classes2.pieChart} width={600} height={400}>
                      <Pie
                        isAnimationActive={true}
                        activeIndex={activeIndex5}
                        data={artifactWiseDistribution}
                        activeShape={renderActiveShape}
                        cx="50%"
                        cy="50%"
                        innerRadius={90}
                        outerRadius={120}
                        fill="#8884d8"
                        dataKey="value"
                        legendType="circle"
                        onMouseEnter={onPieEnter5}
                      >
                        {artifactWiseDistribution.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </CardContent>
                </Card>
              )}
            </Box> */}
          </Paper>
        )}
        {graphType === 'bar' && (
          <Paper className={classes2.ToggleResultContainer}>
            <Box className={classes2.ToggleResBox}>
              {alstomEntityWiseDistribution.length > 0 && (
                <Card className={classes2.cardRoot}>
                  <CardHeader
                    title="ProductLine Wise Distribution"
                    action={
                      <IconButton
                        title="Full Screen"
                        style={{ padding: 0 }}
                        onClick={handleFullScreen1.enter}
                      >
                        <FullscreenIcon />
                      </IconButton>
                    }
                  />
                  <CardContent>
                    <Box
                      className={
                        alstomEntityWiseDistribution.length > 5
                          ? classes2.barGraph
                          : classes2.barGraph2
                      }
                    >
                      <FullScreen handle={handleFullScreen1}>
                        <BarChart
                          width={400}
                          height={350}
                          data={alstomEntityWiseDistribution
                            .sort((a: any, b: any) => {
                              return a.value - b.value;
                            })
                            .reverse()}
                          layout="horizontal"
                          style={handleFullScreen1.active ? fullScreenStyle : {}}
                          margin={{
                            bottom: handleFullScreen1.active ? 150 : 75,
                            left: 40,
                          }}
                        >
                          <XAxis
                            dataKey="name"
                            textAnchor="end"
                            sclaeToFit="true"
                            verticalAnchor="start"
                            interval={0}
                            angle="-20"
                            stroke="#000"
                            fontSize={10}
                          />
                          <YAxis />
                          <CartesianGrid strokeDasharray="3 3" />
                          <Tooltip />
                          <Legend verticalAlign="top" height={36} align="right" />
                          <Bar
                            dataKey="value"
                            fill="#DC3223"
                            shape={<TriangleBar />}
                            barSize={50}
                          />
                        </BarChart>
                      </FullScreen>
                    </Box>
                  </CardContent>
                </Card>
              )}
              {platformWiseDistribution.length > 0 && (
                <Card className={classes2.cardRoot}>
                  <CardHeader
                    title="Platform Wise Distribution"
                    action={
                      <IconButton
                        title="Full Screen"
                        style={{ padding: 0 }}
                        onClick={handleFullScreen2.enter}
                      >
                        <FullscreenIcon />
                      </IconButton>
                    }
                  />
                  <CardContent>
                    <Box
                      className={
                        platformWiseDistribution.length > 5 ? classes2.barGraph : classes2.barGraph2
                      }
                    >
                      <FullScreen handle={handleFullScreen2}>
                        <BarChart
                          width={
                            projectWiseDistribution.length < 7
                              ? 400
                              : projectWiseDistribution.length * 15
                          }
                          height={350}
                          data={platformWiseDistribution
                            .sort((a: any, b: any) => {
                              return a.value - b.value;
                            })
                            .reverse()}
                          layout="horizontal"
                          style={handleFullScreen2.active ? fullScreenStyle : {}}
                          margin={{
                            bottom: handleFullScreen2.active ? 150 : 75,
                            left: 40,
                          }}
                        >
                          <XAxis
                            dataKey="name"
                            textAnchor="end"
                            sclaeToFit="true"
                            verticalAnchor="start"
                            interval={0}
                            angle="-20"
                            stroke="#000"
                            fontSize={10}
                          />
                          <YAxis />
                          <CartesianGrid strokeDasharray="3 3" />
                          <Tooltip />
                          <Legend verticalAlign="top" height={36} align="right" />
                          <Bar
                            dataKey="value"
                            fill="#1e3246"
                            shape={<TriangleBar />}
                            barSize={50}
                          />
                        </BarChart>
                      </FullScreen>
                    </Box>
                  </CardContent>
                </Card>
              )}
            </Box>
            <Box className={classes2.ToggleResBox}>
              {solutionWiseDistribution.length > 0 && (
                <Card className={classes2.cardRoot}>
                  <CardHeader
                    title="Solution Wise Distribution"
                    action={
                      <IconButton
                        title="Full Screen"
                        style={{ padding: 0 }}
                        onClick={handleFullScreen3.enter}
                      >
                        <FullscreenIcon />
                      </IconButton>
                    }
                  />
                  <CardContent>
                    <Box
                      className={
                        solutionWiseDistribution.length > 5 ? classes2.barGraph : classes2.barGraph2
                      }
                    >
                      <FullScreen handle={handleFullScreen3}>
                        <BarChart
                          width={400}
                          height={350}
                          data={solutionWiseDistribution
                            .sort((a: any, b: any) => {
                              return a.value - b.value;
                            })
                            .reverse()}
                          layout="horizontal"
                          style={handleFullScreen3.active ? fullScreenStyle : {}}
                          margin={{
                            bottom: handleFullScreen3.active ? 150 : 75,
                            left: 40,
                          }}
                        >
                          <XAxis
                            dataKey="name"
                            textAnchor="end"
                            sclaeToFit="true"
                            verticalAnchor="start"
                            interval={0}
                            angle="-20"
                            stroke="#000"
                            fontSize={10}
                          />
                          <YAxis />
                          <CartesianGrid strokeDasharray="3 3" />
                          <Tooltip />
                          <Legend verticalAlign="top" height={36} align="right" />
                          <Bar
                            dataKey="value"
                            fill="#DC3223"
                            shape={<TriangleBar />}
                            barSize={50}
                          />
                        </BarChart>
                      </FullScreen>
                    </Box>
                  </CardContent>
                </Card>
              )}
              {productWiseDistribution.length > 0 && (
                <Card className={classes2.cardRoot}>
                  <CardHeader
                    title="Product Wise Distribution"
                    action={
                      <IconButton
                        title="Full Screen"
                        style={{ padding: 0 }}
                        onClick={handleFullScreen4.enter}
                      >
                        <FullscreenIcon />
                      </IconButton>
                    }
                  />
                  <CardContent>
                    <Box
                      className={
                        productWiseDistribution.length > 5 ? classes2.barGraph : classes2.barGraph2
                      }
                    >
                      <FullScreen handle={handleFullScreen4}>
                        <BarChart
                          width={
                            productWiseDistribution.length < 7
                              ? 400
                              : productWiseDistribution.length * 15
                          }
                          height={350}
                          data={productWiseDistribution
                            .sort((a: any, b: any) => {
                              return a.value - b.value;
                            })
                            .reverse()}
                          layout="horizontal"
                          style={handleFullScreen4.active ? fullScreenStyle : {}}
                          margin={{
                            bottom: handleFullScreen4.active ? 150 : 75,
                            left: 40,
                          }}
                        >
                          <XAxis
                            dataKey="name"
                            textAnchor="end"
                            sclaeToFit="true"
                            verticalAnchor="start"
                            interval={0}
                            angle="-20"
                            stroke="#000"
                            fontSize={10}
                          />
                          <YAxis />
                          <CartesianGrid strokeDasharray="3 3" />
                          <Tooltip />
                          <Legend verticalAlign="top" height={36} align="right" />
                          <Bar
                            dataKey="value"
                            fill="#1e3246"
                            shape={<TriangleBar />}
                            barSize={50}
                          />
                        </BarChart>
                      </FullScreen>
                    </Box>
                  </CardContent>
                </Card>
              )}
            </Box>
            <Box className={classes2.ToggleResBox}>
              {projectWiseDistribution.length > 0 && (
                <Card Card className={classes2.cardRoot}>
                  <CardHeader
                    title="Project Wise Distribution"
                    action={
                      <IconButton
                        title="Full Screen"
                        style={{ padding: 0 }}
                        onClick={handleFullScreen5.enter}
                      >
                        <FullscreenIcon />
                      </IconButton>
                    }
                  />
                  <CardContent>
                    <Box
                      className={
                        projectWiseDistribution.length > 5 ? classes2.barGraph : classes2.barGraph2
                      }
                    >
                      <FullScreen handle={handleFullScreen5}>
                        <BarChart
                          width={
                            projectWiseDistribution.length < 10
                              ? 800
                              : projectWiseDistribution.length * 29
                          }
                          height={350}
                          data={projectWiseDistribution
                            .sort((a: any, b: any) => {
                              return a.value - b.value;
                            })
                            .reverse()}
                          layout="horizontal"
                          style={handleFullScreen5.active ? fullScreenStyle : {}}
                          margin={{
                            bottom: handleFullScreen5.active ? 150 : 75,
                            left: 40,
                          }}
                        >
                          <XAxis
                            dataKey="name"
                            textAnchor="end"
                            sclaeToFit="true"
                            verticalAnchor="start"
                            interval={0}
                            angle="-20"
                            stroke="#000"
                            fontSize={10}
                          />
                          <YAxis />
                          <CartesianGrid strokeDasharray="3 3" />
                          <Tooltip />
                          <Legend verticalAlign="top" height={36} align="right" />
                          <Bar
                            dataKey="value"
                            fill="#205723"
                            shape={<TriangleBar />}
                            barSize={50}
                          />
                        </BarChart>
                      </FullScreen>
                    </Box>
                  </CardContent>
                </Card>
              )}
              {documentProfileWiseDistribution.length > 0 && (
                <Card Card className={classes2.cardRoot}>
                  <CardHeader
                    title="Document Profile Wise Distribution"
                    action={
                      <IconButton
                        title="Full Screen"
                        style={{ padding: 0 }}
                        onClick={handleFullScreen6.enter}
                      >
                        <FullscreenIcon />
                      </IconButton>
                    }
                  />
                  <CardContent>
                    <Box
                      className={
                        documentProfileWiseDistribution.length > 5
                          ? classes2.barGraph
                          : classes2.barGraph2
                      }
                    >
                      <FullScreen handle={handleFullScreen6}>
                        <BarChart
                          width={
                            documentProfileWiseDistribution.length < 7
                              ? 400
                              : projectWiseDistribution.length * 29
                          }
                          height={350}
                          data={documentProfileWiseDistribution
                            .sort((a: any, b: any) => {
                              return a.value - b.value;
                            })
                            .reverse()}
                          layout="horizontal"
                          style={handleFullScreen6.active ? fullScreenStyle : {}}
                          margin={{
                            bottom: handleFullScreen6.active ? 150 : 75,
                            left: 40,
                          }}
                        >
                          <XAxis
                            dataKey="name"
                            textAnchor="end"
                            sclaeToFit="true"
                            verticalAnchor="start"
                            interval={0}
                            angle="-20"
                            stroke="#000"
                            fontSize={10}
                          />
                          <YAxis />
                          <CartesianGrid strokeDasharray="3 3" />
                          <Tooltip />
                          <Legend verticalAlign="top" height={36} align="right" />
                          <Bar
                            dataKey="value"
                            fill="#5e35b1"
                            shape={<TriangleBar />}
                            barSize={50}
                          />
                        </BarChart>
                      </FullScreen>
                    </Box>
                  </CardContent>
                </Card>
              )}
            </Box>
            {/* <Box>
              {countryWiseDistribution.length > 0 && (
                <Card Card className={classes2.cardRoot2}>
                  <CardHeader title="Country Level Distribution" />
                  <CardContent
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      className={
                        countryWiseDistribution.length > 5 ? classes2.barGraph : classes2.barGraph2
                      }
                    >
                      <BarChart
                        width={
                          countryWiseDistribution.length === 1
                            ? 900
                            : countryWiseDistribution.length * 120
                        }
                        height={350}
                        data={countryWiseDistribution
                          .sort((a: any, b: any) => {
                            return a.value - b.value;
                          })
                          .reverse()}
                        layout="horizontal"
                        style={{ marginTop: 20 }}
                        margin={{
                          bottom: 48,
                        }}
                      >
                        <XAxis
                          dataKey="name"
                          textAnchor="end"
                          sclaeToFit="true"
                          verticalAnchor="start"
                          interval={0}
                          angle="-45"
                          stroke="#000"
                          fontSize={10}
                        />
                        <YAxis />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip />
                        <Legend verticalAlign="top" height={36} align="right" />
                        <Bar dataKey="value" fill="#4fa7f9" shape={<TriangleBar />} barSize={50} />
                      </BarChart>
                    </Box>
                  </CardContent>
                </Card>
              )}
            </Box> */}

            {/* <Box>
              {artifactWiseDistribution.length > 0 && (
                <Card Card className={classes2.cardRoot2}>
                  <CardHeader title="Artifact Level Distribution" />
                  <CardContent
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      className={
                        artifactWiseDistribution.length > 5 ? classes2.barGraph : classes2.barGraph2
                      }
                    >
                      <BarChart
                        width={
                          artifactWiseDistribution.length === 1
                            ? 900
                            : artifactWiseDistribution.length * 120
                        }
                        height={350}
                        data={artifactWiseDistribution
                          .sort((a: any, b: any) => {
                            return a.value - b.value;
                          })
                          .reverse()}
                        layout="horizontal"
                        style={{ marginTop: 20 }}
                        margin={{
                          bottom: 48,
                        }}
                      >
                        <XAxis
                          dataKey="name"
                          textAnchor="end"
                          sclaeToFit="true"
                          verticalAnchor="start"
                          interval={0}
                          angle="-45"
                          stroke="#000"
                          fontSize={10}
                        />
                        <YAxis />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip />
                        <Legend verticalAlign="top" height={36} align="right" />
                        <Bar dataKey="value" fill="#4fa7f9" shape={<TriangleBar />} barSize={50} />
                      </BarChart>
                    </Box>
                  </CardContent>
                </Card>
              )}
            </Box> */}
          </Paper>
        )}
      </Box>
    </>
  );
}

export default PeopleSearch;
