import React, { useEffect, useState } from 'react';
import { Box, InputBase, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import { getAllProjects } from '../../../api/module1';
import ProjectCard from './ProjectCard';

const MuiContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: '10px',
  flexWrap: 'wrap',
  padding: '2rem',
  height: '100%',
  width: '100%',
  maxHeight: '100%',
  overflow: 'auto',
}));

const InputBox = styled((props) => <Box {...props} />)(({ theme }) => ({
  borderRadius: '25px',
  padding: '5px',
  display: 'flex',
  border: '1px solid #002A45',
  width: '40%',
}));

const ProjectContainer = () => {
  const [projects, setProjects] = useState([]);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    getAllProjects()
      .then((payload) => {
        setProjects(payload);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        gap: '10px',
        height: '100%',
      }}
    >
      <InputBox>
        <InputBase
          style={{ width: '100%', fontSize: '1.3rem', color: '#002A45' }}
          inputProps={{ maxLength: 2500 }}
          onChange={(event) => {
            setInputValue(event.target.value);
          }}
          placeholder="Search Projects"
          value={inputValue}
          // onKeyDown={(e) => {
          //   if (e.key === 'Enter') handleApiCall();
          // }}
        />
        <IconButton
          style={{ color: '#002A45' }}
          value={inputValue}
          type="button"
          aria-label="search"
          // onClick={handleApiCall}
        >
          <SearchIcon />
        </IconButton>
      </InputBox>
      <MuiContainer>
        {projects.map((project) => {
          return <ProjectCard key={`card-project-${project.id}`} project={project} />;
        })}
      </MuiContainer>
    </div>
  );
};

export default ProjectContainer;
