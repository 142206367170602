/**
 * @param urlHttp
 */

async function downloadFileFromUrl(urlHttp: string, filename: string, token: string): any {
  const URL = process.env.REACT_APP_BACKEND_URL;
  const fullUrl = `${URL}${urlHttp}?filename=${encodeURI(filename)}`;

  try {
    const response = await fetch(fullUrl, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error('Failed to download file');
    }
    // Extract the filename from Content-Disposition header

    // Create a blob from the response
    const blob = await response.blob();

    // Create a link element, set the download attribute, and trigger a click to download the file
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.error('Error downloading file:', error);
  }
}

export default downloadFileFromUrl;
