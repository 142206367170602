/* eslint-disable react/destructuring-assignment */
import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, IconButton, InputBase, Typography, Button } from '@mui/material';
import { styled } from '@mui/styles';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { FileDocIcon } from '../../assets/svg';
import Files from '../Project/ProjectContainer/components/ProjectFolder/Files';
import UserSpaceContext from '../UserWorkSpace/UserSpaceContext';

const Btn = styled((props) => <Button {...props} />)(({ theme, disabled }) => ({
  // margin: '0 auto !important',
  minHeight: '3rem !important',
  minWidth: '5rem !important',
  opacity: disabled ? '0.3' : '1',
  backgroundColor: 'rgb(23, 193, 232) !important',
  color: 'rgb(255, 255, 255) !important',
  display: 'flex !important',
  borderRadius: '0.5rem !important',
  alignItems: 'center !important',
  justifyContent: 'center !important',
  transition: 'all 0.3s ease !important',
  boxShadow:
    'rgba(20, 20, 20, 0.12) 0rem 0.25rem 0.375rem -0.0625rem, rgba(20, 20, 20, 0.07) 0rem 0.125rem 0.25rem -0.0625rem !important',
}));

const AppContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  width: '6rem',
  height: '6rem',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  padding: '1px',
  '&:hover': {
    backgroundColor: '#dde4ea',
    transition: 'all 0.4s ease-out',
  },
}));

const Label = styled((props) => <Typography {...props} />)(({ theme }) => ({
  color: '#002A45',
  letterSpacing: 'normal',
  fontSize: '0.89rem',
  // fontWeight: 'bold',
}));

const SpaceContent = styled((props) => <Box {...props} />)(({ theme }) => ({
  padding: '1rem 0.5rem',
  // border: '1px solid #ccd6f2',
  gap: '5px',
  width: '100%',
  height: 'calc(100% - 3.5rem)',
  maxHeight: '100%',
  overflow: 'auto',
}));

const Header = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'grid',
  height: '3.5rem',
  borderBottom: '1px solid #ccd6f2',
  backgroundColor: 'rgb(255, 255, 255)',
  padding: '0.5rem',
  gridTemplateColumns: '1fr 1fr',
}));

const Nav = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  gap: '10px',
  justifyContent: 'flex-start',
  height: '100%',
  alignItems: 'center',
}));

const IconBtn = styled((props) => <IconButton {...props} />)(({ theme }) => ({
  '&.MuiIconButton-root': {
    padding: '2px',
    margin: '2px',
  },
}));

const InputContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  margin: '2px',
  width: '100%',
  height: '100p%',
  maxHeight: '100%',
  borderRadius: '8px',
  border: '1px solid #998760CE',
}));

const InputFeildEnter = styled((props) => <InputBase {...props} />)(({ theme }) => ({
  fontSize: 'average',
  height: '100%',
  borderRadius: '8px 0 0 8px',
  [theme.breakpoints.up('lg')]: {
    fontSize: '0.89em',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: 'average',
  },
}));

const Folders = ({ folders, setFiles, setMoveTo }) => {
  return (
    <>
      {folders?.map((folder) => {
        return (
          <AppContainer
            onClick={() => {
              setFiles(folder?.resources ?? []);
              setMoveTo('files');
              // setProjectBread(name);
              // setFloderBread(folder.name);
              // history.push(`/project/${projectId}/folders/${folder.id}`);
            }}
          >
            <FileDocIcon style={{ width: '2rem', height: '2rem' }} />
            <Label>{folder.name}</Label>
          </AppContainer>
        );
      })}
    </>
  );
};

const UserFileManager = (props) => {
  const { isChild, numberOfFilesSupport, handleScopeSettings, select = 'single' } = props || false;
  const history = useHistory();
  // const [files, setFiles] = useState([]);
  const [moveTo, setMoveTo] = useState('files');
  const [searchfiles, setSearchFiles] = useState('');
  const { files } = useContext(UserSpaceContext);
  const [selectedFiles, setSelectedFiles] = useState([]);
  // console.log('files', files);

  const actualFiles = useMemo(() => {
    if (searchfiles) {
      return files.filter((file) =>
        file.filename.toLowerCase().includes(searchfiles.toLowerCase())
      );
    }
    return files;
  }, [files, searchfiles]);

  console.log('folders', props);

  const renderComponent = () => {
    switch (moveTo) {
      case 'files':
        return (
          <Files
            data={actualFiles}
            setSelectedFiles={setSelectedFiles}
            selectedFiles={selectedFiles}
            numberOfFilesSupport={numberOfFilesSupport}
          />
        );
      default:
        return (
          <Files
            data={actualFiles}
            setSelectedFiles={setSelectedFiles}
            selectedFiles={selectedFiles}
            numberOfFilesSupport={numberOfFilesSupport}
          />
        );
    }
  };

  const handleGlobalSearch = (e) => {};

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Header>
        <Nav>
          {/* <IconBtn
            onClick={() => {
              setMoveTo('folder');
            }}
          >
            <ArrowBackIcon fontSize="small" />
          </IconBtn>
          <IconBtn
            onClick={() => {
              setMoveTo('files');
            }}
          >
            <ArrowForwardIcon fontSize="small" />
          </IconBtn> */}
        </Nav>

        <Nav>
          <IconButton>
            <FileUploadIcon />
          </IconButton>
          <InputContainer>
            <InputFeildEnter
              placeholder="Search for file"
              sx={{ flex: 9, pl: 1, color: '#002A45' }}
              onChange={(e) => {
                setSearchFiles(e.target.value);
              }}
            />
          </InputContainer>
          {isChild && props.renderCross()}
        </Nav>
      </Header>
      <SpaceContent>{renderComponent()}</SpaceContent>
      <Box
        style={{
          // height: '3rem',
          padding: '0.3rem 1rem',
          width: '100%',
          display: 'flex',
          border: '1px solid #ccd6f2',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          backgroundColor: 'rgb(255, 255, 255)',
        }}
      >
        <Btn
          disabled={
            select === 'single'
              ? numberOfFilesSupport !== selectedFiles.length
              : numberOfFilesSupport < selectedFiles.length || selectedFiles.length === 0
          }
          onClick={() => {
            handleScopeSettings(selectedFiles);
          }}
        >
          Apply
        </Btn>
      </Box>
    </div>
  );
};

export default memo(UserFileManager);
