import * as React from 'react';
import Accordion, { AccordionSlots, accordionClasses } from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails, { accordionDetailsClasses } from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fade from '@mui/material/Fade';
import { Box, Button } from '@mui/material';
import { FileIcon } from '../../../containers/Project/ProjectContainer/components/ProjectFolder/Files';

export default function Item(props) {
  const [expanded, setExpanded] = React.useState(false);
  const { data } = props;

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <div>
      <Accordion
        expanded={expanded}
        onChange={handleExpansion}
        slots={{ transition: Fade as AccordionSlots['transition'] }}
        slotProps={{ transition: { timeout: 400 } }}
        sx={[
          expanded
            ? {
                [`& .${accordionClasses.region}`]: {
                  height: 'auto',
                },
                [`& .${accordionDetailsClasses.root}`]: {
                  display: 'block',
                },
              }
            : {
                [`& .${accordionClasses.region}`]: {
                  height: 0,
                },
                [`& .${accordionDetailsClasses.root}`]: {
                  display: 'none',
                },
              },
        ]}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Box
            style={{
              display: 'flex',
              width: '100%',
            }}
          >
            <Box
              style={{
                display: 'flex',
                flex: 3,
                alignItems: 'center',
                gap: '30px',
              }}
            >
              <Typography style={{ fontWeight: 'bold' }}>{data.id}</Typography>

              <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <FileIcon filename={data.resources[0].filename} />
                <Typography>{data.resources[0].filename}</Typography>
              </div>

              <Typography>{data.resources[0].settings.nameOfCapture}</Typography>
            </Box>
            <Box
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
              }}
            >
              <Button>View</Button>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>created at: {data.created_at}</Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
