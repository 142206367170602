const similaritySearchFilterReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'set_simi_default_fill': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'change_simi_ProductLine': {
      return {
        ...state,
        selectedSimiProductLine: action.payload,
      };
    }

    case 'change_simi_Platform': {
      return {
        ...state,
        selectedSimiPlatform: action.payload,
      };
    }
    case 'change_simi_Solution': {
      return {
        ...state,
        selectedSimiSolution: action.payload,
      };
    }
    case 'change_simi_Product': {
      return {
        ...state,
        selectedSimiProduct: action.payload,
      };
    }

    case 'change_simi_Project': {
      return {
        ...state,
        selectedSimiProject: action.payload,
      };
    }

    case 'change_simi_DocumentProfile': {
      return {
        ...state,
        selectedSimiDocumentProfile: action.payload,
      };
    }

    case 'simi_change_count': {
      return {
        ...state,
        countValue: action.payload,
      };
    }

    case 'simi_change_Threshold': {
      return {
        ...state,
        threshold: action.payload,
      };
    }
    default:
      return state;
  }
};

export default similaritySearchFilterReducer;
