import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { notifByInbox } from '../../../api/wsNotifs';
import Information from './components/ProjectInBox/Information';
import Notification from './components/ProjectInBox/Notification';
import Alert from './components/ProjectInBox/Alert';

function ProjectInbox(props) {
  const { selectedOption } = props;
  const { projectId } = useParams() as any;
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<any>([]);
  const [secondTablerows, setsecondTableRows] = useState<any>([]);
  const [thirdTablerows, setthirdTableRows] = useState<any>([]);

  useEffect(() => {
    notifByInbox().then((payload) => {
      // eslint-disable-next-line func-names
      const ownings = payload.ownings.filter((payload: { ownings: any[] }, index: any) => {
        // eslint-disable-next-line eqeqeq
        return payload.redirect_params.project_id == projectId;
      });
      // eslint-disable-next-line func-names
      const workloads = payload.workloads.filter((payload: { workloads: any[] }, index: any) => {
        // eslint-disable-next-line eqeqeq
        return payload.redirect_params.project_id == projectId;
      });
      // eslint-disable-next-line func-names
      const userManagements = payload.userManagements.filter(
        (payload: { userManagements: any[] }, index: any) => {
          // eslint-disable-next-line eqeqeq
          return payload.redirect_params.project_id == projectId;
        }
      );
      setTimeout(() => {
        setRows(ownings);

        setsecondTableRows(workloads);

        setthirdTableRows(userManagements);
        setLoading(false);
      }, 2000);
    });
  }, [loading, projectId]);

  const renderComponent = () => {
    switch (selectedOption) {
      case 'information':
        return (
          <>
            <Information thirdTablerows={thirdTablerows} loading={loading} />
          </>
        );
      case 'alert':
        return (
          <>
            <Alert rows={secondTablerows} loading={loading} />
          </>
        );
      case 'notification':
        return (
          <>
            <Notification rows={rows} loading={loading} />
          </>
        );
      default:
        return <></>;
    }
  };
  return <>{renderComponent()}</>;
}
export default ProjectInbox;
