import React, { useContext, useMemo } from 'react';
import M2GlobalContext from '../../ContextMager/M2GlobalContext';
import { iSearcheSixLayerNew } from '../../../api/module2';

const IsearcherFilters = ({ isearcherFilter, dispatch, callbackFilters }) => {
  if (!isearcherFilter) {
    return { options: [] };
  }
  const {
    isearcherProductLine,
    isearcherPlatforms,
    isearcherSolution,
    isearcherProduct,
    isearcherProjects,
    isearcherDocumentProfiles,
    isearcherCountry,
    selectedIsearcherProductLine,
    selectedIsearcherProjects,
    selectedIsearcherDocumentProfiles,
    selectedIsearcherPlatforms,
    selectedIsearcherProduct,
    selectedIsearcherSolution,
    selectedIsearcherCountry,
  } = isearcherFilter;

  const multifilterCallback = (payload, key) => {
    const {
      productLine,
      project,
      documentProfile,
      platforms,
      products,
      solution,
      country,
    } = payload;
    iSearcheSixLayerNew(
      productLine,
      platforms,
      solution,
      products,
      project,
      documentProfile,
      country
    ).then((res: any) => {
      if (res?.filters) {
        const tepm = {
          ...res.filters,
        };

        delete tepm[key];
        callbackFilters(tepm);
      }
    });
  };

  const temp = [
    {
      name: 'Product Line',
      key: 'productLines',
      check: 'false',
      collapsed: false,
      checkAllOption: false,
      options: isearcherProductLine,
      _cache: {},
      onChangeOption: (option) => {
        dispatch({ type: 'change_IsercherProductLine', payload: option });
        const payload = {
          productLine: option,
          project: selectedIsearcherProjects,
          documentProfile: selectedIsearcherDocumentProfiles,
          platforms: selectedIsearcherPlatforms,
          products: selectedIsearcherProduct,
          solution: selectedIsearcherSolution,
          country: selectedIsearcherCountry,
        };
        multifilterCallback(payload, 'productLines');
      },
    },
    {
      name: 'Platforms',
      key: 'platforms',
      check: 'false',
      collapsed: false,
      checkAllOption: false,
      options: isearcherPlatforms,
      _cache: {},
      onChangeOption: (option) => {
        dispatch({ type: 'change_IsercherPlatforms', payload: option });
        const payload = {
          productLine: selectedIsearcherProductLine,
          project: selectedIsearcherProjects,
          documentProfile: selectedIsearcherDocumentProfiles,
          platforms: option,
          products: selectedIsearcherProduct,
          solution: selectedIsearcherSolution,
          country: selectedIsearcherCountry,
        };
        multifilterCallback(payload, 'platforms');
      },
    },
    {
      name: 'Solution',
      key: 'solutions',
      check: 'false',
      collapsed: false,
      checkAllOption: false,
      options: isearcherSolution,
      _cache: {},
      onChangeOption: (option) => {
        dispatch({ type: 'change_IsercherSolution', payload: option });
        const payload = {
          productLine: selectedIsearcherProductLine,
          project: selectedIsearcherProjects,
          documentProfile: selectedIsearcherDocumentProfiles,
          platforms: selectedIsearcherPlatforms,
          products: selectedIsearcherProduct,
          solution: option,
          country: selectedIsearcherCountry,
        };
        multifilterCallback(payload, 'solutions');
      },
    },
    {
      name: 'Product',
      key: 'products',
      check: 'false',
      collapsed: false,
      checkAllOption: false,
      options: isearcherProduct,
      _cache: {},
      onChangeOption: (option) => {
        dispatch({ type: 'change_IsercherProduct', payload: option });
        const payload = {
          productLine: selectedIsearcherProductLine,
          project: selectedIsearcherProjects,
          documentProfile: selectedIsearcherDocumentProfiles,
          platforms: selectedIsearcherPlatforms,
          products: option,
          solution: selectedIsearcherSolution,
          country: selectedIsearcherCountry,
        };
        multifilterCallback(payload, 'products');
      },
    },
    {
      name: 'Projects',
      key: 'projects',
      check: 'false',
      collapsed: false,
      checkAllOption: false,
      options: isearcherProjects,
      _cache: {},
      onChangeOption: (option) => {
        dispatch({ type: 'change_IsercherProjects', payload: option });
        const payload = {
          productLine: selectedIsearcherProductLine,
          project: option,
          documentProfile: selectedIsearcherDocumentProfiles,
          platforms: selectedIsearcherPlatforms,
          products: selectedIsearcherProduct,
          solution: selectedIsearcherSolution,
          country: selectedIsearcherCountry,
        };
        multifilterCallback(payload, 'projects');
      },
    },
    {
      name: 'Document Profiles',
      key: 'documentProfiles',
      check: 'false',
      collapsed: false,
      checkAllOption: false,
      options: isearcherDocumentProfiles,
      _cache: {},
      onChangeOption: (option) => {
        dispatch({ type: 'change_IsercherDocumentProfiles', payload: option });
        const payload = {
          productLine: selectedIsearcherProductLine,
          project: selectedIsearcherProjects,
          documentProfile: option,
          platforms: selectedIsearcherPlatforms,
          products: selectedIsearcherProduct,
          solution: selectedIsearcherSolution,
          country: selectedIsearcherCountry,
        };
        multifilterCallback(payload, 'documentProfiles');
      },
    },
    // {
    //   name: 'Country',
    //   key: 'countries',
    //   check: 'false',
    //   collapsed: false,
    //   checkAllOption: false,
    //   options: isearcherCountry,
    //   _cache: {},
    //   onChangeOption: (option) => {
    //     dispatch({ type: 'change_IsercherCountry', payload: option });
    //     const payload = {
    //       productLine: selectedIsearcherProductLine,
    //       project: selectedIsearcherProjects,
    //       documentProfile: selectedIsearcherDocumentProfiles,
    //       platforms: selectedIsearcherPlatforms,
    //       products: selectedIsearcherProduct,
    //       solution: selectedIsearcherSolution,
    //       country: option,
    //     };
    //     multifilterCallback(payload);
    //   },
    // },
  ];
  return { options: temp };
};

export default IsearcherFilters;
