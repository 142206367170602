import React, { useContext, useMemo } from 'react';
import M2GlobalContext from '../../ContextMager/M2GlobalContext';
import Rex360Filters from './Rex360Filters';
import IsearcherFilters from './IsearcherFilters';
import SimilarityFilters from './SimilarityFilters';

const useFilterOptions = ({ service, state, dispatch, callbackFilters }) => {
  const { rexFilter, isearcherFilter, similarityFilter } = state;

  const options = useMemo(() => {
    if (service === 'REX Cards') {
      return Rex360Filters({ rexFilter, dispatch, callbackFilters });
    }

    if (
      [
        'Referents Search',
        'Content Search',
        'People Search',
        'Requirements',
        'Referents',
        'Tests',
      ].includes(service)
    ) {
      return IsearcherFilters({ isearcherFilter, dispatch, callbackFilters });
    }

    if (service === 'Similarity Search' || service === 'GenA') {
      return SimilarityFilters({ similarityFilter, dispatch, callbackFilters });
    }

    return { options: [] };
  }, [service]);

  return options;
};

export default useFilterOptions;
