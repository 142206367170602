import React from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { styled, ThemeProvider } from '@mui/material/styles';
// import Homepage from './components/Homepage';
import ProjectContainer from '../Project/components/ProjectContainer';
import ModuleContainer from './components/ModuleContainer';
import HomeContainer from './components/HomeContainer';
import Module2Home from '../Module2/NewModule/Module2Home';
import Module2Container from './components/Module2Container';
import IconBreadcrumbs from './BreadCrumbs';
import WorkspaceContainer from './components/WorkspaceContainer';
import REXAnalysis from '../HOC/REXAnalysis';
import SearchContainer from '../HOC/SearchContainer';
import theme from './homeTheme';
import RexResultContainer from '../ResultContainer/RexResultContainer';
import Homepage from './components/Homepage';
import Module2Router from '../Module2/Module2Router';
import UserWorkSpaceContainer from './components/UserWorkSpaceContainer';
import HomePageCheck from './components/HomePageCheck';
import GenAHomeSpace from '../UserWorkSpace/Containers/GenALayout';
import AppTopMenu from '../../layouts/AppTopMenu';
import FilterContainer from '../HOC/FilterContainer';
import Knowledge360Result from '../ResultContainer/Knowledge360Result';

const BreadcrumbsContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  backgroundColor: '#eef1f9',
  padding: ' 0.8rem',
}));

const HomeRouter = () => {
  // const { projectId } = useParams();

  return (
    <Switch>
      {/* <Route
        exact
        path="/home"
        render={() => {
          return <Homepage />;
        }}
      /> */}
      <Route
        // exact
        path="/home/projectss"
        render={() => {
          return (
            <>
              <IconBreadcrumbs nodes={[{ name: 'home', route: '/home' }]} service="pojects" />
              <ProjectContainer />
            </>
          );
        }}
      />

      <Route
        // exact
        path="/project/:projectId/modules"
        render={() => {
          return (
            <>
              <IconBreadcrumbs
                nodes={[
                  { name: 'home', route: '/home' },
                  { name: 'projects', route: '/home/projectss' },
                ]}
                service="modules"
              />
              <ModuleContainer />
            </>
          );
        }}
      />

      <Route
        // exact
        path="/project/:projectId/module2"
        render={() => {
          return (
            <>
              {/* <IconBreadcrumbs
                nodes={[
                  { name: 'home', route: '/home' },
                  { name: 'projects', route: '/home/projectss' },
                  { name: 'modules', route: `/home/${projectId}/modules` },
                ]}
                service="Knowledge & Efficiency"
              /> */}
              <ThemeProvider theme={theme}>
                <Module2Router />
              </ThemeProvider>
            </>
          );
        }}
      />

      <Route
        // exact
        path="/home/workspace/RexAnalysis"
        render={() => {
          return (
            <>
              <IconBreadcrumbs
                nodes={[
                  { name: 'home', route: '/home' },
                  { name: 'workspace', route: '/home/workspace' },
                ]}
                service="Rex 360"
              />
            </>
          );
        }}
      />

      <Route
        // exact
        path="/home/workspace"
        render={() => {
          return (
            <>
              <IconBreadcrumbs nodes={[{ name: 'home', route: '/home' }]} service="workspace" />
              <WorkspaceContainer />
            </>
          );
        }}
      />
      <Route
        exact
        path="/home/Rex360"
        render={() => {
          return (
            <>
              <ThemeProvider theme={theme}>
                <FilterContainer>
                  <RexResultContainer />
                </FilterContainer>
              </ThemeProvider>
            </>
          );
        }}
      />

      <Route
        exact
        path="/home/Knowledge360"
        render={() => {
          return (
            <>
              <ThemeProvider theme={theme}>
                <FilterContainer>
                  <Knowledge360Result />
                </FilterContainer>
              </ThemeProvider>
            </>
          );
        }}
      />

      {/* <Route
        exact
        path="/home/user"
        render={() => {
          return (
            <>
              <ThemeProvider theme={theme}>
                <UserWorkSpaceContainer />
              </ThemeProvider>
            </>
          );
        }}
      /> */}

      <Route
        exact
        path="/home"
        render={() => {
          return (
            <>
              <ThemeProvider theme={theme}>
                <FilterContainer>
                  <Homepage />
                </FilterContainer>
              </ThemeProvider>
            </>
          );
        }}
      />

      <Route
        exact
        path="/homecheck"
        render={() => {
          return (
            <>
              <ThemeProvider theme={theme}>
                <GenAHomeSpace />
              </ThemeProvider>
            </>
          );
        }}
      />

      <Route
        exact
        path="/"
        render={() => {
          return (
            <>
              <ThemeProvider theme={theme}>
                <FilterContainer>
                  <Homepage />
                </FilterContainer>
              </ThemeProvider>
            </>
          );
        }}
      />
    </Switch>
  );
};

export default HomeRouter;
