import React from 'react';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';

const Container = styled((props) => <Box {...props} />)(({ theme, panelName }) => ({
  // backgroundColor: 'rgba(0, 0, 0, 0.5)',
  // transition: 'all 0.3s ease-out',
  [theme.breakpoints.up('lg')]: {
    fontSize: 'large',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.7em',
  },
  // '&.inVisible': {
  //   display: 'none',
  //   zIndex: -1,
  //   maxHeight: '0',
  //   overflow: 'hidden',
  // },
  display: 'block',
  position: 'fixed',
  top: 0,
  maxHeight: '100vh',
  maxWidth: '100vw',
  zIndex: 1299,
  width: '100vw',
  height: '100vh',
  overflow: 'hidden',
  [panelName]: '-100vw',
  '&.visible': {
    [panelName]: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },

  '&.inVisible': {
    [panelName]: '-100vw',
  },
}));

const SideBar = ({ sideBar, setSideBar }) => {
  const token = JSON.parse(localStorage.getItem('azJWT') || '{}');
  const isPanelLeft = sideBar.isOpen && sideBar.isPanel === 'left';
  const isPanelRight = sideBar.isOpen && sideBar.isPanel === 'right' && !isPanelLeft;
  // console.log('projects_lite', projects_lite);

  return (
    <Container
      panelName={sideBar.isOpen ? sideBar.isPanel : 'right'}
      className={sideBar.isOpen ? 'visible' : 'inVisible'}
      onClick={(e) => {
        setSideBar({ isPanel: '', isOpen: false });
      }}
    >
      <RightPanel
        isOpen={isPanelRight}
        setSideBar={setSideBar}
        sideBar={sideBar}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
      <LeftPanel
        isOpen={isPanelLeft}
        setSideBar={setSideBar}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
    </Container>
  );
};

export default SideBar;
