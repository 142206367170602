import React from 'react';
import {
  AppBar,
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import AnalysisKpiContainer from '../../../../KPI/AnalysisKpiContainer';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  content: {
    width: '100%',
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    boxShadow: '0px 16px 32px #00000029',
    '&[aria-selected="true"][aria-expanded="true"]>div:nth-of-type(1)': {
      backgroundColor: 'red',
    },
  },
  select: {
    '& > *': { fontWeight: '600 !important', fontSize: theme.typography.body1 },
  },
  formControl: {
    margin: theme.spacing(2),
    backgroundColor: '#FFFFFF',
    width: '95%',
  },
}));

const ProjectAnalysis = ({
  kpiIds,
  kpiSelection,
  kpiUser,
  projectUsers,
  setKpiIds,
  setKpiSelection,
  setKpiUser,
}) => {
  const classes = useStyles();
  const { projectId } = useParams() as any;

  const handleKpiDocument = (e) => {
    if (e.target.value === 'all') {
      setKpiSelection(undefined);
      return;
    }
    setKpiSelection(JSON.parse(e.target.value));
  };

  const handleKpiUsers = (e) => {
    if (e.target.value === 'all') {
      setKpiUser(undefined);
      return;
    }
    setKpiUser(e.target.value);
  };

  return (
    <>
      {' '}
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="stretch"
        spacing={0}
        // className={clsx(classes.bloc)}
      >
        <Grid item xs={6}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="label-select-document">Project Documents</InputLabel>
            <Select
              fullWidth
              label="Documents"
              name="document"
              onChange={handleKpiDocument}
              defaultValue="all"
              className={classes.select}
            >
              <MenuItem key="all-documents" value="all" className={classes.select}>
                <Typography>all documents</Typography>
              </MenuItem>
              {kpiIds &&
                kpiIds.map((cap: KpiId, index: number) => {
                  return (
                    <MenuItem
                      key={`document-${index}`}
                      value={JSON.stringify(cap)}
                      className={classes.select}
                    >
                      <Typography>
                        {cap.settings?.nameOfCapture || cap.workloadId} / {cap.filename} (
                        {cap.count})
                      </Typography>
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="label-select-document">Project Users</InputLabel>
            <Select
              label="Users"
              name="users"
              onChange={handleKpiUsers}
              defaultValue="all"
              className={classes.select}
            >
              <MenuItem key="all-documents" value="all" className={classes.select}>
                <Typography>all Users</Typography>
              </MenuItem>
              {projectUsers &&
                projectUsers.map((cap: KpiId, index: number) => {
                  return (
                    <MenuItem key={`user-${index}`} value={cap?.id} className={classes.select}>
                      <Typography>{cap?.name}</Typography>
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {projectId && (
        <Grid style={{ margin: '20px' }}>
          {kpiSelection && (
            <>
              <Grid container direction="row" alignItems="center">
                <AssignmentIcon />
                <Typography variant="subtitle1">{kpiSelection.filename}</Typography>
              </Grid>
              <Typography variant="subtitle1">
                Number of requirements : {kpiSelection.count}
              </Typography>
            </>
          )}
          <AnalysisKpiContainer
            project_id={projectId}
            workload_id={kpiSelection?.workloadId}
            file_name={kpiSelection?.filename}
            user_id={kpiUser}
            title={kpiSelection?.filename || 'all documents'}
          />
        </Grid>
      )}
    </>
  );
};

export default ProjectAnalysis;
