import React from 'react';
import { useParams } from 'react-router-dom';

const UserWorkSpaceContainer = () => {
  const { service } = useParams();

  return (
    <div>
      <h1>UserWorkSpaceContainer {service}</h1>
    </div>
  );
};

export default UserWorkSpaceContainer;
