/* eslint-disable consistent-return */
import { Avatar, Box, Card, CardContent, Grid, Typography } from '@mui/material';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import BugReportIcon from '@mui/icons-material/BugReport';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { display } from '@mui/system';
import AboutIsens from '../../src/components/Layout/AboutIsens';
import ProfilePanel from './ProfilePanel';
import AlertPanel from './AlertPannel';
import NotificationPanel from './NotificationPanel';
import InformationPanel from './InformationPanel';
import { notifGetCompact } from '../api/wsNotifs';

const Container = styled((props) => <Box {...props} />)(({ theme }) => ({
  position: 'absolute',
  margin: 0,
  padding: 0,
  opacity: 1,
  height: '100vh',
  width: '25vw',
  right: '-25vw',
  boxShadow: '-8px 0px 14px -4px rgba(0,0,0,0.75)',
  borderRadius: '5px',
  backgroundColor: '#fafafa',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  transition: 'right 0.3s ease-in',
  [theme.breakpoints.up('lg')]: {
    width: '20vw',
    right: '-20vw',
  },
  [theme.breakpoints.up('xl')]: {
    width: '15vw',
    right: '-15vw',
  },
  '&.visible': {
    right: 0,
  },
  '&.invisible': {
    right: '-25vw',
    [theme.breakpoints.up('lg')]: {
      right: '-20vw',
    },
    [theme.breakpoints.up('xl')]: {
      right: '-15vw',
    },
  },
  //   alignItems: 'center',
}));

type PayloadNotif = {
  ownings: any[];
  workloads: any[];
  userManagements: any[];
};

const payloadNotifInit = {
  ownings: [],
  workloads: [],
  userManagements: [],
} as PayloadNotif;

function RightPanel({ isOpen, setSideBar, sideBar }) {
  const { selectedRightPannel } = sideBar;

  const [notifs, setNotifs] = useState<PayloadNotif>(payloadNotifInit);

  useEffect(() => {
    try {
      // if (messageChanged || isEqual(wsMessageJSON, payloadNotifInit)) {
      notifGetCompact().then((payload) => {
        setNotifs(payload);

        // setMessageChanged(false);
      });

      // }
    } catch (error) {
      console.error(error);
    }

    // const connectWebSocket = async () => {
    //   try {
    //     // Avoid multiple connections
    //     if (wsClient) {
    //       wsClient.close();
    //     }

    //     await sleep(10000);

    //     if (isNotifOrAlert) {
    //       const wsClientNew = getW3client();
    //       console.log('client', wsClientNew);
    //       setWsClient(wsClientNew);

    //       wsClientNew.onopen = () => {};
    //       wsClientNew.onerror = (error) => {
    //         console.error(error);
    //       };
    //       wsClientNew.onmessage = handleMessage;
    //     }
    //   } catch (error) {
    //     console.error(error);
    //   }
    // };

    // connectWebSocket();

    // return () => {
    //   // don't forget to close
    //   console.log('websocket close');
    //   wsClient?.close();
    // };

    // document.addEventListener('mousedown', handleOutsideClick);
    // return () => {
    //   document.removeEventListener('mousedown', handleOutsideClick);
    // };
  }, []);

  const renderRightPanel = () => {
    switch (selectedRightPannel) {
      case 0:
        return (
          <InformationPanel data={notifs.userManagements} setSideBar={setSideBar} isOpen={isOpen} />
        );
      case 1:
        return <AlertPanel data={notifs.workloads} isOpen={isOpen} setSideBar={setSideBar} />;
      case 2:
        return <NotificationPanel data={notifs.ownings} isOpen={isOpen} setSideBar={setSideBar} />;
      case 3:
        return <ProfilePanel setSideBar={setSideBar} isOpen={isOpen} />;
      default:
        return null;
    }
  };

  return (
    <Container
      className={isOpen ? 'visible' : 'invisible'}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {renderRightPanel()}
    </Container>
  );
}

export default RightPanel;
