import useAppToken from '../app/useAppToken';
import { BACKEND_URL } from '../const';
import axios from './axios';

const getShortcuts = async (user_id: any) => {
  try {
    const response = await axios.get(`${BACKEND_URL.root}/shortcuts/get/${user_id}`);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const updateShortcuts = async (shorts: any[], projectId: any, createdAt: any, userId: any) => {
  try {
    const body = {
      shortcuts: shorts,
      user_id: userId,
      project_id: projectId,
      created_at: createdAt,
    };

    const response = await axios.post(`${BACKEND_URL.root}/shortcuts/update/${userId}`, body);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};

const createShortcuts = async (body: any, user_id: any) => {
  try {
    const response = await axios.post(`${BACKEND_URL.root}/shortcuts/create/${user_id}`, body);
    return response.data as any;
  } catch (error) {
    if (error.response) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  }
};
export { getShortcuts, updateShortcuts, createShortcuts };
