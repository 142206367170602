import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Slide from '@mui/material/Slide';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import AlarmIcon from '@mui/icons-material/Alarm';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import InfoIcon from '@mui/icons-material/Info';
import BugReportIcon from '@mui/icons-material/BugReport';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { display } from '@mui/system';
import { w3cwebsocket } from 'websocket';
import AboutIsens from '../../src/components/Layout/AboutIsens';
import getW3client, { notifGetCompact, notifSetReadByCat } from '../api/wsNotifs';
import sleep from '../../src/tools/sleep';

export const useStyles = makeStyles((theme) => ({
  card: {
    // position: 'relative',
    // width: '100%',
    // marginBottom: '10px',
    // opacity: 1,
    // right: '-1vw',
    // transition: 'right 0.3s ease-in 0.3s',
    // margin: '10px',
    boxShadow: '0 12px 30px 0 rgba(0, 0, 0, 0.09), 0 0 18px 0 rgba(0, 0, 0, 0.08) !important',
  },
  cardContent: {
    // background:
    //   'transparent linear-gradient(128deg, #e8ebf2 0%, #f5f7f9 100%) 0% 0% no-repeat padding-box',
    backgroundColor: 'white',
    // minHeight: '6rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconButtonInner: {
    transform: 'scale(1.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  typo1: {
    color: 'white !important',
    opacity: 1,
    fontWeight: 'bold',
    fontSize: 18,
  },
  typo2: {
    fontWeight: 'bold',
    color: 'yellow !important',
    fontSize: 15,
  },
  typo3: {
    fontWeight: 'bold',
    color: 'white !important',
    fontSize: 15,
  },
  resultIcon: {
    color: 'white',
    transform: 'scale(1)',
    opacity: 1,
    fontWeight: 'bold',
    marginRight: 4,
    marginLeft: 4,
  },
}));

interface layoutType {
  pannelLayout: any;
}

function RightPanelLayout({ pannelLayout }: layoutType) {
  const classes = useStyles();

  const { setSideBar, isOpen } = pannelLayout;
  const containerRef = React.useRef<HTMLElement>(null);

  return (
    <>
      <div>
        <CloseIcon
          onClick={() => {
            setSideBar({ isPanel: '', isOpen: false });
          }}
          style={{
            color: 'black',
            position: 'absolute',
            right: '3px',
            top: '3px',
            cursor: 'pointer',
          }}
        />
        <div
          style={{
            display: 'flex',
            backgroundColor: 'white',
            flexDirection: 'column',
            height: '2rem',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {/* {isPanel === 'ownings' && <NotificationsNoneIcon style={{ color: 'white' }} />}
              {isPanel === 'workloads' && <AlarmIcon style={{ color: 'white' }} />}
              {isPanel === 'userManagements' && <InfoIcon style={{ color: 'white' }} />} */}
          <Typography variant="h6" style={{ color: '#002a45', font: 'bold' }}>
            {pannelLayout.name}
          </Typography>
        </div>
        <hr style={{ width: '100%', color: 'grey' }} />
        <div
          style={{
            display: 'block',
            position: 'absolute',
            width: '100%',
            height: 'calc(100% - 2rem)',
            overflowX: 'hidden',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: '10px !important',
              height: '10px !important',
            },
            '&::-webkit-scrollbar-thumb': {
              border: '5px solid rgba(0, 0, 0, 0)',
              backgroundClip: 'padding-box',
              borderRadius: '10px !important',
              backgroundColor: 'rgba(0, 0, 0, .42)',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'red !important',
            },
            // marginBottom: '5px',
            padding: '5px',
          }}
        >
          {pannelLayout.cardData.map((eachData, i) => (
            <Slide
              direction="right"
              in={isOpen}
              // container={containerRef.current}
              timeout={800 + i * 100}
            >
              <Card
                index={i}
                className={classes.card}
                style={{
                  marginBottom: '10px',
                  marginTop: '10px',
                  marginLeft: '10px',
                  marginRight: '10px',
                }}
              >
                <>
                  <CardContent className={classes.cardContent} style={{ paddingBottom: 0 }}>
                    <Grid container>
                      <Grid item xs={1.5}>
                        {pannelLayout.renderCardIcon()}
                        {/* <NotificationsNoneIcon /> */}
                      </Grid>
                      <Grid item xs={10.5}>
                        {eachData.content.map((eachContent) => {
                          return (
                            <>
                              <Typography variant="h1" fontSize="12px">
                                {eachContent.label}
                              </Typography>
                              <Typography variant="h6" fontSize="12px">
                                {eachContent.value}
                              </Typography>
                            </>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </CardContent>
                  {pannelLayout.isActionRequired && (
                    <>
                      {' '}
                      <CardActions
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          paddingBottom: 0,
                          marginBottom: 0,
                          // height: '1.5rem',
                        }}
                      >
                        <Link to={eachData.url} title="analyze">
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: 0,
                              paddingRight: '16px',
                              paddingBottom: '16px',
                              border: '2px',
                            }}
                          >
                            <VisibilityIcon
                              fontSize="small"
                              style={{ marginBottom: 0, color: '#2878ec' }}
                            />

                            <Typography variant="subtitle2" style={{ fontSize: '10px' }}>
                              View
                            </Typography>
                          </div>
                        </Link>
                      </CardActions>
                    </>
                  )}
                </>
              </Card>
            </Slide>
          ))}
        </div>
      </div>
    </>
  );
}

export default RightPanelLayout;
