/* eslint-disable no-param-reassign */
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  ColumnChooser,
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableColumnVisibility,
  TableFilterRow,
  TableHeaderRow,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { Dialog, DialogTitle, Grid, IconButton, makeStyles, Paper } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useCallback, useRef, useState } from 'react';
import '../../../../../assets/treeview.css';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { Box, List, ListItem } from '@mui/material';
import Loading from '../../../../../components/Core/Loading';
import TableComponent from '../../../../../components/Datagrid/TableComponent';
import {
  tableContainerComponent,
  tableHeaderComponent,
  toolbarComponent,
} from '../../../../../components/Datagrid/TStyles';
import ToolBarPlugin from '../../../../../components/Datagrid/ToolBarPlugin';
import CustomerDocumentTab from './CustomerDocumentTab';
// import Loading from '../../components/Core/Loading';
// import TableComponent from '../../components/Datagrid/TableComponent';
// import ToolBarPlugin from '../../components/Datagrid/ToolBarPlugin';
// import {
//   tableContainerComponent,
//   tableHeaderComponent,
//   toolbarComponent,
// } from '../../components/Datagrid/TStyles';
// import { handleColumns } from '../../tools/datagrid';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    backgroundColor: theme.palette.action.hover,
    fontSize: '1rem',
  },
  heading: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: 10,
  },
  textField: {
    margin: '15px 5px',
    paddingRight: 10,
  },
  radio: {
    textAlign: 'center',
  },
  label: {
    marginRight: '30px',
  },
  tableCell: {
    '&.MuiTableCell-root': {
      padding: '0px 30px !important',
      borderBottom: 'none !important',
    },
    // '&.TableStubCell-cell': {
    //   padding: '0px 30px !important',
    //   borderBottom: 'none !important',
    // },
  },
}));

type Props = {
  rows: any[];
  //   template?: {
  //     name: string;
  //     version: string;
  //   };
  onClose?: any;
};
const columns = [
  { name: 'name', title: 'Phases / Geographical Areas / Trains' },
  //   { name: 'unit', title: 'unit' },
  //   { name: 'date', title: 'Dates/Duration' },
];
const columnsExp = [
  { name: 'name', title: 'Phases / Geographical Areas / Trains' },
  { name: 'value', title: 'stations' },
  //   { name: 'date', title: 'Dates/Duration' },
];

export default function CdaPhaseTabTableResult({ streamDates }) {
  // console.log('streamDates', streamDates);
  const isObjectArray = (arr) =>
    Array.isArray(arr) && arr.every((item) => typeof item === 'object' && item !== null);
  // console.log('isObjectArray', isObjectArray(streamDates));
  const formattedArray = streamDates.map((location) => {
    if (isObjectArray(streamDates) === false) {
      return {
        name: location,
        value: [],
      };
    }
    return {
      name: location?.phase,
      value: location?.stations,
    };
  });
  // console.log('formattedArray', formattedArray);
  const classes = useStyles();

  const [loading, setLoading] = useState<any>(false);
  const [toggleHeight, setToggleHeight] = useState(false);
  const exporterRef = useRef(null);
  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);
  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'data.xlsx');
    });
  };

  const renderFunction = ({ value }: DataTypeProvider.ValueFormatterProps) => {
    return (
      <>
        <b>{value}</b>
      </>
    );
  };
  const customTableCell = ({ className, ...restProps }) => (
    <Table.Cell {...restProps} className={`${className} ${classes.tableCell}`} />
  );
  const showPhaseDetails = (phase: string) => {
    const phaseData = formattedArray.find((item) => item.name === phase);
    if (phaseData) {
      return (
        <Box
          component="dl"
          sx={{
            listStyleType: 'disc',
            '& dt': {
              display: 'list-item',
              fontWeight: 'bold',
            },
            '& dd': {
              marginLeft: '1rem',
            },
            '& dt::marker': {
              color: '#002A45',
              fontSize: '1.5rem',
            },
          }}
        >
          <Box component="dt">{phaseData.name}</Box>
          {phaseData.value.length > 0 ? (
            <>
              {phaseData.value.map((station, index) => (
                <Box component="dd" key={index}>
                  <span style={{ fontWeight: 'bold', marginRight: 5 }}>-</span>
                  {station}
                </Box>
              ))}
            </>
          ) : (
            <Box component="dd">
              <span style={{ fontWeight: 'bold', marginRight: 5 }}>-</span>stations not found.
            </Box>
          )}
        </Box>
      );
      // eslint-disable-next-line no-else-return
    } else {
      return <Box component="dd">-stations not found.</Box>;
    }
  };

  return (
    <Paper>
      <>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="stretch"
          spacing={0}
          style={{ margin: 0 }}
        >
          <Grid item lg={12} sm={12} md={12}>
            <ExpressGrid rows={streamDates === undefined ? [] : formattedArray} columns={columns}>
              {/* <DataTypeProvider for={['function']} formatterComponent={renderFunction} /> */}
              <DataTypeProvider
                for={['name']}
                formatterComponent={({ value }) => showPhaseDetails(value)}
              />

              {/* filtering */}
              <FilteringState />
              <SortingState />
              <IntegratedSorting />
              <IntegratedFiltering />
              <PagingState defaultCurrentPage={0} defaultPageSize={10} />
              <IntegratedPaging />
              <Table
                columnExtensions={columns.map((c) => ({
                  columnName: c.name,
                  wordWrapEnabled: toggleHeight,
                }))}
                tableComponent={TableComponent}
                containerComponent={tableContainerComponent}
                headComponent={tableHeaderComponent}
                cellComponent={customTableCell}
              />
              <TableHeaderRow showSortingControls />
              <TableFilterRow />
              <TableColumnVisibility defaultHiddenColumnNames={[]} />
              <Toolbar rootComponent={toolbarComponent} />
              {/* <ColumnChooser /> */}
              <ToolBarPlugin name="Download" onClick={startExport} />
              <ToolBarPlugin
                name="Height"
                title="Wrap Text"
                onClick={() => {
                  setToggleHeight(!toggleHeight);
                }}
              />
              <PagingPanel pageSizes={[10, 20, 50]} />
            </ExpressGrid>
            <GridExporter
              ref={exporterRef}
              rows={streamDates === undefined ? [] : formattedArray}
              columns={columnsExp}
              onSave={onSave}
            />
          </Grid>
        </Grid>
      </>
    </Paper>
  );
}
