import React, { useEffect, useMemo, useState } from 'react';
import { useMaterialReactTable } from 'material-react-table';
import useAllData from './useAllData';
import useMetaDataOperation from './useMetaDataOperation';
import useTranslationCodeChange from './useTranslationCodeChange';
import useDownloadFile from './useDownloadFile';
import useRefreshLoading from './useRefreshLoading';

const useAllDataTable = (props) => {
  const {
    rows,
    onChangeToggle,
    refreshLoading,
    isFullScreen,
    onChangeFullScreen,
    toggleTable,
    targetColumn,
    displayColumns,
    projectGroups,
    groups,
    users,
    langCheck,
    languagesCode,
    languages,
    projectId,
    workloadId,
    setMetaDAta,
    transCode,
    translationCode,
    onChangeModalData,
    setTranslationCode,
    languageOriginCode,
    i18n,
    setNotify,
    levelsHierarchy,
    filename,
    refreshDATA,
    service,
    user_id,
    urlFile,
  } = props;

  const [rowSelection, setRowSelection] = useState({});

  useEffect(() => {
    setRowSelection({});
  }, [rows]);

  const data = useMemo(() => {
    if (rows) return Object.values(rows);

    return [];
  }, [rows]);

  const {
    loading,
    handleConcat,
    handleMerge,
    handleRepeatHeader,
    handleSaveAlldata,
  } = useMetaDataOperation({
    targetColumn,
    groups,
    users,
    langCheck,
    languagesCode,
    projectId,
    workloadId,
    onChangeTableData: setMetaDAta,
    transCode,
    i18n,
    languages,
    setNotify,
    filename,
    rows,
    refreshDATA,
  });

  const { updateRowLang } = useTranslationCodeChange({
    rowObj: rows,
    onChangeTableData: setMetaDAta,
    langCheck,
    languagesCode,
    targetColumn,
  });

  const { loadTest } = useRefreshLoading({ refreshLoading, loading });

  // console.log('data', data);
  const allDataObject = useAllData({
    data,
    loading: loadTest,
    onChangeToggle,
    isFullScreen,
    onChangeFullScreen,
    toggleTable,
    handleConcat,
    handleMerge,
    handleRepeatHeader,
    handleSaveAlldata,
    languages,
    translationCode,
    onChangeModalData,
    setTranslationCode,
    languageOriginCode,
    rowSelection,
    setRowSelection,
    updateRowLang,
    levelsHierarchy,
    targetColumn,
    displayColumns,
    service,
    Filetype: filename?.split('.')[1] ?? '',
    filename,
    projectId,
    user_id,
    projectGroups,
    groups,
    users,
    urlFile,
    refreshDATA,
    setNotify,
    languagesCode,
    langCheck,
  }) as any;

  const table = useMaterialReactTable({ ...allDataObject });

  return table;
};

export default useAllDataTable;
