import React from 'react';
import logoIcon from '../logo-icon.png';
import logobackground from '../logobackground.png';
import aiBackground from '../background1.jpg';
import expertSearchIcon from '../shorcutsIcons/expertSearchIcon.png';
import translatorIcon from '../shorcutsIcons/translatorIcon.png';
// import GenaIcon from '../../../assets/shorcutsIcons/GenaIcon.png';
import GenaMenuIcon from '../shorcutsIcons/GenaMenuIcon.png';
import rex360Icon from '../shorcutsIcons/rex360Icon.png';

const IsensImage = (props) => {
  return <img src={logoIcon} alt="iSenS" {...props} />;
};

export const IsensBackgroundImage = (props) => {
  return <img src={logobackground} alt="iSenS" {...props} />;
};

export { logobackground, aiBackground, expertSearchIcon, translatorIcon, GenaMenuIcon, rex360Icon };
export default IsensImage;
