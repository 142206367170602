import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  List,
  ListItem,
  Checkbox,
  Button,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import M2GlobalContext from '../ContextMager/M2GlobalContext';
import useFilterOptions from './Hooks/useFilterOptions';

const Header = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  height: '2rem',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '10px',
  transition: 'all 0.3s ease-out',
}));

const AppliedFilters = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'sticky',
  zIndex: 2,
  top: '-0.5rem',
  backgroundColor: '#EEF1F9',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '2px',
  transition: 'all 0.3s ease-out',
}));
const LeftHeader = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  //   justifyContent: 'space-between',
  alignItems: 'center',
  gap: '5px',
  fontWeight: 'bold',
  transition: 'all 0.3s ease-out',
}));

const DropDownBox = styled((props) => <List {...props} />)(({ theme }) => ({
  height: '20%',
  width: '100%',
  backgroundColor: '#fafafa',
  minHeight: '20%',
  overflow: 'auto',
  padding: 0,
  transition: 'all 0.3s ease-out',
  maxHeight: '500px', // Adjust this value based on your content
  '&.collapsed': {
    maxHeight: '0',
    overflow: 'hidden',
  },
  '::-webkit-scrollbar': {
    width: '2px',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: '#ccd6f2',
    borderRadius: '4px',
  },
  '::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1',
  },
}));

const CheckItem = styled((props) => <ListItem {...props} />)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  fontSize: '0.84rem',
  transition: 'all 0.3s ease-out',
}));

const CheckItemCheckBox = styled((props) => <Checkbox {...props} />)(({ theme }) => ({
  color: 'primary',
  transition: 'all 0.3s ease-out',
  padding: 0,
}));

const ItemsHeader = memo(
  ({ index, checkAllOption, handleHeaderCheckAll, name, collapsed, toggleCollapse }) => {
    return (
      <>
        <Header>
          <LeftHeader>
            {/* <CheckItemCheckBox
              checked={checkAllOption}
              onChange={(e) => {
                handleHeaderCheckAll(index, e.target.checked);
              }}
            /> */}
            {name}
          </LeftHeader>
          <div>
            {/* <IconButton onClick={() => console.log('clicked')}>
              <FilterListOffIcon />
            </IconButton> */}
            <IconButton onClick={() => toggleCollapse(index)}>
              {collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </IconButton>
          </div>
        </Header>
      </>
    );
  }
);

const CheckBoxSection = ({ eachoption, _cache, changeInOption }) => {
  const [check, setCheck] = useState(Object.keys(_cache).includes(eachoption));
  useEffect(() => {
    setCheck(Object.keys(_cache).includes(eachoption));
  }, [eachoption, _cache]);

  const onChangeChecBox = (e) => {
    setCheck(e.target.checked);
    const temp = _cache;
    if (e.target.checked) temp[eachoption] = e.target.checked;
    else delete temp[eachoption];

    changeInOption();
  };

  return (
    <>
      <CheckItem>
        <CheckItemCheckBox
          checked={check}
          onChange={(e) => {
            // handleSelection(index, childIndex, e.target.checked);
            onChangeChecBox(e);
          }}
        />
        {eachoption}
      </CheckItem>
    </>
  );
};

const ItemsSection = memo(({ collapsed, _cache, onChangeOption, options }) => {
  // console.log('options', options);
  const changeInOption = useCallback(() => {
    const temp = Object.keys(_cache);
    onChangeOption(temp);
  }, [_cache]);
  return (
    <>
      <DropDownBox className={collapsed ? 'collapsed' : ''}>
        {options.map((eachoption, childIndex) => {
          return (
            <CheckBoxSection
              eachoption={eachoption}
              _cache={_cache}
              key={childIndex}
              changeInOption={changeInOption}
            />
          );
        })}
      </DropDownBox>
    </>
  );
});

const Filters = ({ toggleCollapse: filterToggle, service }) => {
  const { state, dispatch } = useContext(M2GlobalContext);
  const [collapseAll, setCollapseAll] = useState(false);
  const [syncFilters, setSyncFilters] = useState({});

  const callbackFilters = (options) => {
    setSyncFilters((prev) => {
      return {
        ...prev,
        ...options,
      };
    });
  };
  const { options } = useFilterOptions({ service, state, dispatch, callbackFilters });

  const [NumberOfFilters, setNumberOfFilters] = useState([...options]);

  const appliedFilters = useMemo(() => {
    return options
      .filter((item) => Object.keys(item._cache).length > 0)
      .map((item) => {
        return {
          name: item.name,
          value: Object.keys(item._cache).join(','),
        };
      });
  }, [state, service]);

  useMemo(() => {
    if (options && options.length > 0) {
      setNumberOfFilters(options);

      const temp = {};

      options.forEach((eachOption) => {
        temp[eachOption.key] = eachOption.options;
      });
      setSyncFilters(temp);
    }
  }, [options, service]);

  const toggleCollapse = useCallback((index) => {
    setNumberOfFilters((prev) => {
      return prev.map((item, i) => (i === index ? { ...item, collapsed: !item.collapsed } : item));
    });
  }, []);

  const handleAllCollapse = () => {
    setNumberOfFilters((prev) => {
      return prev.map((item) => {
        return { ...item, collapsed: !collapseAll };
      });
    });
    setCollapseAll(!collapseAll);
  };

  const handleHeaderCheckAll = useCallback(
    (index, state) => {
      // console.log('index', index, 'state', state);
      if (NumberOfFilters.length > 0) {
        NumberOfFilters[index].checkAllOption = state;
        NumberOfFilters[index]._cache = NumberOfFilters[index]._cache.map((item) => {
          return { ...item, check: state };
        });
        setNumberOfFilters([...NumberOfFilters]);
      }
    },
    [NumberOfFilters]
  );

  // console.log('syncFilters', syncFilters);

  return (
    <>
      <Header
        sx={{ borderBottom: '1px solid #002A45', paddingBottom: '0.5rem', cursor: 'pointer' }}
        onClick={filterToggle}
      >
        <Typography>{appliedFilters.length > 0 ? 'Applied Filters' : 'Filters'}</Typography>
        <Button variant="text" style={{ outline: 'none', border: 'none' }}>
          <ArrowBackIosIcon fontSize="small" />
        </Button>
      </Header>
      {appliedFilters.length > 0 && (
        <AppliedFilters>
          {appliedFilters.map((eachItem) => {
            return (
              <div style={{ width: '100%' }}>
                <Typography style={{ fontSize: '0.83rem', fontWeight: 'bold', display: 'inline' }}>
                  {eachItem.name} :
                </Typography>
                <Typography style={{ fontSize: '0.83rem', display: 'inline' }}>
                  {eachItem.value}
                </Typography>
              </div>
            );
          })}
        </AppliedFilters>
      )}
      {/* <Header>
        <IconButton
          onClick={() => {
            handleAllCollapse();
          }}
        >
          {collapseAll ? <KeyboardDoubleArrowDownIcon /> : <KeyboardDoubleArrowUpIcon />}
        </IconButton>
      </Header> */}
      {NumberOfFilters.map((data, index) => {
        const { collapsed, name, checkAllOption, options, _cache, key, onChangeOption } = data;
        return (
          <div key={index} style={{ padding: '0.3rem', fontSize: '0.89rem' }}>
            <ItemsHeader
              index={index}
              checkAllOption={checkAllOption}
              handleHeaderCheckAll={handleHeaderCheckAll}
              name={name}
              collapsed={collapsed}
              toggleCollapse={toggleCollapse}
            />
            <ItemsSection
              collapsed={collapsed}
              // handleSelection={handleSelection}
              onChangeOption={onChangeOption}
              options={syncFilters[key] ?? []}
              index={index}
              _cache={_cache}
            />
          </div>
        );
      })}
    </>
  );
};

export default memo(Filters);
