import { List, ListItem } from '@mui/material';
import React, { memo, useMemo } from 'react';
import { styled } from '@mui/styles';
import Icon from '@mui/material/Icon';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { padding } from '@mui/system';
import xlsx_icons from '../../../../../assets/xlsx_icons.png';
import csv_icons from '../../../../../assets/csv_icons.png';
import pdf_icons from '../../../../../assets/pdf_icons.png';
import docx_icons from '../../../../../assets/docx_icons.png';
import unsupported from '../../../../../assets/unsupported.png';

const ListItemMui = styled((props) => <ListItem {...props} />)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '2fr 1fr 1fr',
}));

export const FileIcon = ({ filename }) => {
  const extension = filename.split('.').pop();
  const ImgSrc = () => {
    switch (extension) {
      case 'xlsx':
        return <Icon component="img" fontSize="small" src={xlsx_icons} alt="xlsx_icon" />;
      case 'pdf':
        return <Icon component="img" fontSize="small" src={pdf_icons} alt="pdf_icon" />;
      case 'csv':
        return <Icon component="img" src={csv_icons} alt="csv_icon" />;
      case 'docx':
        return <Icon component="img" fontSize="large" src={docx_icons} alt="docx_icon" />;
      default:
        return <Icon component="img" src={docx_icons} alt="AdemIcon" />;
    }
  };

  return (
    <>
      <div>
        <ImgSrc />
      </div>
    </>
  );
};

const Files = (props) => {
  const { data, setSelectedFiles, numberOfFilesSupport, selectedFiles } = props;
  // console.log('data', props);

  const handleCheckboxChange = (event, item) => {
    if (event.target.checked) {
      setSelectedFiles((prevSelected) => [...prevSelected, item]);
    } else {
      setSelectedFiles((prevSelected) =>
        prevSelected.filter((selectedItem) => selectedItem !== item)
      );
    }
  };

  const calculateSize = (size) => {
    let modifiedSize = size / 1000;
    if (modifiedSize < 1024) {
      return `${modifiedSize.toFixed(2)} KB`;
    }
    modifiedSize /= 1024;
    if (modifiedSize < 1024) {
      return `${modifiedSize.toFixed(2)} MB`;
    }
    modifiedSize /= 1024;
    return `${modifiedSize.toFixed(2)} GB`;
  };

  // console.log('data', data);

  return (
    <>
      <TableContainer>
        <Table>
          <TableBody>
            {data.map((item, index) => {
              const isItemSelected = selectedFiles.includes(item);
              return (
                <TableRow
                  hover
                  // onClick={(event) => handleClick(event, row.id)}
                  role="checkbox"
                  //   aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={index}
                  selected={isItemSelected}
                  //   selected={isItemSelected}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      disabled={numberOfFilesSupport === selectedFiles.length && !isItemSelected}
                      checked={isItemSelected}
                      onChange={(event) => handleCheckboxChange(event, item)}
                    />
                  </TableCell>
                  <TableCell align="left" sx={{ padding: '7px' }}>
                    <FileIcon filename={item.filename} />
                    {item.filename}
                  </TableCell>
                  <TableCell align="left">{calculateSize(Number(item.size))}</TableCell>
                  <TableCell align="left">{item.userName}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default memo(Files);
