import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Avatar, Box, Card, CardContent, Grid, Typography } from '@mui/material';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import BugReportIcon from '@mui/icons-material/BugReport';
import { makeStyles } from '@mui/styles';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import AboutIsens from '../components/Layout/AboutIsens';

const getInitials = (name) => {
  return name
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase())
    .join('')
    .substring(0, 2);
};

export const useStyles = makeStyles((theme) => ({
  card: {
    // position: 'relative',
    width: '100%',
    // margin: '10px',
    opacity: 1,
    // right: '-1vw',
    transition: 'right 0.3s ease-in 0.3s',
  },
  cardContent: {
    background:
      'transparent linear-gradient(128deg, #e8ebf2 0%, #f5f7f9 100%) 0% 0% no-repeat padding-box',
    minHeight: '10rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconButtonInner: {
    transform: 'scale(1.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  typo1: {
    color: 'white !important',
    opacity: 1,
    fontWeight: 'bold',
    fontSize: 18,
  },
  typo2: {
    fontWeight: 'bold',
    color: 'yellow !important',
    fontSize: 15,
  },
  typo3: {
    fontWeight: 'bold',
    color: 'white !important',
    fontSize: 15,
  },
  resultIcon: {
    color: 'white',
    transform: 'scale(1)',
    opacity: 1,
    fontWeight: 'bold',
    marginRight: 4,
    marginLeft: 4,
  },
  linkButton: {
    border: '2px solid rgb(23, 193, 232)',
    cursor: 'pointer',
    transition: 'all 0.3s ease-in',
    '&:hover': {
      transform: 'scale(1)',
      // border: '4px solid rgb(23, 193, 232)',
    },
    borderRadius: 10,
    padding: 5,
  },
}));

const ProfilePanel = ({ setSideBar, isOpen }) => {
  const history = useHistory();
  const token = JSON.parse(localStorage.getItem('azJWT') || '{}');
  const classes = useStyles();
  const UserName = getInitials(token?.account?.idTokenClaims.name);
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => {
    setModalOpen(false);
  };
  const handleModalOpen = () => {
    setModalOpen(true);
  };

  return (
    <>
      <div>
        <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CloseIcon
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setSideBar({ isPanel: '', isOpen: false });
            }}
            // style={{ color: 'black', position: 'absolute', right: 0 }}
          />
        </Box>
        <Box className={`${classes.card}`} style={{ right: isOpen ? '-1vw' : '-27vw' }}>
          <Box className={classes.cardContent}>
            <Grid container>
              <Grid item xs={3} className={classes.iconButtonInner}>
                <Avatar>{UserName}</Avatar>
              </Grid>
              <Grid item xs={9}>
                <Typography
                  style={{ color: 'black ', opacity: 1, fontWeight: 'bold', fontSize: 20 }}
                >
                  {token?.account?.idTokenClaims.name}
                </Typography>
                <Typography style={{ fontWeight: 'bold', color: 'black ', fontSize: 10 }}>
                  {token?.account?.idTokenClaims.jobTitle}
                </Typography>
                <Typography
                  style={{
                    fontWeight: 'bold',
                    color: 'black',
                    fontSize: 10,
                    wordWrap: 'break-word',
                  }}
                >
                  {token?.account?.idTokenClaims.preferred_username}
                </Typography>
                {/* <Typography style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      color: 'black',
                      opacity: 1,
                      fontWeight: 'bold',
                      fontSize: 15,
                    }}
                  >
                    Site Code :
                  </Typography>
                </div>
                <Typography
                  style={{
                    color: '#DC3223',
                    opacity: 1,
                    fontWeight: 'bold',
                    marginLeft: 2,
                    fontSize: 17,
                    wordWrap: 'break-word',
                  }}
                >
                  {token?.account?.idTokenClaims['site code']}
                </Typography>
              </Typography> */}
                <Typography
                  // variant="h6"
                  style={{
                    color: '#DC3223',
                    opacity: 1,
                    fontWeight: 'bold',
                    fontSize: 10,
                    wordWrap: 'break-word',
                    // font: 'normal normal medium 25px/33px Alstom',
                  }}
                >
                  {token?.account?.idTokenClaims.Department}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '0.5rem' }}>
          <Box onClick={() => history.push('/logout')} className={classes.linkButton}>
            {/* <InfoIcon /> */}

            <Typography fontSize="small">
              <ExitToAppIcon fontSize="small" sx={{ mr: '2px' }} />
              Sign Out
            </Typography>
          </Box>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0.5rem' }}>
        <Box onClick={handleModalOpen} className={classes.linkButton}>
          {/* <InfoIcon /> */}
          <Typography fontSize="small">
            <InfoIcon fontSize="small" sx={{ mr: '2px' }} />
            About iSenS
          </Typography>
        </Box>
        <Box
          component="a"
          className={classes.linkButton}
          href="https://btt.alstom.hub/login?back_url=https%3A%2F%2Fbtt.alstom.hub%2Fprojects%2Fisens_requestor%2F"
          target="_blank"
        >
          <Typography fontSize="small">
            <BugReportIcon fontSize="small" sx={{ mr: '2px' }} />
            Report an Issue
          </Typography>
        </Box>
      </div>
      <AboutIsens open={modalOpen} onCancel={handleModalClose} />
    </>
  );
};

export default ProfilePanel;
