import React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Item from './components/Item';
import useHistory from './Hooks/useHistory';

const tempData = [{ name: 'first' }, { name: 'second' }, { name: 'third' }];
const History = (props) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const { modelService, workload } = props;

  const { loading, rows, totalCount } = useHistory({
    serviceName: modelService,
    workload,
    currentPage,
  });

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setCurrentPage(newPage);
  };

  // console.log(`${modelService} rows`, currentPage);
  return (
    <>
      {rows.map((item) => {
        return (
          <>
            <Item {...props} data={item} />
          </>
        );
      })}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'sticky',
          bottom: 0,
          backgroundColor: 'white',
          padding: '0.3rem',
        }}
      >
        <Pagination
          count={Math.ceil(totalCount / 10)}
          variant="outlined"
          shape="rounded"
          page={currentPage}
          onChange={handleChangePage}
        />
      </div>
    </>
  );
};

export default History;
