import React, { useContext } from 'react';
import LayoutContainerContext from '../../layouts/LayoutContainerContext';

const DocResultContainer = (props) => {
  const { workload } = props;

  return (
    <div>
      <h1>DocResultContainer</h1>
    </div>
  );
};

export default DocResultContainer;
