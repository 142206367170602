/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-continue */
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  Plugin,
  Template,
  TemplateConnector,
  TemplatePlaceholder,
} from '@devexpress/dx-react-core';
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
  EditingState,
} from '@devexpress/dx-react-grid';
import {
  Grid as ExpressGrid,
  Table,
  PagingPanel,
  TableFilterRow,
  TableHeaderRow,
  TableEditColumn,
  TableSelection,
  VirtualTable,
  Toolbar,
  TableColumnVisibility,
  ColumnChooser,
  TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import { isEmpty } from '@antv/util';

import { useHistory, useParams } from 'react-router-dom';
import { Theme, useTheme } from '@material-ui/core/styles';
import { makeStyles, withStyles } from '@material-ui/styles';
import {
  CircularProgress,
  TextareaAutosize,
  Card,
  CardHeader,
  Tooltip as CustomTooltip,
  CardContent,
  Typography,
} from '@material-ui/core';
import {
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Button,
  Select,
  InputLabel,
  FormControl,
  OutlinedInput,
  ListItemText,
  Box,
  Grid,
  Paper,
} from '@mui/material';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { Workload3Create } from 'WorkloadType';
import MultiSelect from 'react-multi-select-component';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import ThumbDownAltIcon from '@material-ui/icons/ThumbDownAlt';
import FormGroup from '@mui/material/FormGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MuiGrid from '@mui/material/Grid';
import ReactJson from 'react-json-view';
import Loading from '../../../components/Core/Loading';
import {
  workloadCreate,
  workloadGetById,
  workloadGetByIdIQualimeter,
  workloadRunPost,
  workloadIQualimeterPut,
  workloadIQualimeterRepharasedText,
} from '../../../api/workloads';
import Module1Context from '../Module3Context';
import formatBytes from '../../../tools/formatBytes';
import { getiQualimeterRules } from '../../../api/module3';
import { fileDetails, csvHeader, iCaptureExcel, iCaptureCsv } from '../../../api/module1';
import ToolBarPlugin from '../../../components/Datagrid/ToolBarPlugin';
import TStyles, {
  rowComponent,
  tableAllocatorComponent,
  tableHeaderComponent,
  toolbarComponent,
  tUseStyles,
} from '../../../components/Datagrid/TStyles';
import useStylesCsvExel from '../../Module1/components/UseStyleSettings';
import Run1Context, { Resource } from '../../Module1/RunContext';
import NavigationGoto from '../../../components/Core/NavigationGoto';
import UploadFile from '../../../components/Core/UploadFile';
import { getSafetyConfig } from '../../../api/projects';
import { IS_DEBUG_MODE } from '../../../const';
import NavigationGotoNew from '../../../components/Core/NavigationGotoNew';
import MultiAllocTableComp from '../../../components/Datagrid/MultiAllocTableComp';
import { CustomWidthTooltip } from '../../../components/Core/CustomWidthTooltip';
import { resultStyle } from '../../../components/Filter/textAnalysis';

type Props = {
  id?: number;
};

function changeKey(originalKey: any, newKey: any, arr: any) {
  const newArr = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < arr.length; i++) {
    const obj = arr[i];
    obj[newKey] = obj[originalKey];
    delete obj[originalKey];
    newArr.push(obj);
  }
  return newArr;
}

const useStyles = makeStyles((theme) => ({
  customScrollbar: {
    '&::-webkit-scrollbar': {
      width: '8px', // Set the width of the scrollbar
    },
  },
  customHeight: {
    '& .MuiInputBase-root': {
      height: '50px', // Set your desired height
    },
  },
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 300,
    fontSize: 16,
    border: '1px solid #dadde9',
  },
  formControl: {
    margin: theme.spacing(1),
    width: 200,
  },
  indeterminateColor: {
    color: '#f50057',
  },
  selectAllText: {
    fontWeight: 300,
    height: 20,
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
  multiSelectText: {
    // border: '1px solid red',
    '& .rmsc.dropdown-heading': {
      height: '10px',
    },
  },

  pagingPanelContainer: {
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // marginTop: '10px',
    // width: '50% !important',
    padding: '0px !important',
    marginRight: '244px !important',
    '& .Pager-pager': {
      padding: 0,
    },
    // Apply other custom styles as needed
    '& .MuiIconButton-label': {
      color: '#001F3C',
      padding: 5,
      backgroundColor: '#FFFFFF',
      borderRadius: '50%',
      boxShadow: '#00000029',
    },
    // '& .MuiButton-label': {
    //   color: '#001F3C',
    //   padding: 2,
    //   backgroundColor: '#FFFFFF',
    //   borderRadius: '50%',
    //   boxShadow: '#00000029',
    // },
    '& .MuiButton-root': {
      '&.MuiButton-textPrimary .Pagination-activeButton': {
        backgroundColor: 'red !important',
        color: 'white !important',
        // Add other custom styles as needed
      },
      color: '#001F3C',
      padding: '0px 5px',
      backgroundColor: 'white',
      // borderRadius: '50%',
      boxShadow: '#00000029',
      // gap: 10,
      margin: 9,
      // '&.Pagination-activeButton': {
      //   background: 'red !important',
      // },
      // '& .Pagination-button': {
      //   '& .Pagination-activeButton': {
      //     background: 'red !important',
      //   },
      // },
    },
  },
}));
const Columns = [
  { name: 'filename', title: 'File Name' },
  { name: 'size', title: 'Size' },
  { name: 'folderName', title: 'Folder' },
  { name: 'date', title: 'Date upload' },
  { name: 'userName', title: 'Uploaded by' },
];

const settingsDefault = {
  typesOfCapture: 'Paragraph_Capture',
};

// for popup edit @rahul Kr

function IQulimeterFile({ id: workloadId }: Props) {
  const tableUseStyles = tUseStyles();
  const classessNew = useStyles();
  const classes2 = resultStyle();
  const classes = useStylesCsvExel();
  const [hideRephrase, setHideRephrase] = useState(false);
  const { setSnack } = useContext(Module1Context);
  const [rows, setRows] = useState<any>([]);
  const [columns, setColumns] = useState<any>([]);
  const [newCol, setNewCol] = useState<any>('Text' || 'text');
  const [numberOfRecords, setNumberOfRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingSecond, setLoadingSecond] = useState(false);
  const [loadingFinalTable, setLoadingFinalTable] = useState(false);
  const [display, setDisplay] = useState(false);
  const [displayGrid, setDisplayGrid] = useState<any>();
  const [errorMsg, setErrorMsg] = useState<any>();
  const [sheetNames, setSheetNames] = useState<any[]>([]);
  const [columnName, setColumnName] = useState<any[]>([]);
  const [sheets, setSheets] = useState<any[]>([]);
  const [selectedSheetName, setSelectedSheetName] = useState<any>();
  const [selectedColumnName, setSelectedColumnName] = useState<any>();
  const [fileType, setFileType] = useState<any>();
  const [graphData, setGraphData] = useState<any>();
  const [graphLlmData, setGraphLlmData] = useState<any>();
  const [graphDataDisplay, setGraphDataDisplay] = useState<any>(false);
  const [csvDataframe, setCsvDataframe] = useState<any>();
  const [inputValue, setInputValue] = useState('');
  const exporterRef = useRef(null);
  const { projectId } = useParams() as any;
  const history = useHistory();
  const [files, setFiles] = useState<any[]>([]);
  const { project } = useContext(Module1Context);
  const [fileResultScore, setFileResultScore] = useState<any>();

  const [originTextScore, setOriginTextScore] = useState<any>();
  const [selection, setSelection] = useState([]);
  const [selectionHere, setSelectionHere] = useState([]);
  const [selectedRules, setSelectedRules] = useState([]);
  const [allRules, setAllRules] = useState<any>();
  const [toggleHeight, setToggleHeight] = useState(false);
  const [newLine, setNewLine] = useState<any>('');
  const [sheetName, SetSheetName] = useState<any>('');
  const [state, setState] = useState({});
  const [checked, setChecked] = React.useState(false);
  const [header, setHeader] = useState({ header: '' });
  const [targetNameValue, setTargetNameValue] = useState('');
  const [reqColumnValue, setReqColumnValue] = useState('Not Applicable');
  const [loader, setLoader] = useState<any>(false);
  const [headerName, setHeaderName] = useState<any>(false);
  const [payloadData, setPayloadData] = useState({});
  const [columnNamesCapture, setColumnNamesCapture] = useState([]);
  const [capturePayloadNew, setCapturePayloadNew] = useState({});
  const [col, setCol] = useState({});
  const [tempPage, setTemPage] = useState(0);
  const [pageSizes] = useState([5, 10, 20, 50]);
  const [pageSize, setPageSize] = useState(5);
  const [pageSize2, setPageSize2] = useState(5);
  const [pageSize3, setPageSize3] = useState(5);
  const [pageSize4, setPageSize4] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPage2, setCurrentPage2] = useState(0);
  const [currentPage3, setCurrentPage3] = useState(0);
  const [currentPage4, setCurrentPage4] = useState(0);
  const [dis, setDis] = useState(true);
  const [acronymsResp, setAcronymsResp] = useState([]);
  const [wordGroupsResp, setWordGroupsResp] = useState([]);
  const [columnWidths, setColumnWidths] = useState([] as any);
  const [hiddenColumnNames, setHiddenColumnNames] = useState([
    // 'id',
    // '.',
    // 'Artifact_Hierarchy',
    // 'Alstom_Entity',
    // 'Document_Title',
    // 'Artifact_Type',
    // 'Artifact_Allocation',
    // 'Artifact_Topic',
    // 'Artifact_ID.1',
    // 'URL',
    // 'Absolute Number',
    // '#isDeleted',
    // '#outLinks',
    // '#outLinksFull',
    // '#MODULE',
    // 'DoorsAN',
    // 'Created By',
    // 'Created By_Name',
    // 'Created By_Email',
    // 'Created On',
    // 'Last Modified By',
    // 'Last Modified By_Name',
    // 'Last Modified By_Email',
    // 'Last Modified On',
    // 'Module Unique ID',
    // 'Num Of Line',
  ] as any);
  const [changedRowOnly, setChangedRowOnly] = useState([]);
  const [rawRows, setRawRows] = useState([]);
  const [payloadOri, setPayloadOri] = useState<any>();
  const [rephrasedScore, setRephrasedScore] = useState<any>();
  const [saveIconChange, setSaveIconChange] = useState<boolean>(false);
  const [titleArr, setTitleArr] = useState<any>([]);
  const [checkme, setCheckme] = useState<boolean>(false);
  const [getllm, setgetllm] = useState<boolean>(false);
  const [targetFinalColumns, setTargetFinalColumns] = useState<any>('');

  const commitChanges = ({ changed }) => {
    let changedRows: any;
    // console.log('changedRows', changed);
    if (changed) {
      const r = Object.keys(changed);
      const rVal = Object.values(changed)[0];
      changedRows = rows.map((row, id) => (id === Number(r[0]) ? { ...row, ...rVal } : row));
      if (rVal !== undefined) {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < changedRows.length; index++) {
          if (Number(r[0]) === index) {
            setChangedRowOnly(changedRowOnly.concat(changedRows[index]));
          }
        }
      }
    }
    setRows(changedRows);
  };

  const Popup = ({ row, onChange, onApplyChanges, onCancelChanges, open }) => (
    <Dialog open={open} onClose={onCancelChanges} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Edit Artifact Text:</DialogTitle>
      <DialogContent>
        <MuiGrid container spacing={3}>
          <MuiGrid item xs={6}>
            <FormGroup>
              <b>Artifact Original Text: </b>
              {row.Artifact_Original_Text || ''}
              <TextareaAutosize
                name="Artifact_Text"
                label="Artifact Text"
                value={row.Artifact_Text || ''}
                onChange={onChange}
                style={{
                  height: '200px',
                  width: '400px',
                }}
              />
            </FormGroup>
          </MuiGrid>
        </MuiGrid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelChanges} color="secondary">
          Cancel
        </Button>
        <Button onClick={onApplyChanges} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );

  const PopupEditing = React.memo(({ popupComponent: Popup }) => (
    <Plugin>
      <Template name="popupEditing">
        <TemplateConnector>
          {(
            { rows, getRowId, addedRows, editingRowIds, createRowChange, rowChanges },
            {
              changeRow,
              changeAddedRow,
              commitChangedRows,
              commitAddedRows,
              stopEditRows,
              cancelAddedRows,
              cancelChangedRows,
            }
          ) => {
            const isNew = addedRows.length > 0;
            let editedRow: any;
            let rowId: any;
            if (isNew) {
              rowId = 0;
              editedRow = addedRows[rowId];
            } else {
              [rowId] = editingRowIds;
              const targetRow = rows.filter((row: any) => getRowId(row) === rowId)[0];
              editedRow = { ...targetRow, ...rowChanges[rowId] };
            }
            // console.log('editedRow', editedRow);

            const processValueChange = ({ target: { name, value } }) => {
              const changeArgs = {
                rowId,
                change: createRowChange(editedRow, value, name),
              };
              if (isNew) {
                changeAddedRow(changeArgs);
              } else {
                changeRow(changeArgs);
              }
            };
            const rowIds = isNew ? [0] : editingRowIds;
            const applyChanges = () => {
              setSaveIconChange(true);
              if (isNew) {
                commitAddedRows({ rowIds });
              } else {
                stopEditRows({ rowIds });
                commitChangedRows({ rowIds });
              }
            };
            const cancelChanges = () => {
              if (isNew) {
                cancelAddedRows({ rowIds });
              } else {
                stopEditRows({ rowIds });
                cancelChangedRows({ rowIds });
              }
            };

            const open = editingRowIds.length > 0 || isNew;
            return (
              <Popup
                open={open}
                row={editedRow}
                onChange={processValueChange}
                onApplyChanges={applyChanges}
                onCancelChanges={cancelChanges}
              />
            );
          }}
        </TemplateConnector>
      </Template>
      <Template name="root">
        <TemplatePlaceholder />
        <TemplatePlaceholder name="popupEditing" />
      </Template>
    </Plugin>
  ));

  const handlePageNum = () => {
    const rel = tempPage - 1;
    setCurrentPage(rel);
    setCurrentPage2(rel);
    setCurrentPage3(rel);
    setCurrentPage4(rel);
  };
  const [displays, setDisplays] = useState({
    message: '',
    displayHandleSetRowsColumns: true,
  });
  useEffect(() => {
    // setLoading(false);
    // setDisplay(false);
    // setLoadingFinalTable(true);
    // setLoadingFinalTable(true);
    setLoadingSecond(true);
    workloadGetById(workloadId)
      .then((payload) => {
        // setTimeout(() => {
        //   handleRefesh('forced');
        //   setLoadingFinalTable(false);
        // }, 1000);

        setgetllm(payload?.resources[0]?.llm);
        setTargetFinalColumns(payload?.resources[0]?.TargetColumn);

        setLoadingSecond(false);
        setGraphDataDisplay(true);
        // setDisplay(true);
      })
      .catch(() => {
        setLoadingSecond(false);
        setGraphDataDisplay(false);
        // handleRefesh('normal');
      });
  }, [workloadId, projectId]);
  useEffect(() => {
    const { folders } = project;
    const allResources = [] as any[];
    if (folders) {
      for (const eachFolder of folders) {
        if (eachFolder.resources && eachFolder.resources.length > 0)
          for (const r of eachFolder.resources) {
            // get file extension(only xls, xlsx, csv) before that convert to lower case
            const fileExtension = r.filename.toLowerCase().split('.').pop();
            if (fileExtension === 'csv' || fileExtension === 'xls' || fileExtension === 'xlsx')
              allResources.push({
                ...r,
                size: formatBytes(Number(r.size), 0),
                id: r.etag,
                folderName: eachFolder.name,
                folderId: eachFolder.id,
              });
          }
      }
    }
    setFiles(allResources.sort((a, b) => new Date(b.date) - new Date(a.date)));

    const getAcronym = async () => {
      const acronymResp = await getSafetyConfig(projectId, 'Acronyms');
      setAcronymsResp(acronymResp[0]?.values);
    };

    const getWordGroup = async () => {
      const wordGroupResp = await getSafetyConfig(projectId, 'Word Groups');
      setWordGroupsResp(wordGroupResp[0]?.values);
    };

    const getQualimeter = async () => {
      const qualimeterRules = await getiQualimeterRules();
      const keys: any = [];
      Object.entries(qualimeterRules.file).forEach(([key, value]) => {
        keys.push({
          label: `${value}`,
          value: `${key}`,
        });
      });
      setAllRules(keys);
    };

    getAcronym();
    getWordGroup();
    getQualimeter();
  }, []);

  useEffect(() => {
    getiQualimeterRules()
      .then((payload: any) => {
        if (payload) {
          const keys: any = [];
          Object.entries(payload.file).forEach(([key, value]) => {
            if (
              (key === 'R36' && isEmpty(wordGroupsResp)) ||
              (key === 'R38' && isEmpty(acronymsResp))
            ) {
              keys.push({
                label: `${value}`,
                value: `${key}`,
                disabled: true,
              });
            } else {
              keys.push({
                label: `${value}`,
                value: `${key}`,
              });
            }
          });
          setAllRules(keys);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }, [wordGroupsResp, acronymsResp]);

  let fileIs1;
  if (selection.length > 0 && selection[0] !== undefined && selection.length < 2) {
    fileIs1 = files[selection[0]].filename || '';
    // c
  }

  const handleSetRowsColumns = async (myRows: {}[]) => {
    setLoadingSecond(true);

    const araryCol = Object?.keys(myRows[0]);
    const myCols: any = [];
    araryCol.map((key) => {
      // const keyTemp = key.replace(' ', '_');
      if (key !== 'i18n') {
        if (key === 'Rephrased_Text') {
          myCols.push({
            name: key,
            title: 'Automatic_Rephrased_Requirement',
          });
        } else if (key === 'Rephrased_warning') {
          myCols.push({
            name: key,
            title: 'Automatic_Rephrased_warning',
          });
        } else if (key === 'Rephrased_score') {
          myCols.push({
            name: key,
            title: 'Automatic_Rephrased_Score',
          });
        } else {
          myCols.push({
            name: key,
            title: key.toUpperCase(),
          });
        }
      }
      return key;
    });

    // if (myCols.find((t: any) => t?.name === 'Rephrased_Text') === undefined) {
    //   myCols.push({ name: 'Rephrased_Text', title: 'REPHRASED TEXT' });
    // }
    // if (myCols.find((t: any) => t?.name === 'Rephrased_warning') === undefined) {
    //   myCols.push({ name: 'Rephrased_warning', title: 'REPHRASED WARNING' });
    // }
    // if (myCols.find((t: any) => t?.name === 'Rephrased_score') === undefined) {
    //   myCols.push({ name: 'Rephrased_score', title: 'REPHRASED SCORE' });
    // }
    if (myCols.find((t: any) => t?.name === 'status') === undefined) {
      myCols.push({ name: 'status', title: 'STATUS' });
    }

    const resizeColumns = myCols?.map((c) => {
      if (
        // c.name === 'Rephrased_Text' ||
        c.name === 'Artifact_Original_Text' ||
        // c.name === 'Rephrased_warning' ||
        c.name === 'warning'
      ) {
        return {
          columnName: c.name,
          width: 300,
        };
      }
      // if (c.name === 'Rephrased_score') {
      //   return {
      //     columnName: c.name,
      //     width: 170,
      //   };
      // }
      if (c.name === 'score') {
        return {
          columnName: c.name,
          width: 100,
        };
      }
      return {
        columnName: c.name,
        width: Math.ceil((180 * myCols?.length) / myCols?.length) || Math.ceil(250),
      };
    });
    setColumnWidths(resizeColumns);
    // console.log('resizeColumns', araryCol);
    // setNewCol(myCols[0]?.name);

    setLoadingSecond(true);
    workloadGetById(workloadId)
      .then((payload) => {
        // eslint-disable-next-line eqeqeq
        if (payload?.resources[0]?.llm == true) {
          const elementsToRemoveLlmTrue = [
            `${payload?.resources[0]?.TargetColumn}`,
            'Automatic_Rephrased_Requirement',
            'Automatic_Rephrased_warning',
            'Automatic_Rephrased_Score',
            'Original_Text_warning',
            'Original_Text_Score',
            'id',
          ];
          // console.log('here');
          // console.log('llm false');
          // hiddenColumnNames.push(...elementsToPush);
          // eslint-disable-next-line prefer-const
          const arr = araryCol?.filter((item) => !elementsToRemoveLlmTrue.includes(item));
          // console.log('hiddenColumnNames', hiddenColumnNames);
          // console.log('arr', arr);
          setHiddenColumnNames([...arr]);
          // for (const element of elementsToRemove) {
          //   const index = hiddenColumnNames.indexOf(element);
          //   if (index !== -1) {
          //     hiddenColumnNames.splice(index, 1);
          //   }
          // }
        }
        // console.log('payloadOri?.polar_data_llm.length', payloadOri?.polar_data_llm.length);
        // eslint-disable-next-line eqeqeq
        if (payload?.resources[0]?.llm == false) {
          const elementsToRemove = [
            `${payload?.resources[0]?.TargetColumn}`,
            'Artifact_Type',
            'score',
            'warning',
            'Artifact_Text',
          ];
          // console.log('here zero');
          // console.log('llm false');
          // hiddenColumnNames.push(...elementsToPush);
          // eslint-disable-next-line prefer-const
          const arr = araryCol?.filter((item) => !elementsToRemove.includes(item));

          setHiddenColumnNames([...arr]);
          // for (const element of elementsToRemove) {
          //   const index = hiddenColumnNames.indexOf(element);
          //   if (index !== -1) {
          //     hiddenColumnNames.splice(index, 1);
          //   }
          // }
        }
        setLoadingSecond(false);
      })
      .catch(() => {
        setLoadingSecond(false);
      });
    // console.log('hiddenColumnNames', hiddenColumnNames);

    setColumns(myCols);
    // setHiddenColumnNames();
    // console.log('columns', columns);
    // setTimeout(() => {
    //   handleRefesh('forced');
    //   console.log('forced');
    // }, 2000);
    // Newfunction(myCols);
    // console.log('columns', myCols);

    setRows(myRows);
    setLoadingSecond(false);
    const arrayForTitle = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < myCols.length; index++) {
      arrayForTitle.push(myCols[index].name);
    }
    setTitleArr(arrayForTitle);
  };

  const startExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);
  // setTimeout(() => {
  //   Newfunction(columns);
  // }, 1000);
  // useEffect(() => {
  //   setTimeout(() => {
  //     handleRefesh('forced');
  //   }, 2000);
  // }, [workloadId]);

  const handleRefesh = (type: any) => {
    if (type === 'normal') {
      // setSnack('Refresh Data going on... PLease wait', 'warning');
    }
    workloadGetByIdIQualimeter(workloadId)
      .then((payload) => {
        setSelectionHere([]);
        setPayloadOri(payload);
        const arrayData: any = [];
        // eslint-disable-next-line array-callback-return
        let sumation_for_rephraser = 0;
        payload?.result_output?.map((val: any, id: any) => {
          let obj = val.raw_data;
          const extra = { id: val.id };
          obj = { ...obj, ...extra };
          if ('Rephrased_score' in obj) {
            sumation_for_rephraser += obj.Rephrased_score;
          } else {
            sumation_for_rephraser += obj.score;
          }
          arrayData.push(obj);
          return val;
        });

        // console.log('sumation_for_rephraser', sumation_for_rephraser);

        // console.log('payload?.result_output?.length', payload?.result_output?.length);

        const mean_sum = sumation_for_rephraser / payload?.result_output?.length;
        const roundUp = mean_sum * 100;
        // console.log('mean_sum xxxx', mean_sum);
        setRephrasedScore(roundUp.toFixed(2));

        setFileResultScore(payload?.resultScore);
        setOriginTextScore(payload?.original_text_score);
        setRawRows(arrayData);
        handleSetRowsColumns(arrayData);
        setGraphData(JSON.parse(payload?.polarData));
        // console.log('polart data', payload);
        // setGraphLlmData(JSON.parse(payload?.polar_data_llm));
      })
      .catch((er) => {
        setSnack('error: ', er);
      });
  };

  const passForSave = () => {
    const newChangedRowOnly: any = [];
    const ids: any = [];
    // eslint-disable-next-line array-callback-return
    changedRowOnly?.map((key, id) => {
      newChangedRowOnly.push({
        id: key.id,
        data: changedRowOnly[id],
      });
      // console.log('key: ', key);
      // if (key?.Id?.length > 0) {
      //   // eslint-disable-next-line array-callback-return
      //   payloadOri?.result_output?.map((k, i) => {
      //     // eslint-disable-next-line eqeqeq
      //     if (key?.Id == k?.raw_data?.Id) {
      //       newChangedRowOnly.push({
      //         id: k?.id,
      //         data: changedRowOnly[id],
      //       });
      //     }
      //     // eslint-disable-next-line eqeqeq
      //     // if (key?.Artifact_ID == k?.raw_data?.Artifact_ID) {
      //     //   newChangedRowOnly.push({
      //     //     id: k?.id,
      //     //     data: changedRowOnly[id],
      //     //   });
      //     // }
      //   });
      // }
      // if (key?.Artifact_ID?.length > 0) {
      //   // eslint-disable-next-line array-callback-return
      //   payloadOri?.result_output?.map((k, i) => {
      //     // eslint-disable-next-line eqeqeq
      //     if (key?.Artifact_ID == k?.raw_data?.Artifact_ID) {
      //       newChangedRowOnly.push({
      //         id: k?.id,
      //         data: changedRowOnly[id],
      //       });
      //     }
      //   });
      // }
    });
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    putAPIFun(workloadId, newChangedRowOnly, 'save button');
  };

  const passForNewText = () => {
    if (selectionHere.length > 5000) {
      setSnack('Sorry...Maximum 5000 rows processing allowed', 'warning');
      return;
    }
    if (selectionHere.length === 0) {
      setSnack('Select atleast one row to process', 'error');
      return;
    }
    setSnack('Yours Rephrased Text is in processing... Please Wait.', 'success');
    const newChangedRowOnly: any = [];
    // eslint-disable-next-line array-callback-return
    selectionHere?.map((key, id) => {
      newChangedRowOnly.push({ data: payloadOri?.result_output[Number(key)] });
    });
    const newT: any = [];
    newT.push({ changedRow: newChangedRowOnly });
    let scoring = 0;
    // eslint-disable-next-line array-callback-return
    rows?.map((a, b) => {
      scoring += a.score;
    });
    const dataIs = {
      // num_req: rows.length,
      // result_sum: scoring,
      // req_score_sum: payloadOri?.req_score_sum,
      result_score: fileResultScore,
      result_sum: payloadOri?.req_score_sum,
      num_req: payloadOri?.no_requirement,
    };
    newT.push(dataIs);
    setLoadingFinalTable(true);
    // send for maximum : 5 rows process only unless snack message
    workloadIQualimeterRepharasedText(workloadId, { dataframe: newT })
      .then((output) => {
        setTimeout(() => {
          handleRefesh('forced');
          setLoadingFinalTable(false);
        }, 4000);
      })
      .catch((er) => {
        console.log(er);
      });
  };

  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'data.xlsx');
    });
  };

  const inputHandler = (event: any) => setInputValue(event.target.value);
  // console.log('columns', columns);
  useEffect(() => {
    if (files.length === 0 || projectId === undefined || workloadId === undefined) {
      return;
    }
    // setLoading(true);
    setLoadingSecond(true);
    workloadGetByIdIQualimeter(workloadId)
      .then((payload) => {
        setLoading(false);
        setDisplayGrid(true);

        setPayloadOri(payload);
        const arrayData: any = [];
        // eslint-disable-next-line array-callback-return
        payload?.result_output?.map((val: any, id: any) => {
          let obj = val.raw_data;
          const extra = { id: val.id };
          obj = { ...obj, ...extra };
          arrayData.push(obj);
        });
        setFileResultScore(payload?.resultScore);
        setOriginTextScore(payload?.original_text_score);
        setHideRephrase(payload?.llm_usage);
        setRephrasedScore(payload?.rephrasedScore);
        setRawRows(arrayData);
        handleSetRowsColumns(arrayData);
        // console.log('payload', payload?.polar_data_llm.length);

        setGraphData(JSON.parse(payload?.polarData));
        setGraphLlmData(JSON.parse(payload?.polar_data_llm));
        setGraphDataDisplay(true);
        setDisplay(true);
        setLoadingSecond(false);
      })
      .catch(() => {
        setLoading(false);
        setLoadingSecond(false);
        setDisplay(false);
        // setSnack(messageToDisplay, 'error');
      });
  }, [projectId, workloadId, files]);

  const Newfunction = (cols) => {
    const elementsToPush = [];
    const elementsToRemove = [
      `${targetFinalColumns}`,
      'Artifact_Type',
      'score',
      'warning',
      'Artifact_Text',
    ];
    const elementsToRemoveLlmTrue = [
      'Automatic_Rephrased_Requirement',
      'Automatic_Rephrased_warning',
      'Automatic_Rephrased_Score',
      'Original_Text_warning',
      'Original_Text_Score',
      `${targetFinalColumns}`,
    ];

    cols.map((a) => elementsToPush.push(a.name)); // get all column names
    if (payloadOri?.polar_data_llm.length === 0) {
      // console.log('llm false');
      hiddenColumnNames.push(...elementsToPush);
      // eslint-disable-next-line prefer-const
      let arr = hiddenColumnNames.filter((item) => !elementsToRemove.includes(item));
      setHiddenColumnNames(arr);
      // for (const element of elementsToRemove) {
      //   const index = hiddenColumnNames.indexOf(element);
      //   if (index !== -1) {
      //     hiddenColumnNames.splice(index, 1);
      //   }
      // }
    }
    if (payloadOri?.polar_data_llm?.length > 0) {
      // console.log('llm true');
      hiddenColumnNames.push(...elementsToPush);

      // eslint-disable-next-line prefer-const
      let arr = hiddenColumnNames.filter((item) => !elementsToRemoveLlmTrue.includes(item));

      setHiddenColumnNames(arr);
      // for (const element of elementsToRemoveLlmTrue) {
      //   const index = hiddenColumnNames.indexOf(element);
      //   if (index !== -1) {
      //     setHiddenColumnNames(hiddenColumnNames.splice(index, 1));
      //   }
      // }
    }
  };

  const TableComponentBase = ({ classes, ...restProps }: any) => (
    <Table.Table {...restProps} className={classes.tableStriped} />
  );

  const TableComponent = withStyles(TStyles, { name: 'TableComponent' })(TableComponentBase);

  function onChangeRules(option: any) {
    setSelectedRules(option);
  }

  function HandleRegCsv(e: any) {
    // onChange(filename, { [e.target.name]: e.currentTarget.value });
    // eslint-disable-next-line no-console
    if (e.target.name === 'sheetname') {
      setChecked(e.target.checked);
      setHeader({ header: '' });
    }
    if (e.target.name === 'header') {
      setHeader({ header: e.currentTarget.value });
    }
    if (e.target.name === 'targetColumn') {
      setTargetNameValue(e.currentTarget.value);
    }
    if (e.target.name === 'reqColumn') {
      setReqColumnValue(e.currentTarget.value);
    }
  }
  function HandleReg(e: any) {
    // onChange(filename, { [e.target.name]: e.currentTarget.value });
    // eslint-disable-next-line no-console
    if (e.target.name === 'sheetname') {
      setChecked(e.target.checked);
      setHeader({ header: '' });
    }
    if (e.target.name === 'header') {
      setHeader({ header: e.currentTarget.value });
    }
    if (e.target.name === 'targetColumn') {
      setTargetNameValue(e.currentTarget.value);
    }

    if (e.target.name === 'reqColumn') {
      setReqColumnValue(e.currentTarget.value);
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < payloadData?.Sheets.length; i++) {
      if (payloadData?.Sheets[i].SheetName === e.currentTarget.value) {
        SetSheetName(payloadData?.Sheets[i].SheetName);
        setNumberOfRecords(payloadData?.Sheets[i].NumberOfRecords);
        setState({
          numberOfColumns: payloadData?.Sheets[i].NumberOfColumns,
          numberOfRows: payloadData?.Sheets[i].NumberOfRecords,
          Language: payloadData?.Sheets[i].Language || '-',
          targetColumn: payloadData?.Sheets[i].ColumNames,
          Columns: payloadData?.Sheets[i].ColumNames,
          iValue: String(i),
        });
        setDisplays({
          message: '',
          displayHandleSetRowsColumns: true,
        });
        if (JSON.parse(payloadData.Sheets[i].Text.length) > 2) {
          handleSetRowsColumns(JSON.parse(payloadData?.Sheets[i].Text));
        } else {
          handleSetRowsColumns(JSON.parse('[""]'));
        }

        // dataframe from each sheet
        // onChange(filename, { dataframe: scope?.Sheets[0].Text });
      }
    }
    if (e.currentTarget.value !== null && e.target.name === 'header') {
      csvHeader({ Text: payloadData?.Sheets[state.iValue].Text }, e.currentTarget.value)
        .then((payload: any) => {
          if (payload.message === 'Header Changed') {
            setDisplays({
              message: '',
              displayHandleSetRowsColumns: true,
            });
            if (JSON.parse(payload.output.Text.length) > 2) {
              handleSetRowsColumns(JSON.parse(payload.output.Text));
            } else {
              handleSetRowsColumns(JSON.parse('[""]'));
            }
            // handleSetRowsColumns(JSON.parse(payload.output.Text));
          } else if (payload.message !== 'Header Changed') {
            // console.log(payload.message);
            setDisplays({
              message: payload.message,
              displayHandleSetRowsColumns: false,
            });
          } else {
            // console.log(payload.message);
            setDisplays({
              message: payload.message,
              displayHandleSetRowsColumns: false,
            });
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    }
  }

  const handleChangeDisplayColumns = (e: React.ChangeEvent<{ value: unknown }>) => {
    const { options } = e.target as HTMLSelectElement;
    const value: string[] = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setColumnNamesCapture(value);
  };

  function getIncoseCSVFile() {
    if (inputValue === '') {
      setSnack('Enter activity name', 'error');
    } else if (targetNameValue === '') {
      setSnack('Enter target column', 'error');
    } else if (reqColumnValue === '') {
      setSnack('Enter Requirement column', 'error');
    } else {
      setDisplayGrid(false);
      setLoadingSecond(true);
      const columnValue = [];
      if (columnNamesCapture.length === 0) {
        columnValue.push(...col.Columns);
      } else if (columnNamesCapture.includes(targetNameValue)) {
        columnValue.push(...columnNamesCapture);
      } else {
        columnNamesCapture.push(targetNameValue);
        columnValue.push(...columnNamesCapture);
      }
      const Rules: any = [];
      const userInputData: any = {};
      if (selectedRules.length === 0 || selectedRules.length === allRules.length) {
        Rules.push('*');
        if (acronymsResp !== undefined) {
          const arr: any[] = [];
          const obj = {};
          acronymsResp.map((r) => {
            obj[r?.value] = r?.desc;
            return obj;
          });
          userInputData.R38 = obj;
          wordGroupsResp.map((r) => {
            arr.push(r?.value);
            return arr;
          });
          userInputData.R36 = arr;
        } else {
          userInputData.R36 = [];
          userInputData.R38 = [];
        }
      } else {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < selectedRules.length; index++) {
          const rules_arr = selectedRules.map((obj) => obj.value);
          Rules.push(selectedRules[index].value);

          if (selectedRules[index]?.value === 'R38') {
            const obj = {};
            acronymsResp.map((r) => {
              obj[r?.value] = r?.desc;
              return obj;
            });
            userInputData.R38 = obj;
            if (!rules_arr.includes('R36')) {
              userInputData.R36 = [];
            }
          }

          if (selectedRules[index]?.value === 'R36') {
            const arr: any[] = [];
            wordGroupsResp.map((r) => {
              arr.push(r?.value);
              return arr;
            });
            userInputData.R36 = arr;
            if (!rules_arr.includes('R38')) {
              userInputData.R38 = [];
            }
          }
        }
      }
      let body: any;
      if (fileType === 'CSV') {
        body = {
          container: files[selection[0]].container || '',
          fileBlobName: files[selection[0]].filename || '',
          targetColumn: selectedColumnName,
          userRules: Rules,
        };
      }
      const temp: any = [];
      temp.push(body);
      const rpay = temp.map((d) => ({
        ...d,
        NameofCapture: inputValue,
        Filename: files[selection[0]].filename || '',
        llm: checkme,
        TargetColumn: targetNameValue,
      }));
      iCaptureCsv({
        FileName: capturePayloadNew.FileName,
        FilePath: capturePayloadNew.FilePath,
        Container: capturePayloadNew.Container,
        BlobName: capturePayloadNew.BlobName,
        FileType: 'csv',
        TargetColumn: targetNameValue,
        ColumnNames: columnValue,
        Header: true,
        NameOfCapture: inputValue,
      })
        .then((payload: any) => {
          // console.log('payload', payload);
          if (payload?.message === 'Non Extractable') {
            setSnack('File is Non-Extractable. Please select another file', 'error');
            setLoadingSecond(false);
            return;
          }
          const r = rpay;
          const rr: any = [];
          rr.push(r);
          // console.log('req', reqColumnValue);
          const new_obj = {
            ...payload,
            userRules: Rules,
            userInputData,
            reqColumn: reqColumnValue,
            fileName: capturePayloadNew.FileName,
            llm_usage: checkme,
          };
          // console.log('new obj', new_obj);
          workloadCreate({
            project_id: projectId,
            app: 'module3',
            action: 'iQualimeter',
            resources: r,
          }).then((workloadNew: Workload3Create) => {
            // console.log(workloadNew);
            workloadRunPost(Number(workloadNew.id), new_obj, 'm3-iQulimeter')
              .then(() => {
                window.history.pushState('', '', `/projects/${projectId}`);
                history.push(`/projects/${projectId}`);
              })
              .catch(() => {
                setSnack('Error while workload post.', 'error');
                setDisplay(false);
                setLoading(false);
              })
              .finally(() => {});
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    }
  }
  function getIncoseFile() {
    if (sheetName === '') {
      setSnack('Select sheet nmae', 'error');
    } else if (inputValue === '') {
      setSnack('Enter activity name', 'error');
    } else if (targetNameValue === '') {
      setSnack('Enter target column', 'error');
    } else if (reqColumnValue === '') {
      setSnack('Enter Requirement column', 'error');
    } else {
      setDisplayGrid(false);
      setLoadingSecond(true);
      const Rules: any = [];
      const userInputData: any = {};
      if (selectedRules.length === 0 || selectedRules.length === allRules.length) {
        Rules.push('*');
        if (acronymsResp !== undefined) {
          const arr: any[] = [];
          const obj = {};
          acronymsResp.map((r) => {
            obj[r?.value] = r?.desc;
            return obj;
          });
          userInputData.R38 = obj;
          wordGroupsResp.map((r) => {
            arr.push(r?.value);
            return arr;
          });
          userInputData.R36 = arr;
        } else {
          userInputData.R36 = [];
          userInputData.R38 = [];
        }
      } else {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < selectedRules.length; index++) {
          const rules_arr = selectedRules.map((obj) => obj.value);
          Rules.push(selectedRules[index].value);

          if (selectedRules[index]?.value === 'R38') {
            const obj = {};
            acronymsResp.map((r) => {
              obj[r?.value] = r?.desc;
              return obj;
            });
            userInputData.R38 = obj;
            if (!rules_arr.includes('R36')) {
              userInputData.R36 = [];
            }
          }

          if (selectedRules[index]?.value === 'R36') {
            const arr: any[] = [];
            wordGroupsResp.map((r) => {
              arr.push(r?.value);
              return arr;
            });
            userInputData.R36 = arr;
            if (!rules_arr.includes('R38')) {
              userInputData.R38 = [];
            }
          }
        }
      }

      const columnValue = [];
      if (columnNamesCapture.length === 0) {
        columnValue.push(...state.Columns);
      } else if (columnNamesCapture.includes(targetNameValue)) {
        columnValue.push(...columnNamesCapture);
      } else {
        columnNamesCapture.push(targetNameValue);
        columnValue.push(...columnNamesCapture);
      }

      let body: any;
      if (fileType === 'Excel') {
        body = {
          container: files[selection[0]].container || '',
          fileBlobName: files[selection[0]].filename || '',
          targetColumn: selectedColumnName,
          sheetName: selectedSheetName,
          userRules: Rules,
        };
      }
      const temp: any = [];
      temp.push(body);
      const rpay = temp.map((d) => ({
        ...d,
        NameofCapture: inputValue,
        Filename: files[selection[0]].filename || '',
        llm: checkme,
        TargetColumn: targetNameValue,
      }));
      iCaptureExcel({
        FileName: capturePayloadNew.FileName,
        FilePath: capturePayloadNew.FilePath,
        Container: capturePayloadNew.Container,
        BlobName: capturePayloadNew.BlobName,
        FileType: capturePayloadNew.FileType,
        Sheet: sheetName,
        TargetColumn: targetNameValue,
        ColumnNames: columnValue,
        Header: !checked,
        NameOfCapture: inputValue,
      })
        .then((payload: any) => {
          // console.log('payloadExcel:', payload);
          if (payload?.message === 'Non-Extractable') {
            setSnack('File is Non-Extractable. Please select another file', 'error');
            setLoadingSecond(false);
            return;
          }
          const r = rpay;
          const rr: any = [];
          rr.push(r);
          const new_obj = {
            ...payload,
            userInputData,
            userRules: Rules,
            reqColumn: reqColumnValue,
            fileName: capturePayloadNew.FileName,
            llm_usage: checkme,
          };
          workloadCreate({
            project_id: projectId,
            app: 'module3',
            action: 'iQualimeter',
            resources: r,
          }).then((workloadNew: Workload3Create) => {
            // console.log(workloadNew);
            workloadRunPost(Number(workloadNew.id), new_obj, 'm3-iQulimeter')
              .then(() => {
                window.history.pushState('', '', `/projects/${projectId}`);
                history.push(`/projects/${projectId}`);
              })
              .catch(() => {
                setSnack('Error while workload post.', 'error');
                setDisplay(false);
                setLoading(false);
              })
              .finally(() => {});
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    }
  }
  function getFileDetails(filename, filenameCopy, container) {
    // console.log(projectNames);
    setLoading(true);
    setDisplay(false);
    setDisplayGrid(false);
    setGraphDataDisplay(false);
    setErrorMsg('');
    setState({});
    setPayloadData({});
    setCapturePayloadNew({});
    fileDetails(filename, filenameCopy, container)
      .then((payload: any) => {
        if (payload) {
          setErrorMsg(payload.error);
          if (payload.error === null) {
            if (payload.output.FileType === 'Excel') {
              setPayloadData(payload.output);
              // eslint-disable-next-line no-plusplus
              for (let i = 0; i < payload?.output.Sheets.length; i++) {
                SetSheetName(payload?.output.Sheets[i].SheetName);
                setNumberOfRecords(payload?.output.Sheets[i].NumberOfRecords);
                setState({
                  numberOfColumns: payload?.output.Sheets[i].NumberOfColumns,
                  numberOfRows: payload?.output.Sheets[i].NumberOfRecords,
                  Language: payload.output.Sheets[i].Language || '-',
                  targetColumn: payload.output.Sheets[i].ColumNames,
                  Columns: payload.output.Sheets[i].ColumNames,
                  iValue: String(i),
                });
                if (JSON.parse(payload.output.Sheets[i].Text.length) > 2) {
                  handleSetRowsColumns(JSON.parse(payload.output?.Sheets[i].Text));
                } else {
                  handleSetRowsColumns(JSON.parse('[""]'));
                }
              }
              setSheetNames(payload.output.Sheet_names);
              setSheets(payload.output.Sheets);
              setFileType('Excel');
              const capturePayload = {
                FileName: payload.output.FileName,
                FilePath: payload.output.FilePath,
                Container: payload.output.Container,
                FileType: 'excel',
                BlobName: payload.output.Blob,
              };
              setCapturePayloadNew(capturePayload);
            }
            if (payload.output.FileType === 'CSV') {
              setPayloadData(payload.output);
              setNumberOfRecords(payload.output.NumberOfRecords);
              setState({
                numberOfColumns: payload.output.NumberOfColumns,
                numberOfRows: payload.output.NumberOfRecords,
                Language: payload.output.Language,
                targetColumn: payload.output.ColumnNames,
                Columns: payload.output.ColumnNames,
              });
              const capturePayload = {
                FileName: payload.output.FileName,
                FilePath: payload.output.FilePath,
                Container: payload.output.Container,
                FileType: 'csv',
                BlobName: payload.output.Blob,
              };
              setCapturePayloadNew(capturePayload);
              setCol({ Columns: payload.output.ColumnNames });
              handleSetRowsColumns(JSON.parse(payload.output.Text));
              setColumnName(payload.output.ColumnNames);
              setFileType('CSV');
              setCsvDataframe(payload.output.Text);
            }
            if (payload.output.FileType === 'PDF') {
              setErrorMsg('PDF not allowed. Select only CSV and Excel');
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        setDisplay(true);
      });
  }

  const onChangeAttribute = (selection: any) => {
    setGraphDataDisplay(false);
    setCheckme(false);
    setChecked(false);

    const newselection: any = [0];
    if (selection[0] === undefined) {
      // eslint-disable-next-line no-param-reassign
      selection[0] = selection.slice(-1).pop();
    }
    // eslint-disable-next-line prefer-destructuring
    newselection[0] = selection.slice(-1).pop();
    setSelection(newselection);
    // console.log(selection);
    if (selection.length < 1 || selection.slice(-1).pop() === undefined) {
      setDisplay(false);
      // console.log('if condition no action required: ', selection);
    } else {
      // console.log('else', selection.slice(-1).pop());
      const fileno = selection.slice(-1).pop();
      // console.log(fileno);
      getFileDetails(files[fileno].filename, files[fileno].filename, files[fileno].container);
    }

    // console.log(files[selection[0]]);
  };

  function HandleNoHeader(e: any) {
    setChecked(e.target.checked);
    setLoader(true);
    setHeaderName(false);

    setTimeout(() => {
      // console.log(e.target.name);
      if (e.target.checked === true && e.target.name === 'headerName') {
        setHeaderName(true);
        // console.log(scope?.Sheets);
        // console.log('sheetname: ', sheetName);
        let sheetIvalue: any;
        if (sheetName === '') {
          sheetIvalue = 0;
        } else {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < payloadData?.Sheets.length; i++) {
            if (payloadData?.Sheets[i]?.SheetName === sheetName) {
              sheetIvalue = i;
            }
          }
        }
        // console.log('sheet no', sheetIvalue);
        const col = [];
        // eslint-disable-next-line no-plusplus
        for (
          let index = 0;
          index < payloadData?.Sheets[sheetIvalue].ColumnNamesNoHeader.length;
          // eslint-disable-next-line no-plusplus
          index++
        ) {
          const chr = String.fromCharCode(65 + index);
          col.push(chr);
        }
        const values = payloadData?.Sheets[sheetIvalue].ColumnNamesNoHeader;
        const keys = col;
        // create key-value pair from two different arrays
        const myobj = {};
        // eslint-disable-next-line func-names
        const output = keys.map(function (obj, index) {
          myobj[keys[index]] = values[index];
          return myobj;
        });
        // console.log(col);
        // console.log(values);
        const jsonString = JSON.stringify([output[0]]);
        // handleSetRowsColumns(JSON.parse(jsonString));
        let sheetContent = JSON.parse(payloadData?.Sheets[sheetIvalue].TextNoHeader);
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < col.length; index++) {
          sheetContent = changeKey(values[index], col[index], sheetContent);
        }
        const jsonString1 = JSON.stringify(sheetContent);
        // console.log(jsonString1);
        const combineJsonArray = JSON.stringify([
          ...JSON.parse(jsonString),
          ...JSON.parse(jsonString1),
        ]);
        // console.log(combineJsonArray);
        handleSetRowsColumns(JSON.parse(combineJsonArray));
        setState({
          numberOfColumns: payloadData?.Sheets[sheetIvalue].NumberOfColumns,
          numberOfRows: payloadData?.Sheets[sheetIvalue].NumberOfRecords,
          Language: payloadData?.Sheets[sheetIvalue].Language || '-',
          targetColumn: col,
          Columns: col,
          iValue: String(sheetIvalue),
        });
        setRows(JSON.parse(payloadData?.Sheets[sheetIvalue].TextNoHeader));
      } else {
        setState({
          numberOfColumns: payloadData?.Sheets[state.iValue].NumberOfColumns,
          numberOfRows: payloadData?.Sheets[state.iValue].NumberOfRecords,
          Language: payloadData?.Sheets[state.iValue].Language || '-',
          targetColumn: payloadData?.Sheets[state.iValue].ColumNames,
          Columns: payloadData?.Sheets[state.iValue].ColumNames,
          iValue: String(state.iValue),
        });
        setRows(JSON.parse(payloadData?.Sheets[state.iValue].Text));
        handleSetRowsColumns(JSON.parse(payloadData?.Sheets[state.iValue].Text));
      }
      setLoader(false);
      setSnack('Select target name', 'error');
    }, 2000);
  }
  const HandleNoHeaderCSV = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    setLoader(true);
    setTimeout(() => {
      if (event.target.checked === true) {
        const col = [];
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < payloadData?.ColumnNamesNoHeader.length; index++) {
          const chr = String.fromCharCode(65 + index);
          col.push(chr);
        }
        const values = payloadData?.ColumnNamesNoHeader;
        const keys = col;

        setCol({ Columns: col });

        // create key-value pair from two different arrays
        const myobj = {};
        // eslint-disable-next-line func-names
        const output = keys.map(function (obj, index) {
          myobj[keys[index]] = values[index];
          return myobj;
        });
        // console.log(col);
        // console.log(values);
        const jsonString = JSON.stringify([output[0]]);
        // console.log(jsonString);
        // handleSetRowsColumns(JSON.parse(jsonString));
        let x = JSON.parse(payloadData?.TextNoHeader);
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < col.length; index++) {
          x = changeKey(values[index], col[index], x);
        }
        const jsonString1 = JSON.stringify(x);
        // console.log(jsonString1);
        const combineJsonArray = JSON.stringify([
          ...JSON.parse(jsonString),
          ...JSON.parse(jsonString1),
        ]);
        // console.log(combineJsonArray);
        handleSetRowsColumns(JSON.parse(combineJsonArray));
        setRows(JSON.parse(payloadData?.TextNoHeader));
      } else {
        setCol({ Columns: payloadData?.ColumnNames });
        handleSetRowsColumns(JSON.parse(payloadData.Text));
        setRows(JSON.parse(payloadData?.Text));
      }
      setLoader(false);
      setSnack('Select target name', 'error');
    }, 2000);
  };
  useEffect(() => {
    if (pageSize > files.length) {
      if (dis) {
        setDis((prev) => {
          return !prev;
        });
      }
    } else if (pageSize < files.length) {
      setDis((prev) => {
        return !prev;
      });
    }
  }, [pageSize]);
  useEffect(() => {
    if (pageSize2 > rows.length) {
      if (dis) {
        setDis((prev) => {
          return !prev;
        });
      }
    } else if (pageSize2 < rows.length) {
      setDis((prev) => {
        return !prev;
      });
    }
  }, [pageSize2]);
  useEffect(() => {
    if (pageSize3 > rows.length) {
      if (dis) {
        setDis((prev) => {
          return !prev;
        });
      }
    } else if (pageSize3 < rows.length) {
      setDis((prev) => {
        return !prev;
      });
    }
  }, [pageSize3]);

  // console.log(selection);
  function putAPIFun(workloadId: any, newChangedRowOnly: any, texthere: any) {
    if (texthere === 'save button') {
      // console.log('newChangedRowOnly on click save : ', newChangedRowOnly);
      if (newChangedRowOnly.length === 0) {
        setSnack('Please edit row to save', 'warning');
        setSaveIconChange(false);
        return;
      }
      setSnack('Data Save going on...', 'info');
      workloadIQualimeterPut(workloadId, newChangedRowOnly)
        .then((output) => {
          // ultilized because its the table data array index not row data
          setChangedRowOnly([]);
          setSnack('Data Saved', 'success');
          setSaveIconChange(false);
          // console.log('output put api: ', output);
        })
        .catch((er) => {
          console.log(er);
        });
    }
    if (texthere === 'like dislike button') {
      workloadIQualimeterPut(workloadId, newChangedRowOnly)
        .then((output) => {
          // console.log('output put api: ', output);
        })
        .catch((er) => {
          console.log(er);
        });
    }
  }

  const handleAll = (e, row: any, column: Column, stat: any) => {
    const rowsEditedNew = rows.map((r) => {
      if (r.Artifact_Text === row.Artifact_Text) {
        const newChangedRowOnly: any = [];
        // eslint-disable-next-line array-callback-return
        payloadOri?.result_output?.map((k, i) => {
          // eslint-disable-next-line eqeqeq
          if (r?.Artifact_Text == k?.raw_data?.Artifact_Text) {
            newChangedRowOnly.push({
              id: k?.id,
              data: r,
            });
          }
        });
        newChangedRowOnly[0].data.status = stat;
        // console.log(newChangedRowOnly);
        putAPIFun(workloadId, newChangedRowOnly, 'like dislike button');
        return {
          ...r,
          [column.name]: stat,
        };
      }
      return r;
    });
    setRows(rowsEditedNew);
  };

  const statusCell = ({ value, row, column }: DataTypeProvider.ValueFormatterProps) => {
    if (value === 'Y') {
      return (
        <span>
          <ThumbUpAltIcon
            style={{ color: '#4682B4' }}
            onClick={(e) => handleAll(e, row, column, '')}
          />
          <ThumbDownAltOutlinedIcon
            color="disabled"
            onClick={(e) => handleAll(e, row, column, 'N')}
          />
        </span>
      );
    }
    if (value === 'N') {
      return (
        <span>
          <ThumbUpAltOutlinedIcon
            color="disabled"
            onClick={(e) => handleAll(e, row, column, 'Y')}
          />
          <ThumbDownAltIcon
            style={{ color: '#FF7276' }}
            onClick={(e) => handleAll(e, row, column, '')}
          />
        </span>
      );
    }
    return (
      <span>
        <ThumbUpAltOutlinedIcon color="disabled" onClick={(e) => handleAll(e, row, column, 'Y')} />
        <ThumbDownAltOutlinedIcon
          color="disabled"
          onClick={(e) => handleAll(e, row, column, 'N')}
        />
      </span>
    );
  };
  const tableHeaderComponents: React.FunctionComponent<Table.RowProps> = (props) => {
    return (
      <Table.TableHead
        {...props}
        style={{
          position: 'unset',
          top: 0,
          zIndex: 400,
        }}
      />
    );
  };

  const handleChange = () => {
    setCheckme(!checkme);
  };

  // def seconds_to_hm_format(number_of_record):
  //   total_seconds = int(number_of_record*0.75)
  //   hours = total_seconds // 3600
  //   minutes = (total_seconds % 3600) // 60
  //   seconds = total_seconds % 60
  //   return f"{hours}:{minutes:02}:{seconds:02}"

  const calculateInterval = (number_of_record) => {
    const totalSeconds = Math.floor(number_of_record * 0.75);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours}hr:${minutes.toString().padStart(2, '0')}min:${seconds
      .toString()
      .padStart(2, '0')}sec`;
  };

  return (
    <>
      <div>
        <br />
        <span>
          <b>Select one file:</b>
        </span>
        <span>
          <br />
          {/* Total rows selected: {selection.length} */}
          <div style={{ display: 'flex' }}>
            <span style={{ marginRight: '10px', flexBasis: '50%' }}>
              Selected File Name:<b> {fileIs1 || 'NA'}</b>
            </span>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                flexBasis: '50%',
              }}
            >
              <UploadFile files={files} setFiles={setFiles} projectId={projectId} />
            </div>
          </div>
        </span>
      </div>
      <Paper className={tableUseStyles.panelMain}>
        <div style={{ backgroundColor: '#EEF1F9', border: '1px solid #002A45' }}>
          <ExpressGrid rows={files} columns={Columns}>
            <SelectionState selection={selection} onSelectionChange={onChangeAttribute} />
            <FilteringState defaultFilters={[{ columnName: 'filename', value: '' }]} />
            <IntegratedFiltering />
            <SortingState />
            <IntegratedSorting />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={setCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
            />
            {/* <PagingState defaultCurrentPage={0} defaultPageSize={5} /> */}
            <IntegratedPaging />
            <VirtualTable />
            <Table
              tableComponent={MultiAllocTableComp}
              containerComponent={tableAllocatorComponent}
              headComponent={tableHeaderComponent}
            />
            <TableColumnResizing
              defaultColumnWidths={[
                ...Columns.map((c) => {
                  if (c.name === 'folderName') {
                    return {
                      columnName: c.name,
                      width: '150px',
                      wordWrapEnabled: toggleHeight,
                    };
                  }
                  if (c.name === 'size') {
                    return {
                      columnName: c.name,
                      width: '150px',
                      wordWrapEnabled: toggleHeight,
                    };
                  }
                  return {
                    columnName: c.name,
                    width: '200px',
                  };
                }),
              ]}
            />
            <TableHeaderRow showSortingControls />
            <TableFilterRow rowComponent={rowComponent} />
            <PagingPanel
              pageSizes={pageSizes}
              containerComponent={(props) => (
                <>
                  <PagingPanel.Container {...props} className={classessNew.pagingPanelContainer} />
                </>
              )}
            />
            <TableSelection selectByRowClick highlightRow showSelectionColumn={true} />
          </ExpressGrid>
          <NavigationGotoNew
            setPageSize={setPageSize}
            fileLength={files?.length}
            pageSize={pageSize}
            dis={dis}
            setTemPage={setTemPage}
            handlePageNum={handlePageNum}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
        <span style={{ color: 'red' }}>{errorMsg}</span>
        {loading ? (
          <div style={{ width: '100%', textAlign: 'center' }}>
            <Loading />
          </div>
        ) : (
          ''
        )}
        {display && errorMsg === null && fileType === 'Excel' && (
          <div className={classes.root} style={{ marginTop: '0px' }}>
            <br />
            <Paper className={classes.paper1}>
              <Box
                sx={{
                  padding: 2,
                  // border: '1px solid #002A45',
                  borderRadius: '8px',
                  // maxWidth: '1000px',
                  margin: 'auto',
                  backgroundColor: '#f9f9f9',
                }}
              >
                <Grid container spacing={2}>
                  {/* Left Side */}
                  <Grid item xs={8} sx={{ borderRight: '1px solid #002A45', paddingRight: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <FormControl
                          fullWidth
                          margin="normal"
                          sx={{
                            '& .MuiInputLabel-root': {
                              color: '#002A45', // Label text color
                              fontWeight: 'bold',
                            },
                            '& .MuiInputLabel-root.Mui-focused': {
                              color: '#002A45', // Label text color when focused
                              fontWeight: 'bold',
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#002A45', // Default border color
                              },
                              '&:hover fieldset': {
                                borderColor: '#002A45', // Hover border color
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#002A45', // Focused border color
                              },
                            },
                          }}
                        >
                          <TextField
                            label="Activity Name"
                            required
                            variant="outlined"
                            name="nameOfCapture"
                            size="small"
                            // inputProps={{
                            //   maxlength: 20,
                            // }}
                            // sx={{
                            //   '& .MuiOutlinedInput-root': {
                            //     height: '50px', // Controls the outer container height
                            //     '& input': {
                            //       padding: '10px 14px', // Adjusts inner padding
                            //     },
                            //   },
                            // }}
                            // className={classessNew.customHeight}

                            // className={classes.textFieldSecond}
                            defaultValue=""
                            onChange={inputHandler}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={4}>
                        {' '}
                        <FormControl
                          fullWidth
                          margin="normal"
                          // style={{ flexBasis: '50%', height: '2.5rem' }}
                          sx={{
                            '& .MuiInputLabel-root': {
                              color: '#002A45', // Label text color
                              fontWeight: 'bold',
                            },
                            '& .MuiInputLabel-root.Mui-focused': {
                              color: '#002A45', // Label text color when focused
                              fontWeight: 'bold',
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#002A45', // Default border color
                              },
                              '&:hover fieldset': {
                                borderColor: '#002A45', // Hover border color
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#002A45', // Focused border color
                              },
                            },
                          }}
                        >
                          <InputLabel htmlFor="outlined-age-native-simple">Sheet Name</InputLabel>
                          <Select
                            native
                            label="Sheet Name"
                            name="sheetname"
                            size="small"
                            // style={{ width: 200 }}
                            onChange={HandleReg}
                            defaultValue=""
                          >
                            {/* <option aria-label="None" value="" /> */}
                            {sheetNames &&
                              sheetNames.map((cap: string, index: number) => (
                                <option key={`capture-type-${index}`} value={cap}>
                                  {cap}
                                </option>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={4}>
                        {' '}
                        <FormControl
                          fullWidth
                          margin="normal"
                          sx={{
                            '& .MuiInputLabel-root': {
                              color: '#002A45', // Label text color
                              fontWeight: 'bold',
                            },
                            '& .MuiInputLabel-root.Mui-focused': {
                              color: '#002A45', // Label text color when focused
                              fontWeight: 'bold',
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#002A45', // Default border color
                              },
                              '&:hover fieldset': {
                                borderColor: '#002A45', // Hover border color
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#002A45', // Focused border color
                              },
                            },
                          }}
                        >
                          <InputLabel htmlFor="outlined-age-native-simple">
                            Target Capture
                          </InputLabel>
                          <Select
                            native
                            label="Target Capture"
                            name="targetColumn"
                            size="small"
                            // style={{ width: 180 }}
                            onChange={HandleReg}
                            defaultValue="--select target column--"
                          >
                            {/* <option aria-label="None" value="" /> */}
                            <option aria-label="None" selected disabled>
                              --select target column--
                            </option>
                            {state.targetColumn.map((cap: string, index: number) => (
                              <option key={`capture-type-${index}`} value={cap}>
                                {cap}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <FormControl
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          // className={selClasses.formControlMulti}
                        >
                          <MultiSelect
                            options={allRules}
                            value={selectedRules}
                            onChange={onChangeRules}
                            labelledBy="Rules"
                            className={classes.multiSelectText}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={4}>
                        <FormControl
                          fullWidth
                          margin="normal"
                          sx={{
                            '& .MuiInputLabel-root': {
                              color: '#002A45', // Label text color
                              fontWeight: 'bold',
                            },
                            '& .MuiInputLabel-root.Mui-focused': {
                              color: '#002A45', // Label text color when focused
                              fontWeight: 'bold',
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#002A45', // Default border color
                              },
                              '&:hover fieldset': {
                                borderColor: '#002A45', // Hover border color
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#002A45', // Focused border color
                              },
                            },
                          }}
                        >
                          <InputLabel htmlFor="outlined-age-native-simple">Requirement</InputLabel>
                          <Select
                            native
                            label="requirement"
                            name="reqColumn"
                            style={{ width: '100%' }}
                            onChange={HandleReg}
                            size="small"
                            defaultValue="--select Requirement column--"
                          >
                            {/* <option aria-label="None" value="" /> */}
                            <option aria-label="None" selected disabled>
                              select Requirement column
                            </option>
                            <option value="Not Applicable">Not Applicable</option>
                            {state.targetColumn.map((cap: string, index: number) => (
                              <option key={`capture-type-${index}`} value={cap}>
                                {cap}
                              </option>
                            ))}
                          </Select>
                          {/* <span >
                          Select Column which contains Requirement text*
                        </span> */}
                        </FormControl>
                      </Grid>
                      <Grid item xs={4}>
                        {numberOfRecords < 5000 ? (
                          <div>
                            <CustomTooltip
                              title={`The file contains ${numberOfRecords} requirements and will take approximately ${calculateInterval(
                                numberOfRecords
                              )} to process using the Large Language Model (LLM)`}
                              arrow
                              classes={{ tooltip: classessNew.tooltip }}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={checkme}
                                    onChange={handleChange}
                                    name="checkedB"
                                    color="primary"
                                  />
                                }
                                style={{
                                  fontSize: '1.5rem',
                                  color: '#002A45',
                                  fontWeight: 'bold',
                                }}
                                label="Use Advanced iQualimeter (LLM)"
                              />
                            </CustomTooltip>
                            <div style={{ marginLeft: '2rem' }}>
                              <Typography
                                style={{
                                  fontSize: '0.8rem',
                                  color: '#002A45',
                                  fontWeight: 'bold',
                                }}
                              >
                                The LLM applies only to rules 7,8,9
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: '0.8rem',
                                  color: '#002A45',
                                  fontWeight: 'bold',
                                }}
                              >
                                All the {`requirement's`} will be Automatically Rephrased
                              </Typography>
                            </div>
                          </div>
                        ) : (
                          <>
                            {' '}
                            <Typography style={{ fontSize: '0.8rem' }}>
                              Please contact oleksandr.snihura@alstomgroup.com for requirements
                              exceeding 5000.
                            </Typography>
                          </>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        {}
                      </Grid>
                      <Grid item xs={4}>
                        {}
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                          type="button"
                          disabled={selectedSheetName === '' || selectedColumnName === ''}
                          variant="contained"
                          color="primary"
                          onClick={getIncoseFile}
                          endIcon={
                            loadingSecond && <CircularProgress size={21} color="secondary" />
                          }
                          style={{
                            // marginTop: '5px',
                            height: '50px',
                            width: '150px',
                            float: 'right',
                            backgroundColor: '#002A45',
                            // marginBottom: '4px',
                            // marginRight: '30px',
                          }}
                        >
                          Analyze
                        </Button>
                      </Grid>
                    </Grid>

                    {/* <FormControlLabel
                    control={
                      <Checkbox checked={useLLM} onChange={(e) => setUseLLM(e.target.checked)} />
                    }
                    label="Use Advanced iQualimeter (LLM)"
                  /> */}
                  </Grid>
                  {/* Right Side */}
                  <Grid item xs={4} sx={{ paddingLeft: 2 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={HandleNoHeader}
                          name="headerName"
                          color="primary"
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                      }
                      label="No Header"
                      sx={{ marginBottom: 2 }}
                    />
                    {/* <form className={classes.root} noValidate autoComplete="off">
                      <Checkbox
                        checked={checked}
                        onChange={HandleNoHeader}
                        name="headerName"
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        style={{ width: '50px', marginLeft: '50px' }}
                      />
                      <span style={{ marginLeft: '-20px' }}>No Header</span>
                    </form> */}
                    <form className={classes.root} noValidate autoComplete="off">
                      <FormControl variant="standard" className={classes.formControl1}>
                        {/* <InputLabel
                          htmlFor="outlined-age-native-simple"
                          style={{ color: '#002A45', fontWeight: 'bold' }}
                        >
                          Display
                        </InputLabel> */}
                        <span>Display</span>
                        <Select
                          native
                          multiple
                          label="Display"
                          name="Columns"
                          style={{ width: 180 }}
                          onChange={handleChangeDisplayColumns}
                          // onChange={handleChangeMultiple}
                          defaultValue=""
                        >
                          <option aria-label="None" value="" selected disabled />
                          {state.Columns.map((cap: string, index: number) => (
                            <option
                              style={{ color: '#002A45' }}
                              type="checkbox"
                              key={`capture-type-${index}`}
                              value={cap}
                            >
                              {cap}
                            </option>
                          ))}
                        </Select>
                        <span>(Use Ctrl Key for multiple select)</span>
                      </FormControl>
                    </form>
                  </Grid>
                </Grid>
              </Box>
            </Paper>

            <Paper className={classes.paper2}>
              <Grid style={{ border: '1px solid', borderRadius: 5 }}>
                <span style={{ color: 'red' }}>&nbsp;&nbsp;{display.message}</span>
                <div
                  style={{
                    padding: '0px',
                    border: '1px',
                    backgroundColor: '#EEF1F9',
                    border: '1px solid #002A45',
                  }}
                >
                  <ExpressGrid
                    rows={rows}
                    // columns={columns.map((item, index) => {
                    //   if (
                    //     ['Rephrased_Text', 'Rephrased_warning', 'Rephrased_score'].includes(
                    //       item.name
                    //     )
                    //   ) {
                    //     columns.splice(index, 1);
                    //   }
                    //   return item;
                    // })}
                    columns={columns}
                  >
                    <DataTypeProvider
                      for={state.Columns}
                      formatterComponent={({ value }) => (
                        <CustomWidthTooltip
                          placement="bottom-start"
                          // arrow
                          // style={{
                          //   whiteSpace: `${newLine}`,
                          //   fontWeight: 'bold',
                          //   color: '#002A45',
                          //   overflowY: newLine === 'pre-line' ? 'scroll' : 'hidden',
                          //   maxHeight: '15vh',
                          //   padding: 5,
                          // }}
                          // open={true}
                          title={
                            <>
                              <Card
                                style={{
                                  background: 'while',
                                  color: '#002A45',
                                  fontWeight: 'bold',
                                  margin: 0,
                                  padding: 5,
                                  // overflowY: 'scroll',
                                  // maxHeight: '25vh',
                                  // padding: 5,
                                }}
                                // className={tClasses.customScrollbar}
                              >
                                {value}
                              </Card>
                            </>
                          }
                        >
                          <span
                            title={value}
                            style={{
                              whiteSpace: `${newLine}`,
                              fontWeight: 'bold',
                              color: '#002A45',
                            }}
                          >
                            {value}
                          </span>
                        </CustomWidthTooltip>
                      )}
                    />
                    <SortingState />
                    <IntegratedSorting />
                    {/* <PagingState defaultCurrentPage={0} defaultPageSize={5} /> */}
                    <PagingState
                      currentPage={currentPage2}
                      onCurrentPageChange={setCurrentPage2}
                      pageSize={pageSize2}
                      onPageSizeChange={setPageSize2}
                    />
                    <IntegratedPaging />
                    <Table
                      tableComponent={MultiAllocTableComp}
                      containerComponent={tableAllocatorComponent}
                      headComponent={tableHeaderComponents}
                    />
                    {/* <TableColumnResizing
                      onColumnWidthsChange={setColumnWidths}
                      columnWidths={columnWidths}
                      resizingMode="nextColumn"
                    /> */}
                    <TableHeaderRow showSortingControls />
                    <PagingPanel
                      pageSizes={pageSizes}
                      containerComponent={(props) => (
                        <>
                          <PagingPanel.Container
                            {...props}
                            className={classessNew.pagingPanelContainer}
                          />
                        </>
                      )}
                    />
                  </ExpressGrid>
                  <NavigationGotoNew
                    setPageSize={setPageSize2}
                    fileLength={rows?.length}
                    pageSize={pageSize2}
                    dis={dis}
                    setTemPage={setTemPage}
                    handlePageNum={handlePageNum}
                    currentPage={currentPage2}
                    setCurrentPage={setCurrentPage2}
                  />
                </div>
              </Grid>
            </Paper>
          </div>
        )}
        {display && errorMsg === null && fileType === 'CSV' && (
          <div className={classes.root} style={{ marginTop: '0px' }}>
            <Paper className={classes.paper1}>
              <Box
                sx={{
                  padding: 2,
                  // border: '1px solid #002A45',
                  borderRadius: '8px',
                  // maxWidth: '1000px',
                  margin: 'auto',
                  backgroundColor: '#f9f9f9',
                }}
              >
                <Grid container spacing={2}>
                  {/* Left Side */}
                  <Grid item xs={8} sx={{ borderRight: '1px solid #002A45', paddingRight: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <FormControl
                          fullWidth
                          margin="normal"
                          sx={{
                            '& .MuiInputLabel-root': {
                              color: '#002A45', // Label text color
                              fontWeight: 'bold',
                            },
                            '& .MuiInputLabel-root.Mui-focused': {
                              color: '#002A45', // Label text color when focused
                              fontWeight: 'bold',
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#002A45', // Default border color
                              },
                              '&:hover fieldset': {
                                borderColor: '#002A45', // Hover border color
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#002A45', // Focused border color
                              },
                            },
                          }}
                        >
                          <TextField
                            label="Activity Name"
                            required
                            variant="outlined"
                            name="nameOfCapture"
                            size="small"
                            inputProps={{
                              maxlength: 20,
                            }}
                            defaultValue=""
                            onChange={inputHandler}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={4}>
                        {' '}
                        <FormControl
                          fullWidth
                          margin="normal"
                          sx={{
                            '& .MuiInputLabel-root': {
                              color: '#002A45', // Label text color
                              fontWeight: 'bold',
                            },
                            '& .MuiInputLabel-root.Mui-focused': {
                              color: '#002A45', // Label text color when focused
                              fontWeight: 'bold',
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#002A45', // Default border color
                              },
                              '&:hover fieldset': {
                                borderColor: '#002A45', // Hover border color
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#002A45', // Focused border color
                              },
                            },
                          }}
                        >
                          <InputLabel htmlFor="outlined-age-native-simple">
                            Target Capture
                          </InputLabel>
                          <Select
                            native
                            label="Target Capture"
                            name="targetColumn"
                            size="small"
                            // style={{ width: 180 }}
                            onChange={HandleRegCsv}
                            defaultValue="--select target column--"
                          >
                            {/* <option aria-label="None" value="" /> */}
                            <option aria-label="None" selected disabled>
                              --select target column--
                            </option>
                            {col.Columns.map((cap: string, index: number) => (
                              <option key={`capture-type-${index}`} value={cap}>
                                {cap}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={4}>
                        {' '}
                        <FormControl
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          // className={selClasses.formControlMulti}
                        >
                          <MultiSelect
                            options={allRules}
                            value={selectedRules}
                            onChange={onChangeRules}
                            labelledBy="Rules"
                            className={classes.multiSelectText}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        {/* <FormControl
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          // className={selClasses.formControlMulti}
                        >
                          <MultiSelect
                            options={allRules}
                            value={selectedRules}
                            onChange={onChangeRules}
                            labelledBy="Rules"
                            className={classes.multiSelectText}
                          />
                        </FormControl> */}
                        <FormControl
                          fullWidth
                          margin="normal"
                          sx={{
                            '& .MuiInputLabel-root': {
                              color: '#002A45', // Label text color
                              fontWeight: 'bold',
                            },
                            '& .MuiInputLabel-root.Mui-focused': {
                              color: '#002A45', // Label text color when focused
                              fontWeight: 'bold',
                            },
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#002A45', // Default border color
                              },
                              '&:hover fieldset': {
                                borderColor: '#002A45', // Hover border color
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#002A45', // Focused border color
                              },
                            },
                          }}
                        >
                          <InputLabel htmlFor="outlined-age-native-simple">Requirement</InputLabel>
                          <Select
                            native
                            label="requirement"
                            name="reqColumn"
                            style={{ width: '100%' }}
                            onChange={HandleRegCsv}
                            size="small"
                            defaultValue="--select Requirement column--"
                          >
                            {/* <option aria-label="None" value="" /> */}
                            <option aria-label="None" selected disabled>
                              select Requirement column
                            </option>
                            <option value="Not Applicable">Not Applicable</option>
                            {col.Columns.map((cap: string, index: number) => (
                              <option key={`capture-type-${index}`} value={cap}>
                                {cap}
                              </option>
                            ))}
                          </Select>
                          {/* <span >
                          Select Column which contains Requirement text*
                        </span> */}
                        </FormControl>
                      </Grid>
                      <Grid item xs={4}>
                        {numberOfRecords < 5000 ? (
                          <div>
                            <CustomTooltip
                              title={`The file contains ${numberOfRecords} requirements and will take approximately ${calculateInterval(
                                numberOfRecords
                              )} to process using the Large Language Model (LLM)`}
                              // arrow
                              classes={{ tooltip: classessNew.tooltip }}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={checkme}
                                    onChange={handleChange}
                                    name="checkedB"
                                    color="primary"
                                  />
                                }
                                style={{
                                  fontSize: '1.5rem',
                                  color: '#002A45',
                                  fontWeight: 'bold',
                                }}
                                label="Use Advanced iQualimeter (LLM)"
                              />
                            </CustomTooltip>
                            <div style={{ marginLeft: '2rem' }}>
                              <Typography
                                style={{
                                  fontSize: '0.8rem',
                                  color: '#002A45',
                                  fontWeight: 'bold',
                                }}
                              >
                                The LLM applies only to rules 7,8,9
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: '0.8rem',
                                  color: '#002A45',
                                  fontWeight: 'bold',
                                }}
                              >
                                All the {`requirement's`} will be Automatically Rephrased
                              </Typography>
                            </div>
                          </div>
                        ) : (
                          <>
                            {' '}
                            <Typography style={{ fontSize: '0.8rem' }}>
                              Please contact oleksandr.snihura@alstomgroup.com for requirements
                              exceeding 5000.
                            </Typography>
                          </>
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                          type="button"
                          disabled={selectedSheetName === '' || selectedColumnName === ''}
                          variant="contained"
                          color="primary"
                          onClick={getIncoseCSVFile}
                          endIcon={
                            loadingSecond && <CircularProgress size={21} color="secondary" />
                          }
                          style={{
                            // marginTop: '5px',
                            height: '50px',
                            width: '150px',
                            float: 'right',
                            backgroundColor: '#002A45',
                            // marginBottom: '4px',
                            // marginRight: '30px',
                          }}
                        >
                          Analyze
                        </Button>
                      </Grid>
                    </Grid>
                    {/* <Grid container spacing={2}>
                      <Grid item xs={4}>
                        {}
                      </Grid>
                      <Grid item xs={4}>
                        {}
                      </Grid>
                      <Grid item xs={4}>
                        
                      </Grid>
                    </Grid> */}

                    {/* <FormControlLabel
                    control={
                      <Checkbox checked={useLLM} onChange={(e) => setUseLLM(e.target.checked)} />
                    }
                    label="Use Advanced iQualimeter (LLM)"
                  /> */}
                  </Grid>
                  {/* Right Side */}
                  <Grid item xs={4} sx={{ paddingLeft: 2 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={HandleNoHeaderCSV}
                          name="headerName"
                          color="primary"
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                      }
                      label="No Header"
                      sx={{ marginBottom: 2 }}
                    />
                    {/* <form className={classes.root} noValidate autoComplete="off">
                      <Checkbox
                        checked={checked}
                        onChange={HandleNoHeader}
                        name="headerName"
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        style={{ width: '50px', marginLeft: '50px' }}
                      />
                      <span style={{ marginLeft: '-20px' }}>No Header</span>
                    </form> */}
                    <form className={classes.root} noValidate autoComplete="off">
                      <FormControl variant="standard" className={classes.formControl1}>
                        {/* <InputLabel
                          htmlFor="outlined-age-native-simple"
                          style={{ color: '#002A45', fontWeight: 'bold' }}
                        >
                          Display
                        </InputLabel> */}
                        <span>Display</span>

                        <Select
                          native
                          multiple
                          label="Display"
                          name="Columns"
                          style={{ width: 180 }}
                          onChange={handleChangeDisplayColumns}
                          // onChange={handleChangeMultiple}
                          defaultValue=""
                        >
                          <option aria-label="None" value="" selected disabled />
                          {col.Columns.map((cap: string, index: number) => (
                            <option
                              style={{ color: '#002A45' }}
                              type="checkbox"
                              key={`capture-type-${index}`}
                              value={cap}
                            >
                              {cap}
                            </option>
                          ))}
                        </Select>
                        <span>(Use Ctrl Key for multiple select)</span>
                      </FormControl>
                    </form>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
            <Paper className={classes.paper2}>
              <Grid style={{ border: '1px solid', borderRadius: 5 }}>
                <span style={{ color: 'red' }}>&nbsp;&nbsp;{display.message}</span>
                <div
                  style={{
                    padding: '0px',
                    border: '1px',
                    backgroundColor: '#EEF1F9',
                    border: '1px solid #002A45',
                  }}
                >
                  {/* {console.log('files', rows)}
                  {console.log('selection', columns)} */}
                  <ExpressGrid
                    rows={rows}
                    // columns={columns.map((item, index) => {
                    //   if (
                    //     ['Rephrased_Text', 'Rephrased_warning', 'Rephrased_score'].includes(
                    //       item.name
                    //     ) &&
                    //     item.length !== undefined
                    //   ) {
                    //     columns.splice(index, 1);
                    //   }
                    //   return item;
                    // })}
                    columns={columns}
                  >
                    <DataTypeProvider
                      for={state.Columns || col}
                      formatterComponent={({ value }) => (
                        <CustomWidthTooltip
                          placement="bottom-start"
                          // arrow
                          // style={{
                          //   whiteSpace: `${newLine}`,
                          //   fontWeight: 'bold',
                          //   color: '#002A45',
                          //   overflowY: newLine === 'pre-line' ? 'scroll' : 'hidden',
                          //   maxHeight: '15vh',
                          //   padding: 5,
                          // }}
                          // open={true}
                          title={
                            <>
                              <Card
                                style={{
                                  background: 'while',
                                  color: '#002A45',
                                  fontWeight: 'bold',
                                  margin: 0,
                                  padding: 5,
                                  // overflowY: 'scroll',
                                  // maxHeight: '25vh',
                                  // padding: 5,
                                }}
                                // className={tClasses.customScrollbar}
                              >
                                {value}
                              </Card>
                            </>
                          }
                        >
                          <span
                            title={value}
                            style={{
                              whiteSpace: `${newLine}`,
                              fontWeight: 'bold',
                              color: '#002A45',
                            }}
                          >
                            {value}
                          </span>
                        </CustomWidthTooltip>
                      )}
                    />
                    <SortingState />
                    <IntegratedSorting />
                    {/* <PagingState defaultCurrentPage={0} defaultPageSize={5} /> */}
                    <PagingState
                      currentPage={currentPage3}
                      onCurrentPageChange={setCurrentPage3}
                      pageSize={pageSize2}
                      onPageSizeChange={setPageSize2}
                    />
                    <IntegratedPaging />
                    <Table
                      tableComponent={MultiAllocTableComp}
                      containerComponent={tableAllocatorComponent}
                      headComponent={tableHeaderComponents}
                      columnExtensions={[
                        ...columns.map((c) => {
                          return {
                            columnName: c.name,
                            width: 'auto',
                            wordWrapEnabled: toggleHeight,
                          };
                        }),
                      ]}
                    />

                    {/* <TableColumnResizing
                      onColumnWidthsChange={setColumnWidths}
                      columnWidths={columnWidths}
                      resizingMode="nextColumn"
                    /> */}
                    <TableHeaderRow showSortingControls />

                    <PagingPanel
                      pageSizes={pageSizes}
                      containerComponent={(props) => (
                        <>
                          <PagingPanel.Container
                            {...props}
                            className={classessNew.pagingPanelContainer}
                          />
                        </>
                      )}
                    />
                  </ExpressGrid>
                  <NavigationGotoNew
                    setPageSize={setPageSize2}
                    fileLength={rows?.length}
                    pageSize={pageSize2}
                    dis={dis}
                    setTemPage={setTemPage}
                    handlePageNum={handlePageNum}
                    currentPage={currentPage3}
                    setCurrentPage={setCurrentPage3}
                  />
                </div>
              </Grid>
            </Paper>
          </div>
        )}
      </Paper>
      <br />
      {loadingSecond ? (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Loading />
        </div>
      ) : (
        ''
      )}
      {displayGrid && selection.length === 0 && (
        <div id="tab File table data">
          {getllm === true ? (
            <>
              <span>
                <b> Original Requirement Score: </b>
                {originTextScore}%
              </span>
              <br />
              <span>
                <b> Rephrased Requirement Score: </b>
                {fileResultScore}%
              </span>
            </>
          ) : (
            <>
              <span style={{ display: 'block' }}>
                <b> Original Requirement Score: </b>
                {fileResultScore}%
              </span>
              {rephrasedScore !== 0 && (
                <span style={{ display: 'block' }}>
                  <b> Rephrased Requirement Score: </b>
                  {rephrasedScore}%
                </span>
              )}
            </>
          )}
          {/* {rephrasedScore !== null ? (
            <>
              <br />
              <span>
                <b> REPHRASED SCORE: </b>
                {rephrasedScore}%
              </span>
            </>
          ) : (
            ''
          )} */}
          {IS_DEBUG_MODE && (
            <ReactJson
              src={{
                rows,
                columns,
                payloadOri,
                changedRowOnly,
              }}
              collapsed={true}
              theme="monokai"
            />
          )}
          <Grid style={{ border: '1px solid', borderRadius: 5 }}>
            <div
              style={{
                padding: '0px',
                border: '1px',
                backgroundColor: '#EEF1F9',
                border: '1px solid #002A45',
              }}
            >
              {loadingFinalTable ? (
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <Loading />
                </div>
              ) : (
                <>
                  <ExpressGrid rows={rows} columns={columns}>
                    <DataTypeProvider
                      for={titleArr}
                      formatterComponent={({ value }) => (
                        <CustomWidthTooltip
                          placement="bottom-start"
                          // arrow
                          title={
                            <>
                              <Card
                                style={{
                                  background: 'while',
                                  color: '#002A45',
                                  fontWeight: 'bold',
                                  margin: 0,
                                  padding: 5,
                                  overflowY: 'scroll',
                                  maxHeight: '25vh',
                                }}
                                className={classessNew.customScrollbar}
                              >
                                {value}
                              </Card>
                            </>
                          }
                        >
                          <span
                            style={{
                              whiteSpace: `${newLine}`,
                              fontWeight: 'bold',
                              color: '#002A45',
                              overflowY: newLine === 'pre-line' ? 'scroll' : 'hidden',
                              maxHeight: '15vh',
                              padding: 5,
                            }}
                            className={classessNew.customScrollbar}
                          >
                            {value}
                          </span>
                        </CustomWidthTooltip>
                      )}
                    />

                    <DataTypeProvider for={['status']} formatterComponent={statusCell} />
                    {/*
                  <DataTypeProvider
                    for={['warning']}
                    formatterComponent={({ value }) => (
                      <span
                        style={{
                          whiteSpace: `${newLine}`,
                          // fontWeight: 'bold',
                          color: 'black',
                        }}
                        title={value}
                      >
                        {String(value).replaceAll('\n', '\n\n')}
                      </span>
                    )}
                  /> */}
                    <FilteringState />
                    {/* <PagingState defaultCurrentPage={0} defaultPageSize={5} /> */}
                    <PagingState
                      currentPage={currentPage4}
                      onCurrentPageChange={setCurrentPage4}
                      pageSize={pageSize2}
                      onPageSizeChange={setPageSize2}
                    />
                    <SortingState />
                    <IntegratedSorting />
                    <IntegratedFiltering />
                    <IntegratedPaging />
                    <SelectionState
                      selection={selectionHere}
                      onSelectionChange={setSelectionHere}
                    />
                    <IntegratedSelection />

                    <Table
                      tableComponent={MultiAllocTableComp}
                      containerComponent={tableAllocatorComponent}
                      headComponent={tableHeaderComponent}
                      columnExtensions={[
                        ...columns.map((c) => {
                          return {
                            columnName: c.name,
                            width: 'auto',
                            wordWrapEnabled: toggleHeight,
                          };
                        }),
                      ]}
                    />
                    <TableColumnResizing
                      onColumnWidthsChange={setColumnWidths}
                      columnWidths={columnWidths}
                      // resizingMode="nextColumn"
                    />
                    <TableHeaderRow showSortingControls />

                    <TableFilterRow rowComponent={rowComponent} />
                    <TableColumnVisibility
                      hiddenColumnNames={hiddenColumnNames}
                      onHiddenColumnNamesChange={setHiddenColumnNames}
                    />
                    <Toolbar rootComponent={toolbarComponent} />
                    <TableSelection showSelectAll />
                    <ColumnChooser />
                    <ToolBarPlugin name="Refresh" onClick={() => handleRefesh('normal')} />
                    {!hideRephrase && (
                      <ToolBarPlugin name="Send for Rephrased Text" onClick={passForNewText} />
                    )}
                    {!saveIconChange && <ToolBarPlugin name="Save" onClick={passForSave} />}
                    {saveIconChange && <ToolBarPlugin name="SaveRed" onClick={passForSave} />}
                    <ToolBarPlugin
                      name="Height"
                      title="Wrap Text"
                      onClick={() => {
                        setToggleHeight(!toggleHeight);
                        setNewLine('');
                      }}
                    />
                    <ToolBarPlugin
                      name="NewLine"
                      onClick={() =>
                        newLine !== 'pre-line' ? setNewLine('pre-line') : setNewLine('')
                      }
                    />
                    <ToolBarPlugin name="Download" onClick={startExport} />
                    <EditingState onCommitChanges={commitChanges} />
                    <PagingPanel
                      pageSizes={pageSizes}
                      containerComponent={(props) => (
                        <>
                          <PagingPanel.Container
                            {...props}
                            className={classessNew.pagingPanelContainer}
                          />
                        </>
                      )}
                    />
                    <TableEditColumn showEditCommand width={45} />
                    <PopupEditing popupComponent={Popup} />
                  </ExpressGrid>
                </>
              )}
              <GridExporter ref={exporterRef} rows={rows} columns={columns} onSave={onSave} />
              <NavigationGotoNew
                setPageSize={setPageSize2}
                fileLength={rows?.length}
                pageSize={pageSize2}
                dis={dis}
                setTemPage={setTemPage}
                handlePageNum={handlePageNum}
                currentPage={currentPage4}
                setCurrentPage={setCurrentPage4}
              />
            </div>
          </Grid>
        </div>
      )}
      {displayGrid && graphDataDisplay && selection.length === 0 && (
        <div>
          <br />
          <br />
          <div>
            {getllm === true ? (
              <>
                {' '}
                <Paper className={classes2.ToggleResultContainer}>
                  <Box className={classes2.ToggleResBox}>
                    <Card className={classes2.cardRoot}>
                      <CardHeader title="Original Requirement Graph" />
                      <CardContent className={classes2.cardContent}>
                        <RadarChart outerRadius={200} width={550} height={450} data={graphData}>
                          <PolarGrid />
                          <PolarAngleAxis dataKey="index" />
                          <PolarRadiusAxis angle={30} domain={[0, 1]} />
                          <Radar
                            name={fileIs1}
                            dataKey="0"
                            stroke="#8884d8"
                            fill="#8884d8"
                            fillOpacity={0.6}
                          />
                          {/* <Legend /> */}
                        </RadarChart>
                      </CardContent>
                    </Card>

                    <Card className={classes2.cardRoot}>
                      <CardHeader title="Rephrased Requirement Graph" />
                      <CardContent className={classes2.cardContent}>
                        <RadarChart outerRadius={200} width={550} height={450} data={graphLlmData}>
                          <PolarGrid />
                          <PolarAngleAxis dataKey="index" />
                          <PolarRadiusAxis angle={30} domain={[0, 1]} />
                          <Radar
                            name={fileIs1}
                            dataKey="0"
                            stroke="#8884d8"
                            fill="#8884d8"
                            fillOpacity={0.6}
                          />
                          {/* <Legend /> */}
                        </RadarChart>
                      </CardContent>
                    </Card>
                  </Box>
                </Paper>
              </>
            ) : (
              <>
                <Paper className={classes2.ToggleResultContainer}>
                  <Box className={classes2.ToggleResBox}>
                    <Card className={classes2.cardRoot} style={{ flexBasis: '100%' }}>
                      <CardHeader title="Original Requirement Graph" />
                      <CardContent className={classes2.cardContent}>
                        <RadarChart outerRadius={200} width={1400} height={450} data={graphData}>
                          <PolarGrid />
                          <PolarAngleAxis dataKey="index" />
                          <PolarRadiusAxis angle={30} domain={[0, 1]} />
                          <Radar
                            name={fileIs1}
                            dataKey="0"
                            stroke="#8884d8"
                            fill="#8884d8"
                            fillOpacity={0.6}
                          />
                          {/* <Legend /> */}
                        </RadarChart>
                      </CardContent>
                    </Card>
                  </Box>
                </Paper>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}
export default IQulimeterFile;
