import React, { useMemo } from 'react';
import AlarmIcon from '@mui/icons-material/Alarm';
import RightPanelLayout from './RightPanelLayout';

const AlertPanel = (props) => {
  const { data, isOpen, setSideBar } = props;
  const renderData = useMemo(() => {
    return {
      name: 'Alerts',
      renderCardIcon: () => {
        return <AlarmIcon />;
      },
      cardData: data.map((data) => {
        return {
          content: [
            {
              label: ' Content:',
              value: data.label,
            },
          ],
          url: `/projects/${data.redirect_params?.project_id}/${data.redirect_params?.app_name}/${
            data.redirect_params?.app_name[0] +
            data.redirect_params?.app_name[data.redirect_params?.app_name?.length - 1]
          }/results/${data.redirect_params?.workload_id}`,
        };
      }),
      isOpen,
      setSideBar,
      isActionRequired: true,
    };
  }, [data, isOpen]);

  return <RightPanelLayout pannelLayout={renderData} />;
};

export default AlertPanel;
