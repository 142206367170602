import React, { useMemo } from 'react';
import TranslateIcon from '@mui/icons-material/Translate';
import HistoryIcon from '@mui/icons-material/History';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import LayoutContainer from '../../../layouts/LayoutContainer';
import DocumentTranslator from '../../Module2/Module/Components/DocumentTranslator';
import History from '../../../components/History/History';
import useDocumentTranslator from '../Hooks/useDocumentTranslator';
import useAppToken from '../../../app/useAppToken';
import UserFileManager from '../../FileManger/UserFileManager';
import Description from '../../Module2/Module/Description';
// import History from '../../Module2/Module/History';

const DocumentContainer = () => {
  const { appJWT } = useAppToken();
  const { user_id } = appJWT;
  const { result, loading, CallHistory } = useDocumentTranslator({ user_id });
  const componentsData = useMemo(() => {
    return [
      {
        name: 'Document translator',
        projectName: '',
        icon: <PlaylistAddCheckOutlinedIcon fontSize="small" />,
        route: '/Documenttranslator',
        renderComponent: (props) => {
          return (
            <>
              <DocumentTranslator {...props} />
            </>
          );
        },
        enableLeftOption: true,
        leftOption: [
          {
            name: 'Translator',
            numberOfFilesSupport: 3,
            select: 'multiple',
            icon: <TranslateIcon fontSize="small" />,
            renderComponent: (props) => {
              return (
                <>
                  <DocumentTranslator {...props} />
                </>
              );
            },
            renderFiles: (props) => {
              return (
                <>
                  <UserFileManager {...props} />
                </>
              );
            },
            enableDescription: true,
            renderDescription: () => {
              const list = ['Translator', '', ''];
              return (
                <>
                  <Description list={list} />
                </>
              );
            },
          },
          {
            name: 'History',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <History {...props} />
                </>
              );
            },
            callHistory: CallHistory,
          },
        ],
      },
    ];
  }, []);
  return (
    <LayoutContainer
      componentsData={componentsData}
      workload={{
        workload: 0,
        serviceFor: { name: 'user', id: user_id },
      }}
    />
  );
};

export default DocumentContainer;
