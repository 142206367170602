import * as React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const LeftBlock = styled((props) => <Box {...props} />)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const RightBlock = styled((props) => <Box {...props} />)(({ theme }) => ({
  flex: 2,
  display: 'flex',
  //   justifyContent: 'center',
}));

const MainDiv = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  gap: '0.5rem',
  width: '100%',
}));

export { MainDiv, LeftBlock, RightBlock };
