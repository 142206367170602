import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { AppJwtType } from 'Auth';
import React, { useEffect, useMemo } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { isValidToken } from '../api/auth';
import { getAll, getAllLite } from '../api/projects';
import LoadingApp from '../components/Core/LoadingApp';
import { ENV } from '../const';
import AdminRouter from '../containers/Admin/AdminRouter';
import ErrorContainer from '../containers/Error/ErrorContainer';
import Welcome from '../containers/Home/components/Welcome';
import Connect from '../containers/Login/Connect';
import LoginPage from '../containers/Login/LoginPage';
import LoginTestPage from '../containers/Login/LoginTestPage';
import Logout from '../containers/Login/Logout';
import { ProjectContextProvider } from '../containers/Project/ProjectContext';
import ProjectRouter from '../containers/Project/ProjectRouter';
// import UserRouter from '../containers/User/UserRouter';
import VersionContainer from '../containers/Version/VersionContainer';
import WorkspaceRouter from '../containers/Workspace/WorkspaceRouter';
import withIntl from '../i18n/withIntl';
import AdminLayout from '../layouts/AdminLayout';
import HomeLayout from '../layouts/HomeLayout';
import './App.css';
import AppLogin from './AppLogin';
import { isensTheme } from './isensTheme';
import TableDemo from './TableDemo';
import useAppToken from './useAppToken';
import useAzureToken from './useAzureToken';
import useFrontendVersionControl from './useFrontendVersionControl';
// import IndeationApp from '../Ideation/src/IdeationApp';
import AllocationHome from '../containers/allocation/components/AllocationHome';
import AllocationUpload from '../containers/allocation/components/AllocationUpload';
import EditTemplateTab from '../containers/allocation/components/EditTemplateTab';
import AllocationTest from '../containers/allocation/components/AllocationTest';
import AllocationActivity from '../containers/allocation/components/AllocationActivity';
import HomeRouter from '../containers/Home/HomeRouter';
// import IndeationApp from '../Ideation/src/IdeationApp';
import HomeContainer from '../containers/Home/components/HomeContainer';
import { HomeContextProvider } from '../containers/ContextMager/HomeContext';
import UserRouter from '../containers/UserWorkSpace/UserRouter';
import HomeRouterContainer from './RouterContainer/HomeRouterContainer';
import UserRouterContainer from './RouterContainer/UserRouterContainer';
import GenAHome from '../containers/UserWorkSpace/Containers/GenAHome';
import GenALayout from '../containers/UserWorkSpace/Containers/GenALayout';
import IndeationApp from '../Ideation/src/IdeationApp';

function App() {
  const { jwt: azJWT } = useAzureToken();
  const { appJWT }: { appJWT: AppJwtType } = useAppToken();
  const { isValidLatestVersion } = useFrontendVersionControl();
  const [projects, setProjects] = React.useState([]);
  const [completedProjects, setCompletedProjects] = React.useState(true);
  const [loadingProjects, setLoadingProjects] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState('');

  useEffect(() => {
    // check token is valid before loading projects
    if (azJWT || appJWT) {
      isValidToken()
        .then(() => {
          setLoadingProjects(true);
          getAllLite()
            .then((projects) => {
              setProjects(projects);
              // setProjects([...privateProjects, ...publicProjects] as any);
              // //  call for full projects in background
              // getAll().then((response) => {
              //   setProjects(response as any);
              //   setCompletedProjects(true);
              // });
            })
            .catch((error) => {
              setError(error);
              localStorage.clear();
              sessionStorage.clear();
            })
            .finally(() => {
              setLoadingProjects(false);
            });
        })
        .catch((error) => {
          setLoading(false);
          setError(error);
          // clear all storage
          localStorage.clear();
          sessionStorage.clear();
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [azJWT, appJWT]);

  // useEffect(() => {
  //   if (!azJWT || !appJWT || isValidLatestVersion) {
  //     // clear all storage
  //     // localStorage.clear();
  //     // sessionStorage.clear();
  //   }
  // }, [azJWT, appJWT, isValidLatestVersion]);

  // console.log('isValidLatestVersion', isValidLatestVersion);

  // console.log('!azJWT || !appJWT', !azJWT, !appJWT);

  if (!azJWT || !appJWT || isValidLatestVersion) {
    // clear all storage
    // localStorage.clear();
    // sessionStorage.clear();
    return <AppLogin />;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks

  if (loadingProjects) return <LoadingApp />; // TODO  Loading Page
  if (loading) return <LoadingApp />;
  if (error !== '') return <AppLogin />;
  return (
    <ThemeProvider theme={isensTheme}>
      <CssBaseline />
      <Router>
        <Switch>
          {ENV !== 'production' && ENV !== 'test' && (
            <Route path="/(version|release)" component={VersionContainer} />
          )}
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/test" component={LoginTestPage} />
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/activate" component={Welcome} />
          <Route exact path="/openid/connect" component={Connect} />
          <Route exact path="/Ideation" component={IndeationApp} />

          {/* <Route exact path="/Ideation" component={IndeationApp} /> */}
          {!appJWT.is_new_user && appJWT.is_admin && (
            <AdminLayout path="/admin" component={AdminRouter} />
          )}
          {!appJWT.is_new_user && (
            <ProjectContextProvider project_lite={projects} completedProjects={completedProjects}>
              <Switch>
                <GenALayout path="/GenA" component={GenAHome} />
                {/* <Route exact path="/GenA" component={GenAHomeSpace} /> */}
                <HomeLayout path="/(projects|module2)" component={ProjectRouter} />
                {/* allocation component */}
                <HomeLayout path="/allocation" component={AllocationHome} />
                <HomeLayout path="/allocationUpload/:id" component={AllocationUpload} />
                <HomeLayout path="/editTemplate/:id" component={EditTemplateTab} />
                <HomeLayout path="/allocationTest/:id" component={AllocationTest} />
                <HomeLayout path="/allocationActivity" component={AllocationActivity} />
                <HomeLayout path="/(project|module2)" component={ProjectRouter} />
                {/* <HomeLayout path="/me" component={UserRouter} /> */}
                {/* <HomeLayout path="/workspace" component={WorkspaceRouter} /> */}
                <HomeLayout path="/userspace" component={UserRouterContainer} />
                {/* <HomeLayout exact path="/checkSapce" component={GenAHomeSpace} /> */}
                <HomeLayout path="/home" component={HomeRouterContainer} />
                <Redirect exact from="/" to="/home" />
                <Route path="*">
                  {/* <TableDemo /> */}
                  <ErrorContainer />
                </Route>
              </Switch>
            </ProjectContextProvider>
          )}
          <Route path="*">
            <ErrorContainer />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default React.memo(withIntl(App));
