import React, { memo, useMemo } from 'react';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import TranslateIcon from '@mui/icons-material/Translate';
import HistoryIcon from '@mui/icons-material/History';
import LayoutContainer from '../../../layouts/LayoutContainer';
import History from '../../../components/History/History';
import DetailforTransalator from '../../../components/History/components/DetailforTransalator';
import UserFileManager from '../../FileManger/UserFileManager';
import SimilarityAnalysis from '../Components/SimilarityAnalysis';
import Description from '../../Module2/Module/Description';
import CompareVersion from '../Components/CompareVersion';
import ExtractTopics from '../Components/ExtractTopics';
import FindDuplicates from '../Components/FindDuplicates';
import RexBook from '../Components/RexBook';
import { UserSpaceContextProvider } from '../UserSpaceContext';
import useAppToken from '../../../app/useAppToken';
// import History from '../../Module2/Module/History';

const DocumentAnalysisContainer = () => {
  const { appJWT } = useAppToken();
  const { user_id } = appJWT;

  const componentsData = useMemo(
    () => [
      {
        name: 'Similarity Analysis',
        projectName: '',
        icon: <PlaylistAddCheckOutlinedIcon fontSize="small" />,
        route: '/SimilarityAnalysis',
        enableLeftOption: true,
        leftOption: [
          {
            name: 'Similarity Analysis',
            numberOfFilesSupport: 1,
            icon: <PlaylistAddCheckOutlinedIcon fontSize="small" />,
            renderComponent: (props) => {
              return (
                <>
                  <SimilarityAnalysis {...props} />
                </>
              );
            },
            renderFiles: (props) => {
              return (
                <>
                  <UserFileManager {...props} />
                </>
              );
            },
            enableDescription: true,
            renderDescription: () => {
              const list = [
                'Similarity Analysis shall provide a list of similar information found from previously executed projects and tenders.',
                'Upon uploading a document in the form of excel, pdf or csv, the iSimilarizer shall look for similar information.',
                'User shall be able to download the reports of the analysis provided by iSimilarize',
              ];
              return (
                <>
                  <Description list={list} />
                </>
              );
            },
          },
          {
            name: 'History',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <History {...props} />
                </>
              );
            },
            renderHistoryDetail: (props) => {
              return (
                <>
                  <DetailforTransalator {...props} />
                </>
              );
            },
          },
        ],
      },
      {
        name: 'Compare Versions',
        projectName: '',
        icon: <PlaylistAddCheckOutlinedIcon fontSize="small" />,
        route: '/Compareversions',

        enableLeftOption: true,
        leftOption: [
          {
            name: 'Compare Versions',
            numberOfFilesSupport: 2,
            icon: <TranslateIcon fontSize="small" />,
            renderComponent: (props) => {
              return (
                <>
                  <CompareVersion {...props} />
                </>
              );
            },
            renderFiles: (props) => {
              return (
                <>
                  <UserFileManager {...props} />
                </>
              );
            },
            renderDescription: () => {
              const list = [
                'Comparison of two versions of the same document to find their similarities and differences',
                'Upon uploading a document in the form of text, excel, pdf or csv, iSimilarizer shall look for similar information from the previous version',
                'User shall be able to download the reports of the analysis provided by iSimilarizer.',
              ];
              return (
                <>
                  <Description list={list} />
                </>
              );
            },
            enableDescription: true,
          },
          {
            name: 'History',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <History {...props} />
                </>
              );
            },
          },
        ],
      },
      {
        name: 'Extract Topics',
        projectName: '',
        icon: <PlaylistAddCheckOutlinedIcon fontSize="small" />,
        route: '/Extracttopics',
        enableLeftOption: true,
        leftOption: [
          {
            name: 'Extract Topics',
            numberOfFilesSupport: 2,
            icon: <TranslateIcon fontSize="small" />,
            renderComponent: (props) => {
              return (
                <>
                  <ExtractTopics {...props} />
                </>
              );
            },
            renderFiles: (props) => {
              return (
                <>
                  <UserFileManager {...props} />
                </>
              );
            },
            enableDescription: false,
          },
          {
            name: 'History',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <History {...props} />
                </>
              );
            },
          },
        ],
      },
      {
        name: 'Find Duplicates',
        projectName: '',
        icon: <PlaylistAddCheckOutlinedIcon />,
        route: 'Findduplicates',
        enableLeftOption: true,
        leftOption: [
          {
            name: 'Find Duplicates',
            numberOfFilesSupport: 2,
            icon: <TranslateIcon fontSize="small" />,
            renderComponent: (props) => {
              return (
                <>
                  <FindDuplicates {...props} />
                </>
              );
            },
            renderFiles: (props) => {
              return (
                <>
                  <UserFileManager {...props} />
                </>
              );
            },
            enableDescription: false,
          },
          {
            name: 'History',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <History {...props} />
                </>
              );
            },
          },
        ],
      },
      {
        name: 'Rex Book',
        projectName: '',
        icon: <PlaylistAddCheckOutlinedIcon fontSize="small" />,
        route: '/Rexbook',
        enableLeftOption: true,
        leftOption: [
          {
            name: 'Rex book',
            numberOfFilesSupport: 2,
            icon: <TranslateIcon fontSize="small" />,
            renderComponent: (props) => {
              return (
                <>
                  <RexBook {...props} />
                </>
              );
            },
            renderFiles: (props) => {
              return (
                <>
                  <UserFileManager {...props} />
                </>
              );
            },
            renderDescription: () => {
              const list = [
                'REX Book is a compilation of lessons and Return of Experiences (REX) from the past, and applicable to your tender or project to avoid re-doing the same mistakes as in the past.',
                'Upon uploading a document in form of pdf, excel or csv, the REX Book service looks for and complies a list of REX applicable to your tender or project.',
                'You can download the REX Book document and share it across to the relevant PICs and stakeholders.',
              ];
              return (
                <>
                  <Description list={list} />
                </>
              );
            },
            enableDescription: true,
          },
          {
            name: 'History',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <History {...props} />
                </>
              );
            },
          },
        ],
      },
    ],
    []
  );

  return (
    <>
      <LayoutContainer
        componentsData={componentsData}
        workload={{
          workload: 0,
          serviceFor: { name: 'user', id: user_id },
        }}
      />
    </>
  );
};

export default memo(DocumentAnalysisContainer);
