import React, { memo, useCallback } from 'react';
import FilterComponent from './FilterComponent';

const FilterContainer = ({ children }) => {
  const [isCollapsed, setCollapsed] = React.useState({ open: false, service: '' });

  const toggleCollapse = useCallback((item) => {
    setCollapsed(item);
  }, []);

  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, { toggleCollapse, isCollapsed })
  );

  return (
    <>
      <FilterComponent isCollapsed={isCollapsed} setCollapsed={setCollapsed}>
        {childrenWithProps}
      </FilterComponent>
    </>
  );
};

export default memo(FilterContainer);
