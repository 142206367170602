import React from 'react';
import { Box, List, ListItem } from '@mui/material';
import { styled } from '@mui/material/styles';

const Container = styled((props) => <Box {...props} />)(({ theme }) => ({
  margin: '0 auto',
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
}));

const Description = (props) => {
  const { list } = props;
  return (
    <Container>
      <List>
        {list.map((data) => {
          return <ListItem>{data}</ListItem>;
        })}
      </List>
    </Container>
  );
};

export default Description;
