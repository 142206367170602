import React from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useHistory, useParams } from 'react-router-dom';

const MuiCard = styled((props) => <Card {...props} />)(({ theme }) => ({
  padding: '5px 15px',
  borderLeft: '11px solid #DC3223 !important',
  borderRight: '1px solid gray !important',
  borderTop: '1px solid gray !important',
  borderBottom: '1px solid gray !important',
  borderColor: theme.palette.primary.light,
  borderRadius: 5,
  margin: 5,
  color: '#EEF1F9',
  width: '30%',
  height: '40%',
  opacity: 1,
  background:
    'transparent linear-gradient(128deg, #002A45 0%, #495866 100%) 0% 0% no-repeat padding-box',
  transition:
    '.7s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12)',
  cursor: 'pointer',
  '&:hover': {
    color: '#EEF1F9',
    //   border: 'none',
    transform: 'scale(1.05)',
    zIndex: 4,
    transition: 'all 0.4s ease-out',
    // transform: 'scale(1.05)',
    // boxShadow: '0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06)',
  },
}));

const MuiContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: '10px',
  flexWrap: 'wrap',
  padding: '2rem',
  width: '100%',
  height: '100%',
  maxHeight: '100%',
  overflow: 'auto',
}));

const CardContainer = ({ item, route }) => {
  const history = useHistory();

  return (
    <MuiCard
      onClick={() => {
        history.push(route);
      }}
    >
      <CardContent>
        <Typography variant="h5" component="div">
          {item}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Description of My Projects. {item}
        </Typography>
      </CardContent>
    </MuiCard>
  );
};

const WorkspceContainer = () => {
  const tabs = [
    { name: 'GenA', route: '' },
    { name: 'Translator', route: `` },
    { name: 'Text Analysis', route: '' },
    { name: 'Document Anaylsis', route: '' },
    { name: 'Rex 360', route: '/home/workspace/RexAnalysis' },
  ];
  return (
    <>
      <MuiContainer>
        {tabs.map((items) => {
          return <CardContainer item={items.name} route={items.route} />;
        })}
      </MuiContainer>
    </>
  );
};

export default WorkspceContainer;
