import React, { memo } from 'react';
import { HomeContextProvider } from '../../containers/ContextMager/HomeContext';
import HomeRouter from '../../containers/Home/HomeRouter';

const HomeRouterContainer = () => {
  return (
    <>
      <HomeContextProvider>
        <HomeRouter />
      </HomeContextProvider>
    </>
  );
};

export default memo(HomeRouterContainer);
