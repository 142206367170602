import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import ExpandContainer from '../../HOC/ExpandComponent';
import ProjectContext from '../../Project/ProjectContext';
import IsensChatNew from '../../Module2/NewModule/GPT2/ChatContainer';
import { M2GlobalContextProvider } from '../../ContextMager/M2GlobalContext';

const GenALayout = ({ component: Component, ...rest }) => {
  const [isChatExpand, setIsChatExpand] = useState(false);

  return (
    <Route
      {...rest}
      render={(props: any): any => (
        <ProjectContext.Consumer>
          {({ projects_lite }) => (
            <>
              <div
                style={{
                  maxHeight: '100vh',
                  maxWidth: '100vw',
                  zIndex: 1299,
                  width: '100vw',
                  height: '100vh',
                  overflow: 'hidden',
                }}
              >
                <Component {...props} />
              </div>
            </>
          )}
        </ProjectContext.Consumer>
      )}
    />
  );
};

export default GenALayout;
