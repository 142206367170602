import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import WorkIcon from '@mui/icons-material/Work';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Button,
  IconButton,
  InputBase,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import ArticleIcon from '@mui/icons-material/Article';
import { DocAnalysisIcon, RexBookIcon, DocumentsIcon, DocumentIcon } from '../assets/svg/index';
import ProjectContext from '../containers/Project/ProjectContext';
import { defaultFileIcon, DocxDoc } from '../components/DocumentTranslator/const/png';
import alstom_whiteLogo from '../assets/alstom_whiteLogo.png';
import ModalProjectCreateNew from '../containers/Project/components/ModalProjectCreateNew';

const getFilteredOrSortedProjects = (projects, searchTerm) => {
  if (searchTerm === '') {
    return projects.sort((a, b) => {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });
  }
  return projects.filter((p) => p.name.toLowerCase().includes(searchTerm.toLowerCase()));
};

const SideContent = styled((props) => <Box {...props} />)(({ theme }) => ({
  width: '600px',
  display: 'flex',
  borderRadius: '10px',
  flexDirection: 'column',
  backgroundColor: '#fafafa',
  height: '100%',
  position: 'absolute',
  left: '-600px',
  // transition: 'width 0.3s ease-out',
  transition: 'left 0.3s ease-out',
  '&.visible': {
    left: '0',
    // width: '600px',
  },
  '&.inVisible': {
    left: '-600px',
  },
}));

const NavBar = styled((props) => <Box {...props} />)(({ theme }) => ({
  height: '60px',
  width: '100%',
  backgroundColor: '#002a45',
  [theme.breakpoints.up('lg')]: {
    height: '60px',
  },
  [theme.breakpoints.up('xl')]: {
    height: '80px',
  },
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0.7rem 0.7rem 0.7rem 0.7rem',
  borderBottom: '1px solid #ccd6f2',
}));

const NavBarSection = styled((props) => <Box {...props} />)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
}));

const Name = styled((props) => <Box {...props} />)(({ theme }) => ({
  color: '#002A45',
  fontfamily: 'Volte Rounded,Noto Sans,sans-serif',
  fontSize: '1.25rem',
  fontWeight: 600,
  lineHeight: '1.5rem',
  letterSpacing: 'normal',
}));

const CrossButton = styled((props) => <IconButton {...props} />)(({ theme }) => ({
  // border: '1px solid #ccd6f2',
  color: '#ccd6f2',
}));

const ContentBox = styled((props) => <Box {...props} />)(({ theme }) => ({
  width: '100%',
  height: 'calc(100% - 60px)',
  [theme.breakpoints.up('lg')]: {
    height: 'calc(100% - 60px)',
  },
  [theme.breakpoints.up('xl')]: {
    height: 'calc(100% - 80px)',
  },
  padding: '0.7rem',
  display: 'flex',
}));

const Left = styled((props) => <Box {...props} />)(({ theme }) => ({
  width: '55%',
  height: '100%',
  padding: '0rem 0.5rem 0rem 0rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  borderRight: '1px solid #ccd6f2',
}));

const Right = styled((props) => <Box {...props} />)(({ theme }) => ({
  width: 'calc(100% - 55%)',
  height: '100%',
  // padding: '0rem 0.5rem 0rem 0rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}));

// const SpaceCard = styled((props) => <Box {...props} />)(({ theme }) => ({
//   height: '100%',
//   width: '100%',
// }));

const SpaceCardHeader = styled((props) => <Box {...props} />)(({ theme }) => ({
  height: '20%',
  width: '100%',
}));

const SpacrCardContent = styled((props) => <Box {...props} />)(({ theme }) => ({
  height: '100%',
  width: '100%',
}));

const InputContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  minWidth: '9rem',
  height: '50px',
  maxHeight: '50px',
  fontSize: '1em',
  borderRadius: '8px',
  border: '1px solid #998760CE',
  // [theme.breakpoints.down('md')]: {
  //   fontSize: '1em',
  //   height: '50px',
  //   maxHeight: '50px',
  // },
  // [theme.breakpoints.down('lg')]: {
  //   fontSize: '1.5em',
  //   height: '55px',
  //   maxHeight: '55px',
  // },
  // [theme.breakpoints.up('xl')]: {
  //   height: '100px',
  //   maxHeight: '100px',
  // },
}));

const InputFeildEnter = styled((props) => <InputBase {...props} />)(({ theme }) => ({
  fontSize: 'average',
  height: '100%',
  borderRadius: '8px 0 0 8px',
  [theme.breakpoints.up('lg')]: {
    fontSize: '0.89em',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: 'average',
  },
  // [theme.breakpoints.up('lg')]: {
  //   fontSize: 'large',
  //   height: '70px',
  //   maxHeight: '70px',
  // },
  // [theme.breakpoints.down('md')]: {
  //   fontSize: 'average',
  // },
}));

const ButtonCreate = styled((props) => <Button {...props} />)(({ theme }) => ({
  fontSize: 'large',
  margin: '2px',
  // height: '100%',
  borderRadius: '8px',
  backgroundColor: '#998760CE',
  color: '#EEF1F9',
  [theme.breakpoints.up('lg')]: {
    fontSize: '0.89em',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: 'average',
  },
  '&:hover': {
    backgroundColor: '#20635e !important',
  },
}));

const ProjectCardContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  padding: '0rem 0.5rem 0rem 0rem',
  width: '100%',
  height: 'calc(100% - 40px)',
  maxHeight: 'calc(100% - 40px)',
  overflowX: 'hidden',
  '::-webkit-scrollbar': {
    width: '5px',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: '#582a2a',
    borderRadius: '4px',
  },
  '::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1',
  },
}));

const ProjectCard = styled((props) => <Box {...props} />)(({ theme }) => ({
  padding: '5px 15px',
  // borderLeft: '11px solid #DC3223 !important',
  // borderRight: '1px solid gray !important',
  // borderTop: '1px solid gray !important',
  // borderBottom: '1px solid gray !important',
  borderColor: theme.palette.primary.light,
  borderRadius: 5,
  display: 'flex',
  fontWeight: 0,
  alignItems: 'center',
  margin: 5,
  gap: '5px',
  width: '100%',
  height: '3rem',
  opacity: 1,
  transition:
    '.7s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12)',
  cursor: 'pointer',
  '&:hover': {
    color: '#002A45',
    //   border: 'none',
    transform: 'scale(1.05)',
    zIndex: 4,
    transition: 'all 0.4s ease-out',
    // transform: 'scale(1.05)',
    // boxShadow: '0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06)',
  },
  '&.project': {
    background:
      'transparent linear-gradient(128deg, #002A45 0%, #f5f7f9 100%) 0% 0% no-repeat padding-box',
    color: 'white !important',
  },
  '&.user': {
    background:
      'transparent linear-gradient(128deg, #e8ebf2 0%, #f5f7f9 100%) 0% 0% no-repeat padding-box',
    color: '#002A45',
  },
}));

const SpaceContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0rem 0rem 0rem 0.5rem',
  width: '100%',
  height: '100%',
  maxHeight: '100%',
  gap: '20px',
  overflowX: 'hidden',
  '::-webkit-scrollbar': {
    width: '5px',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: '#002A45',
    borderRadius: '4px',
  },
  '::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1',
  },
}));

const SpaceCard = styled((props) => <Typography {...props} />)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '2px',
  justifyContent: 'center',
  alignItems: 'flex-end',
}));

const SpaceHeader = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  color: '#002A45',
  fontSize: '0.89rem',
  fontWeight: 600,
  padding: '0.5rem 0.25rem',
  letterSpacing: 'normal',
  // borderRadius: '5px',
  borderBottom: '1px solid #ccd6f2',
  // backgroundColor: 'rgba(123, 168, 195, 0.1)',
  width: '100%',
  // maxHeight: '25%',
  // overflowX: 'hidden',
  // gap: '5px',
  // padding: '0.7rem 0rem 0.7rem 0.7rem',
}));

const SpaceContent = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  // flexDirection: 'column',
  // alignItems: 'flex-start',
  // gap: '10px',
  // height: '25%',
  width: '100%',
  // maxHeight: '25%',
  flexWrap: 'wrap',
  // overflowX: 'hidden',
  // gap: '5px',
  // padding: '0.7rem 0rem 0.7rem 0.7rem',
}));

const AppContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  width: '5rem',
  flexBasis: '50%',
  // flex: 1,
  height: '5rem',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  gap: '0.5rem',
  padding: '1px',
  '&:hover': {
    backgroundColor: '#dde4ea',
    transition: 'all 0.4s ease-out',
  },
}));

const Label = styled((props) => <Typography {...props} />)(({ theme }) => ({
  color: '#002A45',
  letterSpacing: 'normal',
  fontSize: '0.89rem',
  // fontWeight: 900,
}));

const MuiIcon = styled((props) => <Box {...props} />)(({ theme, isSelected = false }) => ({
  backgroundColor: isSelected ? 'rgb(23, 193, 232)' : '#dde4ea',
  color: isSelected ? 'rgb(255, 255, 255)' : 'rgb(73, 78, 89)',
  minHeight: '2.3rem',
  minWidth: '2.3rem',
  display: 'flex',
  borderRadius: '0.5rem',

  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all 0.3s ease',
  boxShadow:
    'rgba(20, 20, 20, 0.12) 0rem 0.25rem 0.375rem -0.0625rem, rgba(20, 20, 20, 0.07) 0rem 0.125rem 0.25rem -0.0625rem',
}));

function LeftPanel({ isOpen, setSideBar }) {
  const { projects_lite } = useContext(ProjectContext);
  const location = useLocation();
  const projectTrue = location.pathname.split('/')[1] === 'project';
  const [project_search, setProject_search] = useState('');
  const [openProjectCreate, setOpenProjectCreate] = React.useState(false);
  const history = useHistory();
  const tempprojectsList = getFilteredOrSortedProjects(projects_lite, project_search);
  let projectsList;
  if (projectTrue) {
    projectsList = tempprojectsList.filter((p) => p.id !== Number(location.pathname.split('/')[2]));
    projectsList = [
      ...tempprojectsList.filter((p) => p.id === Number(location.pathname.split('/')[2])),
      ...projectsList,
    ];
  } else {
    projectsList = tempprojectsList;
  }
  const handleOpenProjectCreate = () => {
    setOpenProjectCreate(true);
  };
  const handleCloseProject = () => {
    setOpenProjectCreate(false);
  };

  return (
    <div>
      <SideContent
        style={projects_lite.length !== 0 ? { width: '600px' } : { width: '300px' }}
        className={isOpen ? 'visible' : 'inVisible'}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <NavBar>
          <NavBarSection>
            <img src={alstom_whiteLogo} alt="Alstom" width={100} height={20} />
          </NavBarSection>
          <NavBarSection sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CrossButton
              onClick={() => {
                setSideBar({ isPanel: '', isOpen: false });
              }}
            >
              <CloseIcon />
            </CrossButton>
          </NavBarSection>
        </NavBar>
        <ContentBox>
          {projects_lite.length !== 0 && (
            <Left>
              <InputContainer>
                <InputFeildEnter
                  onChange={(event) => {
                    setProject_search(event?.target.value);
                  }}
                  placeholder="Search for Project"
                  sx={{ flex: 9, pl: 1, color: '#40c2c4' }}
                />
                <ButtonCreate
                  sx={{ flex: '0.5', borderLeft: 'none', backgroundColor: '#20635e' }}
                  onClick={handleOpenProjectCreate}
                >
                  Create
                </ButtonCreate>
              </InputContainer>
              {isOpen && (
                <ProjectCardContainer>
                  {projectsList.map((project, index) => {
                    return (
                      <ProjectCard
                        onClick={() => {
                          history.push(`/project/${project.id}`);
                          setSideBar({ isPanel: '', isOpen: false });
                        }}
                        className={projectTrue && index === 0 ? 'project' : 'user'}
                      >
                        <WorkIcon />
                        <Typography
                          sx={{
                            all: 'unset',
                          }}
                          style={{
                            fontSize: '1rem',
                            fontWeight: 900,
                            letterSpacing: '0.05rem',
                          }}
                        >
                          {project.name.length > 25
                            ? `${project.name.slice(0, 25)}...`
                            : project.name}
                        </Typography>
                      </ProjectCard>
                    );
                  })}

                  {projectsList.length === 0 && (
                    <>
                      <ProjectCard>
                        <Typography
                          sx={{
                            all: 'unset',
                          }}
                          style={{
                            fontSize: '1rem',
                            fontWeight: 900,
                            letterSpacing: '0.05rem',
                          }}
                        >
                          No Projects Found
                        </Typography>
                      </ProjectCard>
                    </>
                  )}
                </ProjectCardContainer>
              )}
            </Left>
          )}
          <Right style={projects_lite.length === 0 ? { width: '100%' } : {}}>
            <Box>
              <SpaceContainer>
                {projects_lite.length === 0 && (
                  <ButtonCreate
                    sx={{ flex: '0.5', borderLeft: 'none', backgroundColor: '#20635e' }}
                    onClick={handleOpenProjectCreate}
                  >
                    Create
                  </ButtonCreate>
                )}
                <SpaceCard>
                  <SpaceHeader>My Workspace</SpaceHeader>
                  <SpaceContent>
                    <AppContainer
                      onClick={() => {
                        history.push(`/userspace/${'Documents'}`);
                        setSideBar({ isPanel: '', isOpen: false });
                      }}
                    >
                      <MuiIcon>
                        <DocumentsIcon style={{ width: '1.4rem', height: '1.4rem' }} />
                      </MuiIcon>
                      <Label>Documents</Label>
                    </AppContainer>
                    <AppContainer
                      onClick={() => {
                        history.push(`/userspace/${'RexBook'}`);
                        setSideBar({ isPanel: '', isOpen: false });
                      }}
                    >
                      <MuiIcon>
                        <RexBookIcon style={{ width: '1.4rem', height: '1.4rem' }} />
                      </MuiIcon>
                      <Label>RexBook</Label>
                    </AppContainer>
                    <AppContainer
                      onClick={() => {
                        history.push(`/userspace/${'DocumentTranslator'}`);
                        setSideBar({ isPanel: '', isOpen: false });
                      }}
                    >
                      <MuiIcon>
                        <DocumentIcon style={{ width: '1.4rem', height: '1.4rem' }} />
                      </MuiIcon>
                      <Label>Doc Translator</Label>
                    </AppContainer>
                    <AppContainer
                      onClick={() => {
                        history.push(`/userspace/${'DocAnalysis'}`);
                        setSideBar({ isPanel: '', isOpen: false });
                      }}
                    >
                      <MuiIcon>
                        <DocAnalysisIcon style={{ width: '1.4rem', height: '1.4rem' }} />
                      </MuiIcon>
                      <Label>Doc Analysis</Label>
                    </AppContainer>
                    {/* <AppContainer
                      onClick={() => {
                        history.push(`/userspace/${'Workbench'}`);
                        setSideBar({ isPanel: '', isOpen: false });
                      }}
                    >
                      <MuiIcon>
                        <ArticleIcon style={{ width: '1.4rem', height: '1.4rem' }} />
                      </MuiIcon>
                      <Label>Workbench</Label>
                    </AppContainer> */}
                  </SpaceContent>
                </SpaceCard>
                <SpaceCard>
                  <SpaceHeader>My Apps</SpaceHeader>
                  <SpaceContent>
                    {/* <AppContainer>
                      <MuiIcon>
                        <ArticleIcon style={{ width: '1.4rem', height: '1.4rem' }} />
                      </MuiIcon>
                      <Label>iContribution</Label>
                    </AppContainer> */}
                    {/* <AppContainer>
                    <MuiIcon>
                      <ArticleIcon style={{ width: '1.4rem', height: '1.4rem' }} />
                    </MuiIcon>
                    <Label>Engg Reainess</Label>
                  </AppContainer> */}
                    <AppContainer
                      onClick={() => {
                        history.push('/Ideation');
                        setSideBar({ isPanel: '', isOpen: false });
                      }}
                    >
                      <MuiIcon>
                        <ArticleIcon style={{ width: '1.4rem', height: '1.4rem' }} />
                      </MuiIcon>
                      <Label>AiDem</Label>
                    </AppContainer>
                  </SpaceContent>
                </SpaceCard>
              </SpaceContainer>
            </Box>
          </Right>
        </ContentBox>
      </SideContent>
      {openProjectCreate && (
        // <ModalProjectCreate open={openProjectCreate} onClose={handleCloseProject} />
        <ModalProjectCreateNew
          open={openProjectCreate}
          onClose={handleCloseProject}
          setSideBar={setSideBar}
        />
      )}
    </div>
  );
}

export default LeftPanel;
