import React, { useEffect, useMemo, useReducer } from 'react';

export interface LayoutContainerContextType {
  workload: any;
  setWorkload: any;
}

type Props = {
  children: any;
};

const LayoutContainerContext = React.createContext<LayoutContainerContextType>({
  workload: {} as any,
  setWorkload: {} as any,
});

const LayoutContainerContextProvider = ({ children }: Props) => {
  const [workload, setWorkload] = React.useState<any>([]);

  const value = useMemo(() => ({ workload, setWorkload }), [workload, setWorkload]);

  return (
    <LayoutContainerContext.Provider value={value}>{children}</LayoutContainerContext.Provider>
  );
};

export default LayoutContainerContext;

export { LayoutContainerContextProvider };
