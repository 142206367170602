/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import { Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import React, { memo } from 'react';
import { CitationCaption, CitationType } from './chat';
import { gptEngineType } from './ChatReducer';
import MarkdownContent from './MarkdownContent';
import { highlightPhrases } from './tools/highlight';

const useStyles = makeStyles((theme) => ({
  topTitle: {
    background: theme.palette.primary.main,
    color: 'white  !important',
    '&.MuiDialogTitle-root': {
      padding: '0',
    },
  },
  contentMessage: {
    wordBreak: 'break-word',
  },
}));

type Props = {
  doc: CitationType;
  captions?: CitationCaption[];
  onCloseCitation: () => void;
  title?: string;
  gptEngine?: gptEngineType;
  toggleCitation?: boolean;
};

const CitationComponent = ({
  doc,
  onCloseCitation,
  title,
  gptEngine,
  captions,
  toggleCitation,
}: Props) => {
  const classes = useStyles();
  function formatURL(url, displayText, color = 'blue', textDecoration = 'underline') {
    // Check if the URL is already formatted
    const regex = new RegExp(
      `<a href=".*" style="color:.*; text-decoration:.*;">${displayText}</a>`
    );
    if (regex.test(url)) {
      return url; // Return the URL as is if it is already formatted
    }
    return `<a href="${url}" target="_blank" style="color: ${color}; text-decoration: ${textDecoration};">${displayText}</a>`;
  }

  let content = '';
  if (gptEngine === 'private' && toggleCitation && doc) {
    // Replace '\n' with <br />
    doc.content = doc?.content.replace(/\n/g, '<br />');
    if (!doc.content.startsWith('<h6>') && !doc.content.endsWith('</h6>')) {
      doc.content = `<h6>${doc.content}</h6>`;
    }
    // Updating the URL
    const urlText = 'DOORS'; // Text to display
    doc.URL = formatURL(doc?.URL, urlText); // Replace the URL field with formatted link
    const clonedDoc = { ...doc };
    for (const [input, value] of Object.entries(clonedDoc)) {
      if (['content'].includes(input.toLowerCase())) {
        const input_caps = 'Content';
        content += `**${input_caps}** :  <br/> ${value}  <br/> `;
      }
      if (
        !['index', 'chunk_id', 'title', 'document_profile', 'document_title'].includes(
          input.toLowerCase()
        ) &&
        !['content'].includes(input.toLowerCase())
      ) {
        // if (input.toLowerCase() === 'content' && captions) {
        //   let newContent = value;
        //   captions.forEach((caption) => {
        //     newContent = highlightPhrases(value, caption.highlights);
        //   });
        //   content += `**${input}**  <br/> ${newContent}  <br/> `;
        // } else {
        //   content += `**${input}**  <br/> ${value}  <br/> `;
        // }
        content += `**${input}**:  ${value}  <br/> `;
      }
    }
  }

  if (gptEngine === 'rex') {
    const clonedDoc = { ...doc };
    clonedDoc.content = doc?.content.replace(/\n/g, '<br />');
    if (!clonedDoc.content.startsWith('<h6>') && !clonedDoc.content.endsWith('</h6>')) {
      clonedDoc.content = `<h6>${clonedDoc.content}</h6>`;
    }
    for (const [input, value] of Object.entries(clonedDoc)) {
      if (['content'].includes(input.toLowerCase())) {
        const input_caps = 'Content';
        content += `**${input_caps}** :  <br/> ${value}  <br/> `;
      }
      if (
        !['index', 'chunk_id', 'title', 'document_profile', 'document_title'].includes(
          input.toLowerCase()
        ) &&
        !['content'].includes(input.toLowerCase())
      ) {
        content += `**${input}** :  ${value}  <br/> `;
      }
    }
  }

  return (
    <div
      style={{
        // maxHeight: '100%',
        // minHeight: '100%',
        height: '100%',
        // overflowY: 'hidden',
        overflowY: 'auto',
      }}
    >
      <DialogTitle className={classes.topTitle}>
        <IconButton aria-label="close" onClick={onCloseCitation}>
          <CloseIcon style={{ color: 'white' }} />
        </IconButton>
        {title?.length > 25 ? (
          <Tooltip title={title} arrow>
            <span>{title?.substring(0, 25)}...</span>
          </Tooltip>
        ) : (
          title
        )}
      </DialogTitle>
      <DialogContent className={classes.contentMessage}>
        <MarkdownContent content={content} gptEngine={gptEngine} />
      </DialogContent>
    </div>
  );
};

// Correctly wrap the component with React.memo and export it
const Citation = React.memo(CitationComponent);

export default Citation;
