/**
 * This component renders of a Context M2Global comp of the page
 * new api iSearcherCEPGetFilters changes done by @Ranjan for the Filter First time loaded
 * new object atrributes added
 * @param {string} props
 * @returns {ReactNode} A React element that renders M2Global component of the page.
 *  Copyright @ Alstom 2023
 *  auther @Ranjan
 */
import React, { useEffect, useMemo, useReducer } from 'react';

export interface UserContextType {
  rexResult: any;
  setRexResult: any;
}

type Props = {
  children: any;
};

const UserContext = React.createContext<UserContextType>({
  rexResult: {} as any,
  setRexResult: {} as any,
});

const UserContextProvider = ({ children }: Props) => {
  const [rexResult, setRexResult] = React.useState<any>([]);

  const value = useMemo(() => ({ rexResult, setRexResult }), [rexResult]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserContext;

export { UserContextProvider };
