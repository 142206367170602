import React from 'react';
import { Box, IconButton } from '@mui/material';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

const MainContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  //   display: 'grid',
  //   gridTemplateColumns: '2fr 3fr',
  width: '100%',
  height: '90%',
  //   backgroundColor: 'rgb(255, 255, 255)',
  //   border: '1px solid #ccd6f2',
  //   margin: '0 auto',
  //   [theme.breakpoints.up('lg')]: {
  //     height: '30rem',
  //   },
  //   [theme.breakpoints.up('xl')]: {
  //     height: '25rem',
  //   },
}));

const SelectorContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'grid',
  gridTemplateColumns: '1.78fr 0.2fr 2.7fr',
  //   backgroundColor: 'rgb(255, 255, 255)',
  position: 'relative',
  transition: 'all 0.3s ease-out',
  //   border: '1px solid #ccd6f2',
}));

const Header = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'inline-block',
  height: '100%',
  //   width: 'calc(100% / 2 - 20px)',
  // cursor: 'pointer',
  backgroundColor: '#fafafa',
}));

const Selector = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'inline-block',
  border: '1px solid #ccd6f2',
  transition: 'all 0.3s ease-out',
  backgroundColor: 'rgb(255, 255, 255)',
  borderRadius: '8px',
  height: '100%',
  cursor: 'pointer',
  '&:hover': {
    color: '#EEF1F9',
    //   border: 'none',
    transform: 'scale(1.0)',
    zIndex: 4,
    transition: 'border 0.1s ease-out',
    border: '1px solid #002a45',
    // transform: 'scale(1.05)',
    // boxShadow: '0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06)',
  },
}));

const Label = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  height: '100%',
  justifyContent: 'space-between',
  width: '100%',
  transition: 'all 0.3s ease-out',
  padding: '0.3rem 0.3rem 0.3rem 0.9rem',
  alignItems: 'center',
  color: '#002a45',
  fontWeight: 800,
}));

const DocTranslatorHeader = (props) => {
  return (
    <MainContainer>
      <SelectorContainer>
        <Header>
          <Selector style={{ width: '100%' }}>
            <Label>
              Source <ArrowDropDownIcon fontSize="large" />
            </Label>
          </Selector>
        </Header>
        <IconButton
          style={{
            // position: 'absolute',
            // top: '50%',
            // left: '50%',
            // transform: 'translate(-50%, -50%)',
            color: '#002a45',
          }}
        >
          <SwapHorizIcon />
        </IconButton>
        <Header>
          <Selector style={{ width: '75%' }}>
            <Label>
              Target <ArrowDropDownIcon fontSize="large" />
            </Label>
          </Selector>
          <Selector style={{ marginLeft: '10px', width: 'calc(25% - 10px)' }}>
            <Label>
              Miscrosoft <ArrowDropDownIcon fontSize="large" />
            </Label>
          </Selector>
        </Header>
      </SelectorContainer>
    </MainContainer>
  );
};

export default DocTranslatorHeader;
