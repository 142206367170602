import {
  Column,
  DataTypeProvider,
  EditingState,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import {
  ColumnChooser,
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableColumnVisibility,
  TableEditColumn,
  TableEditRow,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AnalysisDataset } from 'AnalysisDataset';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import ReactJson from 'react-json-view';
import { Link, useParams } from 'react-router-dom';
import XLSX from 'xlsx';
import ProjectContext from '../../../ProjectContext';
import {
  checkDuplicationName,
  checkDuplicationRationale,
  checkRationales,
  checkRegName,
  deleteAnalysisDataset,
  getAllImportedAnalysis,
  getUserRole,
  writeAnalysisDataset,
} from '../../../../../api/analysis_dataset';
import { IS_DEBUG_MODE } from '../../../../../const';
import ToolBarPlugin from '../../../../../components/Datagrid/ToolBarPlugin';
import formatDate, { formatLocal } from '../../../../../tools/formatDate';
import ModalGapNcRow from '../../../components/ModalGapNcRow';
import Loading from '../../../../../components/Core/Loading';
import useProjectId from '../../../../../app/useProjectId';
import TableComponent from '../../../../../components/Datagrid/TableComponent';
/**
 * 1/ import file + disable buttons Edit & Delete
 * 2/ when imported is OK disable Button Importation and enable buttons Edit & Delete
 * 3/ save button has 2 behaviours : save from import and save new edition/deletion
 */
const useStyles = makeStyles(() => ({
  root: {},
  hoverEff: {
    color: 'red',
    // backgroundColor: 'red',
    '& .MuiButton-root': {
      // backgroundColor: Theme.palette.primary.main,
      textAlign: 'center',
      marginLeft: 60,
      marginTop: 10,
      color: 'white',
    },
    '& :hover': {
      color: 'white !important',
      opacity: 0.8,
    },
  },
}));

const columnsNc = [
  {
    name: 'id',
    title: 'ID',
  },
  {
    name: 'nc',
    title: 'Non conformity',
  },
  {
    name: 'rationale',
    title: 'Rationale',
  },
  // {
  //   name: 'user_id',
  // },
  {
    name: 'user_name',
    title: 'Created by',
  },
  {
    name: 'created_at',
    title: 'Created at',
  },
  // {
  //   name: 'updated_by',
  // },
  {
    name: 'updated_by_username',
    title: 'Updated by',
  },
  {
    name: 'updated_at',
    title: 'Updated at',
  },
  {
    name: 'owning_ids',
    title: 'List of Requirement',
  },
] as Column[];

const columnsGap = [
  {
    name: 'id',
    title: 'ID',
  },
  {
    name: 'gap',
    title: 'GAP',
  },
  {
    name: 'rationale',
    title: 'Rationale',
  },
  // {
  //   name: 'user_id',
  // },
  {
    name: 'user_name',
    title: 'Created by',
  },
  {
    name: 'created_at',
    title: 'Created at',
  },
  // {
  //   name: 'updated_by',
  // },
  {
    name: 'updated_by_username',
    title: 'Updated by',
  },
  {
    name: 'updated_at',
    title: 'Updated at',
  },
  {
    name: 'owning_ids',
    title: 'List of Requirement',
  },
] as Column[];

type editionType = 'excelImport' | 'manualMode';

type Props = {
  datatype: 'nc' | 'gap';
};
const Cell = ({ tableRow, editingEnabled, ...restProps }) => (
  <TableEditRow.Cell
    tableRow={tableRow}
    editingEnabled={tableRow.type === TableEditRow.ADDED_ROW_TYPE ? true : editingEnabled}
    {...restProps}
  />
);

function Dataset({ datatype }: Props) {
  const classes = useStyles();
  const { projectId } = useParams() as any;
  const { projects_lite, setProject, setSnack, userJWT } = useContext(ProjectContext);
  const { project } = useProjectId(projectId, projects_lite);
  const [loading, setLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<AnalysisDataset[]>([]);
  const [columns, setColumns] = useState<Column[]>([]);
  const [selectionRows, setSelectionRows] = useState<any[]>([]);
  const [toggleHeight, setToggleHeight] = useState(false);
  const [idsChanged, setIdsChanged] = useState<any>([]);
  const [roleForUser, setRoleForUser] = useState<any>(undefined);
  // state for new row
  const [openModalGapNc, setOpenModalGapNc] = useState(false);
  const [editColumns, setEditColumns] = useState<{ columnName: string; editingEnabled: boolean }[]>(
    []
  );

  const editionMode: editionType =
    rows.length === 0 || rows.findIndex((r) => Number(r.id) > 0) === -1
      ? 'excelImport'
      : 'manualMode';

  const exporterRef = useRef(null);

  const handleExport = useCallback(() => {
    exporterRef.current.exportGrid();
  }, [exporterRef]);

  const handleSaveAs = (filenameSave: string) => (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), filenameSave);
    });
  };

  useEffect(() => {
    setLoading(true);
    const columns = datatype === 'nc' ? columnsNc : columnsGap;
    setColumns(columns);
    getUserRole(projectId).then((resp) => {
      if (resp) {
        setRoleForUser(resp[0]?.role_id);
      }
    });
    const editCol = columns.map((c) => {
      let editingEnabled = false;

      if (c.name === 'rationale') {
        editingEnabled = true;
      }
      return {
        columnName: c.name,
        editingEnabled,
      };
    });
    setEditColumns(editCol);
    getAllImportedAnalysis(projectId)
      .then((response) => {
        // by default load
        setRows(
          response.filter((r) => r.datatype === datatype).map((r) => ({ ...r, [datatype]: r.name }))
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [datatype, projectId]);

  const handleImport = async (e: React.ChangeEvent<any>) => {
    setSnack('Click on save button', 'info');
    // fix undefined files
    if (!e.currentTarget.files || !e.currentTarget.files[0]) return;
    const myFile = e.currentTarget.files[0];
    const reader = new FileReader();

    reader.readAsArrayBuffer(myFile);
    reader.onload = () => {
      const data = new Uint8Array(reader.result as any);
      const wb = XLSX.read(data, { type: 'array' });
      const jsonImport = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
      // remapping column names for table devexpress
      const jsonTable = jsonImport.map((row: any) => {
        const rowImport = {} as any;
        columns.forEach((col) => {
          rowImport[col.name] = row[col.title ?? ''];
        });
        return rowImport;
      });
      // console.log(jsonTable);
      setRows(jsonTable);
      // console.log(XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]));
    };
    // e.currentTarget.value = '';
  };

  const commitChanges = ({ changed }) => {
    let changedRows = [];
    if (changed) {
      changedRows = rows.map((each, index) =>
        changed[index] ? { ...each, ...changed[index] } : each
      );
      const idsChangedNew = rows.filter((row, index) => changed[index]).map((row) => row.id);
      setIdsChanged([...new Set([...idsChanged, ...idsChangedNew])]);
      setRows(changedRows);
    }
  };

  const renderLinkAllocation = ({ value }: DataTypeProvider.ValueFormatterProps) => {
    if (!value || !Array.isArray(value)) return '-';
    return value.map((v: any) => {
      return (
        <div>
          <Link
            to={`/projects/${v.project_id}/module1/m1/results/${v.workload_id}?action=analyze`}
            style={{ textDecoration: 'none' }}
            title={v.filename}
          >
            {v.owning_id}
          </Link>
        </div>
      );
    });
  };
  const handleSave = (editionMode: editionType) => {
    const payload: AnalysisDataset[] = rows.map(
      (r: any): AnalysisDataset => ({
        ...r,
        datatype,
        name: r[datatype],
        project_id: projectId,
      })
    );

    const iDuplication = checkDuplicationName(payload);
    if (iDuplication !== -1) {
      setSnack(`Duplication label : ${payload[iDuplication].name}`, 'error');
      return;
    }
    const iDuplicationR = checkDuplicationRationale(payload);
    if (iDuplicationR !== -1) {
      setSnack(`Duplication rationale: ${payload[iDuplicationR].rationale}`, 'error');
      return;
    }
    const iReg = checkRegName(payload, datatype);
    if (iReg !== -1) {
      if (datatype === 'gap')
        setSnack(
          `Synthax error (must start with GAP_, 4 chars mini, alphanumeric - _ ): ${payload[iReg].name}`,
          'error'
        );
      if (datatype === 'nc')
        setSnack(
          `Synthax error (must start with NC_, 4 chars mini, alphanumeric - _ ): ${payload[iReg].name}`,
          'error'
        );
      return;
    }
    const checkempty = checkRationales(payload);
    if (checkempty) {
      setSnack('Rationale should not empty', 'error');
      return;
    }
    writeAnalysisDataset(projectId, datatype, payload, editionMode).then((response) => {
      // console.log(response);
      setProject({ ...project, analysis_dataset: response });
      // setNcList(response.filter((r) => r.datatype === 'nc').map((r) => ({ ...r, nc: r.name })));
      // setGapList(response.filter((r) => r.datatype === 'gap').map((r) => ({ ...r, gap: r.name })));
      setRows(
        response.filter((r) => r.datatype === datatype).map((r) => ({ ...r, [datatype]: r.name }))
      );
      setSnack('Update ok', 'success');
    });
  };
  const handleDelete = () => {
    const payload: number[] = selectionRows.map((r: any) => rows[r].id);
    if (payload.length === 0) {
      setSnack('No selection', 'warning');
      return;
    }
    deleteAnalysisDataset(projectId, payload).then((response) => {
      // console.log(response);
      setProject({ ...project, analysis_dataset: response });
      setRows(
        response.filter((r) => r.datatype === datatype).map((r) => ({ ...r, [datatype]: r.name }))
      );
      setSelectionRows([]);
      setSnack('Delete ok', 'success');
    });
  };

  const handleNewRow = () => {
    setOpenModalGapNc(true);
  };

  const handleModalClose = () => {
    setOpenModalGapNc(false);
  };

  const handleSubmitAdd = (added: any) => {
    const payload = [
      ...rows,
      {
        [datatype]: added.name as string,
        name: added.name,
        rationale: added.rationale,
        project_id: projectId,
        datatype,
        owning_ids: null,
      },
    ];
    const iDuplication = checkDuplicationName(payload);
    if (iDuplication !== -1) {
      setSnack(`Duplication label : ${payload[iDuplication].name}`, 'error');
      return;
    }
    const iDuplicationR = checkDuplicationRationale(payload);
    if (iDuplicationR !== -1) {
      setSnack(`Duplication rationale: ${payload[iDuplicationR].rationale}`, 'error');
      return;
    }
    const iReg = checkRegName(payload, datatype);
    if (iReg !== -1) {
      if (datatype === 'gap')
        setSnack(
          `Synthax error (must start with GAP_, 4 chars mini, alphanumeric - _ ): ${payload[iReg].name}`,
          'error'
        );
      if (datatype === 'nc')
        setSnack(
          `Synthax error (must start with NC_, 4 chars mini, alphanumeric - _ ): ${payload[iReg].name}`,
          'error'
        );
      return;
    }
    setRows(payload);
    handleModalClose();
    setSnack('Click on save button', 'info');
  };
  const HeaderHover: React.FunctionComponent<Table.CellProps> = (props) => {
    return <Table.Container {...props} className={classes.hoverEff} />;
  };

  if (!project) return <Loading />;
  if (loading) return <Loading />;
  return (
    <Box className={classes.root}>
      {IS_DEBUG_MODE && (
        <ReactJson
          src={{
            datatype,
            columns,
            selectionRows,
          }}
          collapsed={true}
          theme="monokai"
        />
      )}
      {roleForUser === 1 || roleForUser === 2 ? (
        <>
          <ExpressGrid rows={rows} columns={columns}>
            <DataTypeProvider
              for={['created_at', 'updated_at']}
              formatterComponent={({ value }) => <>{formatDate(value, formatLocal())}</>}
            />
            <DataTypeProvider for={['owning_ids']} formatterComponent={renderLinkAllocation} />
            <SortingState />
            <IntegratedSorting />
            <PagingState defaultCurrentPage={0} defaultPageSize={10} />
            <SelectionState selection={selectionRows} onSelectionChange={setSelectionRows} />
            <IntegratedSelection />
            {/* filtering */}
            <FilteringState />
            <IntegratedPaging />
            <IntegratedFiltering />
            <Table
              columnExtensions={columns.map((c) => ({
                columnName: c.name,
                wordWrapEnabled: toggleHeight,
              }))}
              tableComponent={TableComponent}
            />
            <TableColumnResizing
              defaultColumnWidths={[
                ...columns.map((c) => {
                  if (c.name === 'id') return { columnName: c.name, width: 80 };
                  if (c.name === 'nc') return { columnName: c.name, width: 140 };
                  if (c.name === 'gap') return { columnName: c.name, width: 140 };
                  if (c.name === 'rationale') return { columnName: c.name, width: 200 };
                  if (c.name === 'created_at') return { columnName: c.name, width: 150 };
                  if (c.name === 'updated_at') return { columnName: c.name, width: 150 };
                  if (c.name === 'owning_ids') return { columnName: c.name, width: 150 };
                  return {
                    columnName: c.name,
                    width: Math.ceil(1400 / columns.length),
                  };
                }),
              ]}
            />

            <TableColumnVisibility defaultHiddenColumnNames={['user_id', 'updated_by']} />
            {/* filtering */}
            <TableFilterRow />
            <Toolbar />
            {editionMode === 'manualMode' && <TableSelection showSelectAll />}

            {/* selection */}
            <ColumnChooser />
            <ToolBarPlugin name="NewCapture" onClick={handleNewRow} title="Insert new row" />
            {editionMode === 'manualMode' && <ToolBarPlugin name="Delete" onClick={handleDelete} />}
            {editionMode === 'manualMode' && (
              <ToolBarPlugin
                name="Save"
                title="Save Edition"
                onClick={() => handleSave('manualMode')}
              />
            )}
            {editionMode === 'excelImport' && (
              <ToolBarPlugin
                name="Save"
                title="Save Importation Excel"
                onClick={() => handleSave('excelImport')}
              />
            )}
            {editionMode === 'excelImport' && (
              <ToolBarPlugin name="ExcelImport" title="Import excel file" onClick={handleImport} />
            )}
            <ToolBarPlugin name="Download" title="Download to excel file" onClick={handleExport} />
            <ToolBarPlugin
              name="Height"
              title="Wrap Text"
              onClick={() => setToggleHeight(!toggleHeight)}
            />

            <EditingState onCommitChanges={commitChanges} columnExtensions={editColumns} />
            <TableHeaderRow showSortingControls />
            <TableEditRow cellComponent={Cell} />

            <TableEditColumn
              showEditCommand={editionMode === 'manualMode'}
              headerCellComponent={HeaderHover}
            />

            <PagingPanel pageSizes={[10, 20, 50]} />
          </ExpressGrid>
        </>
      ) : (
        /* performer can :
          - add NC/GAP via button
          */
        <>
          <ExpressGrid rows={rows} columns={columns}>
            <DataTypeProvider
              for={['created_at', 'updated_at']}
              formatterComponent={({ value }) => <>{formatDate(value, formatLocal())}</>}
            />
            <DataTypeProvider for={['owning_ids']} formatterComponent={renderLinkAllocation} />
            <SortingState />
            <IntegratedSorting />
            <PagingState defaultCurrentPage={0} defaultPageSize={10} />
            {/* filtering */}
            <FilteringState />
            <IntegratedPaging />
            <IntegratedFiltering />
            <Table
              columnExtensions={columns.map((c) => ({
                columnName: c.name,
                wordWrapEnabled: toggleHeight,
              }))}
              tableComponent={TableComponent}
            />
            <TableColumnResizing
              defaultColumnWidths={[
                ...columns.map((c) => {
                  if (c.name === 'id') return { columnName: 'id', width: 100 };
                  if (c.name === 'nc') return { columnName: 'nc', width: 100 };
                  if (c.name === 'gap') return { columnName: 'gap', width: 100 };
                  if (c.name === 'rationale') return { columnName: 'rationale', width: 200 };
                  if (c.name === 'created_at') return { columnName: 'created_at', width: 150 };
                  if (c.name === 'owning_ids') return { columnName: 'owning_ids', width: 600 };
                  return {
                    columnName: c.name,
                    width: Math.ceil(1400 / columns.length),
                  };
                }),
              ]}
            />
            <TableColumnVisibility defaultHiddenColumnNames={['user_id', 'updated_by']} />
            {/* filtering */}
            <TableFilterRow />
            <Toolbar />
            <ColumnChooser />
            <ToolBarPlugin name="Save" onClick={handleSave} />
            <ToolBarPlugin name="NewCapture" onClick={handleNewRow} title="Insert new row" />
            <ToolBarPlugin name="Download" title="Download to excel file" onClick={handleExport} />
            <ToolBarPlugin
              name="Height"
              title="Wrap Text"
              onClick={() => setToggleHeight(!toggleHeight)}
            />
            <TableHeaderRow showSortingControls />
            <PagingPanel pageSizes={[10, 20, 50]} />
          </ExpressGrid>
        </>
      )}

      <GridExporter
        ref={exporterRef}
        rows={rows}
        columns={columns.map((c) => ({ name: c.name, title: c.title }))}
        onSave={handleSaveAs(`projectanalysis-${projectId}-${datatype}.xlsx`)}
      />
      {openModalGapNc && (
        <ModalGapNcRow datatype={datatype} onClose={handleModalClose} onSubmit={handleSubmitAdd} />
      )}
    </Box>
  );
}

export default Dataset;
