import { Grid, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowUpOutlinedIcon from '@material-ui/icons/KeyboardArrowUpOutlined';
import { TreeItem, TreeView } from '@material-ui/lab';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import AttributionOutlinedIcon from '@mui/icons-material/AttributionOutlined';
import BallotIcon from '@mui/icons-material/Ballot';
import WorkIcon from '@mui/icons-material/Work';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ProjectBreadCrumbs from '../../components/Core/ProjectBreadCrumbs';
import TreeAllStyle from '../../components/TreeMenu/TreeAllStyle';
import TreeArowIcons from '../../components/TreeMenu/TreeArowIcons';
import ProjectActions from './components/ProjectUsers';
import ProjectAttributeContainer from './ProjectAttributeContainer';
import ProjectBreakdownsContainer from './ProjectBreakdownsContainer';
import ProjectProperties from './components/ProjectProperties';
import IVerifierAttributeContainer from '../Iverifier/Config/IVerifierAttributeContainer';
import { ENV } from '../../const';

function ProjectConfigContainer() {
  const classes = TreeAllStyle();
  const arowClasses = TreeArowIcons();
  const { projectId } = useParams() as any;
  const queryParams = new URLSearchParams(window.location.search);
  const treeItemOpen = queryParams.get('action') || '';

  useEffect(() => {
    // setSnackMsg('dddd');
    // setSnackType('success');
  }, []);

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          {/* <ProjectBreadCrumbs
            projectId={projectId}
            nodes={[
              {
                label: 'Configuration',
                to: `#`,
              },
            ]}
          /> */}
        </Grid>
        <TreeView
          className={classes.treeRoot}
          defaultCollapseIcon={<ExpandMoreIcon className={arowClasses.ArowdownStyle} />}
          defaultExpandIcon={<KeyboardArrowUpOutlinedIcon className={arowClasses.ArowupStyle} />}
          defaultExpanded={[treeItemOpen]}
        >
          <TreeItem
            nodeId="project_properties"
            label={
              <Typography variant="subtitle1" className={classes.treeLabel}>
                <span>
                  <WorkIcon className={classes.treeIcon} />
                </span>
                Properties
              </Typography>
            }
            className={classes.treeitem}
            classes={{
              label: classes.label,
              selected: classes.label,
              content: classes.content,
              group: classes.group,
            }}
          >
            <ProjectProperties />
          </TreeItem>
          <TreeItem
            nodeId="0"
            label={
              <Typography variant="subtitle1" className={classes.treeLabel}>
                <span>
                  <PersonRoundedIcon className={classes.treeIcon} />
                </span>
                Users
              </Typography>
            }
            className={classes.treeitem}
            classes={{
              label: classes.label,
              selected: classes.label,
              content: classes.content,
              group: classes.group,
            }}
          >
            <ProjectActions />
          </TreeItem>
          <TreeItem
            nodeId="1"
            label={
              <Typography variant="subtitle1" className={classes.treeLabel}>
                <span>
                  <GroupRoundedIcon className={classes.treeIcon} />
                </span>
                Groups
              </Typography>
            }
            className={classes.treeitem}
            classes={{
              label: classes.label,
              selected: classes.label,
              content: classes.content,
              group: classes.group,
            }}
          >
            <ProjectBreakdownsContainer />
          </TreeItem>
          <TreeItem
            nodeId="2"
            label={
              <Typography variant="subtitle1" className={classes.treeLabel}>
                <span>
                  <BallotIcon className={classes.treeIcon} />
                </span>
                Attributes
              </Typography>
            }
            className={classes.treeitem}
            classes={{
              label: classes.label,
              selected: classes.label,
              content: classes.content,
              group: classes.group,
            }}
          >
            <ProjectAttributeContainer projectId={projectId} />
          </TreeItem>
          <TreeItem
            nodeId="4"
            label={
              <Typography variant="subtitle1" className={classes.treeLabel}>
                <span>
                  <BallotIcon className={classes.treeIcon} />
                </span>
                iVerifier
              </Typography>
            }
            className={classes.treeitem}
            classes={{
              label: classes.label,
              selected: classes.label,
              content: classes.content,
              group: classes.group,
            }}
          >
            <IVerifierAttributeContainer projectId={projectId} />
          </TreeItem>
          <TreeItem
            nodeId="5"
            label={
              <Typography variant="subtitle1" className={classes.treeLabel}>
                <span>
                  <BallotIcon className={classes.treeIcon} />
                </span>
                iQualimeter
              </Typography>
            }
            className={classes.treeitem}
            classes={{
              label: classes.label,
              selected: classes.label,
              content: classes.content,
              group: classes.group,
            }}
          >
            {/* <IQualimeterAttributeContainer projectId={projectId} /> */}
          </TreeItem>
        </TreeView>
      </Grid>
    </div>
  );
}

export default ProjectConfigContainer;
