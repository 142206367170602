import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import PercentageLoading from './PercentageLoading';
import useWebSocketClient from './useWebSocketClient';

const WebSocketLoading = (props) => {
  const { serviceName, serviceFor, workload, setSelectedChildIndex } = props;

  const { status } = useWebSocketClient({
    serviceName,
    serviceFor,
    workload: workload.workload ?? 0,
  });

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {status.isDone ? (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setSelectedChildIndex(2);
          }}
        >
          View
        </Button>
      ) : (
        <PercentageLoading percentage={status.percentage} />
      )}
      {/* <h1>WebSocketLoading</h1> */}
    </div>
  );
};

export default WebSocketLoading;
