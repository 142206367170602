import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  ColumnChooser,
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableColumnVisibility,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import CheckCircleIcon from '@mui/icons-material/EditOff';
import EditIcon from '@mui/icons-material/Edit';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { deleteFuncBulk, duplicateFuncBulk } from '../../../api/iverifier';
import { getFunctionProject } from '../../../api/module3';
import Loading from '../../../components/Core/Loading';
import TableComponent from '../../../components/Datagrid/TableComponent';
import ToolBarPlugin from '../../../components/Datagrid/ToolBarPlugin';
import {
  tableContainerComponent,
  tableHeaderComponent,
  toolbarComponent,
} from '../../../components/Datagrid/TStyles';
import { handleColumns } from '../../../tools/datagrid';
import ModalFunctionEditor from './ModalFunctionEditor';
import ProjectContext from '../../Project/ProjectContext';
import formatDate, { formatLocal } from '../../../tools/formatDate';
import { renderUser } from '../Admin/IverifierTemplatesAdmin';

const columns = handleColumns([
  'id',
  'standard',
  'function',
  'mapping',
  'example',
  'created_at',
  'created_by_user',
  'updated_at',
  'updated_by_user',
]);

function IverifierFunction() {
  const { projectId } = useParams() as any;
  const { setSnack } = useContext(ProjectContext);
  const [rows, setRows] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [selection, setSelection] = useState<any>([]);
  const [toggleHeight, setToggleHeight] = useState(false);

  // modal state
  const [toggleEditor, setToggleFuncEditor] = useState(false);
  const [rowSelected, setRowSelected] = useState<any>(undefined);
  const [toggleConfirm, setToggleConfirm] = useState(false);
  const [idFilter, setIdFilter] = useState<any>([]);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    refreshPage();
  }, []);

  function refreshPage() {
    getFunctionProject(projectId).then((response) => {
      setRows(response);
      setLoading(false);
    });
  }

  const handleNewFunction = () => {
    setRowSelected(undefined);
    setToggleFuncEditor(true);
  };

  const handleButtonId = (id: number) => {
    const rowIndex = rows.find((e: any) => e.id === id);
    if (rowIndex) {
      setRowSelected(rowIndex);
      setToggleFuncEditor(true);
    }
  };

  const handleCopy = () => {
    if (selection.length === 0) return;
    // const selectionIds = selection.map((e) => rows[e].id);
    duplicateFuncBulk(selection).then((res) => {
      setRows([...res, ...rows]);
      setSelection([]);
    });
  };

  const handleDelete = () => {
    if (selection.length === 0) return;

    if (selection.length > 0) setToggleConfirm(true);
    const standardId: any[] = [];
    // eslint-disable-next-line array-callback-return
    selection.map((a: any, i: any) => {
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < rows.length; index++) {
        // eslint-disable-next-line eqeqeq
        if (rows[index]?.id == selection[i]) {
          if (rows[index]?.standard === true) {
            standardId.push(selection[i]);
          }
        }
      }
    });
    setIdFilter(standardId);
  };

  const handleConfirmOK = () => {
    setToggleConfirm(false);
    const standardId: any[] = [];
    // eslint-disable-next-line array-callback-return
    selection.map((a: any, i: any) => {
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < rows.length; index++) {
        // eslint-disable-next-line eqeqeq
        if (rows[index]?.id == selection[i]) {
          if (rows[index]?.standard === true) {
            standardId.push(selection[i]);
          }
        }
      }
    });

    const diff1 = selection.filter((x) => !standardId.includes(x));

    // const selectionIds = selection.map((e) => rows[e].id);
    deleteFuncBulk(diff1).then((res) => {
      // setRows(rows.filter((e) => !selectionIds.includes(e.id)));
      // setSelection([]);
      setSelection([]);
      setSnack('Selected functions are deleted', 'success');
      if (standardId.length > 0) {
        setSnack(`functions ids: ${standardId}  => standard functions so can't deleted.`, 'error');
      }
      refreshPage();
    });
  };

  const handleConfirmClose = () => {
    setToggleConfirm(false);
  };

  const renderId = ({ value, row }: DataTypeProvider.ValueFormatterProps) => {
    return <Button onClick={() => handleButtonId(Number(value))}>{value}</Button>;
  };

  const renderFunction = ({ value }: DataTypeProvider.ValueFormatterProps) => {
    return (
      <>
        <b>{value}</b>
      </>
    );
  };

  const renderStandard = ({ value, row }: DataTypeProvider.ValueFormatterProps) => {
    if (row.standard) {
      return <CheckCircleIcon />;
    }
    return (
      <EditIcon
        onClick={() => {
          setRowSelected(row);
          setToggleFuncEditor(true);
        }}
      />
    );
  };
  const getRowId = (row) => row.id;
  if (loading) return <Loading />;
  return (
    <div>
      <ExpressGrid rows={rows} columns={columns} getRowId={getRowId}>
        <DataTypeProvider for={['id']} formatterComponent={renderId} />
        <DataTypeProvider for={['standard']} formatterComponent={renderStandard} />
        <DataTypeProvider for={['function']} formatterComponent={renderFunction} />
        <DataTypeProvider
          for={['created_at', 'updated_at']}
          formatterComponent={({ value }) => <>{formatDate(value, formatLocal())}</>}
        />
        <DataTypeProvider
          for={['created_by_user', 'updated_by_user']}
          formatterComponent={renderUser}
        />
        {/* selection */}
        <SelectionState selection={selection} onSelectionChange={setSelection} />
        <IntegratedSelection />
        {/* filtering */}
        <FilteringState />
        <SortingState />
        <IntegratedSorting />
        <IntegratedFiltering />
        <PagingState defaultCurrentPage={0} defaultPageSize={10} />
        <IntegratedPaging />
        <Table
          columnExtensions={columns.map((c) => ({
            columnName: c.name,
            wordWrapEnabled: toggleHeight,
          }))}
          tableComponent={TableComponent}
          containerComponent={tableContainerComponent}
          headComponent={tableHeaderComponent}
        />
        <TableColumnResizing
          defaultColumnWidths={[
            ...columns.map((c) => {
              if (c.name === 'id') return { columnName: c.name, width: 80 };
              if (c.name === 'standard') return { columnName: c.name, width: 120 };
              if (c.name === 'mapping') return { columnName: c.name, width: 400 };
              if (
                ['created_at', 'created_by_user', 'updated_at', 'updated_by_user'].includes(c.name)
              )
                return { columnName: c.name, width: 150 };
              return {
                columnName: c.name,
                width: Math.ceil(1400 / (columns.length - 7)),
              };
            }),
          ]}
        />
        <TableHeaderRow showSortingControls />
        <TableFilterRow />
        <TableColumnVisibility defaultHiddenColumnNames={[]} />
        <Toolbar rootComponent={toolbarComponent} />
        {/* selection */}
        <TableSelection showSelectAll />
        <ColumnChooser />
        {/* <ToolBarPlugin name="NewCapture" onClick={handleNewFunction} title="Create new function" /> */}
        <ToolBarPlugin name="Delete" onClick={handleDelete} />
        {/* <ToolBarPlugin name="Copy" onClick={handleCopy} /> */}
        <ToolBarPlugin name="Refresh" onClick={refreshPage} />
        <ToolBarPlugin
          name="Height"
          title="Wrap Text"
          onClick={() => {
            setToggleHeight(!toggleHeight);
          }}
        />
        <ToolBarPlugin name="Download" onClick={null} />
        <PagingPanel pageSizes={[10, 20, 50]} />
      </ExpressGrid>
      {toggleEditor && (
        <ModalFunctionEditor
          data={rowSelected}
          onClose={(response) => {
            setToggleFuncEditor(false);
            setRowSelected(undefined);
            // if (response) setRows([response, ...rows]);
            refreshPage();
          }}
        />
      )}
      <Dialog
        open={toggleConfirm}
        onClose={handleConfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm deleting the functions</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to delete the selected functions ?
            <br />
            <span style={{ color: 'red' }}>
              {idFilter.length !== 0 ? `ids: ${idFilter}  standard functions so not deletable` : ''}
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirmOK} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default IverifierFunction;
