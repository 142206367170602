/* eslint-disable import/no-mutable-exports */
import * as React from 'react';
import { red } from '@mui/material/colors';
import { ThemeProvider, createTheme } from '@mui/material/styles';

let theme = createTheme({
  palette: {
    primary: {
      main: '#002A45',
    },
    secondary: {
      main: '#DC3223',
      light: '#E15A50',
    },
    info: {
      main: '#002A45',
    },
    success: {
      main: '#fff',
    },
  },
  typography: {
    body1: {
      fontWeight: 500,
      color: '#002A45',
    },
    h1: {
      fontSize: '1.3rem',
      fontWeight: 600,
      color: '#002A45',
    },
    subtitle1: {
      font: 'normal normal medium 40px/52px Alstom',
      fontWeight: 120,
      color: '#002A45',
    },
  },
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          boxShadow: 'none',
          textTransform: 'none',
          fontSize: 16,
          padding: '6px 12px',
          border: '1px solid',
          lineHeight: 1.5,
        },
      },
      // variants: [
      //   {
      //     props: { variant: 'outlined' },
      //     style: {
      //       textTransform: 'none',
      //       border: `2px dashed red`,
      //     },
      //   },
      // ],
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1700,
      xl: 2500,
    },
  },
});

theme = {
  ...theme,
  typography: {
    ...theme.typography,
    h3: {
      fontSize: '1.2rem',
      '@media (min-width:600px)': {
        fontSize: '1.5rem',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '2rem',
      },
    },
  },
};

export default theme;
