import React, { memo, useContext } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import useProjectId from '../../app/useProjectId';
import Loading from '../../components/Core/Loading';
import PageLayout from '../../layouts/PageLayout';
import ProjectContext from '../Project/ProjectContext';
import IverifierContainer from './IverifierContainer';
import Module3Components from './components/Module3Components';
import Module3Context, { Module3ContextProvider } from './Module3Context';
import ResultContainer from './ResultContainer';
import Run3Context, { Run3ContextProvider } from './RunContext';
import Module3ContainerNew from './TestGneratorMain/Module3ContainerNew';

function Module3Router() {
  const { projectId } = useParams() as any;
  const { projects } = useContext(ProjectContext);
  const queryParams = new URLSearchParams(window.location.search);
  const actionIs = queryParams.get('action');
  const { project } = useProjectId(projectId, projects) as Project;

  if (project === undefined) return <Loading />;

  return (
    <Module3ContextProvider project={project}>
      <Module3Context.Consumer>
        {(): any => {
          return (
            <Switch>
              <Route
                exact
                path="/project/:projectId/module3/:moduleroute"
                // render={() => <Module3Components />}
                render={() => <Module3ContainerNew />}
              />
              {/* <PageLayout
                exact
                path="/project/:projectId/module3/:moduleroute"
                render={() => <Module3Components />}
              /> */}
              <PageLayout
                exact
                path="/project/:projectId/module3/:moduleroute/configs/:configId"
                render={() => {
                  return (
                    <>
                      {/* 
                            init context: we select ALL rules & functions by default */}
                      <Run3ContextProvider>
                        <Run3Context.Consumer>{() => <IverifierContainer />}</Run3Context.Consumer>
                      </Run3ContextProvider>
                    </>
                  );
                }}
              />
              {actionIs === 'iQualimeter' && (
                <PageLayout
                  exact
                  path="/project/:projectId/module3/:moduleroute/results/:workloadId"
                  render={() => {
                    return <Module3ContainerNew />;
                    // return <ResultContainer />;
                  }}
                />
              )}
              {actionIs === 'iverifier' && (
                <PageLayout
                  exact
                  path="/project/:projectId/module3/:moduleroute/results/:workloadId"
                  render={() => {
                    return <ResultContainer />;
                  }}
                />
              )}
              <Route path="/project/:projectId/module3/:moduleroute/*" render={() => null} />
            </Switch>
          );
        }}
      </Module3Context.Consumer>
    </Module3ContextProvider>
  );
}

export default memo(Module3Router);
