/* eslint-disable consistent-return */
import React from 'react';
import { BACKEND_URL } from '../../const';
import formatBytes from '../../tools/formatBytes';
import axios from '../../api/axios';
import { fileDetails } from '../../api/module1';

const useUpload = (props) => {
  const { serviceFor = 'project', user_id = '', projectId = '', project } = props;
  const [loading, setLoading] = React.useState(false);

  //   console.log('props useUpload', props);
  const uploadURLCall = async (uploadUrl: any, formdata: FormData) => {
    try {
      const uplResponse = await axios.post(uploadUrl, formdata, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return uplResponse.data;
      // setFiles(allResources);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeFiles = async (event: any) => {
    try {
      const len = Object?.keys(event)?.length;
      const arraylist = [];
      for (let i = 0; i < len; i += 1) {
        arraylist.push(event[i]);
      }
      for (const eachFile of arraylist) {
        const ext = eachFile?.name?.split('.');

        if (!['xlsx', 'csv', 'pdf', 'docx'].includes(ext[ext?.length - 1])) {
          // setSnack(`${eachFile.name} not supported`, 'error');
          return { error: true, message: `${eachFile.name} not supported` };
        }
        if (eachFile.name?.length >= 80) {
          //   setSnack('file name should be less than 80 characters', 'error');
          return { error: true, message: 'file name should be less than 80 characters' };
        }
      }
      const formdata = new FormData();
      const names = arraylist.map((e: any) => {
        formdata.append('files', e);
        return e.name;
      });
      //   for (const eachFile of files) {
      //     if (names.includes(eachFile.filename)) {
      //       //   setSnack(`File name ${eachFile.filename} already exists.`, 'error');
      //       return;
      //     }
      //   }
      if (formdata.get('files') && user_id && serviceFor === 'user') {
        // setIconload(true);
        const uploadUrl = BACKEND_URL.uploadUserDoc.replace('userId', user_id);

        const results = await uploadURLCall(uploadUrl, formdata);
        return { error: false, result: results, name: names };
      }

      if (formdata.get('files') && projectId && serviceFor === 'project') {
        // setIconload(true);
        const uploadUrl = BACKEND_URL.upload
          .replace('projectId', projectId)
          .replace('folderId', project?.folders[0]?.id);
        const results = await uploadURLCall(uploadUrl, formdata);
        return { error: false, result: results, name: names };
      }
    } catch (e) {
      console.log(e);
      return { error: true, message: e };
    }
    return null;
  };

  const UpdateNewDataInContext = (result, names) => {
    project.folders[0] = result;
    //   setProject(project);
    const { resources } = result || [];
    const newUploadfile = resources.filter((data: any) => {
      return names.includes(data.filename);
    });
    const NewData = newUploadfile.map((data) => {
      const obj = {
        ...data,
        size: formatBytes(Number(data?.size), 0),
        id: newUploadfile[0]?.etag,
        folderName: project?.folders[0]?.name,
        folderId: project?.folders[0]?.id,
        isNew: true,
      };
      return obj;
    });

    // console.log('NewData', NewData);

    return NewData;
  };

  const FindeFileDetails = async (filename, filenameCopy, container) => {
    try {
      const data = await fileDetails(filename, filenameCopy, container);
      return data;
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleChangeInDragefile = async (event: any) => {
    try {
      setLoading(true);
      const data = await handleChangeFiles(event);
      if (!data?.error && data) {
        const { result, name: names } = data;
        const NewData = UpdateNewDataInContext(result, names);
        const fileDetailsData = await FindeFileDetails(
          NewData[0]?.filename,
          NewData[0]?.filename,
          NewData[0]?.container
        );
        setLoading(false);
        return fileDetailsData?.output ?? null;
      }
      setLoading(false);
      return data;
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleUploadFile = async (event: any) => {
    setLoading(true);
    const data = await handleChangeFiles(event);
    if (!data?.error && data) {
      const { result, name: names } = data;
      const NewData = UpdateNewDataInContext(result, names);
      const fileDetailsData = await FindeFileDetails(
        NewData[0]?.filename,
        NewData[0]?.filename,
        NewData[0]?.container
      );
      setLoading(false);
      return fileDetailsData?.output ?? null;
    }
    setLoading(false);
    return null;
  };

  return { loading, handleChangeInDragefile, handleUploadFile, FindeFileDetails };
};

export default useUpload;
