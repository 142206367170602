/* eslint-disable react/no-children-prop */
/* eslint-disable react/style-prop-object */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
/**
 * This component renders of a Rex analysis comp of the page
 * new api iSearcherSearchRTE changes done by @Ranjan for the Req, Test , Expert
 * Css also fixed here
 * @param {string} props
 * @returns {ReactNode} A React element that renders Rex Analysis component of the page.
 *  Copyright @ Alstom 2023
 *  auther @Ranjan
 */
import {
  Box,
  Button,
  IconButton,
  InputBase,
  Paper,
  Popover,
  Typography,
  Tooltip,
  Slider,
} from '@material-ui/core';
import { List, ListItem } from '@mui/material';
import clsx from 'clsx';
import { isEmpty } from '@antv/util';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useParams,
  useHistory,
} from 'react-router-dom';
import { styled } from '@mui/material/styles';
import GroupsIcon from '@mui/icons-material/Groups';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import ImageIcon from '@mui/icons-material/Image';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import SearchIcon from '@mui/icons-material/Search';
import MultiSelect from 'react-multi-select-component';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import BookIcon from '@mui/icons-material/Book';
import ThreeSixtyIcon from '@mui/icons-material/ThreeSixty';
import RuleOutlinedIcon from '@mui/icons-material/RuleOutlined';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import ChatIcon from '@mui/icons-material/Chat';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';

import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import logoGenA from '../../assets/images/chat_icon_mini.png';

import '../ResultContainer/RexResultsContainer/transitionCss.css';
import { ContainerStyle } from '../Workspace/Components/style/ContainerStyle';
import {
  iSearcherSearch,
  getREX360Response,
  getCRsResponse,
  SixSigmaRex,
  iSearcherSearchRTE,
} from '../../api/module2';
import { getAllProjectCreationLanguages } from '../../api/projects';

// import Remind from '../coredata/Remind';
import { BACKEND_URL, ENV } from '../../const';
import LoadingApp from '../../components/Core/Loading';
import useAppToken from '../../app/useAppToken';
// import Module2Context from '../../Module2Context';
// import REX360 from './REX360';
import M2GlobalContext from '../ContextMager/M2GlobalContext';
import HomeContext from '../ContextMager/HomeContext';
// import UploadButton from '../../core/components/UploadButton';

function useRexApiCall() {
  // TextAnalsis operation states
  const classes = ContainerStyle();
  const history = useHistory();
  const { appJWT } = useAppToken();
  // const { shortCuts, setShortCuts } = useContext(ProjectContext);
  const { state, dispatch, setSideFilter } = useContext(M2GlobalContext);
  const { rexFilter, isearcherFilter, similarityFilter } = state;
  const {
    rexPaltform,
    rexSelectedPlatform,
    rexStatus,
    rexSelectedStatus,
    rexIssuerSite,
    rexSelectedIssuerSite,
    rexTypology,
    rexSelectedTypology,
    rexSource,
    rexSelectedSource,
    rexSolution,
    rexSelectedSolution,
    rexProduct,
    rexSelectedProduct,
    rexProject,
    rexSelectedProject,
    rexCountry,
    rexSelectedCountry,
    rexProductLine,
    rexSelectedProductLine,
    rexMaxResultsValue,
    rexSelectedMaxResultsValue,
    rexMinResultsValue,
    rexSelectedMinResultsValue,
    rexDoor,
    rexSelectedDoor,
  } = rexFilter;

  // const productRex = product;
  // const solutionRex = solution;
  // const projectRex = project;
  // const countryRex = country;

  const {
    isearcherProductLine,
    isearcherProjects,
    isearcherDocumentProfiles,
    isearcherPlatforms,
    isearcherSolution,
    isearcherProduct,
    isearcherCountry,
    isearcherArtiFact,
    selectedIsearcherProductLine,
    selectedIsearcherPlatforms,
    selectedIsearcherProjects,
    selectedIsearcherDocumentProfiles,
    selectedIsearcherArtiFact,
    selectedIsearcherSolution,
    selectedIsearcherProduct,
    selectedIsearcherCountry,
    selectedIsearcherMaxResultsValue: maxResultsValue,
    selectedIsearcherCheckedA,
    selectedIsearcherDoor: door,
  } = isearcherFilter;

  const [progressTime, setProgressTime] = useState(0);
  let progressInterval = null as any;
  // const [remind, setRemind] = useState();
  const { projectId, userId } = useParams() as any;
  const [errorMsg, setErrorMsg] = useState(false);
  const [remidtext, setRemidText] = useState('');
  const [operation, setOperation] = useState(0);
  //   const [inputValue, setInputValue] = useState('');
  const [langResult, setLangResult] = useState('');
  const queryParams = new URLSearchParams(window.location.search);
  const actionIs = queryParams.get('service');
  // const [callOperation, setcallOperation] = useState('Content Search');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [callOperation, setCallOperation] = useState('Content Search');
  const [display, setDisplay] = useState(false);
  const [loading, setLoading] = useState(false);
  //   const [RexResult, setRexResult] = useState({ count: [], data: {} });
  const [REXData, setREXdata] = useState();

  const { setRexResult } = useContext(HomeContext);
  function intlFormat(num: number) {
    return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
  }
  function makeFriendly(num: number) {
    if (num >= 1000000) return `${intlFormat(num / 1000000)}M`;
    if (num >= 1000) return `${intlFormat(num / 1000)}k`;
    return intlFormat(num);
  }

  useEffect(() => {
    return () => {
      clearInterval(progressInterval);
    };
  }, []);

  const generateREX360ApiCall = async (inputValue, redirect, asyncFlag) => {
    setLoading(true);
    let issuerParseValue: any = [];
    let rexplatfromParseValue: any = [];
    let rexSourceParseValue: any = [];
    let rexStatusPareValue: any = [];
    let rexTypologyParseValue: any = [];
    // added @RK
    let rexProductLineVal: any = [];
    let rexsolutionVal: any = [];
    let rexproductVal: any = [];
    let rexprojectVal: any = [];
    let rexcountryVal: any = [];

    let entityIs: any = [];
    let platform: any = [];
    let project: any = [];
    let documentProfile: any = [];
    let artifactEnt: any = [];
    // @ ranjan
    let solution: any = [];
    let product: any = [];
    let country: any = [];
    selectedIsearcherProductLine.forEach((obj: any) => {
      entityIs.push(obj);
    });
    selectedIsearcherPlatforms.forEach((obj: any) => {
      platform.push(obj);
    });
    selectedIsearcherProjects.forEach((obj: any) => {
      project.push(obj);
    });
    selectedIsearcherDocumentProfiles.forEach((obj: any) => {
      documentProfile.push(obj);
    });
    selectedIsearcherSolution.forEach((obj: any) => {
      solution.push(obj);
    });
    selectedIsearcherProduct.forEach((obj: any) => {
      product.push(obj);
    });
    selectedIsearcherCountry.forEach((obj: any) => {
      country.push(obj);
    });
    selectedIsearcherArtiFact.forEach((obj: any) => {
      artifactEnt.push(obj);
    });
    // const startTime = performance.now();
    if (entityIs.length === 0 || entityIs.length === isearcherProductLine.length) {
      entityIs = ['*'];
    }
    if (platform.length === 0 || platform.length === isearcherPlatforms.length) {
      platform = ['*'];
    }
    if (project.length === 0 || project.length === isearcherProjects.length) {
      project = ['*'];
    }
    if (artifactEnt.length === 0 || artifactEnt.length === isearcherArtiFact.length) {
      artifactEnt = ['*'];
    }
    if (
      documentProfile.length === 0 ||
      documentProfile.length === isearcherDocumentProfiles.length
    ) {
      documentProfile = ['*'];
    }
    if (solution.length === 0 || solution.length === isearcherSolution.length) {
      solution = ['*'];
    }
    if (product.length === 0 || product.length === isearcherProduct.length) {
      product = ['*'];
    }
    if (country.length === 0 || country.length === isearcherCountry.length) {
      country = ['*'];
    }

    // console.log(issureSite);

    rexSelectedIssuerSite?.forEach((obj: any) => {
      issuerParseValue.push(obj);
    });
    rexSelectedPlatform?.forEach((obj: any) => {
      rexplatfromParseValue.push(obj);
    });
    rexSelectedSource?.forEach((obj: any) => {
      rexSourceParseValue.push(obj);
    });
    rexSelectedStatus?.forEach((obj: any) => {
      rexStatusPareValue.push(obj);
    });
    rexSelectedTypology?.forEach((obj: any) => {
      rexTypologyParseValue.push(obj);
    });

    if (rexSelectedProductLine?.length > 0) {
      rexSelectedProductLine.forEach((obj: any) => {
        rexProductLineVal.push(obj);
      });
    }

    if (rexSelectedSolution?.length > 0) {
      rexSelectedSolution.forEach((obj: any) => {
        rexsolutionVal.push(obj);
      });
    }

    if (rexSelectedProduct?.length > 0) {
      rexSelectedProduct.forEach((obj: any) => {
        rexproductVal.push(obj);
      });
    }
    if (rexSelectedProject?.length > 0) {
      rexSelectedProject.forEach((obj: any) => {
        rexprojectVal.push(obj);
      });
    }
    if (rexSelectedCountry?.length > 0) {
      rexSelectedCountry.forEach((obj: any) => {
        rexcountryVal.push(obj);
      });
    }

    if (issuerParseValue.length === 0 || issuerParseValue.length === rexIssuerSite.length) {
      issuerParseValue = ['*'];
    }
    if (rexplatfromParseValue.length === 0 || rexplatfromParseValue.length === rexPaltform.length) {
      rexplatfromParseValue = ['*'];
    }
    if (rexSourceParseValue.length === 0 || rexSourceParseValue.length === rexSource.length) {
      rexSourceParseValue = ['*'];
    }
    if (rexStatusPareValue.length === 0 || rexStatusPareValue.length === rexStatus.length) {
      rexStatusPareValue = ['*'];
    }
    if (rexTypologyParseValue.length === 0 || rexTypologyParseValue.length === rexTypology.length) {
      rexTypologyParseValue = ['*'];
    }

    if (rexProductLineVal.length === 0) {
      rexProductLineVal = ['*'];
    }
    if (rexsolutionVal.length === 0) {
      rexsolutionVal = ['*'];
    }
    if (rexproductVal.length === 0) {
      rexproductVal = ['*'];
    }
    if (rexprojectVal.length === 0) {
      rexprojectVal = ['*'];
    }
    if (rexcountryVal.length === 0) {
      rexcountryVal = ['*'];
    }

    const startTime = Date.now();

    progressInterval = setInterval(() => {
      setProgressTime(Math.floor((Date.now() - startTime) / 1000) + 40);
    }, 1000);

    const fetchData = async () => {
      try {
        const body: any = {
          query: inputValue,
          maxResults: rexSelectedMaxResultsValue,
          platform: rexplatfromParseValue,
          status: rexStatusPareValue,
          issuerSite: issuerParseValue,
          typology: rexTypologyParseValue,
          rexSource: rexSourceParseValue,
          solution: rexsolutionVal,
          product: rexproductVal,
          project: rexprojectVal,
          country: rexcountryVal,
          productLine: rexProductLineVal,
          translation: true,
          searchTitle: true,
          searchDescription: true,
          ner: [''],
        };
        const sigSigmaBody: any = {
          query: inputValue,
          maxResults: rexSelectedMaxResultsValue,
          translation: true,
          ner: [''],
          searchTitle: true,
          searchDescription: true,
        };
        const allpromises = [];
        allpromises.push(
          ...[
            getREX360Response(body, projectId ?? undefined),
            iSearcherSearchRTE(
              'Query Search',
              inputValue,
              platform,
              project,
              documentProfile,
              ['Requirement'], // artifactEnt
              entityIs,
              solution,
              product,
              country,
              maxResultsValue,
              1,
              door,
              selectedIsearcherCheckedA,
              projectId ?? undefined
            ),
            iSearcherSearchRTE(
              'Expert Search',
              inputValue,
              platform,
              project,
              documentProfile,
              artifactEnt,
              entityIs,
              solution,
              product,
              country,
              maxResultsValue,
              1,
              door,
              selectedIsearcherCheckedA,
              projectId ?? undefined
            ),
            iSearcherSearchRTE(
              'Query Search',
              inputValue,
              platform,
              project,
              ['RTD', 'RVT'],
              artifactEnt, // artifactEnt
              entityIs,
              solution,
              product,
              country,
              maxResultsValue,
              1,
              door,
              selectedIsearcherCheckedA,
              projectId ?? undefined
            ),
            getCRsResponse(inputValue),
            SixSigmaRex(sigSigmaBody),
          ]
        );

        const response = await Promise.all(allpromises);
        const jsonData = await Promise.all(response.map((res) => res));
        const obj = {
          data: jsonData,
          result: true,
        };
        // setREXdata(obj);
        const resultsCount = [
          'numberOfResults',
          'numberOfResultsFound',
          'experts',
          'numberOfResultsFound',
          'numberOfResults',
          // 'numberOfResultsFound',
          'numberOfResults',
        ];
        const Count = [] as any;
        jsonData?.map((count, index) => {
          // eslint-disable-next-line no-self-compare
          if (count === null || isEmpty(count)) {
            Count.push(0);
          } else if (resultsCount[index] in count) {
            if (resultsCount[index] === 'experts') {
              Count.push(Object.keys(count[resultsCount[index]]).length);
            } else {
              Count.push(count[resultsCount[index]]);
            }
          }
          return count;
        });
        const temp = [];
        temp.push(Count[0]);
        temp.push(0);
        // temp.push(0);
        setLoading(false);
        setRexResult({ count: Count, data: obj, text: inputValue });
        // setCountResult([...temp, ...Count.slice(1, Count.length)]);
        return { count: Count, data: obj };
        // setLanguage(jsonData[2]?.translation?.detectedLanguage);
      } catch (error) {
        console.error('Error fetching data:', error);
        return { count: [], data: {} };
        setErrorMsg(true);
        // setSnack(`something went wrong ${error}`, 'warning');
      } finally {
        clearInterval(progressInterval);
        if (asyncFlag) {
          history.push(redirect);
        }
      }
    };

    return fetchData();
  };

  const handleApiCall = useCallback(async (inputValue, redirect = '', asyncFlag = false) => {
    if (inputValue === '') {
      return [];
    }
    const reult = await generateREX360ApiCall(inputValue, redirect, asyncFlag);
    return reult;
  }, []);

  return {
    handleApiCall,
    loading,
    progressTime,
    error: errorMsg,
    setSideFilter,
  };
}

export default useRexApiCall;
