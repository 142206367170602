/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import ModuleLayout from '../../../../layouts/ModuleLayout';
import ModuleAnalysis from '../ModuleAnalysis';
import DocTranslatorHeader from '../core/DocTranslatorHeader';
import ProjectContext from '../../../Project/ProjectContext';
import useUpload from '../../../../layouts/Hooks/useUpload';

const DocumentTranslator = (props) => {
  const [inputFileOpen, setInputFileOpen] = React.useState(false);
  const [isAnalysis, setIsAnalysis] = React.useState(false);
  const [selectionFile, setSelectionFile] = React.useState(null);
  const {
    enableDescription,
    renderDescription,
    numberOfFilesSupport,
    setSelectedChildIndex,
    renderFiles,
    select,
    workload,
    setWorkload,
  } = props;
  const { projectId } = useParams();
  const { getProject } = useContext(ProjectContext);
  const project = getProject(Number(projectId)) || {};
  const { loading, handleChangeInDragefile, handleUploadFile, FindeFileDetails } = useUpload({
    project,
    projectId,
  });

  const DocumentTranslatorProps = useMemo(
    () => ({
      state: {
        inputFileOpen,
        setInputFileOpen,
        isAnalysis,
        setIsAnalysis,
        selectionFile,
        setSelectionFile,
        setSelectedChildIndex,
        loading,
        workload,
        setWorkload,
      },
      enableDescription,
      enableHeader: true,
      select,
      renderDescription,
      renderFiles,
      numberOfFilesSupport,
      disableScopeSettings: true,
      handleChangeInDragefile,
      handleUploadFile,
      FindeFileDetails,
      renderHeader: () => {
        return (
          <>
            <DocTranslatorHeader />
          </>
        );
      },
      renderAnalysis: (props) => {
        console.log('props', props);
        return <>Rendered analysis</>;
      },
    }),
    [
      inputFileOpen,
      isAnalysis,
      selectionFile,
      enableDescription,
      renderDescription,
      loading,
      workload,
    ]
  );

  return (
    <>
      <ModuleLayout moduleData={DocumentTranslatorProps} />{' '}
    </>
  );
};

export default memo(DocumentTranslator);
