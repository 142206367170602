import React, { useEffect, useState } from 'react';
import useWebSocketClient from '../../Core/useWebSocketClient';
import { userWorkloadGetbyPageNumber } from '../../../api/workloads';

const useHistory = (props) => {
  const { serviceName, workload, currentPage } = props;
  const [historyData, setHistoryData] = useState({
    loading: false,
    rows: [],
    totalCount: 0,
  });

  const { rows, totalCount } = historyData;
  const { status, webSocketResponse } = useWebSocketClient({
    serviceName,
    serviceFor: workload.serviceFor,
    workload: workload.workload ?? 0,
  });

  const getWorkLoadAll = (currentPage: number, serivceName: string) => {
    let action;
    switch (serivceName) {
      case 'Compare Versions':
        action = 'docdiffer';
        break;
      case 'Extract Topics':
        action = 'iner';
        break;
      case 'Similarity Analysis':
        action = 'DocToDataset';
        break;
      case 'Document Similarity':
        action = 'DocToDoc';
        break;
      case 'Find Duplicates':
        action = 'SelfDoc';
        break;
      default:
        break;
    }
    const { name, id } = workload.serviceFor;
    userWorkloadGetbyPageNumber(id, currentPage, action, name, id)
      .then((activities) => {
        const { activity_counts, workload } = activities as any;
        setHistoryData((prev) => {
          return {
            loading: false,
            rows: workload,
            totalCount: activity_counts[0].total_num_activity,
          };
        });
        // return Promise.resolve({ activities, listIds: '7605' });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getWorkLoadAll(currentPage, serviceName);
  }, [currentPage]);

  useEffect(() => {
    if (webSocketResponse) {
      const temp = rows.map((data) => {
        if (data.id === webSocketResponse.id) {
          return webSocketResponse;
        }
        return data;
      });
      setHistoryData((prev) => {
        return {
          ...prev,
          loading: false,
          rows: temp,
        };
      });
      //   getWorkLoadAll(currentPage, serviceName);
    }
  }, [status, webSocketResponse]);

  return historyData;
};

export default useHistory;
