import React, { memo, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import TranslateIcon from '@mui/icons-material/Translate';
import HistoryIcon from '@mui/icons-material/History';
import ProjectContext from '../../Project/ProjectContext';
import Description from './Description';
import LayoutContainer from '../../../layouts/LayoutContainer';
import ModuleLayout from '../../../layouts/ModuleLayout';
import SimilarityAnalysis from './Components/SimilarityAnalysis';
import CompareVersion from './Components/CompareVersion';
import ExtractTopics from './Components/ExtractTopics';
import FindDuplicates from './Components/FindDuplicates';
import RexBook from './Components/RexBook';
import DocumentTranslator from './Components/DocumentTranslator';
// import History from './History';
import ProjectFolder from '../../Project/ProjectContainer/components/ProjectFolder/ProjectFolder';
import DetailforTransalator from '../../../components/History/components/DetailforTransalator';
import DocResultContainer from '../../ResultContainer/DocResultContainer';
import History from '../../../components/History/History';

const Module2Components = () => {
  const { setProjects, projects, getProjectDetails } = useContext(ProjectContext);
  const { projectId } = useParams();
  const project = getProjectDetails(Number(projectId));

  const componentsData = useMemo(
    () => [
      {
        name: 'Document translator',
        projectName: project?.name ?? '',
        icon: <PlaylistAddCheckOutlinedIcon fontSize="small" />,
        route: `/project/${projectId}`,
        enableLeftOption: true,
        leftOption: [
          {
            name: 'Translator',
            icon: <TranslateIcon fontSize="small" />,
            renderComponent: (props) => {
              return (
                <>
                  <DocumentTranslator {...props} />
                </>
              );
            },
            renderFiles: (props) => {
              return (
                <>
                  <ProjectFolder {...props} />
                </>
              );
            },
          },
          {
            name: 'History',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <History {...props} />
                </>
              );
            },
          },
        ],
      },
      {
        name: 'Similarity Analysis',
        projectName: project?.name ?? '',
        numberOfFilesSupport: 1,
        icon: <PlaylistAddCheckOutlinedIcon fontSize="small" />,
        route: `/project/${projectId}`,
        enableLeftOption: true,
        leftOption: [
          {
            name: 'Similarity Analysis',
            numberOfFilesSupport: 1,
            icon: <PlaylistAddCheckOutlinedIcon fontSize="small" />,
            renderFiles: (props) => {
              return (
                <>
                  <ProjectFolder {...props} />
                </>
              );
            },
            renderComponent: (props) => {
              return (
                <>
                  <SimilarityAnalysis {...props} />
                </>
              );
            },
            enableDescription: true,
            renderDescription: () => {
              const list = [
                'Similarity Analysis shall provide a list of similar information found from previously executed projects and tenders.',
                'Upon uploading a document in the form of excel, pdf or csv, the iSimilarizer shall look for similar information.',
                'User shall be able to download the reports of the analysis provided by iSimilarize',
              ];
              return (
                <>
                  <Description list={list} />
                </>
              );
            },
          },
          {
            name: 'History',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <History {...props} />
                </>
              );
            },
            renderHistoryDetail: (props) => {
              return (
                <>
                  <DetailforTransalator {...props} />
                </>
              );
            },
          },
          {
            name: 'Result',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <DocResultContainer {...props} />
                </>
              );
            },
          },
        ],
      },
      {
        name: 'Document Similarity',
        projectName: project?.name ?? '',
        numberOfFilesSupport: 2,
        icon: <PlaylistAddCheckOutlinedIcon fontSize="small" />,
        route: `/project/${projectId}`,
        enableLeftOption: true,
        leftOption: [
          {
            name: 'Document Similarity',
            numberOfFilesSupport: 1,
            icon: <PlaylistAddCheckOutlinedIcon fontSize="small" />,
            renderFiles: (props) => {
              return (
                <>
                  <ProjectFolder {...props} />
                </>
              );
            },
            renderComponent: (props) => {
              return (
                <>
                  <SimilarityAnalysis {...props} />
                </>
              );
            },
            enableDescription: false,
            renderDescription: () => {
              const list = [
                'Similarity Analysis shall provide a list of similar information found from previously executed projects and tenders.',
                'Upon uploading a document in the form of excel, pdf or csv, the iSimilarizer shall look for similar information.',
                'User shall be able to download the reports of the analysis provided by iSimilarize',
              ];
              return (
                <>
                  <Description list={list} />
                </>
              );
            },
          },
          {
            name: 'History',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <History {...props} />
                </>
              );
            },
            renderHistoryDetail: (props) => {
              return (
                <>
                  <DetailforTransalator {...props} />
                </>
              );
            },
          },
          {
            name: 'Result',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <DocResultContainer {...props} />
                </>
              );
            },
          },
        ],
      },
      {
        name: 'Compare Versions',
        projectName: project?.name ?? '',
        numberOfFilesSupport: 2,
        icon: <PlaylistAddCheckOutlinedIcon fontSize="small" />,
        route: `/project/${projectId}`,
        enableLeftOption: true,
        leftOption: [
          {
            name: 'Compare Versions',
            numberOfFilesSupport: 2,
            icon: <PlaylistAddCheckOutlinedIcon fontSize="small" />,
            renderComponent: (props) => {
              return (
                <>
                  <CompareVersion {...props} />
                </>
              );
            },
            renderFiles: (props) => {
              return (
                <>
                  <ProjectFolder {...props} />
                </>
              );
            },
            renderDescription: () => {
              const list = [
                'Comparison of two versions of the same document to find their similarities and differences',
                'Upon uploading a document in the form of text, excel, pdf or csv, iSimilarizer shall look for similar information from the previous version',
                'User shall be able to download the reports of the analysis provided by iSimilarizer.',
              ];
              return (
                <>
                  <Description list={list} />
                </>
              );
            },
            enableDescription: true,
          },
          {
            name: 'History',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <History {...props} />
                </>
              );
            },
          },
          {
            name: 'Result',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <DocResultContainer {...props} />
                </>
              );
            },
          },
        ],
      },
      {
        name: 'Extract Topics',
        projectName: project?.name ?? '',
        numberOfFilesSupport: 2,
        icon: <PlaylistAddCheckOutlinedIcon fontSize="small" />,
        route: `/project/${projectId}`,
        enableLeftOption: true,
        leftOption: [
          {
            name: 'Extract Topics',
            numberOfFilesSupport: 2,
            icon: <PlaylistAddCheckOutlinedIcon fontSize="small" />,
            renderComponent: (props) => {
              return (
                <>
                  <ExtractTopics {...props} />
                </>
              );
            },
            renderFiles: (props) => {
              return (
                <>
                  <ProjectFolder {...props} />
                </>
              );
            },
            enableDescription: false,
          },
          {
            name: 'History',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <History {...props} />
                </>
              );
            },
          },
          {
            name: 'Result',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <DocResultContainer {...props} />
                </>
              );
            },
          },
        ],
      },
      {
        name: 'Find Duplicates',
        projectName: project?.name ?? '',
        numberOfFilesSupport: 2,
        icon: <PlaylistAddCheckOutlinedIcon />,
        route: `/project/${projectId}`,
        enableLeftOption: true,
        leftOption: [
          {
            name: 'Find Duplicates',
            numberOfFilesSupport: 2,
            icon: <PlaylistAddCheckOutlinedIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <FindDuplicates {...props} />
                </>
              );
            },
            renderFiles: (props) => {
              return (
                <>
                  <ProjectFolder {...props} />
                </>
              );
            },
            enableDescription: false,
          },
          {
            name: 'History',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <History {...props} />
                </>
              );
            },
          },
          {
            name: 'Result',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <DocResultContainer {...props} />
                </>
              );
            },
          },
        ],
      },
      {
        name: 'Rex book',
        projectName: project?.name ?? '',
        numberOfFilesSupport: 1,
        icon: <PlaylistAddCheckOutlinedIcon fontSize="small" />,
        route: `/project/${projectId}`,
        enableLeftOption: true,
        leftOption: [
          {
            name: 'Rex book',
            numberOfFilesSupport: 2,
            icon: <TranslateIcon fontSize="small" />,
            renderComponent: (props) => {
              return (
                <>
                  <RexBook {...props} />
                </>
              );
            },
            renderFiles: (props) => {
              return (
                <>
                  <ProjectFolder {...props} />
                </>
              );
            },
            renderDescription: () => {
              const list = [
                'REX Book is a compilation of lessons and Return of Experiences (REX) from the past, and applicable to your tender or project to avoid re-doing the same mistakes as in the past.',
                'Upon uploading a document in form of pdf, excel or csv, the REX Book service looks for and complies a list of REX applicable to your tender or project.',
                'You can download the REX Book document and share it across to the relevant PICs and stakeholders.',
              ];
              return (
                <>
                  <Description list={list} />
                </>
              );
            },
            enableDescription: true,
          },
          {
            name: 'History',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <History {...props} />
                </>
              );
            },
          },
          {
            name: 'Result',
            icon: <HistoryIcon />,
            renderComponent: (props) => {
              return (
                <>
                  <DocResultContainer {...props} />
                </>
              );
            },
          },
        ],
      },
    ],
    [projectId, project]
  );

  return (
    <LayoutContainer
      componentsData={componentsData}
      workload={{
        workload: 0,
        serviceFor: { name: 'project', id: projectId },
      }}
    />
  );
};

export default memo(Module2Components);
