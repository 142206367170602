import React from 'react';
import { userWorkloadGetbyPageNumber } from '../../../api/workloads';

const useDocumentTranslator = (props) => {
  const { user_id } = props;
  const [result, setSetResult] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const CallHistory = (currentPage) => {
    setLoading(true);
    userWorkloadGetbyPageNumber(user_id, currentPage + 1, 'Translator')
      .then((activities) => {
        const { activity_counts, workload } = activities;

        // setTotalCount(activity_counts[0].total_num_activity);
        const temp = workload % 10;
        let p = 0;
        if (temp > 0) {
          p = parseInt(workload.length / 10, 10) + 1;
        } else {
          p = workload.length / 10;
        }
        const updatedRows = workload.map((data) => {
          const obj = {
            id: data.id,
            activity_details: !data?.results_data
              ? ''
              : data?.results_data[0]?.data?.output.filename ?? '',
            resources: data.resources[0] ?? '',
            source_doc: data.resources[0] ?? '',
            status: data.status,
            isNew: data.status !== 'end',
            created_by: data.created_by.name,
            created_at: data.created_at,
            updated_at: data.updated_at,
            progress_percent: data.progress_percent,
            errors: data.errors,
          };
          return obj;
        });

        const result = {
          rows: updatedRows,
          activity_counts,
          totalCount: activity_counts[0].total_num_activity,
          pageCount: p,
        };
        setSetResult(result);
        // setHistoryRows(updatedRows);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return { result, loading, CallHistory };
};

export default useDocumentTranslator;
