import React, { useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

import ExcelJS from 'exceljs';

import { saveAs } from 'file-saver';

import AllocationExtraction, { AnalysisAllocationExtraction } from '../utils/allocationExtraction';
import { downloadExcelFile } from '../../../../../../api/downloadDataset';

// import {
//   getiCapture,
//   getiCharacterizer,
//   getiAllocator,
//   downloadExcelFile,
// } from '../../../../../../api/downloadDataset';

const useDownloadFile = (props) => {
  const { projectId: proId, workloadId: workId } = useParams();
  const [loading, setLoading] = useState(false);
  const {
    columns,
    row: data,
    filename,
    service,
    targetColumn,
    translationCode,
    displayColumns,
    projectGroups,
    userId,
    groups,
    users,
    setNotify,
    languagesCode,
    langCheck,
  } = props;

  const [mergColumn, setMergColumns] = useState([]);
  const [progress, setProgress] = useState(0);
  const capturedColumn = targetColumn ?? 'Text';

  const extractOriginalLang = () => {
    if (langCheck) {
      const orignalLangCode = languagesCode.filter((data: any) => {
        return data.lang === langCheck;
      });
      return orignalLangCode[0]?.code;
    }
    return '-';
  };

  // const exportRows = useMemo(() => {
  //   if (!data) {
  //     return [];
  //   }

  //   let exportRows = [] as any;

  //   for (const item of data) {
  //     if (service === 'analysis') {
  //       // const AllocationsTemp = [] as any;
  //       if (item.Characterization === 'Requirement') {
  //         AnalysisAllocationExtraction(exportRows, item, targetColumn, projectGroups, userId);
  //       } else {
  //         exportRows.push({
  //           Id: item.id,
  //           ...item,
  //         });
  //       }
  //     } else if (service === 'assignment') {
  //       if (item.Characterization === 'Requirement') {
  //         // const AllocationsTemp = [] as any;
  //         AllocationExtraction(exportRows, item, groups, users, targetColumn);
  //         // const temp = [...exportRows, AllocationsTemp]
  //       } else {
  //         exportRows.push({
  //           Id: item.id,
  //           ...item,
  //         });
  //       }
  //     }
  //     if (['capture', 'characterizer'].includes(service)) {
  //       exportRows = data;
  //     }
  //   }
  //   return exportRows;
  // }, [data, projectGroups]);

  const extractAllocatorColomn = () => {
    const col = columns.map((data) => {
      if (typeof data.header === 'string') {
        if (displayColumns && displayColumns?.includes(data.header)) {
          return {
            header: data.header,
            key: data.header,
            width: 20,
          };
        }
        if (data.header === 'iSenS_ID') {
          return {
            header: data.header,
            key: 'id',
            width: 10,
          };
        }
        return {
          header: data.header,
          key: data.accessorKey === 'extractedText' ? capturedColumn : data.accessorKey,
          width: data.accessorKey === 'extractedText' ? 50 : 20,
        };
      }

      return {
        header: data.header[0],
        key: data.accessorKey[0],
        width: 50,
      };
    });

    if (service === 'assignment') {
      const temp = [
        {
          header: 'Lifecycle',
          key: 'lifecycle',
          width: 30,
        },
        {
          header: 'PBS',
          key: 'pbs',
          width: 30,
        },
        {
          header: 'ABS',
          key: 'abs',
          width: 30,
        },
        {
          header: 'OBS',
          key: 'obs',
          width: 30,
        },
        {
          header: 'Receiver',
          key: 'receiverName',
          width: 30,
        },
      ];
      const mCol = ['G', 'H', 'I', 'J', 'K'] as any;
      setMergColumns(mCol);

      return [...col.slice(0, 6), ...temp, ...col.slice(6, col.length)];
    }

    if (service === 'analysis') {
      const temp = [
        {
          header: 'Lifecycle',
          key: 'lifecycle',
          width: 30,
        },
        {
          header: 'PBS',
          key: 'pbs',
          width: 30,
        },
        {
          header: 'Compliance',
          key: 'compliance',
          width: 30,
        },
        {
          header: 'NC',
          key: 'nc',
          width: 30,
        },
        {
          header: 'Gap',
          key: 'gap',
          width: 30,
        },
        {
          header: 'Rationale',
          key: 'rationale',
          width: 30,
        },
      ];
      const mCol = ['G', 'H', 'I', 'J', 'K', 'L'] as any;
      setMergColumns(mCol);

      return [...col.slice(0, 6), ...temp, ...col.slice(6, col.length)];
    }

    return col;
  };

  const extractColumn = () => {
    const col = columns.map((data) => {
      if (data.name === 'Characterization' && service === 'characterizer') {
        return {
          header: 'Characterization',
          key: 'Characterization',
          width: 30,
        };
      }

      if (data.name === 'comment' && service === 'characterizer') {
        return {
          header: 'Comment',
          key: 'comment',
          width: 30,
        };
      }

      if (data.name === 'id') {
        return {
          header: data.title,
          key: data.name,
          width: 10,
        };
      }

      if (data.name === 'Char_Score' && service === 'characterizer') {
        return {
          header: 'Char_Score',
          key: 'Char_Score',
          width: 30,
        };
      }
      return {
        header: data.title,
        key: data.name,
        width: 30,
      };
    });

    return col;
  };

  const exportColumns = useMemo(() => {
    if (['capture', 'characterizer'].includes(service)) {
      return extractColumn();
    }

    return extractAllocatorColomn();
  }, [columns, service]);

  // console.log('exportColumns', exportColumns);

  const exportAllDataforAllocator = async () => {
    try {
      setLoading(true);

      const projectUsers = service === 'assignment' ? users : [];

      // console.log(
      //   'users exportAllDataforAllocator',
      //   users.map((u) => ({ id: u.user.id, name: u.user.name }))
      // );
      const payload = {
        fileName: filename,
        workloadId: Number(workId),
        projectId: Number(proId),
        service,
        projectUsers,
        columns: [...exportColumns],
        targetColumn,
        translationCode: translationCode === '-' ? extractOriginalLang() : translationCode,
      };

      await downloadExcelFile('/module1/iAllocator', payload);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setNotify('Eorror while downloading file', 'error');
    }
  };

  const exportAllData = async () => {
    setLoading(true);
    try {
      if (service === 'capture') {
        const payload = {
          fileName: filename,
          workloadId: Number(workId),
          projectId: Number(proId),
          columns: [...exportColumns],
          targetColumn,
          translationCode: translationCode === '-' ? extractOriginalLang() : translationCode,
        };

        await downloadExcelFile('/module1/iCapture', payload);
        setLoading(false);
      }

      if (service === 'characterizer') {
        const payload = {
          fileName: filename,
          workloadId: Number(workId),
          projectId: Number(proId),
          columns: [...exportColumns],
          targetColumn,
          translationCode: translationCode === '-' ? extractOriginalLang() : translationCode,
        };

        await downloadExcelFile('/module1/iCharacterizer', payload);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setNotify('Eorror while downloading file', 'error');
    }
  };

  return { exportAllData, exportAllDataforAllocator, loading, progress };
};

export default useDownloadFile;
