import React, { useEffect, useState } from 'react';
import getW3client from '../../api/wsWorkloads';
import { Artifact, CreatedByUser } from '../../react-app-env';

type wsMessageType = {
  id: number;
  project_id: number;
  app: string;
  action: string;
  created_by: CreatedByUser;
  created_at: string;
  file_number: any;
  progress_percent: number;
  updated_at: string;
  status: 'end' | 'start' | 'progress';
  errors: any[];
  resources: any[];
};

const useWebSocketClient = (props) => {
  const { workload, serviceFor } = props;
  const wsClientNew = getW3client();
  const [status, setStatus] = useState({
    percentage: 0,
    isDone: false,
  });
  const [webSocketResponse, setWebSocketResponse] = useState<wsMessageType | undefined>(undefined);

  function handleMessage(message: { data: string }) {
    const msgData: wsMessageType[] = JSON.parse(String(message.data));
    if (msgData && msgData.length > 0) {
      const rowWebsocket = msgData.find((r) => r.id === workload) as Artifact;

      if (rowWebsocket && rowWebsocket.status === 'end') {
        wsClientNew?.close();
      }

      setStatus((prev) => {
        return {
          ...prev,
          percentage: rowWebsocket.progress_percent,
          isDone: rowWebsocket.status === 'end',
        };
      });
      setWebSocketResponse(rowWebsocket);
    }
  }

  useEffect(() => {
    if (workload) {
      wsClientNew.onopen = () => {
        const stingTemp = workload.toString();
        wsClientNew.send(`${stingTemp}`);
      };
      wsClientNew.onmessage = (message) => handleMessage(message);
    }
    return () => {
      if (wsClientNew) {
        wsClientNew.close();
      }
    };
  }, [workload]);

  return { status, webSocketResponse };
};

export default useWebSocketClient;
