import React, { useEffect, useState } from 'react';
import { userWorkloadGetbyPageNumber, workloadGetbyPageNumber } from '../../../api/workloads';
import useProjectWebSocketClient from '../../Core/useProjectWebSocketClient';

const useProjectHistory = (props) => {
  const { serviceName, workload, currentPage, filterColumns } = props;
  const [historyData, setHistoryData] = useState({
    loading: false,
    rows: [],
    totalCount: 0,
    listIds: [],
  });

  const { listIds } = historyData;

  const getWorkLoadAll = (
    currentPage: number,
    reset = 'none',
    payload = {
      activityId: '',
      modules: [],
      action: [],
      fromDate: '',
      toDate: '',
    }
  ) => {
    // const payload =
    //   reset === 'reset'
    //     ? {
    //         activityId: '',
    //         modules: [],
    //         action: [],
    //         fromDate: '',
    //         toDate: '',
    //       }
    //     : {
    //         activityId: '',
    //         modules: [],
    //         action: [],
    //         fromDate: '',
    //         toDate: '',
    //       };
    return workloadGetbyPageNumber(workload.serviceFor.id, currentPage, payload)
      .then((activities) => {
        let listIds = [] as any[];
        const { activity_counts, workload } = activities;
        listIds = workload.filter((act) => act.status !== 'end').map((act) => act.id);
        setHistoryData((prev) => {
          return {
            loading: false,
            rows: workload,
            totalCount: activity_counts[0].total_num_activity,
            listIds,
          };
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const { status, webSocketResponse } = useProjectWebSocketClient({
    serviceName,
    serviceFor: workload.serviceFor,
    workload: workload.workload ?? 0,
    listIds,
  });

  useEffect(() => {
    getWorkLoadAll(currentPage, serviceName, filterColumns);
  }, [currentPage, workload]);

  useEffect(() => {
    if (webSocketResponse) {
      setHistoryData((prev) => {
        return {
          ...prev,
          loading: false,
          rows: prev.rows.map((eachRow) => {
            const rowFound = webSocketResponse.find((r) => r.id === eachRow.id);
            if (rowFound) {
              return rowFound;
            }
            return eachRow;
          }),
        };
      });
      //   getWorkLoadAll(currentPage, serviceName);
    }
  }, [status, webSocketResponse]);

  return { historyData, getWorkLoadAll };
};

export default useProjectHistory;
