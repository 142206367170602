/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Popover,
  Theme,
  Toolbar,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import PrintIcon from '@material-ui/icons/Print';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import PublicIcon from '@mui/icons-material/Public';
import SaveIcon from '@mui/icons-material/Save';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useReactToPrint } from 'react-to-print';
import FilterAlt from '@mui/icons-material/FilterAlt';
import ReactJson from 'react-json-view';
import { useHistory } from 'react-router-dom';
import { chatGPTApiGet, chatGPTFeedbackApiPut, chatGPTTitleApiPut } from '../../../../api/chat_gpt';
import chat_home from '../../../../assets/images/chat_icon_mini.png';
import Loading from '../../../../components/Core/Loading';
import Sixlayer from '../../../Home/components/Sixlayer';
import Module2Context from '../../Module2Context';
import { CitationCaption, CitationPrivate, CitationRex, DiscussionType } from './chat';
import ChatContextProvider, { ChatContext } from './ChatContext';
import reducerDiscussions, { gptEngineType, initDisccussionState } from './ChatReducer';
import Citation from './Citation';
import DiscussBoard from './DiscussBoard';
import DiscussBoardWithData from './DiscussBoardWithData';
// import DiscussList from './DiscussList';
import formatDate from '../../../../tools/formatDate';
import DiscussListScroll from './DiscussListScroll';
import { IS_DEBUG_MODE } from '../../../../const';
import PrivateGenAFilter from './PrivateGenAFilter';
import M2GlobalContext from '../../../ContextMager/M2GlobalContext';
import FilterContainer from '../../../HOC/FilterContainer';

export const extractTitle = (
  citation: CitationPrivate | CitationRex,
  genType: gptEngineType
): string => {
  const lines = citation.content.replace(/\\n/g, '\n').split('\\n');

  const isCitationPrivate = (
    citation: CitationPrivate | CitationRex
  ): citation is CitationPrivate => genType === 'private';

  const isCitationRex = (citation: CitationPrivate | CitationRex): citation is CitationPrivate =>
    genType === 'rex';
  if (isCitationPrivate(citation)) {
    // for Private
    if (citation.Title) {
      return citation.Title;
    }

    // TODO: Check for "Change Request ID" format in private
    const reg2 = /change request id:\s*(.+)/i;
    for (const l of lines) {
      const m = l.match(reg2); // Use reg2 here
      if (m?.[1]) {
        return m[1].trim();
      }
    }

    // TODO: Check for Title in content in private

    const reg4 = /title:\s*(.+)/i;
    for (const l of lines) {
      const m2 = l.match(reg4);
      if (m2?.[1]) {
        return m2[1].trim();
      }
    }
  }

  if (isCitationRex(citation)) {
    // Check for "title" format in REX in lines
    const reg = /title:\s*(.+)/i;
    for (const l of lines) {
      const m = l.match(reg);
      if (m?.[1]) {
        return m[1].trim();
      }
    }

    // Check for "Headline" format in rex
    const reg2 = /Headline: \s*(.+)/i;
    for (const l of lines) {
      const m = l.match(reg2); // Use reg2 here
      if (m?.[1]) {
        return m[1].trim();
      }
    }
  }

  return '';
};

const useStyles = makeStyles((theme: Theme) => ({
  btnToggle: {
    height: '3rem',
    '&.MuiToggleButtonGroup-root': {
      border: '1px solid',
      borderColor: theme.palette.primary.main,
      borderRadius: '30px !important',
      backgroundColor: 'white',
    },
    '& .MuiToggleButton-root': {
      color: 'black',
      textTransform: 'none',
      paddingLeft: '15px',
      paddingRight: '15px',
      border: '0',
      '&.Mui-selected': {
        borderRadius: '30px !important',
        borderColor: theme.palette.secondary.main,
        background: theme.palette.secondary.main,
        color: 'white',
      },
    },
  },

  btnNewChat: {
    marginLeft: 10,
    width: '150px',
  },

  titleHeader: {
    '& .MuiCardHeader-title': {
      color: 'white',
      fontSize: '20px',
      fontWeight: 'bold',
    },
    '&.MuiCardHeader-root': {
      padding: 0,
      marginLeft: 5,
    },
    '& .MuiAvatar-root	': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  gridHeader: {
    // padding: '5px 3px',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    // backgroundColor: theme.palette.primary.main,
    // borderRadius: '15px 15px 0 0',
  },

  headerTool: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    fontSize: 'average',
    [theme.breakpoints.up('lg')]: {
      fontSize: 'large',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 'medium',
    },
  },
  gridSubHeader: {
    padding: '0px',
    paddingLeft: 0,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.blue.light,
  },
  leftBar: {
    backgroundColor: '#EEF1F9',
    marginTop: '0',
    maxHeight: '100%',
    minHeight: '100%',
    overflow: 'auto',
    width: '20%',
    // '@media (max-width: 1500px)': {
    //   width: '300px',
    //   textOverflow: 'ellipsis',
    // },
    // '@media (max-width: 1280px)': {
    //   width: '200px',
    //   textOverflow: 'ellipsis',
    // },
    // '@media (max-width: 1024px)': {
    //   width: '120px',
    //   textOverflow: 'ellipsis',
    // },
    // '@media (max-width: 1020px)': {
    //   display: 'none',
    // },
  },
  iconButtonSixLayer: {
    backgroundColor: '#EEF1F9',
    // boxShadow: '0px 4px 8px #00000029',
    width: '3rem',
    color: '#002A45',
    height: '2.4rem',
    maxWidth: '2.4rem',
    verticalAlign: 'top',
    marginLeft: '3px',
  },

  rexContainer: {
    boxShadow: 'none',
    height: '100%',
  },

  genAContainer: {
    boxShadow: 'none',
    height: '40rem',
    [theme.breakpoints.down('xl')]: {
      height: '50rem',
    },
    [theme.breakpoints.down('lg')]: {
      height: '40rem',
    },
    [theme.breakpoints.down('md')]: {
      height: '30rem',
    },
    [theme.breakpoints.down('sm')]: {
      height: '20rem',
    },
    [theme.breakpoints.down('xs')]: {
      height: '20rem',
    },
  },

  chatHeader: {
    border: 'None',
    height: '4rem',

    // [theme.breakpoints.up('lg')]: {
    //   height: '7%',
    // },

    // [theme.breakpoints.down('lg')]: {
    //   height: '10%',
    // },

    // [theme.breakpoints.down('md')]: {
    //   height: '12%',
    // },
  },
  chatBody: {
    width: '100%',
    height: '100%',
    fontSize: 'average',
    [theme.breakpoints.up('lg')]: {
      fontSize: 'large',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 'small',
    },
  },
  chatContent: {
    border: 'None',
    height: 'calc(100% - 4rem)',

    // [theme.breakpoints.up('lg')]: {
    //   height: '93%',
    // },
    // [theme.breakpoints.down('lg')]: {
    //   height: '90%',
    // },
    // [theme.breakpoints.down('md')]: {
    //   height: '88%',
    // },
  },
}));

export const useDiscussFetch = (action?: gptEngineType | gptEngineType[], start = 0) => {
  const [discussions, setDiscussions] = useState<DiscussionType[]>([]);
  const [loading, setLoading] = useState(false); // page

  const actionFilter = Array.isArray(action) ? Array(action).join(',') : action;

  const callHistoryAPI = () => {
    chatGPTApiGet(actionFilter, start)
      .then((response) => {
        setDiscussions(response);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    callHistoryAPI();
  }, []);

  return { loading, discussions, setDiscussions };
};

const MainChatContainer = React.forwardRef((props, ref) => {
  // console.log('MainChatContainer', props);
  const {
    toggleCollapse,
    isCollapsed,
    state,
    toggleMenu,
    classes,
    setToggleCitation,
    handleOpenCitation,
    setToggleMenu,
    toggleCitation,
    citation,
    citationCaptions,
    handleCloseCitation,
    handlePrint,
    handleSave,
    dispatch,
    action,
    setOpenDialogChangeChat,
  } = props;
  const history = useHistory();

  return (
    <>
      <Grid
        container
        spacing={0}
        className={classes.chatHeader}
        // style={{
        //   border: 'None',
        //   height: '10%',
        // }}
      >
        <Grid container spacing={0} className={classes.gridHeader}>
          <AppBar
            position="static"
            elevation={0}
            style={{
              zIndex: 999,
              margin: 0,
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              // alignItems: 'center',
            }}
          >
            <Toolbar>
              <div className={classes.headerTool}>
                <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                  {/* Content for the left section */}
                  <IconButton
                    style={{
                      backgroundColor: 'transparent',
                      textTransform: 'none',
                    }}
                    onClick={() => history.replace('home')}
                  >
                    <Avatar src={chat_home} />
                    <Typography
                      style={{
                        paddingLeft: 10,
                        color: 'white',
                        fontSize: '20px',
                        fontWeight: 'bold',
                      }}
                    >
                      {state.gptEngine === 'rex' ? 'Rex Chat' : 'GenA'}
                    </Typography>
                  </IconButton>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ cursor: 'pointer', height: '100%' }}
                    onClick={() => {
                      setToggleCitation(false);
                      dispatch({ type: 'DISCUSSION_START_NEW' });
                    }}
                    className={classes.btnNewChat}
                  >
                    <AddCircleIcon style={{ marginRight: 10 }} />
                    New Chat
                  </Button>
                  <IconButton
                    // variant="contained"
                    // color="primary"
                    style={{
                      width: '10px',
                      color: 'white',
                      backgroundColor: 'transparent',
                    }}
                    onClick={() => {
                      if (isCollapsed) {
                        toggleCollapse({ isCollapsed: false, service: 'GenA' });
                      }
                      setToggleMenu(!toggleMenu);
                      setToggleCitation(false);
                    }}
                  >
                    <MenuIcon />
                  </IconButton>
                  {state.discussionId && state.discussionId > 0 && (
                    <IconButton
                      // variant="contained"
                      style={{
                        width: '10px',
                        color: 'white',
                        backgroundColor: 'transparent',
                      }}
                      onClick={handlePrint}
                    >
                      <PrintIcon />
                    </IconButton>
                  )}

                  {state.discussionId && state.discussionId > 0 && state.discussionHasChanged && (
                    <IconButton onClick={handleSave}>
                      <SaveIcon style={{ color: '#dc3223' }} />
                    </IconButton>
                  )}
                </div>
                <div />
                {/* Hide toggle buttons if action is rex */}
                <div
                  style={{
                    marginTop: '0px',
                    marginRight: '10px',
                    ...(action === 'rex'
                      ? { display: 'none' }
                      : { display: 'flex', height: '100%', alignItems: 'center' }),
                  }}
                >
                  {/* Content for the right section */}
                  <ToggleButtonGroup
                    value={state.gptEngine}
                    exclusive
                    onChange={(e, value) => {
                      if (state.loadingPrompt) {
                        setOpenDialogChangeChat(true);
                        return;
                      }
                      setToggleCitation(false);
                      dispatch({
                        type: 'SWITCH_ENGINE_GPT',
                        payload: value,
                      });
                    }}
                    aria-label="Platform"
                    className={classes.btnToggle}
                  >
                    <ToggleButton size="small" value="public">
                      <PublicIcon style={{ marginRight: 5 }} /> Public generic data
                    </ToggleButton>
                    <ToggleButton size="small" value="private">
                      <VerifiedUserIcon style={{ marginRight: 5 }} /> Alstom private data
                    </ToggleButton>
                  </ToggleButtonGroup>
                  {/* only private conversation and new conversation */}
                  {/* && !state.discussionId */}
                </div>
              </div>
            </Toolbar>
          </AppBar>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={0}
        className={classes.chatContent}
        // style={{
        //   border: '1px solid #002D45',
        //   height: '90%',
        // }}
      >
        <div className={classes.chatBody}>
          <Box
            display="flex"
            p={1}
            justifyContent="flex-start"
            style={{ width: '100%', height: '100%' }}
            flexGrow={2}
            margin={0}
            padding={0}
          >
            {toggleMenu && (
              <Box className={classes.leftBar} flexShrink={0}>
                <DiscussListScroll
                  gptEngine={state.gptEngine}
                  onClick={() => {
                    setToggleCitation(false);
                  }}
                />
              </Box>
            )}
            <Box
              style={
                toggleMenu
                  ? { backgroundColor: 'white', height: '100%', width: '80%' }
                  : { backgroundColor: 'white', height: '100%', width: '100%' }
              }
            >
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                {state.gptEngine === 'private' || state.gptEngine === 'rex' ? (
                  <DiscussBoardWithData
                    discussion={state.discussion}
                    toggleCollapse={toggleCollapse}
                    setToggleMenu={setToggleMenu}
                    onOpenCitation={handleOpenCitation}
                    ref={ref}
                  />
                ) : (
                  <DiscussBoard
                    discussion={state.discussion}
                    ref={ref}
                    toggleCollapse={toggleCollapse}
                    setToggleMenu={setToggleMenu}
                  />
                )}
              </div>
            </Box>
            {toggleCitation && citation && (
              <Box
                flexShrink={0}
                style={{ backgroundColor: '#EEF1F9', height: '100%', width: '30%' }}
                // width={500}
              >
                <Citation
                  title={extractTitle(citation, state.gptEngine as gptEngineType)}
                  doc={citation}
                  captions={citationCaptions}
                  onCloseCitation={handleCloseCitation}
                  gptEngine={state.gptEngine}
                  toggleCitation={toggleCitation}
                />
              </Box>
            )}
          </Box>
        </div>
      </Grid>
    </>
  );
});

function ChatContainer({
  action,
  initPrompt,
  isChatExpandable = false,
  setIsChatExpand,
}: {
  action?: gptEngineType | gptEngineType[];
  initPrompt?: string;
  isChatExpandable?: boolean;
  setIsChatExpand?: any;
}) {
  const { setSnack } = useContext(Module2Context);
  const { state: rexState, dispatch: rexDispatch } = useContext(M2GlobalContext);
  const { rexFilter, similarityFilter } = rexState;
  const [loading, setLoading] = useState(false); // page

  // const { loading: loadingDiscuss, discussions } = useDiscussFetch(action, 0);
  let initRedux = initDisccussionState;
  if (action === 'rex') {
    initRedux = {
      ...initDisccussionState,
      initPrompt,
      gptEngine: action,
    };
  }

  const [state, dispatch] = useReducer(reducerDiscussions, initRedux);

  const [toggleMenu, setToggleMenu] = useState<boolean>(action !== 'rex');
  const [toggleMenuBak, setToggleMenuBak] = useState<boolean>(true);
  const [toggleCitation, setToggleCitation] = useState<boolean>(false);
  const [citation, setCitation] = useState<CitationPrivate | CitationRex>();
  const [citationCaptions, setCitationCaptions] = useState<CitationCaption[]>([]);
  const [openDialogChangeChat, setOpenDialogChangeChat] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const refPrint = useRef(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  function openArray(arr) {
    const r = arr?.map((obj) => obj);
    // return r.join(',');
    return r;
  }
  const passedData = {
    // m2 rex filter
    Product_Line:
      rexFilter?.rexSelectedProductLine?.length !== 0
        ? openArray(rexFilter?.rexSelectedProductLine)
        : null,
    Platform:
      rexFilter?.rexSelectedPlatform?.length !== 0
        ? openArray(rexFilter?.rexSelectedPlatform)
        : null,
    Solution:
      rexFilter?.rexSelectedSolution?.length !== 0
        ? openArray(rexFilter?.rexSelectedSolution)
        : null,
    Product:
      rexFilter?.rexSelectedProduct?.length !== 0 ? openArray(rexFilter?.rexSelectedProduct) : null,
    Project:
      rexFilter?.rexSelectedProject?.length !== 0 ? openArray(rexFilter?.rexSelectedProject) : null,
  };

  const passedDataPrivate = useMemo(() => {
    return {
      Product_Line:
        similarityFilter?.selectedSimiProductLine?.length !== 0
          ? openArray(similarityFilter?.selectedSimiProductLine)
          : null,
      Platform:
        similarityFilter?.selectedSimiPlatform?.length !== 0
          ? openArray(similarityFilter?.selectedSimiPlatform)
          : null,
      Solution:
        similarityFilter?.selectedSimiSolution?.length !== 0
          ? openArray(similarityFilter?.selectedSimiSolution)
          : null,
      Product:
        similarityFilter?.selectedSimiProduct?.length !== 0
          ? openArray(similarityFilter?.selectedSimiProduct)
          : null,
      Project:
        similarityFilter?.selectedSimiProject?.length !== 0
          ? openArray(similarityFilter?.selectedSimiProject)
          : null,
      Document_Profile:
        similarityFilter?.selectedSimiDocumentProfile?.length !== 0
          ? openArray(similarityFilter?.selectedSimiDocumentProfile)
          : null,
    };
  }, [similarityFilter]);

  // Define the state and ref for passedDataPrivate
  const [passedDataPrivateInt, setPassedDataPrivateInt] = useState(passedDataPrivate);
  const passedDataPrivateRef = useRef(passedDataPrivateInt);

  // Update the ref whenever the state changes
  useEffect(() => {
    passedDataPrivateRef.current = passedDataPrivateInt;
  }, [passedDataPrivateInt]);

  useEffect(() => {
    if (passedDataPrivate) {
      setPassedDataPrivateInt(passedDataPrivate);
    }
  }, [passedDataPrivate]);

  function removeEmpty(passedData: any) {
    const newData = Object.fromEntries(
      Object.entries(passedData).filter(([_, value]) => value != null)
    );
    return newData;
  }

  function transformQueryNew(input) {
    // Step 1: Process each key-value pair in the input object
    const outputClauses = Object.keys(input).map((key) => {
      const valuesArray = input[key];
      // Step 2: Create output clause
      if (valuesArray.length > 1) {
        return `(${valuesArray.map((val) => `${key} eq '${val}'`).join(' or ')})`;
      }
      return `(${key} eq '${valuesArray}')`;
    });

    // Step 3: Join output clauses back together with ' and '
    return outputClauses.join(' and ');
  }

  const sixlayerFilter = transformQueryNew(
    removeEmpty(state.gptEngine === 'private' ? passedDataPrivateRef.current : passedData)
  );

  const handlePrint = useReactToPrint({
    content: () => refPrint.current,
    onBeforeGetContent: () => {
      document.title = `${formatDate(state.discussion?.created_at, 'YY-mm-DD')}_${
        state.discussion?.title ?? ''
      }`;
    },
  });

  const handleDialogChangeChat = () => {
    setOpenDialogChangeChat(false);
    setToggleCitation(false);
    dispatch({
      type: 'SWITCH_ENGINE_GPT',
      payload: state.gptEngine === 'private' ? 'public' : 'private',
    });
  };

  const handleSave = (event: any, popup = true) => {
    chatGPTTitleApiPut(state.discussionId, {
      cache: state.discussion?.cache,
      cache_metadata: state.discussion?.cache_metadata,
      sixlayer: undefined,
    })
      .then(() => {
        dispatch({
          type: 'EDIT_UPDATE',
          payload: {
            id: state.discussionId,
            cache: state.discussion?.cache,
            cache_metadata: state.discussion?.cache_metadata,
            sixlayer: undefined,
          },
        });
        if (popup) setSnack('Conversation updated', 'success');
      })
      .catch((err) => {
        console.log(err);
        setSnack('Update error', 'error');
      });
  };

  const handleSaveFeedback = (event: any) => {
    chatGPTFeedbackApiPut(
      state.discussionId,
      state.discussionFeedback.response_id,
      state.discussionFeedback,
      sixlayerFilter
    )
      .then(() => {
        dispatch({
          type: 'EDIT_UPDATE',
          payload: {
            id: state.discussionId,
            cache: state.discussion?.cache,
            cache_metadata: state.discussion?.cache_metadata,
            sixlayer: sixlayerFilter,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        setSnack('Update error', 'error');
      });
  };

  useEffect(() => {
    if (action && typeof action === 'string') {
      dispatch({ type: 'SWITCH_ENGINE_GPT', payload: action });
    }
  }, [action]);

  // auto save after delete
  useEffect(() => {
    if (state.discussionAutoSave) handleSave(null, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.discussionAutoSave]);

  // auto save after feedback
  useEffect(() => {
    if (state.discussionAutoSaveFeedback) handleSaveFeedback(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.discussionAutoSaveFeedback]);

  const classes = useStyles();
  // useEffect(() => {
  //   setLoading(loadingDiscuss);
  //   dispatch({ type: 'INIT', payload: discussions });
  // }, [discussions, loadingDiscuss]);

  const handleOpenCitation = useCallback(
    (doc: CitationPrivate, captions?: CitationCaption[] = []) => {
      setToggleMenuBak(toggleMenu);
      setToggleMenu(false);
      setToggleCitation(true);
      setCitation(doc);
      setCitationCaptions(captions);
    },
    [toggleMenu]
  );

  const handleCloseCitation = useCallback(() => {
    setToggleMenu(toggleMenuBak);
    setToggleCitation(false);
  }, [toggleMenuBak]);

  // const citationTitle = useMemo(() => extractTitle(citation, state.gptEngine as gptEngineType), [
  //   citation,
  //   state.gptEngine,
  // ]);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  if (loading)
    return (
      <>
        <h2>Chat history loading. Please wait...</h2>
        <Loading />
      </>
    );

  return (
    <ChatContextProvider state={state} dispatch={dispatch}>
      <ChatContext.Consumer>
        {() => {
          return (
            <Paper
              style={isChatExpandable ? { height: '100%' } : {}}
              className={action === 'rex' ? classes.rexContainer : classes.genAContainer}
              // style={
              //   action === 'rex'
              //     ? { boxShadow: 'none', height: '100%' }
              //     : { boxShadow: 'none', height: '100%' }
              // }
            >
              {IS_DEBUG_MODE && <ReactJson collapsed={true} src={{ state }} />}
              <FilterContainer>
                <MainChatContainer
                  ref={refPrint}
                  state={state}
                  toggleMenu={toggleMenu}
                  setToggleMenu={setToggleMenu}
                  classes={classes}
                  setToggleCitation={setToggleCitation}
                  handleOpenCitation={handleOpenCitation}
                  toggleCitation={toggleCitation}
                  citation={citation}
                  citationCaptions={citationCaptions}
                  handleCloseCitation={handleCloseCitation}
                  handlePrint={handlePrint}
                  handleSave={handleSave}
                  dispatch={dispatch}
                  action={action}
                  setOpenDialogChangeChat={setOpenDialogChangeChat}
                />
              </FilterContainer>
              {/* </Grid> */}
              <Dialog
                open={openDialogChangeChat}
                // onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  You are going to switch to another conversation. <br />
                  Do you want to continue?
                </DialogTitle>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setOpenDialogChangeChat(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button onClick={handleDialogChangeChat} autoFocus>
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
            </Paper>
          );
        }}
      </ChatContext.Consumer>
    </ChatContextProvider>
  );
}

export default ChatContainer;
