import React from 'react';
import CircularPogressBar from './CircularProgressBar';

function PercentageLoading({ percentage }) {
  return (
    <div
      style={{
        width: '100%',
        minHeight: 250,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
        flexDirection: 'column',
        // position: 'relative',
      }}
    >
      <CircularPogressBar percentage={percentage} />
    </div>
  );
}

export default PercentageLoading;
