import { AskResponse, CitationType } from '../chat';

export type ParsedAnswer = {
  citations: CitationType[];
  markdownFormatText: string;
  assistant: boolean;
};

export function parseAnswer(input: AskResponse): ParsedAnswer {
  if (!input.answer) {
    return {
      citations: [],
      markdownFormatText: '',
      assistant: false,
    };
  }

  if (!input.citations.length) {
    return {
      citations: [],
      markdownFormatText: input.answer,
      assistant: false,
    };
  }

  let answerText = input.answer;

  const citationLinks = answerText.match(/\[(doc\d\d?\d?)]/g);

  const lengthDocN = '[doc'.length;

  const filteredCitations = [] as CitationType[];
  let citationReindex = 0;
  citationLinks?.forEach((link) => {
    // Replacing the links/citations with number
    const citationIndex = link.slice(lengthDocN, link.length - 1);

    const citation = JSON.parse(
      JSON.stringify(input.citations[Number(citationIndex) - 1])
    ) as CitationType;
    if (!filteredCitations.find((c) => c.id === citationIndex)) {
      answerText = answerText.replaceAll(link, ` [${citationReindex + 1}] `);
      citation.id = citationIndex; // original doc index to de-dupe
      citation.reindex_id = citationReindex.toString(); // reindex from 1 for display
      filteredCitations.push(citation);
      citationReindex += 1;
    }
  });

  return {
    citations: filteredCitations,
    markdownFormatText: answerText,
    assistant: false,
  };
}

export function parseAnswerAzure(input: AskResponse): ParsedAnswer {
  let answerText = input.answer;

  const citationLinks = answerText.match(/\[(doc\d\d?\d?)]/g);

  const lengthDocN = '[doc'.length;

  // to track the indices where we have citations
  const citationIndexes = new Set();

  let citationReindex = 0;

  const filteredCitations = [] as CitationType[];

  // Identify existing citation indices
  citationLinks?.forEach((link) => {
    const citationIndex = link.slice(lengthDocN, link.length - 1);
    citationIndexes.add(Number(citationIndex)); // Store citation index as a number
  });

  // Create an ordered array of document indices
  const orderedIndexes = Array.from(citationIndexes).sort((a, b) => a - b);
  // Now we create a mapping for each citation found
  const indexMapping = {};

  // Fill in the gaps and create a mapping
  orderedIndexes.forEach((index, newIndex) => {
    indexMapping[index] = newIndex + 1; // Reindexing starts from 1
  });

  citationLinks?.forEach((link) => {
    // Replacing the links/citations with number
    const citationIndex = link.slice(lengthDocN, link.length - 1);
    const newCitationIndex = indexMapping[Number(citationIndex)];

    // Replace link in answerText
    answerText =
      input?.gpt_Engine === 'private'
        ? answerText.replace(link, `[REF${newCitationIndex}]`)
        : answerText.replace(link, ` [DOC${newCitationIndex}] `);

    // Handle citation data (where input.citations indexes correspond to document indices)
    const citation = input.citations[Number(citationIndex) - 1] as any;

    if (!filteredCitations.find((c) => c?.id === newCitationIndex) && !input.assistant) {
      citation.id = newCitationIndex; // original doc index to de-dupe
      citation.reindex_id = citationReindex.toString(); // reindex from 1 for display
      filteredCitations.push(citation);
      citationReindex += 1;
    }
  });
  return {
    citations: filteredCitations,
    markdownFormatText: answerText,
    assistant: true,
  };
}

export default parseAnswer;
