import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import IsensImage from '../../../assets/IconComponents/IsensImage';
import useAppToken from '../../../app/useAppToken';

const IconBackground = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  height: '20rem',
  //   height: '100vh',
  //   backgroundColor: '#f0f0f0',
});

const WelcomeMessage = styled(Typography)({
  fontSize: '1.2rem',
  // fontWeight: 'bold',
  marginTop: '20px',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  fontFamily: 'Orbitron, sans-serif',
  overflow: 'hidden',
  //   borderRight: '0.15em solid orange',
  //   animation: 'typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite',
  animation: 'typing 1.5s steps(40, end)',
  '@keyframes typing': {
    from: { width: 0 },
    to: { width: '100%' },
  },

  //   '@keyframes blink-caret': {
  //     from: { borderColor: 'transparent' },
  //     to: { borderColor: 'transparent' },
  //     '50%': { borderColor: 'orange' },
  //   },
});

const HomeBackgroundImage = () => {
  const [message, setMessage] = useState('');
  const { appJWT } = useAppToken();
  // console.log('appJWT', appJWT);
  const { user_id, name } = appJWT;
  const fullMessage = `Hello, ${name}.\nWelcome back to iSenS`.replace(/\n/g, '<br />');
  // useEffect(() => {
  //   const fullMessage = `Hello, ${name}.\nWelcome to Isens`;
  //   let index = 0;

  //   const interval = setInterval(() => {
  //     setMessage(fullMessage.slice(0, index).replace(/\n/g, '<br />'));
  //     index += 1;
  //     if (index > fullMessage.length) {
  //       clearInterval(interval);
  //     }
  //   }, 20);

  //   return () => clearInterval(interval);
  // }, [name]);

  return (
    <IconBackground>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '20px',
        }}
      >
        <IsensImage height={200} width={200} style={{ borderRadius: '50%', opacity: '0.9' }} />
        {/* <Typography style={{ fontFamily: 'Orbitron, sans-serif' }}></Typography> */}
        <WelcomeMessage dangerouslySetInnerHTML={{ __html: fullMessage }} />
      </Box>
    </IconBackground>
  );
};

export default HomeBackgroundImage;
