/* eslint-disable react/no-unused-prop-types */
import {
  Column,
  DataTypeProvider,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';

import {
  Grid as ExpressGrid,
  PagingPanel,
  Table,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  TextField,
} from '@material-ui/core';
import { SelectChangeEvent, Switch, ToggleButtonGroup } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import React, { useContext, useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { csvHeader } from '../../../../api/module1';
import TableComponent from '../../../../components/Datagrid/TableComponent';
import Module1Context from '../../Module1Context';
import { Resource } from '../../RunContext';
import useStylesCsvExel from '../UseStyleSettings';
import TranslateSelection, { getLanguageCode } from './TranslateSelection';

type Props = {
  onChange?: any;
  resource: Resource;
};
// set state hide and use it

function FileSettingsExcel(this: any, { onChange, resource }: Props) {
  const classes = useStylesCsvExel();
  const { settings, filename, scope } = resource;

  const { project, setSnack } = useContext(Module1Context);
  const [rows, setRows] = useState<any>([]);
  const [columns, setColumns] = useState<any>([]);

  const [pageSizes] = useState([5, 10, 20, 50]);
  const [checked, setChecked] = React.useState(false);
  const [loader, setLoader] = useState<any>(false);
  const [sheetName, SetSheetName] = useState<any>('');
  const [advanceDisplayShow, setAdvanceDisplayShow] = React.useState<boolean>(false);

  const [state, setState] = useState<any>({
    numberOfColumns: scope?.Sheets[0].NumberOfColumns,
    numberOfRows: scope?.Sheets[0].NumberOfRecords,
    Language: scope?.Sheets[0].Language,
    LanguageCode: getLanguageCode(scope?.Sheets[0].Language || ''),
    targetColumn: scope?.Sheets[0].ColumNames,
    Columns: scope?.Sheets[0].ColumNames,
    iValue: '0',
    translateTo: getLanguageCode(scope?.Sheets[0].Language || '') !== 'en' ? ['en'] : undefined,
    fastm1: 'yes',
  });

  const [display, setDisplay] = useState({
    message: '',
    displayHandleSetRowsColumns: true,
  });

  const [header, setHeader] = useState({ header: '' });

  const [checkedSwitch, setCheckedSwitch] = React.useState(true);
  const [buttonColorChange, setButtonColorChange] = React.useState({
    commonM1: 'default',
    quickM1: 'primary',
  });
  const handleChangeFast = (event: any) => {
    if (event === true) {
      onChange(filename, { fastm1: 'yes' });
      setState({ ...state, fastm1: 'yes' });
    } else {
      onChange(filename, { fastm1: 'no' });
      setState({ ...state, fastm1: 'no' });
    }
  };

  const handleSetRowsColumns = async (myRows) => {
    if (myRows.length === 0) return;
    const myCols = Object.keys(myRows[0]).map((key) => ({
      name: key,
      title: key.toUpperCase(),
    })) as Column[];

    setColumns(myCols);
    setRows(myRows);
  };

  function changeKey(originalKey: any, newKey: any, arr: any) {
    const newArr = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < arr.length; i++) {
      const obj = arr[i];
      obj[newKey] = obj[originalKey];
      delete obj[originalKey];
      newArr.push(obj);
    }
    return newArr;
  }

  function HandleNoHeader(e: any) {
    setChecked(e.target.checked);
    setLoader(true);
    setTimeout(() => {
      // console.log(e.target.name);
      let sheetIvalue: any;
      if (e.target.checked === true && e.target.name === 'headerName') {
        // console.log(scope?.Sheets);
        // console.log('sheetname: ', sheetName);

        if (sheetName === '') {
          sheetIvalue = 0;
        } else {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < scope?.Sheets.length; i++) {
            if (scope?.Sheets[i].SheetName === sheetName) {
              sheetIvalue = i;
            }
          }
        }
        // console.log('sheet no', sheetIvalue);
        const col = [];
        // eslint-disable-next-line no-plusplus
        for (
          let index = 0;
          index < scope?.Sheets[sheetIvalue].ColumnNamesNoHeader.length;
          // eslint-disable-next-line no-plusplus
          index++
        ) {
          const chr = String.fromCharCode(65 + index);
          col.push(chr);
        }
        const values = scope?.Sheets[sheetIvalue].ColumnNamesNoHeader;
        const keys = col;
        // create key-value pair from two different arrays
        const myobj = {};
        // eslint-disable-next-line func-names
        const output = keys.map(function (obj, index) {
          myobj[keys[index]] = values[index];
          return myobj;
        });
        // console.log(col);
        // console.log(values);
        const jsonString = JSON.stringify([output[0]]);
        // handleSetRowsColumns(JSON.parse(jsonString));
        let sheetContent = JSON.parse(scope?.Sheets[sheetIvalue].TextNoHeader);
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < col.length; index++) {
          sheetContent = changeKey(values[index], col[index], sheetContent);
        }
        const jsonString1 = JSON.stringify(sheetContent);
        // console.log(jsonString1);
        const combineJsonArray = JSON.stringify([
          ...JSON.parse(jsonString),
          ...JSON.parse(jsonString1),
        ]);
        // console.log(combineJsonArray);
        handleSetRowsColumns(JSON.parse(combineJsonArray));
        setState({
          numberOfColumns: scope?.Sheets[sheetIvalue].NumberOfColumns,
          numberOfRows: scope?.Sheets[sheetIvalue].NumberOfRecords,
          Language: scope?.Sheets[sheetIvalue]?.Language || '',
          LanguageCode: getLanguageCode(scope?.Sheets[sheetIvalue]?.Language || ''),
          targetColumn: col,
          Columns: col,
          iValue: String(sheetIvalue),
        });
        setRows(JSON.parse(scope?.Sheets[sheetIvalue].TextNoHeader));
        onChange(filename, { Header: false });
      } else {
        setState({
          numberOfColumns: scope?.Sheets[state.iValue].NumberOfColumns,
          numberOfRows: scope?.Sheets[state.iValue].NumberOfRecords,
          Language: scope?.Sheets[state.iValue]?.Language || '',
          LanguageCode: getLanguageCode(scope?.Sheets[sheetIvalue]?.Language || ''),
          targetColumn: scope?.Sheets[state.iValue].ColumNames,
          Columns: scope?.Sheets[state.iValue].ColumNames,
          iValue: String(state.iValue),
        });
        setRows(JSON.parse(scope?.Sheets[state.iValue].Text));
        onChange(filename, { Header: true });
        handleSetRowsColumns(JSON.parse(scope?.Sheets[state.iValue].Text));
      }
      setLoader(false);
      setSnack('Select target name', 'error');
    }, 2000);
  }

  function HandleReg(e: any) {
    // onChange(filename, { [e.target.name]: e.currentTarget.value });
    // eslint-disable-next-line no-console
    // console.log(e.currentTarget.value);
    if (e.target.name === 'sheetname') {
      setChecked(e.target.checked);
      setHeader({ header: '' });
    }
    if (e.target.name === 'header') {
      setHeader({ header: e.currentTarget.value });
    }

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < scope?.Sheets.length; i++) {
      if (scope?.Sheets[i].SheetName === e.currentTarget.value) {
        SetSheetName(scope?.Sheets[i].SheetName);
        setState({
          numberOfColumns: scope?.Sheets[i].NumberOfColumns,
          numberOfRows: scope?.Sheets[i].NumberOfRecords,
          Language: scope?.Sheets[i].Language || '-',
          LanguageCode: getLanguageCode(scope?.Sheets[i].Language || ''),
          targetColumn: scope?.Sheets[i].ColumNames,
          Columns: scope?.Sheets[i].ColumNames,
          iValue: String(i),
        });
        onChange(filename, {
          dataframe: scope?.Sheets[i].Text ? scope?.Sheets[i].Text : scope?.Sheets[0].Text,
        });
        setDisplay({
          message: '',
          displayHandleSetRowsColumns: true,
        });
        if (JSON.parse(scope.Sheets[i].Text.length) > 2) {
          handleSetRowsColumns(JSON.parse(scope?.Sheets[i].Text));
        } else {
          handleSetRowsColumns(JSON.parse('[""]'));
        }

        // dataframe from each sheet
        // onChange(filename, { dataframe: scope?.Sheets[0].Text });
      }
    }
    onChange(filename, { [e.target.name]: e.currentTarget.value });
    if (e.currentTarget.value !== null && e.target.name === 'header') {
      csvHeader({ Text: scope?.Sheets[state.iValue].Text }, e.currentTarget.value)
        .then((payload: any) => {
          // console.log(payload);
          if (payload.message === 'Header Changed') {
            // console.log(JSON.parse(payload.output.Text));
            setDisplay({
              message: '',
              displayHandleSetRowsColumns: true,
            });
            if (JSON.parse(payload.output.Text.length) > 2) {
              handleSetRowsColumns(JSON.parse(payload.output.Text));
            } else {
              handleSetRowsColumns(JSON.parse('[""]'));
            }
            // handleSetRowsColumns(JSON.parse(payload.output.Text));
          } else if (payload.message !== 'Header Changed') {
            // console.log(payload.message);
            setDisplay({
              message: payload.message,
              displayHandleSetRowsColumns: false,
            });
          } else {
            // console.log(payload.message);
            setDisplay({
              message: payload.message,
              displayHandleSetRowsColumns: false,
            });
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    }
  }

  const handleChangeDisplayColumns = (e: React.ChangeEvent<{ value: unknown }>) => {
    const { options } = e.target as HTMLSelectElement;
    const value: string[] = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    // console.log('multiselect: ', value);
    onChange(filename, { columnNames: value });
  };

  useEffect(() => {
    handleSetRowsColumns(JSON.parse(scope?.Sheets[0].Text));
    onChange(filename, {
      dataframe: scope?.Sheets[0].Text,
      language: scope?.Sheets[0].Language,
    });
    onChange(filename, { Header: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Translate
  useEffect(() => {
    // init settings with translation
    if (state.LanguageCode !== 'en') {
      onChange(filename, { translateTo: ['en'] });
    }
  }, [state.LanguageCode]);

  const handleChangeTranslate = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    const listTranslate = typeof value === 'string' ? value.split(',') : value;
    onChange(filename, { translateTo: listTranslate });
    setState({ ...state, translateTo: listTranslate });
  };

  // console.log(JSON.parse(scope?.Sheets[0].Text));
  function handleAdvance() {
    setAdvanceDisplayShow(!advanceDisplayShow);
  }

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        marginTop: '2rem',
      }}
    >
      {/* <ReactJson src={{ state }} collapsed={true} theme="monokai" /> */}
      <Box
        style={{
          display: 'flex',
          border: '1px solid #002A45',
          borderRadius: '5px',
        }}
      >
        <Box
          style={{
            flexBasis: '70%',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            borderRight: '1px solid #002A45',
          }}
        >
          <Box>
            <form noValidate autoComplete="off" style={{ display: 'flex', gap: '20px' }}>
              {advanceDisplayShow && (
                <TextField
                  disabled
                  id="outlined-disabled"
                  label="Project Name"
                  variant="filled"
                  name="filename"
                  className={classes.textField}
                  style={{ flexBasis: '33.33%' }}
                  defaultValue={project.name}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              )}
              <TextField
                label="Capture Name"
                required={advanceDisplayShow}
                variant="outlined"
                name="nameOfCapture"
                inputProps={{
                  maxlength: 20,
                }}
                className={classes.textFieldSecond}
                style={{ flexBasis: '33.33%' }}
                defaultValue=""
                onChange={(e: any) =>
                  onChange(filename, {
                    [e.target.name]: e.currentTarget.value,
                  })
                }
              />
              {advanceDisplayShow && (
                <TextField
                  disabled
                  id="outlined-disabled"
                  label="File Type"
                  variant="filled"
                  name="filetype"
                  className={classes.textField}
                  style={{ flexBasis: '33.33%' }}
                  defaultValue={scope?.FileType}
                  onChange={(e: any) =>
                    onChange(filename, {
                      [e.target.name]: e.currentTarget.value,
                    })
                  }
                />
              )}
            </form>
          </Box>
          <Box>
            <form noValidate autoComplete="off" style={{ display: 'flex', gap: '20px' }}>
              <FormControl
                variant="outlined"
                className={classes.formControl1}
                style={{ flexBasis: '33.33%' }}
              >
                <InputLabel htmlFor="outlined-age-native-simple">Sheet Name</InputLabel>
                <Select
                  native
                  label="Sheet Name"
                  name="sheetname"
                  // style={{ width: 200 }}
                  onChange={HandleReg}
                  defaultValue=""
                >
                  {/* <option aria-label="None" value="" /> */}
                  {scope &&
                    scope.Sheet_names.map((cap: string, index: number) => (
                      <option key={`capture-type-${index}`} value={cap}>
                        {cap}
                      </option>
                    ))}
                </Select>
              </FormControl>
              {advanceDisplayShow && (
                <TextField
                  disabled
                  label="Number of Columns"
                  style={{ flexBasis: '33.33%' }}
                  variant="filled"
                  name="numberOfColumns"
                  className={classes.textField}
                  value={state.numberOfColumns}
                  onChange={HandleReg}
                />
              )}
              <FormControl variant="outlined" style={{ flexBasis: '33.33%' }}>
                <InputLabel htmlFor="outlined-age-native-simple">Translate</InputLabel>
                <TranslateSelection
                  languageCode={state.LanguageCode}
                  onChange={handleChangeTranslate}
                />
              </FormControl>
            </form>
          </Box>
          <Box>
            <Box style={{ display: 'flex', gap: '20px' }}>
              {advanceDisplayShow && (
                <>
                  <TextField
                    disabled
                    label="Number of Rows"
                    style={{ flexBasis: '33.33%' }}
                    variant="filled"
                    name="numberOfRows"
                    className={classes.textField}
                    value={state.numberOfRows}
                    onChange={HandleReg}
                  />
                  <TextField
                    disabled
                    id="outlined-disabled"
                    label="Language"
                    style={{ flexBasis: '33.33%' }}
                    variant="filled"
                    name="language"
                    className={classes.textField}
                    value={state.Language}
                    onChange={HandleReg}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </>
              )}
              {loader && (
                <Skeleton variant="rectangular" height={50} style={{ flexBasis: '33.33%' }} />
              )}
              {!loader && (
                <FormControl variant="outlined" style={{ flexBasis: '33.33%' }}>
                  <InputLabel htmlFor="outlined-age-native-simple">Target Capture</InputLabel>
                  <Select
                    native
                    label="Target Capture"
                    name="targetColumn"
                    onChange={HandleReg}
                    defaultValue="--select target column--"
                  >
                    {/* <option aria-label="None" value="" /> */}
                    <option aria-label="None" selected disabled>
                      --select target column--
                    </option>
                    {scope &&
                      state.targetColumn.map((cap: string, index: number) => (
                        <option key={`capture-type-${index}`} value={cap}>
                          {cap}
                        </option>
                      ))}
                  </Select>
                  <span style={{ color: 'red' }}>
                    Select Column which contains Requirement text*
                  </span>
                </FormControl>
              )}
            </Box>
          </Box>
        </Box>
        <Box
          style={{
            flexBasis: '30%',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          {advanceDisplayShow && (
            <form
              style={{
                width: '100%',
                display: 'flex',
                gap: '1rem',
                alignItems: 'center',
              }}
              noValidate
              autoComplete="off"
            >
              <Checkbox
                checked={checked}
                onChange={HandleNoHeader}
                name="headerName"
                color="primary"
                style={{ padding: 0 }}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <span style={{ color: '#002A45', fontWeight: 'bold' }}>No Header</span>
            </form>
          )}

          <FormControl variant="outlined" style={{ width: '100%', height: '100%' }}>
            <InputLabel
              htmlFor="outlined-age-native-simple"
              style={{
                color: '#002A45',
                fontWeight: 'bold',
                backgroundColor: 'white',
              }}
            >
              Display
            </InputLabel>
            <Select
              native
              multiple
              label="Display"
              name="Columns"
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: 'white',
              }}
              onChange={handleChangeDisplayColumns}
              // onChange={handleChangeMultiple}
              defaultValue=""
            >
              <option aria-label="None" value="" selected disabled />

              {scope &&
                state.Columns.map((cap: string, index: number) => (
                  <option
                    style={{ color: '#002A45' }}
                    type="checkbox"
                    key={`capture-type-${index}`}
                    value={cap}
                  >
                    {cap}
                  </option>
                ))}
            </Select>
            <span>(Use Ctrl Key for multiple select)</span>
          </FormControl>
        </Box>
      </Box>

      {display.displayHandleSetRowsColumns && (
        <Box style={{ backgroundColor: '#EEF1F9', border: '1px solid #002A45' }}>
          {advanceDisplayShow && (
            <ExpressGrid rows={rows} columns={columns}>
              <DataTypeProvider
                for={state.Columns}
                formatterComponent={({ value }) => <span title={value}>{value}</span>}
              />
              <SortingState />
              <IntegratedSorting />
              <PagingState defaultCurrentPage={0} defaultPageSize={5} />
              <IntegratedPaging />
              <Table tableComponent={TableComponent} />
              <TableHeaderRow showSortingControls />

              <PagingPanel pageSizes={pageSizes} />
            </ExpressGrid>
          )}
        </Box>
      )}
      <div style={{ overflow: 'auto' }}>
        <div style={{ float: 'right' }}>
          {/* <span>
            <span>
              <b>Normal M1</b>
            </span>
            <Switch
              checked={checkedSwitch}
              onChange={handleChangeFast}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <span>
              <b>Fast M1</b>
            </span>
          </span>
          &emsp;&emsp; */}
          <ToggleButtonGroup size="small">
            <Button
              value="no"
              variant="contained"
              color={buttonColorChange.commonM1}
              onClick={() => {
                handleChangeFast(false);
                setButtonColorChange({
                  commonM1: 'primary',
                  quickM1: 'default',
                });
              }}
            >
              Classic M1
            </Button>
            <Button
              value="yes"
              variant="contained"
              color={buttonColorChange.quickM1}
              onClick={() => {
                handleChangeFast(true);
                setButtonColorChange({
                  commonM1: 'default',
                  quickM1: 'primary',
                });
              }}
            >
              Quick M1
            </Button>
          </ToggleButtonGroup>
          &emsp;&emsp;&emsp;
          <Button
            variant="contained"
            color="primary"
            // style={{ float: 'left' }}
            onClick={handleAdvance}
          >
            Advanced Setting
          </Button>
        </div>
      </div>
    </Box>
  );
}

export default FileSettingsExcel;
