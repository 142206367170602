import React from 'react';

const DetailforTransalator = (props) => {
  const { source_file, translatedLang, dataofcreation } = props;
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
      <h3>Source File: {source_file}</h3>
      <h3>Translated Language: {translatedLang}</h3>
      <h3>Date of Creation: {dataofcreation}</h3>
    </div>
  );
};

export default DetailforTransalator;
