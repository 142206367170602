import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Button, Typography } from '@mui/material';
import { FileIcon } from '../../../containers/Project/ProjectContainer/components/ProjectFolder/Files';

export const RowItem = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'grid',
  width: '100%',
  padding: '0.5rem',
  fontSize: '0.89rem',
  gridTemplateColumns: '0.5fr 1.5fr 0.5fr 0.5fr 1fr 1fr',
  justifyContent: 'end',
  gap: '0.5rem',
  borderBottom: '1px solid #e0e0e0',
}));

const ServiceItem = (props) => {
  const { data } = props;
  return (
    <>
      <RowItem>
        <div>{data.id}</div>
        <div>
          <div style={{ display: 'flex', gap: '0.5rem' }}>
            <FileIcon filename={data.resources[0].filename} />
            <Typography style={{ fontSize: '0.89rem' }}>{data.resources[0].filename}</Typography>
          </div>
          <Typography style={{ fontSize: '0.89rem' }}>
            {data.resources[0].settings.nameOfCapture}
          </Typography>
        </div>
        <div>
          <Typography style={{ fontSize: '0.89rem' }}>{data.action}</Typography>
        </div>
        <div>
          <Button style={{ backgroundColor: 'rgb(23, 193, 232)', color: 'rgb(255, 255, 255)' }}>
            {data.status === 'progress' ? data.progress_percent : 'view'}
          </Button>
        </div>
        <div>
          <Typography style={{ fontSize: '0.89rem' }}>{data.created_by.name}</Typography>
        </div>
        <div>
          <Typography style={{ fontSize: '0.89rem' }}>{data.created_at}</Typography>
        </div>
      </RowItem>
    </>
  );
};

export default ServiceItem;
