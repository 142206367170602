import React, { memo, useContext, useRef, useState } from 'react';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import ArticleIcon from '@mui/icons-material/Article';
import CloseIcon from '@mui/icons-material/Close';
import ProjectFolder from '../containers/Project/ProjectContainer/components/ProjectFolder/ProjectFolder';
import useUpload from './Hooks/useUpload';
import ProjectContext from '../containers/Project/ProjectContext';
import DragAndDrop from '../components/Core/DragAndDrop';
import WebSocketLoading from '../components/Core/WebSocketLoading';
import LayoutContainerContext from './LayoutContainerContext';
// import CSVFileSetting from './core/CSVFileSetting';
// import ExcelFileSetting from './core/ExcelFileSetting';
// import PdfFileSetting from './core/PdfFileSetting';
// import ProjectFolder from '../../Project/ProjectContainer/components/ProjectFolder/ProjectFolder';

const Main = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
  width: '100%',
  // margin: '1rem auto',
  margin: 'auto',
  [theme.breakpoints.up('lg')]: {
    // margin: '4rem auto',
    width: '80%',
  },
  [theme.breakpoints.up('xl')]: {
    // margin: '6rem auto',
    width: '70%',
  },
}));

const Header = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  height: '3rem',
  width: '100%',
  gap: '5px',
  // backgroundColor: '#002A45',
  color: '002A45',
  margin: '0 auto',
}));

const MainContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '2fr 3fr',
  width: '100%',
  height: '20rem',
  backgroundColor: 'rgb(255, 255, 255)',
  border: '1px solid #ccd6f2',
  margin: '0 auto',
  [theme.breakpoints.up('lg')]: {
    height: '30rem',
  },
  [theme.breakpoints.up('xl')]: {
    height: '25rem',
  },
}));

const FolderContainer = styled((props) => <Box {...props} />)(({ theme }) => ({
  width: '100%',
  height: '20rem',
  backgroundColor: 'rgb(255, 255, 255)',
  border: '1px solid #ccd6f2',
  margin: '0 auto',
  [theme.breakpoints.up('lg')]: {
    height: '30rem',
  },
  [theme.breakpoints.up('xl')]: {
    height: '25rem',
  },
}));

const DivSection = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'center',
  // alignItems: 'center',
  gap: '0.5rem',
  height: '100%',
  padding: '1rem',
  width: '100%',
}));

const FileInputBox = styled((props) => <Box {...props} />)(({ theme }) => ({
  margin: '0 auto',
  padding: '0.5rem',
  display: 'flex',
  gap: '1rem',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  // borderRight: '1px solid #ccd6f2',
  // borderRadius: '5px',
  width: '80%',
  height: '80%',
}));

const ChosseBtn = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  flex: 1,
  width: '100%',
  // height: '4rem',
}));

const Btn = styled((props) => <Button {...props} />)(({ theme }) => ({
  margin: '0 auto',
  // borderRadius: '5px',
  // flex: 1,
  // width: '100%',
  minHeight: '3rem',
  minWidth: '3rem',
  // padding: '0.1rem',
  // color: 'rgba(147, 103, 103, 0.54)',
  backgroundColor: 'rgb(23, 193, 232)',
  color: 'rgb(255, 255, 255)',
  display: 'flex',
  borderRadius: '0.5rem',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all 0.3s ease',
  boxShadow:
    'rgba(20, 20, 20, 0.12) 0rem 0.25rem 0.375rem -0.0625rem, rgba(20, 20, 20, 0.07) 0rem 0.125rem 0.25rem -0.0625rem',
}));

const DragFile = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 2,
  width: '100%',
  height: '100%',
  border: '1px dashed #002A45',
}));

const ModuleLayout = ({ moduleData }) => {
  const [scopeSettings, setScopeSettings] = useState([]);
  const inputRef = useRef();
  const {
    enableDescription,
    renderDescription,
    state,
    renderAnalysis,
    numberOfFilesSupport,
    renderHeader,
    select = 'single',
    renderFiles,
    FindeFileDetails,
    handleChangeInDragefile,
    handleUploadFile,
    disableScopeSettings = false,
    serviceName,
    enableHeader,
    serviceFor,
  } = moduleData;

  const {
    inputFileOpen,
    setInputFileOpen,
    isAnalysis,
    setIsAnalysis,
    selectionFile,
    setSelectionFile,
    setSelectedChildIndex,
    workload,
    setWorkload,
    loading,
  } = state;

  console.log('workload', workload);

  const handleScopeSettings = (data) => {
    // FindeFileDetails();
    // console.log('handleScopeSettings', data);

    if (data && !disableScopeSettings) {
      const promises = data.map((item) => {
        return () => {
          return FindeFileDetails(item.filename, item.filename, item.container);
        };
      });

      Promise.all(promises.map((p) => p()))
        .then((responses) => {
          // Handle the responses here

          const response = responses.map((fileDetailsData) => {
            return fileDetailsData?.output;
          }) as [];
          setScopeSettings(response);
          setInputFileOpen(false);
        })
        .catch((error) => {
          // Handle any errors here
          console.error(error);
        });
    } else {
      setScopeSettings(data);
      setInputFileOpen(false);
    }
  };

  // console.log('scopeSettings', scopeSettings);

  const filehandleChangeFiles = async (event: any) => {
    try {
      const arr = [];
      arr.push(event.target.files[0]);
      const data = await handleUploadFile(arr);
      if (data) {
        setScopeSettings((pre) => {
          return [...pre, data];
        });
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const UploadFile = () => {
    inputRef.current.click();
  };

  const draghandleChangeFiles = async (event: any) => {
    try {
      const arr = [];
      arr.push(event);
      // console.log(arr);
      const data = await handleChangeInDragefile(arr);
      if (data) {
        setScopeSettings((pre) => {
          return [...pre, data];
        });
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const propsForFiles = {
    isChild: true,
    setSelectionFile,
    numberOfFilesSupport,
    handleScopeSettings,
    select,
    renderCross: () => {
      return (
        <>
          <IconButton
            onClick={() => {
              setInputFileOpen(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </>
      );
    },
  };

  const propsForWebSocket = {
    serviceFor,
    serviceName,
    workload,
    setSelectedChildIndex,
  };

  return (
    <Main>
      {!inputFileOpen ? (
        <>
          {enableHeader && <Header>{renderHeader()}</Header>}
          <MainContainer>
            <DivSection
              style={{
                borderRight: '1px solid #ccd6f2',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {/* <Header>{renderHeader()}</Header> */}
              <FileInputBox>
                <DragFile>
                  <DragAndDrop onChange={draghandleChangeFiles}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        width: '100%',
                      }}
                    >
                      <input type="file" ref={inputRef} hidden onChange={filehandleChangeFiles} />
                      <Typography>Drag & Drop here</Typography>
                      <Typography fontWeight="bold">or</Typography>
                      <Btn
                        onClick={() => {
                          UploadFile();
                        }}
                      >
                        Upload file
                      </Btn>
                    </div>
                  </DragAndDrop>
                </DragFile>
                <ChosseBtn>
                  <Btn
                    onClick={() => {
                      setInputFileOpen(true);
                    }}
                  >
                    Select file
                  </Btn>
                </ChosseBtn>
              </FileInputBox>
            </DivSection>
            <DivSection>
              {loading && <Typography>Loading...</Typography>}
              {!loading &&
                scopeSettings.length === 0 &&
                enableDescription &&
                !workload.workload &&
                renderDescription()}
              {!loading &&
                scopeSettings.length > 0 &&
                !workload.workload &&
                renderAnalysis({
                  scopeSettings,
                  numberOfFilesSupport,
                  serviceName,
                  serviceFor,
                  setWorkload,
                })}
              {workload.workload > 0 && (
                <>
                  <WebSocketLoading {...propsForWebSocket} />
                </>
              )}
            </DivSection>
          </MainContainer>
        </>
      ) : (
        <FolderContainer>{renderFiles({ ...propsForFiles })}</FolderContainer>
      )}
    </Main>
  );
};

export default memo(ModuleLayout);
