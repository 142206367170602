import React, { memo, useCallback, useContext, useEffect, useMemo } from 'react';
import { Box, List, ListItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import { InputField } from '../Home/components/Homepage';
import HomeContext from '../ContextMager/HomeContext';
import useKnowledge360Api from '../HOC/useKnowledge360Api';
import KnowledgeResultContainer from './TextAnalysisResultContainer/KnowledgeResultContainer';
import NoResultsFound from '../../components/Core/NoResultsFound';

const Container = styled((props) => <Box {...props} />)(({ theme }) => ({
  padding: '0rem 1rem 1rem 1rem',
  width: '100%',
  height: 'calc(100% - 5px)',
  maxHeight: 'calc(100% - 5px)',
  overflow: 'auto',
  // display: 'flex',
  // flexDirection: 'column',
  // justifyContent: 'center',
  // alignItems: 'center',
  // maxHeight: 'calc(100% - 5px)',
  // overflow: 'auto',
}));

const serviceButton = ['Content Search', 'Similarity Search', 'Referents Search', 'People Search'];

const ServiceList = styled((props) => <List {...props} />)(({ theme }) => ({
  display: 'flex',
  overflowX: 'auto',
  //   maxWidth: '100%',
  margin: 0,
  scrollBehavior: 'smooth',
  scrollbarWidth: 'none',
}));

const ServiceListItem = styled((props) => <ListItem {...props} />)(({ theme, isSelected }) => ({
  display: 'inline-flex',
  justifyContent: 'center',
  width: 'max-content',
  maxWidth: '100%',
  gap: '0.3rem',
  // flexBasis: '16%',
  //   flexGrow: 0,
  //   flexShrink: 0,
  // flex: 1,
  cursor: 'pointer',
  // maxWidth: '24%',
  textAlign: 'center',
  borderBottom: isSelected ? '5px solid #DC3223' : 'none',
  color: isSelected ? '#DC3223' : '#002A45',
}));

const ToolContainer = memo(
  ({ toggleCollapse, text, handleApiCall, selectedIndex, setSelectedIndex, isCollapsed }) => {
    //   const rexResult = {
    //     text: 'REX Cards',
    //     data: [],
    //   };
    const [enterValue, setEnterValue] = React.useState(text ?? '');
    // const [selectedIndex, setSelectedIndex] = React.useState(0);

    const onChangeOption = (payload) => {
      const { index, item } = payload;
      setSelectedIndex(index);
    };

    const SubmitSearch = useCallback(() => {
      handleApiCall(enterValue, '/home/Knowledge360', serviceButton[selectedIndex] ?? '', false);
    }, [enterValue, handleApiCall]);

    const handleTextChange = useCallback((e) => {
      setEnterValue(e.target.value);
    }, []);

    return (
      <div
        style={{
          // width: '70%',
          // minWidth: '70%',
          width: '70rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          // maxWidth: '50%',
          // padding: '0.5rem',
          // position: 'sticky',
          // top: '0',
          // zIndex: 999,
          // backgroundColor: '#fafafa',
        }}
      >
        <InputField
          filterType="Rex360"
          isCollapsed={isCollapsed}
          toggleCollapse={toggleCollapse}
          onChangeOption={onChangeOption}
          SubmitSearch={SubmitSearch}
          enterValue={enterValue}
          options={serviceButton}
          handleTextChange={handleTextChange}
        />
        {/* <ServiceList>
          {serviceButton.map((button, index) => (
            <ServiceListItem
              key={index}
              isSelected={index === selectedIndex}
              onClick={() => {
                setSelectedIndex(index);
              }}
            >
              {button}
            </ServiceListItem>
          ))}
        </ServiceList> */}
      </div>
    );
  }
);

const Knowledge360Result = memo(({ toggleCollapse, isCollapsed }) => {
  const { response } = useContext(HomeContext);
  //   const { data: reult, text } = rexResult;
  //   const { data, result } = reult || {};
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const { text, error, name } = response;

  useEffect(() => {
    const index = serviceButton.indexOf(name);
    setSelectedIndex(index);
  }, [response]);

  //   console.log(response);

  //   console.log('text: MemomiToolContainer render');

  //   const resultData = useMemo(() => {
  //     if (data) {
  //       return { data, text, result };
  //     }
  //     return { data: [], result: false };
  //   }, [data, text]);

  //   const { handleApiCall, loading, error, progressTime, setSideFilter } = useRexApiCall();
  const {
    progressTime: progressTimeKnowledge,
    handleMuliApiCall,
    knowlede360loading,
    nameClick,
    handleClickText2DatasetNew,
  } = useKnowledge360Api();

  return (
    <>
      {knowlede360loading && (
        <>
          <Box sx={{ width: '100%', height: '5px' }}>
            <LinearProgress
              variant="determinate"
              color="secondary"
              value={progressTimeKnowledge + 10}
            />
          </Box>
        </>
      )}
      <Container>
        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '2rem' }}>
          <ToolContainer
            toggleCollapse={toggleCollapse}
            text={text}
            isCollapsed={isCollapsed}
            handleApiCall={handleMuliApiCall}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
          />{' '}
        </div>
        {error ? (
          <NoResultsFound />
        ) : (
          <KnowledgeResultContainer
            response={response}
            callOperation={response.name}
            nameClick={nameClick}
            handleClickText2DatasetNew={handleClickText2DatasetNew}
          />
        )}
      </Container>
    </>
  );
});

export default memo(Knowledge360Result);
