import React from 'react';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const MuiButton = styled((props) => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  minWidth: '9rem',
  padding: '0.5rem',
  gap: '0.5rem',
  boxShadow: '0 2px 20px 0 rgb(0 0 0 / 10%)',
  backgroundColor: 'rgb(255, 255, 255)',
  borderRadius: '5px',
  border: '1px solid #ccd6f2',
  cursor: 'pointer',
  // margin: '0 auto',
}));

const MuiButtonLeft = styled((props) => <Box {...props} />)(({ theme }) => ({
  // width: '2rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  // margin: '0 auto',
}));

const MuiButtonRight = styled((props) => <Box {...props} />)(({ theme }) => ({
  // width: '6rem',
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  // margin: '0 auto',
}));

const HeaderButton = (props) => {
  const { icons, name } = props;
  return (
    <>
      <MuiButton>
        <MuiButtonLeft>{icons}</MuiButtonLeft>
        <MuiButtonRight>{name}</MuiButtonRight>
      </MuiButton>
    </>
  );
};

export default HeaderButton;
