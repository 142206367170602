import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

interface FileContainerEvent {
  onChange: any;
  children: any;
}

const DragAndDrop = ({ onChange, children }: FileContainerEvent) => {
  const onDrop = useCallback(
    (acceptedFiles: any) => {
      onChange(acceptedFiles[0]);
    },
    [children]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop, noClick: true });

  return (
    <div style={{ width: '100%', height: '100%' }} {...getRootProps()}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
};

export default DragAndDrop;
