/* eslint-disable no-alert */
import { Divider, ListItem, ListItemIcon, ListItemText, TextField } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import PublicIcon from '@mui/icons-material/Public';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { ListItemButton } from '@mui/material';
import React, { memo, useState } from 'react';
import { chatGetConversationById, chatGPTTitleApiPut } from '../../../../api/chat_gpt';
import { DiscussionType } from './chat';

type Props = {
  discussion: DiscussionType;
  classes: any;
  dispatch: any;
  state: any;
  setSnack: any;
  setModalOpenId: any;
  onClick: any;
  title: string;
  isActiveDiscussion: boolean;
  listItemDate: any;
};

const DiscussListItem = ({
  discussion,
  classes,
  dispatch,
  state,
  setSnack,
  setModalOpenId,
  onClick,
  title,
  isActiveDiscussion,
  listItemDate,
}: Props) => {
  const [hoverId, setHoverId] = useState(0);
  const [toggleSaveEdit, setToggleSaveEdit] = useState<'edit' | 'save'>('edit');
  const [discussionEditId, setDiscussionEditId] = useState<number>(0);

  const myTitle = title;
  const activeClass = isActiveDiscussion ? classes.activeButton : '';

  return (
    <>
      {listItemDate}
      <ListItem
        className={`${classes.li} ${activeClass}`}
        selected={isActiveDiscussion}
        onMouseOver={() => {
          setHoverId(discussion.id);
        }}
        onMouseOut={() => {
          setHoverId(0);
        }}
      >
        {toggleSaveEdit === 'save' && discussionEditId === discussion.id ? (
          <>
            <TextField
              id={`discussion-title-${discussion.id}`}
              variant="standard"
              defaultValue={myTitle}
              style={{ width: '100%' }}
            />
            <ListItemIcon
              style={{
                background: '#DC3223 0% 0% no-repeat padding-box !important',
                color: 'white',
              }}
            >
              <SaveIcon
                className={classes.icon}
                onClick={() => {
                  const newTitle = document.getElementById(`discussion-title-${discussion.id}`)
                    .value;
                  chatGPTTitleApiPut(discussion.id, { title: newTitle })
                    .then(() => {
                      setToggleSaveEdit('edit');
                      dispatch({
                        type: 'EDIT_UPDATE',
                        payload: {
                          id: discussion.id,
                          title: newTitle,
                        },
                      });
                    })
                    .catch((err) => {
                      console.log(err);
                      setSnack('Update error', 'error');
                    });
                }}
                style={{ marginRight: '5px' }}
              />
              <CancelIcon
                className={classes.icon}
                onClick={(e) => {
                  setToggleSaveEdit('edit');
                  dispatch({ type: 'EDIT_CLICK', payload: 0 });
                }}
              />
            </ListItemIcon>
          </>
        ) : (
          <>
            <ListItemButton
              title={myTitle}
              onClick={async () => {
                if (state.loadingPrompt) {
                  alert('Please wait for the current request to finish');
                  return;
                }
                await chatGetConversationById(discussion.id)
                  .then((response) => {
                    dispatch({
                      type: 'EDIT_UPDATE',
                      payload: {
                        id: discussion.id,
                        cache: response?.cache,
                        cache_metadata: response?.cache_metadata,
                        sixlayer: undefined,
                      },
                    });
                  })
                  .catch((err) => {
                    console.log(err);
                    setSnack('Update error', 'error');
                  });
                dispatch({
                  type: 'SELECT_DISCUSS',
                  payload: { id: discussion.id, action: discussion.action },
                });
                onClick();
              }}
            >
              {discussion.action && ['private', 'rex'].includes(discussion.action) ? (
                <VerifiedUserIcon style={{ marginRight: '5px' }} />
              ) : (
                <PublicIcon style={{ marginRight: '5px' }} />
              )}
              <ListItemText primary={myTitle.substring(0, 24)} />
            </ListItemButton>
            <ListItemIcon
              style={{
                background: '#DC3223 0% 0% no-repeat padding-box !important',
                color: 'white',
                visibility:
                  isActiveDiscussion || Number(discussion.id) === Number(hoverId)
                    ? 'visible'
                    : 'hidden',
              }}
            >
              <EditOutlinedIcon
                className={classes.icon}
                onClick={() => {
                  setToggleSaveEdit('save');
                  setDiscussionEditId(discussion.id);
                }}
              />
              <DeleteForeverOutlinedIcon
                className={classes.icon}
                onClick={() => {
                  setModalOpenId(discussion.id);
                  setDiscussionEditId(discussion.id);
                }}
              />
            </ListItemIcon>
          </>
        )}
      </ListItem>
    </>
  );
};

export default memo(DiscussListItem);
